.text-primary { color: #1F2937; }
.text-secundary { color: #202020; }

.hover:hover {
    background-color: #e4e4e4;
}

.text-danger {
    color: #FF3368 !important;
}
.absolute { position: absolute !important; }
.relative { position:  relative !important; }
.text-align-center { text-align: center; }
.text-center { text-align: center !important; }
.text-end { text-align: end; }
.flex { display: flex !important; }
.flex-col { flex-direction: column; }
.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.between { justify-content: space-between; }
.items-center { align-items: center; }
.items-start { align-items: flex-start; }
.items-end { align-items: flex-end; }

.flex-start { justify-content: flex-start; }
.flex-end { align-items: flex-end; }
.content-center { justify-content: center; }
.content-end { justify-content: flex-end; }

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.item-center{ display: flex; justify-content: center; align-items: center;}
.align-start{align-content: flex-start}
.wrap {
    flex-wrap: wrap;
}

.pointer { cursor: pointer; }

.mt-05 { margin-top: .5rem !important; }
.mt-1 { margin-top: 1rem !important; }
.mt-2 { margin-top: 2rem !important; }
.mt-3 { margin-top: 3rem !important; }
.mt-4 { margin-top: 4rem !important; }
.mt-5 { margin-top: 5rem !important; }
.mt-6 { margin-top: 6rem !important; }
.mt-7 { margin-top: 7rem !important; }
.mt-8 { margin-top: 8rem !important; }
.mt-9 { margin-top: 9rem !important; }
.mt-10 { margin-top: 10rem !important; }
.mt-11 { margin-top: 11rem !important; }
.mt-12 { margin-top: 12rem !important; }
.mt-13 { margin-top: 13rem !important; }
.mt-14 { margin-top: 14rem !important; }
.mt-15 { margin-top: 15rem !important; }

.mb-1 { margin-bottom: 0.25rem !important; }
.mb-2 { margin-bottom: 0.5rem !important; }
.mb-3 { margin-bottom: 1rem !important; }
.mb-4 { margin-bottom: 1.5rem !important; }
.mb-5 { margin-bottom: 2rem !important; }
.mb-6 { margin-bottom: 2.5rem !important; }
.mb-7 { margin-bottom: 3rem !important; }
.mb-8 { margin-bottom: 3.5rem !important; }
.mb-9 { margin-bottom: 4rem !important; }
.mb-10 { margin-bottom: 4.5rem !important; }
.mb-11 { margin-bottom: 5rem !important; }
.mb-12 { margin-bottom: 5.5rem !important; }
.mb-13 { margin-bottom: 6rem !important; }
.mb-14 { margin-bottom: 6.5rem !important; }
.mb-15 { margin-bottom: 7rem !important; }


.ml-0 { margin-left: 0 !important; }
.ml-1 { margin-left: 0.25rem !important; }
.ml-2 { margin-left: 0.5rem !important; }
.ml-3 { margin-left: 1rem !important; }
.ml-4 { margin-left: 1.5rem !important; }
.ml-5 { margin-left: 2rem !important; }
.ml-6 { margin-left: 2.5rem !important; }
.ml-7 { margin-left: 3rem !important; }
.ml-8 { margin-left: 3.5rem !important; }
.ml-9 { margin-left: 4rem !important; }
.ml-10 { margin-left: 4.5rem !important; }
.ml-11 { margin-left: 5rem !important; }
.ml-12 { margin-left: 5.5rem !important; }
.ml-13 { margin-left: 6rem !important; }
.ml-14 { margin-left: 6.5rem !important; }
.ml-15 { margin-left: 7rem !important; }

.mr-1 { margin-right: 0.25rem !important; }
.mr-2 { margin-right: 0.5rem !important; }
.mr-3 { margin-right: 1rem !important; }
.mr-4 { margin-right: 1.5rem !important; }
.mr-5 { margin-right: 2rem !important; }
.mr-6 { margin-right: 2.5rem !important; }
.mr-7 { margin-right: 3rem !important; }
.mr-8 { margin-right: 3.5rem !important; }
.mr-9 { margin-right: 4rem !important; }
.mr-10 { margin-right: 4.5rem !important; }
.mr-11 { margin-right: 5rem !important; }
.mr-12 { margin-right: 5.5rem !important; }
.mr-13 { margin-right: 6rem !important; }
.mr-14 { margin-right: 6.5rem !important; }
.mr-15 { margin-right: 7rem !important; }



.fw-100 { font-weight: 100 !important; }
.fw-200 { font-weight: 200 !important; }
.fw-300 { font-weight: 300 !important; }
.fw-400 { font-weight: 400 !important; }
.fw-500 { font-weight: 500 !important; }
.fw-600 { font-weight: 600 !important; }
.fw-700 { font-weight: 700 !important; }
.fw-800 { font-weight: 800 !important; }
.fw-900 { font-weight: 900 !important; }


.fs-10 { font-size: 10px !important; }
.fs-12 { font-size: 12px !important; }
.fs-13 { font-size: 13px !important; }
.fs-14 { font-size: 14px !important; }
.fs-16 { font-size: 16px !important; }
.fs-18 { font-size: 18px !important; }
.fs-20 { font-size: 20px !important; }
.fs-22 { font-size: 22px !important; }
.fs-24 { font-size: 24px !important; }
.fs-28 { font-size: 28px !important; }
.fs-32 { font-size: 32px !important; }
.fs-36 { font-size: 36px !important; }
.fs-40 { font-size: 40px !important; }
.fs-48 { font-size: 48px !important; }
.fs-56 { font-size: 56px !important; }
.fs-64 { font-size: 64px !important; }
.fs-72 { font-size: 72px !important; }


.gap-0 { gap: 0 !important; }
.gap-1 { gap: 0.25rem !important; }
.gap-2 { gap: 0.5rem !important; }
.gap-3 { gap: 1rem !important; }
.gap-4 { gap: 1.5rem !important; }
.gap-5 { gap: 2rem !important; }
.gap-6 { gap: 2.5rem !important; }
.gap-7 { gap: 3rem !important; }
.gap-8 { gap: 3.5rem !important; }
.gap-9 { gap: 4rem !important; }
.gap-10 { gap: 4.5rem !important; }
.gap-11 { gap: 5rem !important; }
.gap-12 { gap: 5.5rem !important; }
.gap-13 { gap: 6rem !important; }
.gap-14 { gap: 6.5rem !important; }
.gap-15 { gap: 7rem !important; }

.p-0 { padding: 0 !important; }
.p-1 { padding: 0.25rem !important; }
.p-2 { padding: 0.5rem !important; }
.p-3 { padding: 1rem !important; }
.p-4 { padding: 1.5rem !important; }
.p-5 { padding: 2rem !important; }
.p-6 { padding: 2.5rem !important; }
.p-7 { padding: 3rem !important; }
.p-8 { padding: 3.5rem !important; }
.p-9 { padding: 4rem !important; }
.p-10 { padding: 4.5rem !important; }
.p-11 { padding: 5rem !important; }
.p-12 { padding: 5.5rem !important; }
.p-13 { padding: 6rem !important; }
.p-14 { padding: 6.5rem !important; }
.p-15 { padding: 7rem !important; }

.r-0 { right: 0.25rem !important; }
.r-1 { right: 0.5rem !important; }
.r-2 { right: 1rem !important;; }
.r-3 { right: 1.5rem !important; }
.r-4 { right: 2rem !important; }
.r-5 { right: 2.5rem !important; }

.py-0 { padding-top: 0 !important; padding-bottom: 0 !important; }
.py-1 { padding-top: 0.25rem !important; padding-bottom: 0.25rem !important; }
.py-2 { padding-top: 0.5rem !important; padding-bottom: 0.5rem !important; }
.py-3 { padding-top: 1rem !important; padding-bottom: 1rem !important; }
.py-4 { padding-top: 1.5rem !important; padding-bottom: 1.5rem !important; }
.py-5 { padding-top: 2rem !important; padding-bottom: 2rem !important; }
.py-6 { padding-top: 2.5rem !important; padding-bottom: 2.5rem !important; }
.py-7 { padding-top: 3rem !important; padding-bottom: 3rem !important; }
.py-8 { padding-top: 3.5rem !important; padding-bottom: 3.5rem !important; }
.py-9 { padding-top: 4rem !important; padding-bottom: 4rem !important; }
.py-10 { padding-top: 4.5rem !important; padding-bottom: 4.5rem !important; }
.py-11 { padding-top: 5rem !important; padding-bottom: 5rem !important; }
.py-12 { padding-top: 5.5rem !important; padding-bottom: 5.5rem !important; }
.py-13 { padding-top: 6rem !important; padding-bottom: 6rem !important; }
.py-14 { padding-top: 6.5rem !important; padding-bottom: 6.5rem !important; }
.py-15 { padding-top: 7rem !important; padding-bottom: 7rem !important; }


.px-0 { padding-left: 0 !important; padding-right: 0 !important; }
.px-1 { padding-left: 0.25rem !important; padding-right: 0.25rem !important; }
.px-2 { padding-left: 0.5rem !important; padding-right: 0.5rem !important; }
.px-3 { padding-left: 1rem !important; padding-right: 1rem !important; }
.px-4 { padding-left: 1.5rem !important; padding-right: 1.5rem !important; }
.px-5 { padding-left: 2rem !important; padding-right: 2rem !important; }
.px-6 { padding-left: 2.5rem !important; padding-right: 2.5rem !important; }
.px-7 { padding-left: 3rem !important; padding-right: 3rem !important; }
.px-8 { padding-left: 3.5rem !important; padding-right: 3.5rem !important; }
.px-9 { padding-left: 4rem !important; padding-right: 4rem !important; }
.px-10 { padding-left: 4.5rem !important; padding-right: 4.5rem !important; }
.px-11 { padding-left: 5rem !important; padding-right: 5rem !important; }
.px-12 { padding-left: 5.5rem !important; padding-right: 5.5rem !important; }
.px-13 { padding-left: 6rem !important; padding-right: 6rem !important; }
.px-14 { padding-left: 6.5rem !important; padding-right: 6.5rem !important; }
.px-15 { padding-left: 7rem !important; padding-right: 7rem !important; }

.pb-1 { padding-bottom: 0.25rem !important; }
.pb-2 { padding-bottom: 0.5rem !important; }
.pb-3 { padding-bottom: 1rem !important; }
.pb-4 { padding-bottom: 1.5rem !important; }
.pb-5 { padding-bottom: 2rem !important; }
.pb-6 { padding-bottom: 2.5rem !important; }
.pb-7 { padding-bottom: 3rem !important; }
.pb-8 { padding-bottom: 3.5rem !important; }
.pb-9 { padding-bottom: 4rem !important; }
.pb-10 { padding-bottom: 4.5rem !important; }
.pb-11 { padding-bottom: 5rem !important; }
.pb-12 { padding-bottom: 5.5rem !important; }
.pb-13 { padding-bottom: 6rem !important; }
.pb-14 { padding-bottom: 6.5rem !important; }
.pb-15 { padding-bottom: 7rem !important; }

.pt-1 { padding-top: 0.25rem !important; }
.pt-2 { padding-top: 0.5rem !important; }
.pt-3 { padding-top: 1rem !important; }
.pt-4 { padding-top: 1.5rem !important; }
.pt-5 { padding-top: 2rem !important; }
.pt-6 { padding-top: 2.5rem !important; }
.pt-7 { padding-top: 3rem !important; }
.pt-8 { padding-top: 3.5rem !important; }
.pt-9 { padding-top: 4rem !important; }
.pt-10 { padding-top: 4.5rem !important; }
.pt-11 { padding-top: 5rem !important; }
.pt-12 { padding-top: 5.5rem !important; }
.pt-13 { padding-top: 6rem !important; }
.pt-14 { padding-top: 6.5rem !important; }
.pt-15 { padding-top: 7rem !important; }


.br-0 { border-radius: 0 !important; }
.br-1 { border-radius: 0.125rem !important; } /* 2px */
.br-2 { border-radius: 0.25rem !important; } /* 4px */
.br-3 { border-radius: 0.375rem !important; } /* 6px */
.br-4 { border-radius: 0.5rem !important; } /* 8px */
.br-5 { border-radius: 0.625rem !important; } /* 10px */
.br-6 { border-radius: 0.75rem !important; } /* 12px */
.br-7 { border-radius: 0.875rem !important; } /* 14px */
.br-8 { border-radius: 1rem !important; } /* 16px */
.br-9 { border-radius: 1.125rem !important; } /* 18px */
.br-10 { border-radius: 1.25rem !important; } /* 20px */
.br-11 { border-radius: 1.375rem !important; } /* 22px */
.br-12 { border-radius: 1.5rem !important; } /* 24px */
.br-13 { border-radius: 1.625rem !important; } /* 26px */
.br-14 { border-radius: 1.75rem !important; } /* 28px */
.br-15 { border-radius: 1.875rem !important; } /* 30px */
.br-16 { border-radius: 1.985rem !important; } 
.br-18 { border-radius: 2.155rem !important; } 
.br-rounded { border-radius: 50% !important; } /* 50% */


.z-1 { z-index: 1 !important; }
.z-2 { z-index: 2 !important; }
.z-3 { z-index: 3 !important; }
.z-4 { z-index: 4 !important; }
.z-5 { z-index: 5 !important; }
.z-6 { z-index: 6 !important; }
.z-7 { z-index: 7 !important; }
.z-8 { z-index: 8 !important; }
.z-9 { z-index: 9 !important; }
.z-10 { z-index: 10 !important; }
.z-11 { z-index: 11 !important; }
.z-12 { z-index: 12 !important; }
.z-13 { z-index: 13 !important; }
.z-14 { z-index: 14 !important; }
.z-15 { z-index: 15 !important; }


.wrap { flex-wrap: wrap; }

.border-0 { border: 0px solid !important;  box-shadow: none !important;}
.border-0:focus { border: 0px solid !important; box-shadow: none !important; }
.border { border: 1px solid #a5a5a5; }
.border-l { border: 1px solid #cfcfcf; }

.focus-primary:focus {
    box-shadow: #F5792C 0px 1px 3px, #F5792C 0px 0px 0px 3px !important;
}

.focus-primary {
    outline: none !important;
    border: 2px solid #F5792C !important;
}

.c-black {
    color: black !important;
}

.bg-white { background-color: white; }
.bg-success { background-color: #2CD863 !important; color: white; }
.bg-danger { background-color: #FF3368 !important; color: white; }
.bg-alert { background-color: #e2bb3b40 !important; color: #816b23; }
.bg-secundary { background-color: #01aae7 !important; color: white; }

.field-error { border-color: #FF3368 !important; }
.gray-text { color: #666668 !important}
.text-black { color: black !important; }
.text-white { color: white !important; }
.text-orange { color: #F5792C !important; }
.block {
    display: block !important;
}

.none {
    display: none !important;
}


.fullwidth { width: 100%; }
.w-100 { width: 100%; }
.w-75 { width: 100%; }
.w-50 { width: 100%; }
.w-25 { width: 100%; }

.overflow-y-auto { overflow-y: auto; }
.overflow-x-auto { overflow-x: auto; }
.overflow-auto { overflow: auto; }
.overflow-y-hidden { overflow-y: hidden; }
.overflow-x-hidden { overflow-x: hidden; }
.overflow-hidden { overflow: hidden; }



.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    /* gap: 1rem; */
    margin-top: calc(-1 * var(--bs-gutter-y));
}

.col-md-6 {
    width: 100%;
}

@media (min-width: 768px) {
    .col-md-6 {
        flex: 0 0 auto;
        width: calc(50% - 1rem);
    }
}

.avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0.0625rem solid #E5E7EB !important;
}
