.recovery-pass-header {
    width: 100%;
    padding: 1rem;
    background-color: #f17f38;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container-recovery {
    width: 100%;
    max-width: 1280px;
}

.input-mail {
    display: block;
    width: calc(100% - 2rem);
    padding: 0.5rem 1rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: #80716b;
    background-color: #ffffff;
    background-clip: padding-box;
    border: 0.0625rem solid #dbd6d1;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.07);
    outline: none;
}

.input-mail:focus {
    border-color: #f59631;
    box-shadow: #da9954 0px 1px 3px, #eec481 0px 0px 0px 3px;
}

.btn-email {
    all: unset;
}

.t-em {
    font-size: 12px;
    font-weight: 300;
    font-family: "Inter", sans-serif;
    color: black;
}

.btn-icon {
    width: 3.375rem;
    height: 3.375rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: #1F2937;
    border-radius: 50%;
    border: none;
}

.c-default {
    cursor: default !important;
}

.input-error {
    border: 1px solid red;
}