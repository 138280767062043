.container-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-overlay {
  background: linear-gradient(to right, #2e2e2e99, #00000099);
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  animation: modal-animation .2s backwards;
  transition: all .2s;
}
.modal-btn-close{
  position: absolute;
  right: 2rem;
  top: 1rem;
  cursor: pointer;
}

.n-padding {
  padding: 0px !important;
  background-color: #F5F5F5 !important;
}

.modal-content {
  background-color: white;
  border-radius: 6px;
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 800px;
  max-height: 95vh;
  padding: 25px;
  animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);
  overflow-y: auto;
  z-index: 100;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  padding: 5px 5px;
  font-size: 12px;
  border-bottom: 1px solid var(--dark-bg);
}

.modal-header svg {
  margin-top: 5px;
  align-self: center;
}


.modal-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;


}

.modal-main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  max-height: 200px;
}


.modal-button-insc {
  flex-grow: 3;
  width: 50%;
}

.modal-textarea {
  margin-top: 20px;
  width: 100%;
}

.modal-textarea textarea {
  height: 120px;
  color: var(--text-color);
}

.modal-textarea input {
  height: 30px;
}

.modal-textarea-itens {
  display: flex;
  flex-direction: row;
}

.modal-textarea-itens svg {
  font-size: 26px;
  margin-left: 10px;
  cursor: pointer;

}


@keyframes modal-animation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes contentShow {
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
}

100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
}
}