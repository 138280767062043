@import url(https://fonts.googleapis.com/css?family=Inter:300,400,600,700&display=swap);.datepicker {
    display: none
}

.section-image {
    background-image: url(../../assets/bg-register.jpg);
}

.sibtile-px-register{
    font-size: .9rem;
    margin-top: 1rem;
}

.datepicker.active {
    display: block
}

.datepicker-dropdown {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    padding-top: 4px
}

.datepicker-dropdown.datepicker-orient-top {
    padding-top: 0;
    padding-bottom: 4px
}

.datepicker-picker {
    display: inline-block;
    border-radius: 1rem;
    background-color: #fff
}

.datepicker-dropdown .datepicker-picker {
    box-shadow: rgba(0,0,0,.1) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px
}

.datepicker-picker span {
    display: block;
    flex: 1;
    border: 0;
    border-radius: 1rem;
    cursor: default;
    text-align: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none
}

.datepicker-main {
    padding: 2px
}

.datepicker-footer {
    box-shadow: inset 0 1px 1px rgba(23,31,56,.1);
    background-color: #f5f5f5
}

.datepicker-controls,.datepicker-grid,.datepicker-view,.datepicker-view .days-of-week {
    display: flex
}

.datepicker-grid {
    flex-wrap: wrap
}

.datepicker-view .days .datepicker-cell,.datepicker-view .dow {
    flex-basis: 14.28571%
}

.datepicker-view.datepicker-grid .datepicker-cell {
    flex-basis: 25%
}

.datepicker-cell,.datepicker-view .week {
    height: 2.25rem;
    line-height: 2.25rem
}

.datepicker-title {
    box-shadow: inset 0 -1px 1px rgba(23,31,56,.1);
    background-color: #f5f5f5;
    padding: .375rem .75rem;
    text-align: center;
    font-weight: 700
}

.datepicker-header .datepicker-controls {
    padding: 2px 2px 0
}

.datepicker-controls .button {
    display: inline-flex;
    position: relative;
    align-items: center;
    justify-content: center;
    margin: 0;
    border: 1px solid #dbdbdb;
    border-radius: 1rem;
    box-shadow: none;
    background-color: #fff;
    cursor: pointer;
    padding: calc(.375em - 1px) .75em;
    height: 2.25em;
    vertical-align: top;
    text-align: center;
    line-height: 1.5;
    white-space: nowrap;
    color: #1c2540;
    font-size: 1rem
}

.datepicker-controls .button:active,.datepicker-controls .button:focus {
    outline: 0
}

.datepicker-controls .button:hover {
    border-color: #b5b5b5;
    color: #1c2540
}

.datepicker-controls .button:focus {
    border-color: #3273dc;
    color: #1c2540
}

.datepicker-controls .button:focus:not(:active) {
    box-shadow: 0 0 0 .125em rgba(50,115,220,.25)
}

.datepicker-controls .button:active {
    border-color: #3c5192;
    color: #1c2540
}

.datepicker-controls .button[disabled] {
    cursor: not-allowed
}

.datepicker-header .datepicker-controls .button {
    border-color: transparent;
    font-weight: 700
}

.datepicker-header .datepicker-controls .button:hover {
    background-color: #f9f9f9
}

.datepicker-header .datepicker-controls .button:focus:not(:active) {
    box-shadow: 0 0 0 .125em rgba(255,255,255,.25)
}

.datepicker-header .datepicker-controls .button:active {
    background-color: #f2f2f2
}

.datepicker-header .datepicker-controls .button[disabled] {
    box-shadow: none
}

.datepicker-footer .datepicker-controls .button {
    margin: calc(.375rem - 1px) .375rem;
    border-radius: 2px;
    width: 100%;
    font-size: .75rem
}

.datepicker-controls .view-switch {
    flex: auto
}

.datepicker-controls .next-btn,.datepicker-controls .prev-btn {
    padding-right: .375rem;
    padding-left: .375rem;
    width: 2.25rem
}

.datepicker-controls .next-btn.disabled,.datepicker-controls .prev-btn.disabled {
    visibility: hidden
}

.datepicker-view .dow {
    height: 1.5rem;
    line-height: 1.5rem;
    font-size: .875rem;
    font-weight: 700
}

.datepicker-view .week {
    width: 2.25rem;
    color: #b5b5b5;
    font-size: .75rem
}

@media (max-width: 22.5rem) {
    .datepicker-view .week {
        width:1.96875rem
    }
}

.datepicker-grid {
    width: 15.75rem
}

@media (max-width: 22.5rem) {
    .calendar-weeks+.days .datepicker-grid {
        width:13.78125rem
    }
}

.datepicker-cell:not(.disabled):hover {
    background-color: #f9f9f9;
    cursor: pointer
}

.datepicker-cell.focused:not(.selected) {
    background-color: #e8e8e8
}

.datepicker-cell.selected,.datepicker-cell.selected:hover {
    background-color: #3273dc;
    color: #fff;
    font-weight: 600
}

.datepicker-cell.disabled {
    color: #dbdbdb
}

.datepicker-cell.next:not(.disabled),.datepicker-cell.prev:not(.disabled) {
    color: #7a7a7a
}

.datepicker-cell.next.selected,.datepicker-cell.prev.selected {
    color: #e6e6e6
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today) {
    border-radius: 0;
    background-color: #f5f5f5
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today):not(.disabled):hover {
    background-color: #eee
}

.datepicker-cell.highlighted:not(.selected):not(.range):not(.today).focused {
    background-color: #e8e8e8
}

.datepicker-cell.today:not(.selected) {
    background-color: #00d1b2
}

.datepicker-cell.today:not(.selected):not(.disabled) {
    color: #fff
}

.datepicker-cell.today.focused:not(.selected) {
    background-color: #00c4a7
}

.datepicker-cell.range-end:not(.selected),.datepicker-cell.range-start:not(.selected) {
    background-color: #b5b5b5;
    color: #fff
}

.datepicker-cell.range-end.focused:not(.selected),.datepicker-cell.range-start.focused:not(.selected) {
    background-color: #afafaf
}

.datepicker-cell.range-start {
    border-radius: 1rem 0 0 1rem
}

.datepicker-cell.range-end {
    border-radius: 0 1rem 1rem 0
}

.datepicker-cell.range {
    border-radius: 0;
    background-color: #dbdbdb
}

.datepicker-cell.range:not(.disabled):not(.focused):not(.today):hover {
    background-color: #d5d5d5
}

.datepicker-cell.range.disabled {
    color: #c2c2c2
}

.datepicker-cell.range.focused {
    background-color: #cfcfcf
}

.datepicker-view.datepicker-grid .datepicker-cell {
    height: 4.5rem;
    line-height: 4.5rem
}

.datepicker-input.in-edit {
    border-color: #1c243b
}

.datepicker-input.in-edit:active,.datepicker-input.in-edit:focus {
    box-shadow: 0 0 .25em .25em rgba(28,36,59,.2)
}

.ct-label {
    fill: #4b5563;
    color: #4b5563;
    font-size: .75rem;
    line-height: 1
}

.ct-chart-bar .ct-label,.ct-chart-line .ct-label {
    display: block;
    display: flex
}

.ct-chart-donut .ct-label,.ct-chart-pie .ct-label {
    dominant-baseline: central
}

.ct-label.ct-horizontal.ct-start {
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start
}

.ct-label.ct-horizontal.ct-end {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start
}

.ct-label.ct-vertical.ct-start {
    align-items: flex-end;
    justify-content: flex-end;
    text-align: right;
    text-anchor: end
}

.ct-label.ct-vertical.ct-end {
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start
}

.ct-chart-bar .ct-label.ct-horizontal.ct-start {
    align-items: flex-end;
    justify-content: center;
    text-align: center;
    text-anchor: start
}

.ct-chart-bar .ct-label.ct-horizontal.ct-end {
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    text-anchor: start
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-start {
    align-items: flex-end;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-horizontal.ct-end {
    align-items: flex-start;
    justify-content: flex-start;
    text-align: left;
    text-anchor: start
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-start {
    align-items: center;
    justify-content: flex-end;
    text-align: right;
    text-anchor: end
}

.ct-chart-bar.ct-horizontal-bars .ct-label.ct-vertical.ct-end {
    align-items: center;
    justify-content: flex-start;
    text-align: left;
    text-anchor: end
}

.ct-grid {
    stroke: #d1d5db;
    stroke-width: 1px;
    stroke-dasharray: 1px
}

.ct-grid-background {
    fill: none
}

.ct-point {
    stroke-width: 10px;
    stroke-linecap: round
}

.ct-line {
    fill: none;
    stroke-width: 3px
}

.ct-area {
    stroke: none;
    fill-opacity: .1
}

.ct-bar {
    fill: none;
    stroke-width: 7px
}

.ct-slice-donut {
    fill: none;
    stroke-width: 50px
}

.ct-series-a .ct-bar,.ct-series-a .ct-line,.ct-series-a .ct-point,.ct-series-a .ct-slice-donut {
    stroke: #893168
}

.ct-series-a .ct-area,.ct-series-a .ct-slice-donut-solid,.ct-series-a .ct-slice-pie {
    fill: #893168
}

.ct-series-b .ct-bar,.ct-series-b .ct-line,.ct-series-b .ct-point,.ct-series-b .ct-slice-donut {
    stroke: #ff6200
}

.ct-series-b .ct-area,.ct-series-b .ct-slice-donut-solid,.ct-series-b .ct-slice-pie {
    fill: #ff6200
}

.ct-series-c .ct-bar,.ct-series-c .ct-line,.ct-series-c .ct-point,.ct-series-c .ct-slice-donut {
    stroke: #564787
}

.ct-series-c .ct-area,.ct-series-c .ct-slice-donut-solid,.ct-series-c .ct-slice-pie {
    fill: #564787
}

.ct-series-d .ct-bar,.ct-series-d .ct-line,.ct-series-d .ct-point,.ct-series-d .ct-slice-donut {
    stroke: #218380
}

.ct-series-d .ct-area,.ct-series-d .ct-slice-donut-solid,.ct-series-d .ct-slice-pie {
    fill: #218380
}

.ct-square {
    display: block;
    position: relative;
    width: 100%
}

.ct-square:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 100%
}

.ct-square:after {
    content: "";
    display: table;
    clear: both
}

.ct-square>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-minor-second {
    display: block;
    position: relative;
    width: 100%
}

.ct-minor-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 93.75%
}

.ct-minor-second:after {
    content: "";
    display: table;
    clear: both
}

.ct-minor-second>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-major-second {
    display: block;
    position: relative;
    width: 100%
}

.ct-major-second:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 88.88889%
}

.ct-major-second:after {
    content: "";
    display: table;
    clear: both
}

.ct-major-second>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-minor-third {
    display: block;
    position: relative;
    width: 100%
}

.ct-minor-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 83.33333%
}

.ct-minor-third:after {
    content: "";
    display: table;
    clear: both
}

.ct-minor-third>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-major-third {
    display: block;
    position: relative;
    width: 100%
}

.ct-major-third:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 80%
}

.ct-major-third:after {
    content: "";
    display: table;
    clear: both
}

.ct-major-third>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-perfect-fourth {
    display: block;
    position: relative;
    width: 100%
}

.ct-perfect-fourth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 75%
}

.ct-perfect-fourth:after {
    content: "";
    display: table;
    clear: both
}

.ct-perfect-fourth>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-perfect-fifth {
    display: block;
    position: relative;
    width: 100%
}

.ct-perfect-fifth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 66.66667%
}

.ct-perfect-fifth:after {
    content: "";
    display: table;
    clear: both
}

.ct-perfect-fifth>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-minor-sixth {
    display: block;
    position: relative;
    width: 100%
}

.ct-minor-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 62.5%
}

.ct-minor-sixth:after {
    content: "";
    display: table;
    clear: both
}

.ct-minor-sixth>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-golden-section {
    display: block;
    position: relative;
    width: 100%
}

.ct-golden-section:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 61.8047%
}

.ct-golden-section:after {
    content: "";
    display: table;
    clear: both
}

.ct-golden-section>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-major-sixth {
    display: block;
    position: relative;
    width: 100%
}

.ct-major-sixth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 60%
}

.ct-major-sixth:after {
    content: "";
    display: table;
    clear: both
}

.ct-major-sixth>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-minor-seventh {
    display: block;
    position: relative;
    width: 100%
}

.ct-minor-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 56.25%
}

.ct-minor-seventh:after {
    content: "";
    display: table;
    clear: both
}

.ct-minor-seventh>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-major-seventh {
    display: block;
    position: relative;
    width: 100%
}

.ct-major-seventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 53.33333%
}

.ct-major-seventh:after {
    content: "";
    display: table;
    clear: both
}

.ct-major-seventh>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-octave {
    display: block;
    position: relative;
    width: 100%
}

.ct-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 50%
}

.ct-octave:after {
    content: "";
    display: table;
    clear: both
}

.ct-octave>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-major-tenth {
    display: block;
    position: relative;
    width: 100%
}

.ct-major-tenth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 40%
}

.ct-major-tenth:after {
    content: "";
    display: table;
    clear: both
}

.ct-major-tenth>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-major-eleventh {
    display: block;
    position: relative;
    width: 100%
}

.ct-major-eleventh:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 37.5%
}

.ct-major-eleventh:after {
    content: "";
    display: table;
    clear: both
}

.ct-major-eleventh>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-major-twelfth {
    display: block;
    position: relative;
    width: 100%
}

.ct-major-twelfth:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 33.33333%
}

.ct-major-twelfth:after {
    content: "";
    display: table;
    clear: both
}

.ct-major-twelfth>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

.ct-double-octave {
    display: block;
    position: relative;
    width: 100%
}

.ct-double-octave:before {
    display: block;
    float: left;
    content: "";
    width: 0;
    height: 0;
    padding-bottom: 25%
}

.ct-double-octave:after {
    content: "";
    display: table;
    clear: both
}

.ct-double-octave>svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0
}

:root {
    --bs-blue: #0d6efd;
    --bs-indigo: #6610f2;
    --bs-purple: #6f42c1;
    --bs-pink: #d63384;
    --bs-red: #dc3545;
    --bs-orange: #e84422;
    --bs-yellow: #ffc107;
    --bs-green: #3aaf85;
    --bs-teal: #20c997;
    --bs-cyan: #17a2b8;
    --bs-white: #ffffff;
    --bs-gray: #6B7280;
    --bs-gray-dark: #374151;
    --bs-primary: #ff6200;
    --bs-secondary: #1c2540;
    --bs-tertiary: #893168;
    --bs-primary-app: #EBF4F6;
    --bs-secondary-app: #424AA0;
    --bs-success: #3aaf85;
    --bs-info: #0d6efd;
    --bs-warning: #ffc107;
    --bs-danger: #dc3545;
    --bs-white: #ffffff;
    --bs-gray: #1F2937;
    --bs-dark: #1c2540;
    --bs-black: #171f38;
    --bs-purple: #6f42c1;
    --bs-orange: #e84422;
    --bs-gray-100: #F9FAFB;
    --bs-gray-200: #F3F4F6;
    --bs-gray-300: #E5E7EB;
    --bs-gray-400: #D1D5DB;
    --bs-gray-500: #9CA3AF;
    --bs-gray-600: #6B7280;
    --bs-gray-700: #4B5563;
    --bs-gray-800: #374151;
    --bs-font-sans-serif: "Inter",sans-serif;
    --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
    --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0))
}

*,::after,::before {
    box-sizing: border-box
}

@media (prefers-reduced-motion:no-preference) {
    :root {
        scroll-behavior: smooth
    }
}

body {
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1f2937;
    background-color: #fff;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(23,31,56,0)
}

hr {
    margin: 1rem 0;
    color: inherit;
    background-color: currentColor;
    border: 0;
    opacity: .25
}

hr:not([size]) {
    height: .0625rem
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    font-weight: 600;
    line-height: 1.3;
    color: #1c2540
}

.h1,h1 {
    font-size: calc(1.375rem + 1.5vw)
}

@media (min-width: 1200px) {
    .h1,h1 {
        font-size:2.5rem
    }
}

.h2,h2 {
    font-size: calc(1.325rem + .9vw)
}

@media (min-width: 1200px) {
    .h2,h2 {
        font-size:2rem
    }
}

.h3,h3 {
    font-size: calc(1.3rem + .6vw)
}

@media (min-width: 1200px) {
    .h3,h3 {
        font-size:1.75rem
    }
}

.h4,h4 {
    font-size: calc(1.275rem + .3vw)
}

@media (min-width: 1200px) {
    .h4,h4 {
        font-size:1.5rem
    }
}

.h5,h5 {
    font-size: 1.25rem
}

.h6,h6 {
    font-size: 1rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-bs-original-title],abbr[title] {
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    -webkit-text-decoration-skip-ink: none;
    text-decoration-skip-ink: none
}

address {
    margin-bottom: 1rem;
    font-style: normal;
    line-height: inherit
}

ol,ul {
    padding-left: 2rem
}

dl,ol,ul {
    margin-top: 0;
    margin-bottom: 1rem
}

ol ol,ol ul,ul ol,ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 600
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b,strong {
    font-weight: 700
}

.small,small {
    font-size: .875em
}

.mark,mark {
    padding: .2em;
    background-color: #fcf8e3
}

sub,sup {
    position: relative;
    font-size: .75em;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #ff6200;
    text-decoration: none
}

a:hover {
    color: #0b0f18
}

a:not([href]):not([class]),a:not([href]):not([class]):hover {
    color: inherit;
    text-decoration: none
}

code,kbd,pre,samp {
    font-family: var(--bs-font-monospace);
    font-size: 1em;
    direction: ltr;
    unicode-bidi: bidi-override
}

pre {
    display: block;
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto;
    font-size: .875em
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

code {
    font-size: .875em;
    color: #d63384;
    word-wrap: break-word
}

a>code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: .875em;
    color: #fff;
    background-color: #1f2937;
    border-radius: .6rem
}

kbd kbd {
    padding: 0;
    font-size: 1em;
    font-weight: 600
}

figure {
    margin: 0 0 1rem
}

img,svg {
    vertical-align: middle
}

table {
    caption-side: bottom;
    border-collapse: collapse
}

caption {
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #58677d;
    text-align: left
}

th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

tbody,td,tfoot,th,thead,tr {
    border-color: inherit;
    border-style: solid;
    border-width: 0
}

label {
    display: inline-block
}

button {
    border-radius: 0
}

button:focus:not(:focus-visible) {
    outline: 0
}

button,input,optgroup,select,textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button,select {
    text-transform: none
}

[role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

select:disabled {
    opacity: 1
}

[list]::-webkit-calendar-picker-indicator {
    display: none
}

[type=button],[type=reset],[type=submit],button {
    -webkit-appearance: button
}

[type=button]:not(:disabled),[type=reset]:not(:disabled),[type=submit]:not(:disabled),button:not(:disabled) {
    cursor: pointer
}

::-moz-focus-inner {
    padding: 0;
    border-style: none
}

textarea {
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    float: left;
    width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: calc(1.275rem + .3vw);
    line-height: inherit
}

@media (min-width: 1200px) {
    legend {
        font-size:1.5rem
    }
}

legend+* {
    clear: left
}

::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-fields-wrapper,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-text,::-webkit-datetime-edit-year-field {
    padding: 0
}

::-webkit-inner-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: textfield
}

::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-color-swatch-wrapper {
    padding: 0
}

::-webkit-file-upload-button {
    font: inherit
}

::file-selector-button {
    font: inherit
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

iframe {
    border: 0
}

summary {
    display: list-item;
    cursor: pointer
}

progress {
    vertical-align: baseline
}

[hidden] {
    display: none!important
}

.lead {
    font-size: 1.25rem;
    font-weight: 300
}

.display-1 {
    font-size: calc(1.625rem + 4.5vw);
    font-weight: 600;
    line-height: 1.3
}

@media (min-width: 1200px) {
    .display-1 {
        font-size:5rem
    }
}

.display-2 {
    font-size: calc(1.475rem + 2.7vw);
    font-weight: 600;
    line-height: 1.3
}

@media (min-width: 1200px) {
    .display-2 {
        font-size:3.5rem
    }
}

.display-3 {
    font-size: calc(1.375rem + 1.5vw);
    font-weight: 600;
    line-height: 1.3
}

@media (min-width: 1200px) {
    .display-3 {
        font-size:2.5rem
    }
}

.display-4 {
    font-size: calc(1.3125rem + .75vw);
    font-weight: 600;
    line-height: 1.3
}

@media (min-width: 1200px) {
    .display-4 {
        font-size:1.875rem
    }
}

.display-5 {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 1.3
}

.display-6 {
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.3
}

.list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: .875em;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: 1.25rem
}

.blockquote>:last-child {
    margin-bottom: 0
}

.blockquote-footer {
    margin-top: -1rem;
    margin-bottom: 1rem;
    font-size: .875em;
    color: #6b7280
}

.blockquote-footer::before {
    content: "\2014\00A0"
}

.img-fluid {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: .0625rem solid #e5e7eb;
    border-radius: 1rem;
    box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0;
    max-width: 100%;
    height: auto
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: .875em;
    color: #6b7280
}

.container,.container-fluid,.container-lg,.container-md,.container-sm,.container-xl {
    width: 100%;
    padding-right: var(--bs-gutter-x,1rem);
    padding-left: var(--bs-gutter-x,1rem);
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container,.container-sm {
        max-width:540px
    }
}

@media (min-width: 768px) {
    .container,.container-md,.container-sm {
        max-width:720px
    }
}

@media (min-width: 992px) {
    .container,.container-lg,.container-md,.container-sm {
        max-width:960px
    }
}

@media (min-width: 1200px) {
    .container,.container-lg,.container-md,.container-sm,.container-xl {
        max-width:1140px
    }
}

.row {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y) * -1);
    margin-right: calc(var(--bs-gutter-x)/ -2);
    margin-left: calc(var(--bs-gutter-x)/ -2)
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x)/ 2);
    padding-left: calc(var(--bs-gutter-x)/ 2);
    margin-top: var(--bs-gutter-y)
}

.col {
    flex: 1 0 0%
}

.row-cols-auto>* {
    flex: 0 0 auto;
    width: auto
}

.row-cols-1>* {
    flex: 0 0 auto;
    width: 100%
}

.row-cols-2>* {
    flex: 0 0 auto;
    width: 50%
}

.row-cols-3>* {
    flex: 0 0 auto;
    width: 33.33333%
}

.row-cols-4>* {
    flex: 0 0 auto;
    width: 25%
}

.row-cols-5>* {
    flex: 0 0 auto;
    width: 20%
}

.row-cols-6>* {
    flex: 0 0 auto;
    width: 16.66667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto
}

.col-1 {
    flex: 0 0 auto;
    width: 8.33333%
}

.col-2 {
    flex: 0 0 auto;
    width: 16.66667%
}

.col-3 {
    flex: 0 0 auto;
    width: 25%
}

.col-4 {
    flex: 0 0 auto;
    width: 33.33333%
}

.col-5 {
    flex: 0 0 auto;
    width: 41.66667%
}

.col-6 {
    flex: 0 0 auto;
    width: 50%
}

.col-7 {
    flex: 0 0 auto;
    width: 58.33333%
}

.col-8 {
    flex: 0 0 auto;
    width: 66.66667%
}

.col-9 {
    flex: 0 0 auto;
    width: 75%
}

.col-10 {
    flex: 0 0 auto;
    width: 83.33333%
}

.col-11 {
    flex: 0 0 auto;
    width: 91.66667%
}

.col-12 {
    flex: 0 0 auto;
    width: 100%
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

.g-0,.gx-0 {
    --bs-gutter-x: 0
}

.g-0,.gy-0 {
    --bs-gutter-y: 0
}

.g-1,.gx-1 {
    --bs-gutter-x: 0.25rem
}

.g-1,.gy-1 {
    --bs-gutter-y: 0.25rem
}

.g-2,.gx-2 {
    --bs-gutter-x: 0.5rem
}

.g-2,.gy-2 {
    --bs-gutter-y: 0.5rem
}

.g-3,.gx-3 {
    --bs-gutter-x: 1rem
}

.g-3,.gy-3 {
    --bs-gutter-y: 1rem
}

.g-4,.gx-4 {
    --bs-gutter-x: 1.5rem
}

.g-4,.gy-4 {
    --bs-gutter-y: 1.5rem
}

.g-5,.gx-5 {
    --bs-gutter-x: 3rem
}

.g-5,.gy-5 {
    --bs-gutter-y: 3rem
}

.g-6,.gx-6 {
    --bs-gutter-x: 5rem
}

.g-6,.gy-6 {
    --bs-gutter-y: 5rem
}

.g-7,.gx-7 {
    --bs-gutter-x: 8rem
}

.g-7,.gy-7 {
    --bs-gutter-y: 8rem
}

.g-8,.gx-8 {
    --bs-gutter-x: 10rem
}

.g-8,.gy-8 {
    --bs-gutter-y: 10rem
}

.g-9,.gx-9 {
    --bs-gutter-x: 11rem
}

.g-9,.gy-9 {
    --bs-gutter-y: 11rem
}

.g-10,.gx-10 {
    --bs-gutter-x: 14rem
}

.g-10,.gy-10 {
    --bs-gutter-y: 14rem
}

.g-11,.gx-11 {
    --bs-gutter-x: 16rem
}

.g-11,.gy-11 {
    --bs-gutter-y: 16rem
}

.g-12,.gx-12 {
    --bs-gutter-x: 20rem
}

.g-12,.gy-12 {
    --bs-gutter-y: 20rem
}

.g-sm,.gx-sm {
    --bs-gutter-x: 1rem
}

.g-sm,.gy-sm {
    --bs-gutter-y: 1rem
}

.g-md,.gx-md {
    --bs-gutter-x: 2rem
}

.g-md,.gy-md {
    --bs-gutter-y: 2rem
}

.g-lg,.gx-lg {
    --bs-gutter-x: 4rem
}

.g-lg,.gy-lg {
    --bs-gutter-y: 4rem
}

.g-xl,.gx-xl {
    --bs-gutter-x: 8rem
}

.g-xl,.gy-xl {
    --bs-gutter-y: 8rem
}

@media (min-width: 576px) {
    .col-sm {
        flex:1 0 0%
    }

    .row-cols-sm-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-sm-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-sm-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-sm-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-sm-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-sm-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-sm-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-sm-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-sm-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-sm-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-sm-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-sm-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-sm-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-sm-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-sm-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-sm-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-sm-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-sm-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-sm-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }

    .g-sm-0,.gx-sm-0 {
        --bs-gutter-x: 0
    }

    .g-sm-0,.gy-sm-0 {
        --bs-gutter-y: 0
    }

    .g-sm-1,.gx-sm-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-sm-1,.gy-sm-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-sm-2,.gx-sm-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-sm-2,.gy-sm-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-sm-3,.gx-sm-3 {
        --bs-gutter-x: 1rem
    }

    .g-sm-3,.gy-sm-3 {
        --bs-gutter-y: 1rem
    }

    .g-sm-4,.gx-sm-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-sm-4,.gy-sm-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-sm-5,.gx-sm-5 {
        --bs-gutter-x: 3rem
    }

    .g-sm-5,.gy-sm-5 {
        --bs-gutter-y: 3rem
    }

    .g-sm-6,.gx-sm-6 {
        --bs-gutter-x: 5rem
    }

    .g-sm-6,.gy-sm-6 {
        --bs-gutter-y: 5rem
    }

    .g-sm-7,.gx-sm-7 {
        --bs-gutter-x: 8rem
    }

    .g-sm-7,.gy-sm-7 {
        --bs-gutter-y: 8rem
    }

    .g-sm-8,.gx-sm-8 {
        --bs-gutter-x: 10rem
    }

    .g-sm-8,.gy-sm-8 {
        --bs-gutter-y: 10rem
    }

    .g-sm-9,.gx-sm-9 {
        --bs-gutter-x: 11rem
    }

    .g-sm-9,.gy-sm-9 {
        --bs-gutter-y: 11rem
    }

    .g-sm-10,.gx-sm-10 {
        --bs-gutter-x: 14rem
    }

    .g-sm-10,.gy-sm-10 {
        --bs-gutter-y: 14rem
    }

    .g-sm-11,.gx-sm-11 {
        --bs-gutter-x: 16rem
    }

    .g-sm-11,.gy-sm-11 {
        --bs-gutter-y: 16rem
    }

    .g-sm-12,.gx-sm-12 {
        --bs-gutter-x: 20rem
    }

    .g-sm-12,.gy-sm-12 {
        --bs-gutter-y: 20rem
    }

    .g-sm-sm,.gx-sm-sm {
        --bs-gutter-x: 1rem
    }

    .g-sm-sm,.gy-sm-sm {
        --bs-gutter-y: 1rem
    }

    .g-sm-md,.gx-sm-md {
        --bs-gutter-x: 2rem
    }

    .g-sm-md,.gy-sm-md {
        --bs-gutter-y: 2rem
    }

    .g-sm-lg,.gx-sm-lg {
        --bs-gutter-x: 4rem
    }

    .g-sm-lg,.gy-sm-lg {
        --bs-gutter-y: 4rem
    }

    .g-sm-xl,.gx-sm-xl {
        --bs-gutter-x: 8rem
    }

    .g-sm-xl,.gy-sm-xl {
        --bs-gutter-y: 8rem
    }
}

@media (min-width: 768px) {
    .col-md {
        flex:1 0 0%
    }

    .row-cols-md-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-md-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-md-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-md-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-md-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-md-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-md-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-md-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-md-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-md-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-md-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-md-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-md-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-md-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-md-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-md-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-md-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-md-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-md-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }

    .g-md-0,.gx-md-0 {
        --bs-gutter-x: 0
    }

    .g-md-0,.gy-md-0 {
        --bs-gutter-y: 0
    }

    .g-md-1,.gx-md-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-md-1,.gy-md-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-md-2,.gx-md-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-md-2,.gy-md-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-md-3,.gx-md-3 {
        --bs-gutter-x: 1rem
    }

    .g-md-3,.gy-md-3 {
        --bs-gutter-y: 1rem
    }

    .g-md-4,.gx-md-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-md-4,.gy-md-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-md-5,.gx-md-5 {
        --bs-gutter-x: 3rem
    }

    .g-md-5,.gy-md-5 {
        --bs-gutter-y: 3rem
    }

    .g-md-6,.gx-md-6 {
        --bs-gutter-x: 5rem
    }

    .g-md-6,.gy-md-6 {
        --bs-gutter-y: 5rem
    }

    .g-md-7,.gx-md-7 {
        --bs-gutter-x: 8rem
    }

    .g-md-7,.gy-md-7 {
        --bs-gutter-y: 8rem
    }

    .g-md-8,.gx-md-8 {
        --bs-gutter-x: 10rem
    }

    .g-md-8,.gy-md-8 {
        --bs-gutter-y: 10rem
    }

    .g-md-9,.gx-md-9 {
        --bs-gutter-x: 11rem
    }

    .g-md-9,.gy-md-9 {
        --bs-gutter-y: 11rem
    }

    .g-md-10,.gx-md-10 {
        --bs-gutter-x: 14rem
    }

    .g-md-10,.gy-md-10 {
        --bs-gutter-y: 14rem
    }

    .g-md-11,.gx-md-11 {
        --bs-gutter-x: 16rem
    }

    .g-md-11,.gy-md-11 {
        --bs-gutter-y: 16rem
    }

    .g-md-12,.gx-md-12 {
        --bs-gutter-x: 20rem
    }

    .g-md-12,.gy-md-12 {
        --bs-gutter-y: 20rem
    }

    .g-md-sm,.gx-md-sm {
        --bs-gutter-x: 1rem
    }

    .g-md-sm,.gy-md-sm {
        --bs-gutter-y: 1rem
    }

    .g-md-md,.gx-md-md {
        --bs-gutter-x: 2rem
    }

    .g-md-md,.gy-md-md {
        --bs-gutter-y: 2rem
    }

    .g-md-lg,.gx-md-lg {
        --bs-gutter-x: 4rem
    }

    .g-md-lg,.gy-md-lg {
        --bs-gutter-y: 4rem
    }

    .g-md-xl,.gx-md-xl {
        --bs-gutter-x: 8rem
    }

    .g-md-xl,.gy-md-xl {
        --bs-gutter-y: 8rem
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex:1 0 0%
    }

    .row-cols-lg-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-lg-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-lg-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-lg-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-lg-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-lg-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-lg-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-lg-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-lg-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-lg-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-lg-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-lg-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-lg-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-lg-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-lg-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-lg-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-lg-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-lg-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-lg-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }

    .g-lg-0,.gx-lg-0 {
        --bs-gutter-x: 0
    }

    .g-lg-0,.gy-lg-0 {
        --bs-gutter-y: 0
    }

    .g-lg-1,.gx-lg-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-lg-1,.gy-lg-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-lg-2,.gx-lg-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-lg-2,.gy-lg-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-lg-3,.gx-lg-3 {
        --bs-gutter-x: 1rem
    }

    .g-lg-3,.gy-lg-3 {
        --bs-gutter-y: 1rem
    }

    .g-lg-4,.gx-lg-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-lg-4,.gy-lg-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-lg-5,.gx-lg-5 {
        --bs-gutter-x: 3rem
    }

    .g-lg-5,.gy-lg-5 {
        --bs-gutter-y: 3rem
    }

    .g-lg-6,.gx-lg-6 {
        --bs-gutter-x: 5rem
    }

    .g-lg-6,.gy-lg-6 {
        --bs-gutter-y: 5rem
    }

    .g-lg-7,.gx-lg-7 {
        --bs-gutter-x: 8rem
    }

    .g-lg-7,.gy-lg-7 {
        --bs-gutter-y: 8rem
    }

    .g-lg-8,.gx-lg-8 {
        --bs-gutter-x: 10rem
    }

    .g-lg-8,.gy-lg-8 {
        --bs-gutter-y: 10rem
    }

    .g-lg-9,.gx-lg-9 {
        --bs-gutter-x: 11rem
    }

    .g-lg-9,.gy-lg-9 {
        --bs-gutter-y: 11rem
    }

    .g-lg-10,.gx-lg-10 {
        --bs-gutter-x: 14rem
    }

    .g-lg-10,.gy-lg-10 {
        --bs-gutter-y: 14rem
    }

    .g-lg-11,.gx-lg-11 {
        --bs-gutter-x: 16rem
    }

    .g-lg-11,.gy-lg-11 {
        --bs-gutter-y: 16rem
    }

    .g-lg-12,.gx-lg-12 {
        --bs-gutter-x: 20rem
    }

    .g-lg-12,.gy-lg-12 {
        --bs-gutter-y: 20rem
    }

    .g-lg-sm,.gx-lg-sm {
        --bs-gutter-x: 1rem
    }

    .g-lg-sm,.gy-lg-sm {
        --bs-gutter-y: 1rem
    }

    .g-lg-md,.gx-lg-md {
        --bs-gutter-x: 2rem
    }

    .g-lg-md,.gy-lg-md {
        --bs-gutter-y: 2rem
    }

    .g-lg-lg,.gx-lg-lg {
        --bs-gutter-x: 4rem
    }

    .g-lg-lg,.gy-lg-lg {
        --bs-gutter-y: 4rem
    }

    .g-lg-xl,.gx-lg-xl {
        --bs-gutter-x: 8rem
    }

    .g-lg-xl,.gy-lg-xl {
        --bs-gutter-y: 8rem
    }
}

@media (min-width: 1200px) {
    .col-xl {
        flex:1 0 0%
    }

    .row-cols-xl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xl-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-xl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xl-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xl-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-xl-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xl-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-xl-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-xl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xl-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-xl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xl-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-xl-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-xl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }

    .g-xl-0,.gx-xl-0 {
        --bs-gutter-x: 0
    }

    .g-xl-0,.gy-xl-0 {
        --bs-gutter-y: 0
    }

    .g-xl-1,.gx-xl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xl-1,.gy-xl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xl-2,.gx-xl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xl-2,.gy-xl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xl-3,.gx-xl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xl-3,.gy-xl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xl-4,.gx-xl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xl-4,.gy-xl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xl-5,.gx-xl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xl-5,.gy-xl-5 {
        --bs-gutter-y: 3rem
    }

    .g-xl-6,.gx-xl-6 {
        --bs-gutter-x: 5rem
    }

    .g-xl-6,.gy-xl-6 {
        --bs-gutter-y: 5rem
    }

    .g-xl-7,.gx-xl-7 {
        --bs-gutter-x: 8rem
    }

    .g-xl-7,.gy-xl-7 {
        --bs-gutter-y: 8rem
    }

    .g-xl-8,.gx-xl-8 {
        --bs-gutter-x: 10rem
    }

    .g-xl-8,.gy-xl-8 {
        --bs-gutter-y: 10rem
    }

    .g-xl-9,.gx-xl-9 {
        --bs-gutter-x: 11rem
    }

    .g-xl-9,.gy-xl-9 {
        --bs-gutter-y: 11rem
    }

    .g-xl-10,.gx-xl-10 {
        --bs-gutter-x: 14rem
    }

    .g-xl-10,.gy-xl-10 {
        --bs-gutter-y: 14rem
    }

    .g-xl-11,.gx-xl-11 {
        --bs-gutter-x: 16rem
    }

    .g-xl-11,.gy-xl-11 {
        --bs-gutter-y: 16rem
    }

    .g-xl-12,.gx-xl-12 {
        --bs-gutter-x: 20rem
    }

    .g-xl-12,.gy-xl-12 {
        --bs-gutter-y: 20rem
    }

    .g-xl-sm,.gx-xl-sm {
        --bs-gutter-x: 1rem
    }

    .g-xl-sm,.gy-xl-sm {
        --bs-gutter-y: 1rem
    }

    .g-xl-md,.gx-xl-md {
        --bs-gutter-x: 2rem
    }

    .g-xl-md,.gy-xl-md {
        --bs-gutter-y: 2rem
    }

    .g-xl-lg,.gx-xl-lg {
        --bs-gutter-x: 4rem
    }

    .g-xl-lg,.gy-xl-lg {
        --bs-gutter-y: 4rem
    }

    .g-xl-xl,.gx-xl-xl {
        --bs-gutter-x: 8rem
    }

    .g-xl-xl,.gy-xl-xl {
        --bs-gutter-y: 8rem
    }
}

@media (min-width: 1400px) {
    .col-xxl {
        flex:1 0 0%
    }

    .row-cols-xxl-auto>* {
        flex: 0 0 auto;
        width: auto
    }

    .row-cols-xxl-1>* {
        flex: 0 0 auto;
        width: 100%
    }

    .row-cols-xxl-2>* {
        flex: 0 0 auto;
        width: 50%
    }

    .row-cols-xxl-3>* {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .row-cols-xxl-4>* {
        flex: 0 0 auto;
        width: 25%
    }

    .row-cols-xxl-5>* {
        flex: 0 0 auto;
        width: 20%
    }

    .row-cols-xxl-6>* {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xxl-auto {
        flex: 0 0 auto;
        width: auto
    }

    .col-xxl-1 {
        flex: 0 0 auto;
        width: 8.33333%
    }

    .col-xxl-2 {
        flex: 0 0 auto;
        width: 16.66667%
    }

    .col-xxl-3 {
        flex: 0 0 auto;
        width: 25%
    }

    .col-xxl-4 {
        flex: 0 0 auto;
        width: 33.33333%
    }

    .col-xxl-5 {
        flex: 0 0 auto;
        width: 41.66667%
    }

    .col-xxl-6 {
        flex: 0 0 auto;
        width: 50%
    }

    .col-xxl-7 {
        flex: 0 0 auto;
        width: 58.33333%
    }

    .col-xxl-8 {
        flex: 0 0 auto;
        width: 66.66667%
    }

    .col-xxl-9 {
        flex: 0 0 auto;
        width: 75%
    }

    .col-xxl-10 {
        flex: 0 0 auto;
        width: 83.33333%
    }

    .col-xxl-11 {
        flex: 0 0 auto;
        width: 91.66667%
    }

    .col-xxl-12 {
        flex: 0 0 auto;
        width: 100%
    }

    .offset-xxl-0 {
        margin-left: 0
    }

    .offset-xxl-1 {
        margin-left: 8.33333%
    }

    .offset-xxl-2 {
        margin-left: 16.66667%
    }

    .offset-xxl-3 {
        margin-left: 25%
    }

    .offset-xxl-4 {
        margin-left: 33.33333%
    }

    .offset-xxl-5 {
        margin-left: 41.66667%
    }

    .offset-xxl-6 {
        margin-left: 50%
    }

    .offset-xxl-7 {
        margin-left: 58.33333%
    }

    .offset-xxl-8 {
        margin-left: 66.66667%
    }

    .offset-xxl-9 {
        margin-left: 75%
    }

    .offset-xxl-10 {
        margin-left: 83.33333%
    }

    .offset-xxl-11 {
        margin-left: 91.66667%
    }

    .g-xxl-0,.gx-xxl-0 {
        --bs-gutter-x: 0
    }

    .g-xxl-0,.gy-xxl-0 {
        --bs-gutter-y: 0
    }

    .g-xxl-1,.gx-xxl-1 {
        --bs-gutter-x: 0.25rem
    }

    .g-xxl-1,.gy-xxl-1 {
        --bs-gutter-y: 0.25rem
    }

    .g-xxl-2,.gx-xxl-2 {
        --bs-gutter-x: 0.5rem
    }

    .g-xxl-2,.gy-xxl-2 {
        --bs-gutter-y: 0.5rem
    }

    .g-xxl-3,.gx-xxl-3 {
        --bs-gutter-x: 1rem
    }

    .g-xxl-3,.gy-xxl-3 {
        --bs-gutter-y: 1rem
    }

    .g-xxl-4,.gx-xxl-4 {
        --bs-gutter-x: 1.5rem
    }

    .g-xxl-4,.gy-xxl-4 {
        --bs-gutter-y: 1.5rem
    }

    .g-xxl-5,.gx-xxl-5 {
        --bs-gutter-x: 3rem
    }

    .g-xxl-5,.gy-xxl-5 {
        --bs-gutter-y: 3rem
    }

    .g-xxl-6,.gx-xxl-6 {
        --bs-gutter-x: 5rem
    }

    .g-xxl-6,.gy-xxl-6 {
        --bs-gutter-y: 5rem
    }

    .g-xxl-7,.gx-xxl-7 {
        --bs-gutter-x: 8rem
    }

    .g-xxl-7,.gy-xxl-7 {
        --bs-gutter-y: 8rem
    }

    .g-xxl-8,.gx-xxl-8 {
        --bs-gutter-x: 10rem
    }

    .g-xxl-8,.gy-xxl-8 {
        --bs-gutter-y: 10rem
    }

    .g-xxl-9,.gx-xxl-9 {
        --bs-gutter-x: 11rem
    }

    .g-xxl-9,.gy-xxl-9 {
        --bs-gutter-y: 11rem
    }

    .g-xxl-10,.gx-xxl-10 {
        --bs-gutter-x: 14rem
    }

    .g-xxl-10,.gy-xxl-10 {
        --bs-gutter-y: 14rem
    }

    .g-xxl-11,.gx-xxl-11 {
        --bs-gutter-x: 16rem
    }

    .g-xxl-11,.gy-xxl-11 {
        --bs-gutter-y: 16rem
    }

    .g-xxl-12,.gx-xxl-12 {
        --bs-gutter-x: 20rem
    }

    .g-xxl-12,.gy-xxl-12 {
        --bs-gutter-y: 20rem
    }

    .g-xxl-sm,.gx-xxl-sm {
        --bs-gutter-x: 1rem
    }

    .g-xxl-sm,.gy-xxl-sm {
        --bs-gutter-y: 1rem
    }

    .g-xxl-md,.gx-xxl-md {
        --bs-gutter-x: 2rem
    }

    .g-xxl-md,.gy-xxl-md {
        --bs-gutter-y: 2rem
    }

    .g-xxl-lg,.gx-xxl-lg {
        --bs-gutter-x: 4rem
    }

    .g-xxl-lg,.gy-xxl-lg {
        --bs-gutter-y: 4rem
    }

    .g-xxl-xl,.gx-xxl-xl {
        --bs-gutter-x: 8rem
    }

    .g-xxl-xl,.gy-xxl-xl {
        --bs-gutter-y: 8rem
    }
}

.table {
    --bs-table-bg: transparent;
    --bs-table-accent-bg: transparent;
    --bs-table-striped-color: #1F2937;
    --bs-table-striped-bg: rgba(23, 31, 56, 0.05);
    --bs-table-active-color: #1F2937;
    --bs-table-active-bg: rgba(23, 31, 56, 0.1);
    --bs-table-hover-color: #1F2937;
    --bs-table-hover-bg: rgba(23, 31, 56, 0.075);
    width: 100%;
    margin-bottom: 1rem;
    color: #1f2937;
    vertical-align: top;
    border-color: #e5e7eb
}

.table>:not(caption)>*>* {
    padding: .75rem .5rem;
    background-color: var(--bs-table-bg);
    border-bottom-width: .0625rem;
    box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg)
}

.table>tbody {
    vertical-align: inherit
}

.table>thead {
    vertical-align: bottom
}

.table>:not(:last-child)>:last-child>* {
    border-bottom-color: currentColor
}

.caption-top {
    caption-side: top
}

.table-sm>:not(caption)>*>* {
    padding: .25rem .25rem
}

.table-bordered>:not(caption)>* {
    border-width: .0625rem 0
}

.table-bordered>:not(caption)>*>* {
    border-width: 0 .0625rem
}

.table-borderless>:not(caption)>*>* {
    border-bottom-width: 0
}

.table-striped>tbody>tr:nth-of-type(odd) {
    --bs-table-accent-bg: var(--bs-table-striped-bg);
    color: var(--bs-table-striped-color)
}

.table-active {
    --bs-table-accent-bg: var(--bs-table-active-bg);
    color: var(--bs-table-active-color)
}

.table-hover>tbody>tr:hover {
    --bs-table-accent-bg: var(--bs-table-hover-bg);
    color: var(--bs-table-hover-color)
}

.table-primary {
    --bs-table-bg: #d3d5db;
    --bs-table-striped-bg: #caccd3;
    --bs-table-striped-color: #1c2540;
    --bs-table-active-bg: #c1c3cc;
    --bs-table-active-color: #1c2540;
    --bs-table-hover-bg: #c5c8cf;
    --bs-table-hover-color: #1c2540;
    color: #1c2540;
    border-color: #c1c3cc
}

.table-secondary {
    --bs-table-bg: #d2d3d9;
    --bs-table-striped-bg: #c9cad1;
    --bs-table-striped-color: #1c2540;
    --bs-table-active-bg: #c0c2ca;
    --bs-table-active-color: #1c2540;
    --bs-table-hover-bg: #c4c6ce;
    --bs-table-hover-color: #1c2540;
    color: #1c2540;
    border-color: #c0c2ca
}

.table-success {
    --bs-table-bg: #d8efe7;
    --bs-table-striped-bg: #cfe5df;
    --bs-table-striped-color: #1c2540;
    --bs-table-active-bg: #c5dbd6;
    --bs-table-active-color: #1c2540;
    --bs-table-hover-bg: #cae0da;
    --bs-table-hover-color: #1c2540;
    color: #1c2540;
    border-color: #c5dbd6
}

.table-info {
    --bs-table-bg: #cfe2ff;
    --bs-table-striped-bg: #c6d9f5;
    --bs-table-striped-color: #1c2540;
    --bs-table-active-bg: #bdcfec;
    --bs-table-active-color: #1c2540;
    --bs-table-hover-bg: #c2d4f1;
    --bs-table-hover-color: #1c2540;
    color: #1c2540;
    border-color: #bdcfec
}

.table-warning {
    --bs-table-bg: #fff3cd;
    --bs-table-striped-bg: #f4e9c6;
    --bs-table-striped-color: #1c2540;
    --bs-table-active-bg: #e8debf;
    --bs-table-active-color: #1c2540;
    --bs-table-hover-bg: #eee4c2;
    --bs-table-hover-color: #1c2540;
    color: #1c2540;
    border-color: #e8debf
}

.table-danger {
    --bs-table-bg: #f8d7da;
    --bs-table-striped-bg: #edced2;
    --bs-table-striped-color: #1c2540;
    --bs-table-active-bg: #e2c5cb;
    --bs-table-active-color: #1c2540;
    --bs-table-hover-bg: #e8cace;
    --bs-table-hover-color: #1c2540;
    color: #1c2540;
    border-color: #e2c5cb
}

.table-light {
    --bs-table-bg: whitesmoke;
    --bs-table-striped-bg: #eaeaec;
    --bs-table-striped-color: #1c2540;
    --bs-table-active-bg: #dfe0e3;
    --bs-table-active-color: #1c2540;
    --bs-table-hover-bg: #e5e5e7;
    --bs-table-hover-color: #1c2540;
    color: #1c2540;
    border-color: #dfe0e3
}

.table-dark {
    --bs-table-bg: #1c2540;
    --bs-table-striped-bg: #27304a;
    --bs-table-striped-color: #ffffff;
    --bs-table-active-bg: #333b53;
    --bs-table-active-color: #ffffff;
    --bs-table-hover-bg: #2d354e;
    --bs-table-hover-color: #ffffff;
    color: #fff;
    border-color: #333b53
}

.table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

@media (max-width: 1399.98px) {
    .table-responsive-xxl {
        overflow-x:auto;
        -webkit-overflow-scrolling: touch
    }
}

.form-label {
    margin-bottom: .5rem
}

.col-form-label {
    padding-top: .6125rem;
    padding-bottom: .6125rem;
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: .5625rem;
    padding-bottom: .5625rem;
    font-size: 1.25rem
}

.col-form-label-sm {
    padding-top: .3125rem;
    padding-bottom: .3125rem;
    font-size: .875rem
}

.form-text {
    margin-top: .25rem;
    font-size: .875em;
    color: #58677d
}

.form-control {
    display: block;
    width: 100%;
    padding: .55rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4b5563;
    background-color: #f9fafb;
    background-clip: padding-box;
    border: .0625rem solid #d1d5db;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(23,31,56,.075);
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control {
        transition: none
    }
}

.form-control[type=file] {
    overflow: hidden
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control:focus {
    color: #4b5563;
    background-color: #f9fafb;
    border-color: #4d62a3;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(23,31,56,.075),0 0 0 .2rem rgba(36,46,76,.25)
}

.form-control::-webkit-date-and-time-value {
    height: 1.5em
}

.form-control::placeholder {
    color: #6b7280;
    opacity: 1
}

.form-control:disabled,.form-control[readonly] {
    background-color: #f3f4f6;
    opacity: 1
}

.form-control::-webkit-file-upload-button {
    padding: .55rem .75rem;
    margin: -.55rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end:.75rem;color: #4b5563;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:.0625rem;border-radius: 0;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.form-control::file-selector-button {
    padding: .55rem .75rem;
    margin: -.55rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end:.75rem;color: #4b5563;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:.0625rem;border-radius: 0;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }

    .form-control::file-selector-button {
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #f2f2f2
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #f2f2f2
}

.form-control::-webkit-file-upload-button {
    padding: .55rem .75rem;
    margin: -.55rem -.75rem;
    -webkit-margin-end: .75rem;
    margin-inline-end:.75rem;color: #4b5563;
    background-color: #fff;
    pointer-events: none;
    border-color: inherit;
    border-style: solid;
    border-width: 0;
    border-inline-end-width:.0625rem;border-radius: 0;
    -webkit-transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #f2f2f2
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: .55rem 0;
    margin-bottom: 0;
    line-height: 1.5;
    color: #1f2937;
    background-color: transparent;
    border: solid transparent;
    border-width: .0625rem 0
}

.form-control-plaintext.form-control-lg,.form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    min-height: calc(1.5em + .625rem);
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .6rem
}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end:.5rem}

.form-control-sm::file-selector-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end:.5rem}

.form-control-sm::-webkit-file-upload-button {
    padding: .25rem .5rem;
    margin: -.25rem -.5rem;
    -webkit-margin-end: .5rem;
    margin-inline-end:.5rem}

.form-control-lg {
    min-height: calc(1.5em + 1.125rem);
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end:1rem}

.form-control-lg::file-selector-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end:1rem}

.form-control-lg::-webkit-file-upload-button {
    padding: .5rem 1rem;
    margin: -.5rem -1rem;
    -webkit-margin-end: 1rem;
    margin-inline-end:1rem}

textarea.form-control {
    min-height: calc(1.5em + 1.225rem)
}

textarea.form-control-sm {
    min-height: calc(1.5em + .625rem)
}

textarea.form-control-lg {
    min-height: calc(1.5em + 1.125rem)
}

.form-control-color {
    max-width: 3rem;
    height: auto;
    padding: .55rem
}

.form-control-color:not(:disabled):not([readonly]) {
    cursor: pointer
}

.form-control-color::-moz-color-swatch {
    height: 1.5em;
    border-radius: 1rem
}

.form-control-color::-webkit-color-swatch {
    height: 1.5em;
    border-radius: 1rem
}

.form-select {
    display: block;
    width: 100%;
    padding: .55rem 1rem .55rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4b5563;
    background-color: #f9fafb;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: .0625rem solid #d1d5db;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(23,31,56,.075);
    -webkit-appearance: none;
    appearance: none
}

.form-select:focus {
    border-color: #4d62a3;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(23,31,56,.075),0 0 0 .2rem rgba(36,46,76,.25)
}

.form-select[multiple],.form-select[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none
}

.form-select:disabled {
    color: #6b7280;
    background-color: #f3f4f6
}

.form-select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #4b5563
}

.form-select-sm {
    padding-top: .25rem;
    padding-bottom: .25rem;
    padding-left: .5rem;
    font-size: .875rem
}

.form-select-lg {
    padding-top: .5rem;
    padding-bottom: .5rem;
    padding-left: 1rem;
    font-size: 1.25rem
}

.form-check {
    display: block;
    min-height: 1.5rem;
    padding-left: 1.625em;
    margin-bottom: .125rem
}

.form-check .form-check-input {
    float: left;
    margin-left: -1.625em
}

.form-check-input {
    width: 1.125em;
    height: 1.125em;
    margin-top: .1875em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid #d1d5db;
    -webkit-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    transition: background-color .2s ease-in-out,background-position .2s ease-in-out,border-color .2s ease-in-out,box-shadow .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-check-input {
        transition: none
    }
}

.form-check-input[type=checkbox] {
    border-radius: .25em
}

.form-check-input[type=radio] {
    border-radius: 50%
}

.form-check-input:active {
    filter: brightness(90%)
}

.form-check-input:focus {
    border-color: #4d62a3;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(36,46,76,.25)
}

.form-check-input:checked {
    background-color: #ff6200;
    border-color: #ff6200
}

.form-check-input:checked[type=checkbox] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e")
}

.form-check-input:checked[type=radio] {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23ffffff'/%3e%3c/svg%3e")
}

.form-check-input[type=checkbox]:indeterminate {
    background-color: #ff6200;
    border-color: #ff6200;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ffffff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e")
}

.form-check-input:disabled {
    pointer-events: none;
    filter: none;
    opacity: .5
}

.form-check-input:disabled~.form-check-label,.form-check-input[disabled]~.form-check-label {
    opacity: .5
}

.form-switch {
    padding-left: 2.75em
}

.form-switch .form-check-input {
    width: 2.25em;
    margin-left: -2.75em;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    background-position: left center;
    border-radius: 2.25em;
    transition: background-position .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-switch .form-check-input {
        transition: none
    }
}

.form-switch .form-check-input:focus {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%234d62a3'/%3e%3c/svg%3e")
}

.form-switch .form-check-input:checked {
    background-position: right center;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23ffffff'/%3e%3c/svg%3e")
}

.form-check-inline {
    display: inline-block;
    margin-right: 1rem
}

.btn-check {
    position: absolute;
    clip: rect(0,0,0,0);
    pointer-events: none
}

.btn-check:disabled+.btn,.btn-check[disabled]+.btn {
    pointer-events: none;
    filter: none;
    opacity: .65
}

.form-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    appearance: none
}

.form-range:focus {
    outline: 0
}

.form-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(36,46,76,.25)
}

.form-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff,0 0 0 .2rem rgba(36,46,76,.25)
}

.form-range::-moz-focus-outer {
    border: 0
}

.form-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #ff6200;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(23,31,56,.1);
    -webkit-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-webkit-slider-thumb {
        -webkit-transition: none;
        transition: none
    }
}

.form-range::-webkit-slider-thumb:active {
    background-color: #6a7eb8
}

.form-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e5e7eb;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(23,31,56,.075)
}

.form-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #ff6200;
    border: 0;
    border-radius: 1rem;
    box-shadow: 0 .1rem .25rem rgba(23,31,56,.1);
    -moz-transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    transition: background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion:reduce) {
    .form-range::-moz-range-thumb {
        -moz-transition: none;
        transition: none
    }
}

.form-range::-moz-range-thumb:active {
    background-color: #6a7eb8
}

.form-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e5e7eb;
    border-color: transparent;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(23,31,56,.075)
}

.form-range:disabled {
    pointer-events: none
}

.form-range:disabled::-webkit-slider-thumb {
    background-color: #9ca3af
}

.form-range:disabled::-moz-range-thumb {
    background-color: #9ca3af
}

.form-floating {
    position: relative
}

.form-floating>.form-control,.form-floating>.form-select {
    height: 3.625rem;
    padding: 1rem .75rem
}

.form-floating>label {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    padding: 1rem .75rem;
    pointer-events: none;
    border: .0625rem solid transparent;
    transform-origin: 0 0;
    transition: opacity .1s ease-in-out,transform .1s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .form-floating>label {
        transition: none
    }
}

.form-floating>.form-control::placeholder {
    color: transparent
}

.form-floating>.form-control:focus,.form-floating>.form-control:not(:placeholder-shown) {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:-webkit-autofill {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-select {
    padding-top: 1.625rem;
    padding-bottom: .625rem
}

.form-floating>.form-control:focus~label,.form-floating>.form-control:not(:placeholder-shown)~label,.form-floating>.form-select~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.form-floating>.form-control:-webkit-autofill~label {
    opacity: .65;
    transform: scale(.85) translateY(-.5rem) translateX(.15rem)
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group>.form-control,.input-group>.form-select {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

.input-group>.form-control:focus,.input-group>.form-select:focus {
    z-index: 3
}

.input-group .btn {
    position: relative;
    z-index: 2
}

.input-group .btn:focus {
    z-index: 3
}

.input-group-text {
    display: flex;
    align-items: center;
    padding: .55rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #4b5563;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: .0625rem solid #d1d5db;
    border-radius: 1rem
}

.input-group-lg>.btn,.input-group-lg>.form-control,.input-group-lg>.form-select,.input-group-lg>.input-group-text {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.input-group-sm>.btn,.input-group-sm>.form-control,.input-group-sm>.form-select,.input-group-sm>.input-group-text {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .6rem
}

.input-group-lg>.form-select,.input-group-sm>.form-select {
    padding-right: 1.75rem
}

.input-group:not(.has-validation)>.dropdown-toggle:nth-last-child(n+3),.input-group:not(.has-validation)>:not(:last-child):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group.has-validation>.dropdown-toggle:nth-last-child(n+4),.input-group.has-validation>:nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group>:not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    margin-left: -.0625rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #3aaf85
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #1c2540;
    background-color: rgba(58,175,133,.9);
    border-radius: 1rem
}

.is-valid~.valid-feedback,.is-valid~.valid-tooltip,.was-validated :valid~.valid-feedback,.was-validated :valid~.valid-tooltip {
    display: block
}

.form-control.is-valid,.was-validated .form-control:valid {
    border-color: #3aaf85;
    padding-right: calc(1.5em + 1.1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233aaf85' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .275rem) center;
    background-size: calc(.75em + .55rem) calc(.75em + .55rem)
}

.form-control.is-valid:focus,.was-validated .form-control:valid:focus {
    border-color: #3aaf85;
    box-shadow: 0 0 0 .2rem rgba(58,175,133,.25)
}

.was-validated textarea.form-control:valid,textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1.1rem);
    background-position: top calc(.375em + .275rem) right calc(.375em + .275rem)
}

.form-select.is-valid,.was-validated .form-select:valid {
    border-color: #3aaf85
}

.form-select.is-valid:not([multiple]):not([size]),.form-select.is-valid:not([multiple])[size="1"],.was-validated .form-select:valid:not([multiple]):not([size]),.was-validated .form-select:valid:not([multiple])[size="1"] {
    padding-right: 2.875rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%233aaf85' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-position: right .75rem center,center right 1.75rem;
    background-size: 16px 12px,calc(.75em + .55rem) calc(.75em + .55rem)
}

.form-select.is-valid:focus,.was-validated .form-select:valid:focus {
    border-color: #3aaf85;
    box-shadow: 0 0 0 .2rem rgba(58,175,133,.25)
}

.form-check-input.is-valid,.was-validated .form-check-input:valid {
    border-color: #3aaf85
}

.form-check-input.is-valid:checked,.was-validated .form-check-input:valid:checked {
    background-color: #3aaf85
}

.form-check-input.is-valid:focus,.was-validated .form-check-input:valid:focus {
    box-shadow: 0 0 0 .2rem rgba(58,175,133,.25)
}

.form-check-input.is-valid~.form-check-label,.was-validated .form-check-input:valid~.form-check-label {
    color: #3aaf85
}

.form-check-inline .form-check-input~.valid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-valid,.input-group .form-select.is-valid,.was-validated .input-group .form-control:valid,.was-validated .input-group .form-select:valid {
    z-index: 1
}

.input-group .form-control.is-valid:focus,.input-group .form-select.is-valid:focus,.was-validated .input-group .form-control:valid:focus,.was-validated .input-group .form-select:valid:focus {
    z-index: 3
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: .875em;
    color: #dc3545
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .875rem;
    color: #fff;
    background-color: rgba(220,53,69,.9);
    border-radius: 1rem
}

.is-invalid~.invalid-feedback,.is-invalid~.invalid-tooltip,.was-validated :invalid~.invalid-feedback,.was-validated :invalid~.invalid-tooltip {
    display: block
}

.form-control.is-invalid,.was-validated .form-control:invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 1.1rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(.375em + .275rem) center;
    background-size: calc(.75em + .55rem) calc(.75em + .55rem)
}

.form-control.is-invalid:focus,.was-validated .form-control:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220,53,69,.25)
}

.was-validated textarea.form-control:invalid,textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1.1rem);
    background-position: top calc(.375em + .275rem) right calc(.375em + .275rem)
}

.form-select.is-invalid,.was-validated .form-select:invalid {
    border-color: #dc3545
}

.form-select.is-invalid:not([multiple]):not([size]),.form-select.is-invalid:not([multiple])[size="1"],.was-validated .form-select:invalid:not([multiple]):not([size]),.was-validated .form-select:invalid:not([multiple])[size="1"] {
    padding-right: 2.875rem;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23374151' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"),url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-position: right .75rem center,center right 1.75rem;
    background-size: 16px 12px,calc(.75em + .55rem) calc(.75em + .55rem)
}

.form-select.is-invalid:focus,.was-validated .form-select:invalid:focus {
    border-color: #dc3545;
    box-shadow: 0 0 0 .2rem rgba(220,53,69,.25)
}

.form-check-input.is-invalid,.was-validated .form-check-input:invalid {
    border-color: #dc3545
}

.form-check-input.is-invalid:checked,.was-validated .form-check-input:invalid:checked {
    background-color: #dc3545
}

.form-check-input.is-invalid:focus,.was-validated .form-check-input:invalid:focus {
    box-shadow: 0 0 0 .2rem rgba(220,53,69,.25)
}

.form-check-input.is-invalid~.form-check-label,.was-validated .form-check-input:invalid~.form-check-label {
    color: #dc3545
}

.form-check-inline .form-check-input~.invalid-feedback {
    margin-left: .5em
}

.input-group .form-control.is-invalid,.input-group .form-select.is-invalid,.was-validated .input-group .form-control:invalid,.was-validated .input-group .form-select:invalid {
    z-index: 2
}

.input-group .form-control.is-invalid:focus,.input-group .form-select.is-invalid:focus,.was-validated .input-group .form-control:invalid:focus,.was-validated .input-group .form-select:invalid:focus {
    z-index: 3
}

.btn {
    display: inline-block;
    font-weight: 600;
    line-height: 1.5;
    color: #1f2937;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: .0625rem solid transparent;
    padding: .55rem .75rem;
    font-size: 1rem;
    border-radius: 1rem;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #1f2937
}

.btn-check:focus+.btn,.btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(36,46,76,.25)
}

.btn-check:active+.btn,.btn-check:checked+.btn,.btn.active,.btn:active {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125)
}

.btn-check:active+.btn:focus,.btn-check:checked+.btn:focus,.btn.active:focus,.btn:active:focus {
    box-shadow: 0 0 0 .2rem rgba(36,46,76,.25),inset 0 3px 5px rgba(23,31,56,.125)
}

.btn.disabled,.btn:disabled,fieldset:disabled .btn {
    pointer-events: none;
    opacity: .65;
    box-shadow: none
}

.btn-primary {
    color: #fff;
    background-color: #ff6200;
    border-color: #ff6200;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-primary:hover {
    color: #fff;
    background-color: #1f2741;
    border-color: #1d253d
}

.btn-check:focus+.btn-primary,.btn-primary:focus {
    color: #fff;
    background-color: #1f2741;
    border-color: #1d253d;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(69,77,103,.5)
}

.btn-check:active+.btn-primary,.btn-check:checked+.btn-primary,.btn-primary.active,.btn-primary:active,.show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #1d253d;
    border-color: #1b2339
}

.btn-check:active+.btn-primary:focus,.btn-check:checked+.btn-primary:focus,.btn-primary.active:focus,.btn-primary:active:focus,.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(69,77,103,.5)
}

.btn-primary.disabled,.btn-primary:disabled {
    color: #fff;
    background-color: #ff6200;
    border-color: #ff6200
}

.btn-secondary {
    color: #fff;
    background-color: #1c2540;
    border-color: #1c2540;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-secondary:hover {
    color: #fff;
    background-color: #181f36;
    border-color: #161e33
}

.btn-check:focus+.btn-secondary,.btn-secondary:focus {
    color: #fff;
    background-color: #181f36;
    border-color: #161e33;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(62,70,93,.5)
}

.btn-check:active+.btn-secondary,.btn-check:checked+.btn-secondary,.btn-secondary.active,.btn-secondary:active,.show>.btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #161e33;
    border-color: #151c30
}

.btn-check:active+.btn-secondary:focus,.btn-check:checked+.btn-secondary:focus,.btn-secondary.active:focus,.btn-secondary:active:focus,.show>.btn-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(62,70,93,.5)
}

.btn-secondary.disabled,.btn-secondary:disabled {
    color: #fff;
    background-color: #1c2540;
    border-color: #1c2540
}

.btn-tertiary {
    color: #fff;
    background-color: #893168;
    border-color: #893168;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-tertiary:hover {
    color: #fff;
    background-color: #742a58;
    border-color: #6e2753
}

.btn-check:focus+.btn-tertiary,.btn-tertiary:focus {
    color: #fff;
    background-color: #742a58;
    border-color: #6e2753;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(155,80,127,.5)
}

.btn-check:active+.btn-tertiary,.btn-check:checked+.btn-tertiary,.btn-tertiary.active,.btn-tertiary:active,.show>.btn-tertiary.dropdown-toggle {
    color: #fff;
    background-color: #6e2753;
    border-color: #67254e
}

.btn-check:active+.btn-tertiary:focus,.btn-check:checked+.btn-tertiary:focus,.btn-tertiary.active:focus,.btn-tertiary:active:focus,.show>.btn-tertiary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(155,80,127,.5)
}

.btn-tertiary.disabled,.btn-tertiary:disabled {
    color: #fff;
    background-color: #893168;
    border-color: #893168
}

.btn-primary-app {
    color: #1c2540;
    background-color: #b2b7b8;
    border-color: #ebf4f6;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-primary-app:hover {
    color: #1c2540;
    background-color: #b2b7b8;
    border-color: #edf5f7
}

.btn-check:focus+.btn-primary-app,.btn-primary-app:focus {
    color: #1c2540;
    background-color: #eef6f7;
    border-color: #edf5f7;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(204,213,219,.5)
}

.btn-check:active+.btn-primary-app,.btn-check:checked+.btn-primary-app,.btn-primary-app.active,.btn-primary-app:active,.show>.btn-primary-app.dropdown-toggle {
    color: #1c2540;
    background-color: #eff6f8;
    border-color: #edf5f7
}

.btn-check:active+.btn-primary-app:focus,.btn-check:checked+.btn-primary-app:focus,.btn-primary-app.active:focus,.btn-primary-app:active:focus,.show>.btn-primary-app.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(204,213,219,.5)
}

.btn-primary-app.disabled,.btn-primary-app:disabled {
    color: #1c2540;
    background-color: #ebf4f6;
    border-color: #ebf4f6
}

.btn-secondary-app {
    color: #fff;
    background-color: #424aa0;
    border-color: #424aa0;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-secondary-app:hover {
    color: #fff;
    background-color: #383f88;
    border-color: #353b80
}

.btn-check:focus+.btn-secondary-app,.btn-secondary-app:focus {
    color: #fff;
    background-color: #383f88;
    border-color: #353b80;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(94,101,174,.5)
}

.btn-check:active+.btn-secondary-app,.btn-check:checked+.btn-secondary-app,.btn-secondary-app.active,.btn-secondary-app:active,.show>.btn-secondary-app.dropdown-toggle {
    color: #fff;
    background-color: #353b80;
    border-color: #323878
}

.btn-check:active+.btn-secondary-app:focus,.btn-check:checked+.btn-secondary-app:focus,.btn-secondary-app.active:focus,.btn-secondary-app:active:focus,.show>.btn-secondary-app.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(94,101,174,.5)
}

.btn-secondary-app.disabled,.btn-secondary-app:disabled {
    color: #fff;
    background-color: #424aa0;
    border-color: #424aa0
}

.btn-success {
    color: #1c2540;
    background-color: #3aaf85;
    border-color: #3aaf85;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-success:hover {
    color: #1c2540;
    background-color: #58bb97;
    border-color: #4eb791
}

.btn-check:focus+.btn-success,.btn-success:focus {
    color: #1c2540;
    background-color: #58bb97;
    border-color: #4eb791;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(54,154,123,.5)
}

.btn-check:active+.btn-success,.btn-check:checked+.btn-success,.btn-success.active,.btn-success:active,.show>.btn-success.dropdown-toggle {
    color: #1c2540;
    background-color: #61bf9d;
    border-color: #4eb791
}

.btn-check:active+.btn-success:focus,.btn-check:checked+.btn-success:focus,.btn-success.active:focus,.btn-success:active:focus,.show>.btn-success.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(54,154,123,.5)
}

.btn-success.disabled,.btn-success:disabled {
    color: #1c2540;
    background-color: #3aaf85;
    border-color: #3aaf85
}

.btn-info {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-info:hover {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca
}

.btn-check:focus+.btn-info,.btn-info:focus {
    color: #fff;
    background-color: #0b5ed7;
    border-color: #0a58ca;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(49,132,253,.5)
}

.btn-check:active+.btn-info,.btn-check:checked+.btn-info,.btn-info.active,.btn-info:active,.show>.btn-info.dropdown-toggle {
    color: #fff;
    background-color: #0a58ca;
    border-color: #0a53be
}

.btn-check:active+.btn-info:focus,.btn-check:checked+.btn-info:focus,.btn-info.active:focus,.btn-info:active:focus,.show>.btn-info.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(49,132,253,.5)
}

.btn-info.disabled,.btn-info:disabled {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-warning {
    color: #1c2540;
    background-color: #ffc107;
    border-color: #ffc107;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-warning:hover {
    color: #1c2540;
    background-color: #ffca2c;
    border-color: #ffc720
}

.btn-check:focus+.btn-warning,.btn-warning:focus {
    color: #1c2540;
    background-color: #ffca2c;
    border-color: #ffc720;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(221,170,16,.5)
}

.btn-check:active+.btn-warning,.btn-check:checked+.btn-warning,.btn-warning.active,.btn-warning:active,.show>.btn-warning.dropdown-toggle {
    color: #1c2540;
    background-color: #ffcd39;
    border-color: #ffc720
}

.btn-check:active+.btn-warning:focus,.btn-check:checked+.btn-warning:focus,.btn-warning.active:focus,.btn-warning:active:focus,.show>.btn-warning.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(221,170,16,.5)
}

.btn-warning.disabled,.btn-warning:disabled {
    color: #1c2540;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-danger {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-danger:hover {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37
}

.btn-check:focus+.btn-danger,.btn-danger:focus {
    color: #fff;
    background-color: #bb2d3b;
    border-color: #b02a37;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(225,83,97,.5)
}

.btn-check:active+.btn-danger,.btn-check:checked+.btn-danger,.btn-danger.active,.btn-danger:active,.show>.btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #b02a37;
    border-color: #a52834
}

.btn-check:active+.btn-danger:focus,.btn-check:checked+.btn-danger:focus,.btn-danger.active:focus,.btn-danger:active:focus,.show>.btn-danger.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(225,83,97,.5)
}

.btn-danger.disabled,.btn-danger:disabled {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-white {
    color: #1c2540;
    background-color: #fff;
    border-color: #fff;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-white:hover {
    color: #1c2540;
    background-color: #fff;
    border-color: #fff
}

.btn-check:focus+.btn-white,.btn-white:focus {
    color: #1c2540;
    background-color: #fff;
    border-color: #fff;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(221,222,226,.5)
}

.btn-check:active+.btn-white,.btn-check:checked+.btn-white,.btn-white.active,.btn-white:active,.show>.btn-white.dropdown-toggle {
    color: #1c2540;
    background-color: #fff;
    border-color: #fff
}

.btn-check:active+.btn-white:focus,.btn-check:checked+.btn-white:focus,.btn-white.active:focus,.btn-white:active:focus,.show>.btn-white.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(221,222,226,.5)
}

.btn-white.disabled,.btn-white:disabled {
    color: #1c2540;
    background-color: #fff;
    border-color: #fff
}

.btn-gray {
    color: #fff;
    background-color: #1f2937;
    border-color: #1f2937;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray:hover {
    color: #fff;
    background-color: #1a232f;
    border-color: #19212c
}

.btn-check:focus+.btn-gray,.btn-gray:focus {
    color: #fff;
    background-color: #1a232f;
    border-color: #19212c;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(65,73,85,.5)
}

.btn-check:active+.btn-gray,.btn-check:checked+.btn-gray,.btn-gray.active,.btn-gray:active,.show>.btn-gray.dropdown-toggle {
    color: #fff;
    background-color: #19212c;
    border-color: #171f29
}

.btn-check:active+.btn-gray:focus,.btn-check:checked+.btn-gray:focus,.btn-gray.active:focus,.btn-gray:active:focus,.show>.btn-gray.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(65,73,85,.5)
}

.btn-gray.disabled,.btn-gray:disabled {
    color: #fff;
    background-color: #1f2937;
    border-color: #1f2937
}

.btn-dark {
    color: #fff;
    background-color: #1c2540;
    border-color: #1c2540;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-dark:hover {
    color: #fff;
    background-color: #181f36;
    border-color: #161e33
}

.btn-check:focus+.btn-dark,.btn-dark:focus {
    color: #fff;
    background-color: #181f36;
    border-color: #161e33;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(62,70,93,.5)
}

.btn-check:active+.btn-dark,.btn-check:checked+.btn-dark,.btn-dark.active,.btn-dark:active,.show>.btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #161e33;
    border-color: #151c30
}

.btn-check:active+.btn-dark:focus,.btn-check:checked+.btn-dark:focus,.btn-dark.active:focus,.btn-dark:active:focus,.show>.btn-dark.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(62,70,93,.5)
}

.btn-dark.disabled,.btn-dark:disabled {
    color: #fff;
    background-color: #1c2540;
    border-color: #1c2540
}

.btn-black {
    color: #fff;
    background-color: #171f38;
    border-color: #171f38;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-black:hover {
    color: #fff;
    background-color: #141a30;
    border-color: #12192d
}

.btn-black:focus,.btn-check:focus+.btn-black {
    color: #fff;
    background-color: #141a30;
    border-color: #12192d;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(58,65,86,.5)
}

.btn-black.active,.btn-black:active,.btn-check:active+.btn-black,.btn-check:checked+.btn-black,.show>.btn-black.dropdown-toggle {
    color: #fff;
    background-color: #12192d;
    border-color: #11172a
}

.btn-black.active:focus,.btn-black:active:focus,.btn-check:active+.btn-black:focus,.btn-check:checked+.btn-black:focus,.show>.btn-black.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(58,65,86,.5)
}

.btn-black.disabled,.btn-black:disabled {
    color: #fff;
    background-color: #171f38;
    border-color: #171f38
}

.btn-purple {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-purple:hover {
    color: #fff;
    background-color: #5e38a4;
    border-color: #59359a
}

.btn-check:focus+.btn-purple,.btn-purple:focus {
    color: #fff;
    background-color: #5e38a4;
    border-color: #59359a;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(133,94,202,.5)
}

.btn-check:active+.btn-purple,.btn-check:checked+.btn-purple,.btn-purple.active,.btn-purple:active,.show>.btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #59359a;
    border-color: #533291
}

.btn-check:active+.btn-purple:focus,.btn-check:checked+.btn-purple:focus,.btn-purple.active:focus,.btn-purple:active:focus,.show>.btn-purple.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(133,94,202,.5)
}

.btn-purple.disabled,.btn-purple:disabled {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1
}

.btn-orange {
    color: #fff;
    background-color: #e84422;
    border-color: #e84422;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-orange:hover {
    color: #fff;
    background-color: #c53a1d;
    border-color: #ba361b
}

.btn-check:focus+.btn-orange,.btn-orange:focus {
    color: #fff;
    background-color: #c53a1d;
    border-color: #ba361b;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(235,96,67,.5)
}

.btn-check:active+.btn-orange,.btn-check:checked+.btn-orange,.btn-orange.active,.btn-orange:active,.show>.btn-orange.dropdown-toggle {
    color: #fff;
    background-color: #ba361b;
    border-color: #ae331a
}

.btn-check:active+.btn-orange:focus,.btn-check:checked+.btn-orange:focus,.btn-orange.active:focus,.btn-orange:active:focus,.show>.btn-orange.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(235,96,67,.5)
}

.btn-orange.disabled,.btn-orange:disabled {
    color: #fff;
    background-color: #e84422;
    border-color: #e84422
}

.btn-gray-100 {
    color: #1c2540;
    background-color: #f9fafb;
    border-color: #f9fafb;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray-100:hover {
    color: #1c2540;
    background-color: #fafbfc;
    border-color: #fafbfb
}

.btn-check:focus+.btn-gray-100,.btn-gray-100:focus {
    color: #1c2540;
    background-color: #fafbfc;
    border-color: #fafbfb;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(216,218,223,.5)
}

.btn-check:active+.btn-gray-100,.btn-check:checked+.btn-gray-100,.btn-gray-100.active,.btn-gray-100:active,.show>.btn-gray-100.dropdown-toggle {
    color: #1c2540;
    background-color: #fafbfc;
    border-color: #fafbfb
}

.btn-check:active+.btn-gray-100:focus,.btn-check:checked+.btn-gray-100:focus,.btn-gray-100.active:focus,.btn-gray-100:active:focus,.show>.btn-gray-100.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(216,218,223,.5)
}

.btn-gray-100.disabled,.btn-gray-100:disabled {
    color: #1c2540;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-gray-200 {
    color: #1c2540;
    background-color: #f3f4f6;
    border-color: #f3f4f6;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray-200:hover {
    color: #1c2540;
    background-color: #f5f6f7;
    border-color: #f4f5f7
}

.btn-check:focus+.btn-gray-200,.btn-gray-200:focus {
    color: #1c2540;
    background-color: #f5f6f7;
    border-color: #f4f5f7;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(211,213,219,.5)
}

.btn-check:active+.btn-gray-200,.btn-check:checked+.btn-gray-200,.btn-gray-200.active,.btn-gray-200:active,.show>.btn-gray-200.dropdown-toggle {
    color: #1c2540;
    background-color: #f5f6f8;
    border-color: #f4f5f7
}

.btn-check:active+.btn-gray-200:focus,.btn-check:checked+.btn-gray-200:focus,.btn-gray-200.active:focus,.btn-gray-200:active:focus,.show>.btn-gray-200.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(211,213,219,.5)
}

.btn-gray-200.disabled,.btn-gray-200:disabled {
    color: #1c2540;
    background-color: #f3f4f6;
    border-color: #f3f4f6
}

.btn-gray-300 {
    color: #1c2540;
    background-color: #e5e7eb;
    border-color: #e5e7eb;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray-300:hover {
    color: #1c2540;
    background-color: #e9ebee;
    border-color: #e8e9ed
}

.btn-check:focus+.btn-gray-300,.btn-gray-300:focus {
    color: #1c2540;
    background-color: #e9ebee;
    border-color: #e8e9ed;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(199,202,209,.5)
}

.btn-check:active+.btn-gray-300,.btn-check:checked+.btn-gray-300,.btn-gray-300.active,.btn-gray-300:active,.show>.btn-gray-300.dropdown-toggle {
    color: #1c2540;
    background-color: #eaecef;
    border-color: #e8e9ed
}

.btn-check:active+.btn-gray-300:focus,.btn-check:checked+.btn-gray-300:focus,.btn-gray-300.active:focus,.btn-gray-300:active:focus,.show>.btn-gray-300.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(199,202,209,.5)
}

.btn-gray-300.disabled,.btn-gray-300:disabled {
    color: #1c2540;
    background-color: #e5e7eb;
    border-color: #e5e7eb
}

.btn-gray-400 {
    color: #1c2540;
    background-color: #d1d5db;
    border-color: #d1d5db;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray-400:hover {
    color: #1c2540;
    background-color: #d8dbe0;
    border-color: #d6d9df
}

.btn-check:focus+.btn-gray-400,.btn-gray-400:focus {
    color: #1c2540;
    background-color: #d8dbe0;
    border-color: #d6d9df;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(182,187,196,.5)
}

.btn-check:active+.btn-gray-400,.btn-check:checked+.btn-gray-400,.btn-gray-400.active,.btn-gray-400:active,.show>.btn-gray-400.dropdown-toggle {
    color: #1c2540;
    background-color: #dadde2;
    border-color: #d6d9df
}

.btn-check:active+.btn-gray-400:focus,.btn-check:checked+.btn-gray-400:focus,.btn-gray-400.active:focus,.btn-gray-400:active:focus,.show>.btn-gray-400.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(182,187,196,.5)
}

.btn-gray-400.disabled,.btn-gray-400:disabled {
    color: #1c2540;
    background-color: #d1d5db;
    border-color: #d1d5db
}

.btn-gray-500 {
    color: #1c2540;
    background-color: #9ca3af;
    border-color: #9ca3af;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray-500:hover {
    color: #1c2540;
    background-color: #abb1bb;
    border-color: #a6acb7
}

.btn-check:focus+.btn-gray-500,.btn-gray-500:focus {
    color: #1c2540;
    background-color: #abb1bb;
    border-color: #a6acb7;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(137,144,158,.5)
}

.btn-check:active+.btn-gray-500,.btn-check:checked+.btn-gray-500,.btn-gray-500.active,.btn-gray-500:active,.show>.btn-gray-500.dropdown-toggle {
    color: #1c2540;
    background-color: #b0b5bf;
    border-color: #a6acb7
}

.btn-check:active+.btn-gray-500:focus,.btn-check:checked+.btn-gray-500:focus,.btn-gray-500.active:focus,.btn-gray-500:active:focus,.show>.btn-gray-500.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(137,144,158,.5)
}

.btn-gray-500.disabled,.btn-gray-500:disabled {
    color: #1c2540;
    background-color: #9ca3af;
    border-color: #9ca3af
}

.btn-gray-600 {
    color: #fff;
    background-color: #6b7280;
    border-color: #6b7280;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray-600:hover {
    color: #fff;
    background-color: #5b616d;
    border-color: #565b66
}

.btn-check:focus+.btn-gray-600,.btn-gray-600:focus {
    color: #fff;
    background-color: #5b616d;
    border-color: #565b66;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(129,135,147,.5)
}

.btn-check:active+.btn-gray-600,.btn-check:checked+.btn-gray-600,.btn-gray-600.active,.btn-gray-600:active,.show>.btn-gray-600.dropdown-toggle {
    color: #fff;
    background-color: #565b66;
    border-color: #505660
}

.btn-check:active+.btn-gray-600:focus,.btn-check:checked+.btn-gray-600:focus,.btn-gray-600.active:focus,.btn-gray-600:active:focus,.show>.btn-gray-600.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(129,135,147,.5)
}

.btn-gray-600.disabled,.btn-gray-600:disabled {
    color: #fff;
    background-color: #6b7280;
    border-color: #6b7280
}

.btn-gray-700 {
    color: #fff;
    background-color: #4b5563;
    border-color: #4b5563;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray-700:hover {
    color: #fff;
    background-color: #404854;
    border-color: #3c444f
}

.btn-check:focus+.btn-gray-700,.btn-gray-700:focus {
    color: #fff;
    background-color: #404854;
    border-color: #3c444f;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(102,111,122,.5)
}

.btn-check:active+.btn-gray-700,.btn-check:checked+.btn-gray-700,.btn-gray-700.active,.btn-gray-700:active,.show>.btn-gray-700.dropdown-toggle {
    color: #fff;
    background-color: #3c444f;
    border-color: #38404a
}

.btn-check:active+.btn-gray-700:focus,.btn-check:checked+.btn-gray-700:focus,.btn-gray-700.active:focus,.btn-gray-700:active:focus,.show>.btn-gray-700.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(102,111,122,.5)
}

.btn-gray-700.disabled,.btn-gray-700:disabled {
    color: #fff;
    background-color: #4b5563;
    border-color: #4b5563
}

.btn-gray-800 {
    color: #fff;
    background-color: #374151;
    border-color: #374151;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-gray-800:hover {
    color: #fff;
    background-color: #2f3745;
    border-color: #2c3441
}

.btn-check:focus+.btn-gray-800,.btn-gray-800:focus {
    color: #fff;
    background-color: #2f3745;
    border-color: #2c3441;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(85,94,107,.5)
}

.btn-check:active+.btn-gray-800,.btn-check:checked+.btn-gray-800,.btn-gray-800.active,.btn-gray-800:active,.show>.btn-gray-800.dropdown-toggle {
    color: #fff;
    background-color: #2c3441;
    border-color: #29313d
}

.btn-check:active+.btn-gray-800:focus,.btn-check:checked+.btn-gray-800:focus,.btn-gray-800.active:focus,.btn-gray-800:active:focus,.show>.btn-gray-800.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(85,94,107,.5)
}

.btn-gray-800.disabled,.btn-gray-800:disabled {
    color: #fff;
    background-color: #374151;
    border-color: #374151
}

.btn-outline-primary {
    color: #ff6200;
    border-color: #ff6200
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #ff6200;
    border-color: #ff6200
}

.btn-check:focus+.btn-outline-primary,.btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(36,46,76,.5)
}

.btn-check:active+.btn-outline-primary,.btn-check:checked+.btn-outline-primary,.btn-outline-primary.active,.btn-outline-primary.dropdown-toggle.show,.btn-outline-primary:active {
    color: #fff;
    background-color: #ff6200;
    border-color: #ff6200
}

.btn-check:active+.btn-outline-primary:focus,.btn-check:checked+.btn-outline-primary:focus,.btn-outline-primary.active:focus,.btn-outline-primary.dropdown-toggle.show:focus,.btn-outline-primary:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(36,46,76,.5)
}

.btn-outline-primary.disabled,.btn-outline-primary:disabled {
    color: #ff6200;
    background-color: transparent
}

.btn-outline-secondary {
    color: #1c2540;
    border-color: #1c2540
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #1c2540;
    border-color: #1c2540
}

.btn-check:focus+.btn-outline-secondary,.btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(28,37,64,.5)
}

.btn-check:active+.btn-outline-secondary,.btn-check:checked+.btn-outline-secondary,.btn-outline-secondary.active,.btn-outline-secondary.dropdown-toggle.show,.btn-outline-secondary:active {
    color: #fff;
    background-color: #1c2540;
    border-color: #1c2540
}

.btn-check:active+.btn-outline-secondary:focus,.btn-check:checked+.btn-outline-secondary:focus,.btn-outline-secondary.active:focus,.btn-outline-secondary.dropdown-toggle.show:focus,.btn-outline-secondary:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(28,37,64,.5)
}

.btn-outline-secondary.disabled,.btn-outline-secondary:disabled {
    color: #1c2540;
    background-color: transparent
}

.btn-outline-tertiary {
    color: #893168;
    border-color: #893168
}

.btn-outline-tertiary:hover {
    color: #fff;
    background-color: #893168;
    border-color: #893168
}

.btn-check:focus+.btn-outline-tertiary,.btn-outline-tertiary:focus {
    box-shadow: 0 0 0 .2rem rgba(137,49,104,.5)
}

.btn-check:active+.btn-outline-tertiary,.btn-check:checked+.btn-outline-tertiary,.btn-outline-tertiary.active,.btn-outline-tertiary.dropdown-toggle.show,.btn-outline-tertiary:active {
    color: #fff;
    background-color: #893168;
    border-color: #893168
}

.btn-check:active+.btn-outline-tertiary:focus,.btn-check:checked+.btn-outline-tertiary:focus,.btn-outline-tertiary.active:focus,.btn-outline-tertiary.dropdown-toggle.show:focus,.btn-outline-tertiary:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(137,49,104,.5)
}

.btn-outline-tertiary.disabled,.btn-outline-tertiary:disabled {
    color: #893168;
    background-color: transparent
}

.btn-outline-primary-app {
    color: #ebf4f6;
    border-color: #ebf4f6
}

.btn-outline-primary-app:hover {
    color: #1c2540;
    background-color: #ebf4f6;
    border-color: #ebf4f6
}

.btn-check:focus+.btn-outline-primary-app,.btn-outline-primary-app:focus {
    box-shadow: 0 0 0 .2rem rgba(235,244,246,.5)
}

.btn-check:active+.btn-outline-primary-app,.btn-check:checked+.btn-outline-primary-app,.btn-outline-primary-app.active,.btn-outline-primary-app.dropdown-toggle.show,.btn-outline-primary-app:active {
    color: #1c2540;
    background-color: #ebf4f6;
    border-color: #ebf4f6
}

.btn-check:active+.btn-outline-primary-app:focus,.btn-check:checked+.btn-outline-primary-app:focus,.btn-outline-primary-app.active:focus,.btn-outline-primary-app.dropdown-toggle.show:focus,.btn-outline-primary-app:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(235,244,246,.5)
}

.btn-outline-primary-app.disabled,.btn-outline-primary-app:disabled {
    color: #ebf4f6;
    background-color: transparent
}

.btn-outline-secondary-app {
    color: #424aa0;
    border-color: #424aa0
}

.btn-outline-secondary-app:hover {
    color: #fff;
    background-color: #424aa0;
    border-color: #424aa0
}

.btn-check:focus+.btn-outline-secondary-app,.btn-outline-secondary-app:focus {
    box-shadow: 0 0 0 .2rem rgba(66,74,160,.5)
}

.btn-check:active+.btn-outline-secondary-app,.btn-check:checked+.btn-outline-secondary-app,.btn-outline-secondary-app.active,.btn-outline-secondary-app.dropdown-toggle.show,.btn-outline-secondary-app:active {
    color: #fff;
    background-color: #424aa0;
    border-color: #424aa0
}

.btn-check:active+.btn-outline-secondary-app:focus,.btn-check:checked+.btn-outline-secondary-app:focus,.btn-outline-secondary-app.active:focus,.btn-outline-secondary-app.dropdown-toggle.show:focus,.btn-outline-secondary-app:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(66,74,160,.5)
}

.btn-outline-secondary-app.disabled,.btn-outline-secondary-app:disabled {
    color: #424aa0;
    background-color: transparent
}

.btn-outline-success {
    color: #3aaf85;
    border-color: #3aaf85
}

.btn-outline-success:hover {
    color: #1c2540;
    background-color: #3aaf85;
    border-color: #3aaf85
}

.btn-check:focus+.btn-outline-success,.btn-outline-success:focus {
    box-shadow: 0 0 0 .2rem rgba(58,175,133,.5)
}

.btn-check:active+.btn-outline-success,.btn-check:checked+.btn-outline-success,.btn-outline-success.active,.btn-outline-success.dropdown-toggle.show,.btn-outline-success:active {
    color: #1c2540;
    background-color: #3aaf85;
    border-color: #3aaf85
}

.btn-check:active+.btn-outline-success:focus,.btn-check:checked+.btn-outline-success:focus,.btn-outline-success.active:focus,.btn-outline-success.dropdown-toggle.show:focus,.btn-outline-success:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(58,175,133,.5)
}

.btn-outline-success.disabled,.btn-outline-success:disabled {
    color: #3aaf85;
    background-color: transparent
}

.btn-outline-info {
    color: #0d6efd;
    border-color: #0d6efd
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:focus+.btn-outline-info,.btn-outline-info:focus {
    box-shadow: 0 0 0 .2rem rgba(13,110,253,.5)
}

.btn-check:active+.btn-outline-info,.btn-check:checked+.btn-outline-info,.btn-outline-info.active,.btn-outline-info.dropdown-toggle.show,.btn-outline-info:active {
    color: #fff;
    background-color: #0d6efd;
    border-color: #0d6efd
}

.btn-check:active+.btn-outline-info:focus,.btn-check:checked+.btn-outline-info:focus,.btn-outline-info.active:focus,.btn-outline-info.dropdown-toggle.show:focus,.btn-outline-info:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(13,110,253,.5)
}

.btn-outline-info.disabled,.btn-outline-info:disabled {
    color: #0d6efd;
    background-color: transparent
}

.btn-outline-warning {
    color: #ffc107;
    border-color: #ffc107
}

.btn-outline-warning:hover {
    color: #1c2540;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:focus+.btn-outline-warning,.btn-outline-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(255,193,7,.5)
}

.btn-check:active+.btn-outline-warning,.btn-check:checked+.btn-outline-warning,.btn-outline-warning.active,.btn-outline-warning.dropdown-toggle.show,.btn-outline-warning:active {
    color: #1c2540;
    background-color: #ffc107;
    border-color: #ffc107
}

.btn-check:active+.btn-outline-warning:focus,.btn-check:checked+.btn-outline-warning:focus,.btn-outline-warning.active:focus,.btn-outline-warning.dropdown-toggle.show:focus,.btn-outline-warning:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(255,193,7,.5)
}

.btn-outline-warning.disabled,.btn-outline-warning:disabled {
    color: #ffc107;
    background-color: transparent
}

.btn-outline-danger {
    color: #dc3545;
    border-color: #dc3545
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:focus+.btn-outline-danger,.btn-outline-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(220,53,69,.5)
}

.btn-check:active+.btn-outline-danger,.btn-check:checked+.btn-outline-danger,.btn-outline-danger.active,.btn-outline-danger.dropdown-toggle.show,.btn-outline-danger:active {
    color: #fff;
    background-color: #dc3545;
    border-color: #dc3545
}

.btn-check:active+.btn-outline-danger:focus,.btn-check:checked+.btn-outline-danger:focus,.btn-outline-danger.active:focus,.btn-outline-danger.dropdown-toggle.show:focus,.btn-outline-danger:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(220,53,69,.5)
}

.btn-outline-danger.disabled,.btn-outline-danger:disabled {
    color: #dc3545;
    background-color: transparent
}

.btn-outline-white {
    color: #fff;
    border-color: #fff
}

.btn-outline-white:hover {
    color: #1c2540;
    background-color: #fff;
    border-color: #fff
}

.btn-check:focus+.btn-outline-white,.btn-outline-white:focus {
    box-shadow: 0 0 0 .2rem rgba(255,255,255,.5)
}

.btn-check:active+.btn-outline-white,.btn-check:checked+.btn-outline-white,.btn-outline-white.active,.btn-outline-white.dropdown-toggle.show,.btn-outline-white:active {
    color: #1c2540;
    background-color: #fff;
    border-color: #fff
}

.btn-check:active+.btn-outline-white:focus,.btn-check:checked+.btn-outline-white:focus,.btn-outline-white.active:focus,.btn-outline-white.dropdown-toggle.show:focus,.btn-outline-white:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(255,255,255,.5)
}

.btn-outline-white.disabled,.btn-outline-white:disabled {
    color: #fff;
    background-color: transparent
}

.btn-outline-gray {
    color: #1f2937;
    border-color: #1f2937
}

.btn-outline-gray:hover {
    color: #fff;
    background-color: #1f2937;
    border-color: #1f2937
}

.btn-check:focus+.btn-outline-gray,.btn-outline-gray:focus {
    box-shadow: 0 0 0 .2rem rgba(31,41,55,.5)
}

.btn-check:active+.btn-outline-gray,.btn-check:checked+.btn-outline-gray,.btn-outline-gray.active,.btn-outline-gray.dropdown-toggle.show,.btn-outline-gray:active {
    color: #fff;
    background-color: #1f2937;
    border-color: #1f2937
}

.btn-check:active+.btn-outline-gray:focus,.btn-check:checked+.btn-outline-gray:focus,.btn-outline-gray.active:focus,.btn-outline-gray.dropdown-toggle.show:focus,.btn-outline-gray:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(31,41,55,.5)
}

.btn-outline-gray.disabled,.btn-outline-gray:disabled {
    color: #1f2937;
    background-color: transparent
}

.btn-outline-dark {
    color: #1c2540;
    border-color: #1c2540
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #1c2540;
    border-color: #1c2540
}

.btn-check:focus+.btn-outline-dark,.btn-outline-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(28,37,64,.5)
}

.btn-check:active+.btn-outline-dark,.btn-check:checked+.btn-outline-dark,.btn-outline-dark.active,.btn-outline-dark.dropdown-toggle.show,.btn-outline-dark:active {
    color: #fff;
    background-color: #1c2540;
    border-color: #1c2540
}

.btn-check:active+.btn-outline-dark:focus,.btn-check:checked+.btn-outline-dark:focus,.btn-outline-dark.active:focus,.btn-outline-dark.dropdown-toggle.show:focus,.btn-outline-dark:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(28,37,64,.5)
}

.btn-outline-dark.disabled,.btn-outline-dark:disabled {
    color: #1c2540;
    background-color: transparent
}

.btn-outline-black {
    color: #171f38;
    border-color: #171f38
}

.btn-outline-black:hover {
    color: #fff;
    background-color: #171f38;
    border-color: #171f38
}

.btn-check:focus+.btn-outline-black,.btn-outline-black:focus {
    box-shadow: 0 0 0 .2rem rgba(23,31,56,.5)
}

.btn-check:active+.btn-outline-black,.btn-check:checked+.btn-outline-black,.btn-outline-black.active,.btn-outline-black.dropdown-toggle.show,.btn-outline-black:active {
    color: #fff;
    background-color: #171f38;
    border-color: #171f38
}

.btn-check:active+.btn-outline-black:focus,.btn-check:checked+.btn-outline-black:focus,.btn-outline-black.active:focus,.btn-outline-black.dropdown-toggle.show:focus,.btn-outline-black:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(23,31,56,.5)
}

.btn-outline-black.disabled,.btn-outline-black:disabled {
    color: #171f38;
    background-color: transparent
}

.btn-outline-purple {
    color: #6f42c1;
    border-color: #6f42c1
}

.btn-outline-purple:hover {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1
}

.btn-check:focus+.btn-outline-purple,.btn-outline-purple:focus {
    box-shadow: 0 0 0 .2rem rgba(111,66,193,.5)
}

.btn-check:active+.btn-outline-purple,.btn-check:checked+.btn-outline-purple,.btn-outline-purple.active,.btn-outline-purple.dropdown-toggle.show,.btn-outline-purple:active {
    color: #fff;
    background-color: #6f42c1;
    border-color: #6f42c1
}

.btn-check:active+.btn-outline-purple:focus,.btn-check:checked+.btn-outline-purple:focus,.btn-outline-purple.active:focus,.btn-outline-purple.dropdown-toggle.show:focus,.btn-outline-purple:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(111,66,193,.5)
}

.btn-outline-purple.disabled,.btn-outline-purple:disabled {
    color: #6f42c1;
    background-color: transparent
}

.btn-outline-orange {
    color: #e84422;
    border-color: #e84422
}

.btn-outline-orange:hover {
    color: #fff;
    background-color: #e84422;
    border-color: #e84422
}

.btn-check:focus+.btn-outline-orange,.btn-outline-orange:focus {
    box-shadow: 0 0 0 .2rem rgba(232,68,34,.5)
}

.btn-check:active+.btn-outline-orange,.btn-check:checked+.btn-outline-orange,.btn-outline-orange.active,.btn-outline-orange.dropdown-toggle.show,.btn-outline-orange:active {
    color: #fff;
    background-color: #e84422;
    border-color: #e84422
}

.btn-check:active+.btn-outline-orange:focus,.btn-check:checked+.btn-outline-orange:focus,.btn-outline-orange.active:focus,.btn-outline-orange.dropdown-toggle.show:focus,.btn-outline-orange:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(232,68,34,.5)
}

.btn-outline-orange.disabled,.btn-outline-orange:disabled {
    color: #e84422;
    background-color: transparent
}

.btn-outline-gray-100 {
    color: #f9fafb;
    border-color: #f9fafb
}

.btn-outline-gray-100:hover {
    color: #1c2540;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:focus+.btn-outline-gray-100,.btn-outline-gray-100:focus {
    box-shadow: 0 0 0 .2rem rgba(249,250,251,.5)
}

.btn-check:active+.btn-outline-gray-100,.btn-check:checked+.btn-outline-gray-100,.btn-outline-gray-100.active,.btn-outline-gray-100.dropdown-toggle.show,.btn-outline-gray-100:active {
    color: #1c2540;
    background-color: #f9fafb;
    border-color: #f9fafb
}

.btn-check:active+.btn-outline-gray-100:focus,.btn-check:checked+.btn-outline-gray-100:focus,.btn-outline-gray-100.active:focus,.btn-outline-gray-100.dropdown-toggle.show:focus,.btn-outline-gray-100:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(249,250,251,.5)
}

.btn-outline-gray-100.disabled,.btn-outline-gray-100:disabled {
    color: #f9fafb;
    background-color: transparent
}

.btn-outline-gray-200 {
    color: #f3f4f6;
    border-color: #f3f4f6
}

.btn-outline-gray-200:hover {
    color: #1c2540;
    background-color: #f3f4f6;
    border-color: #f3f4f6
}

.btn-check:focus+.btn-outline-gray-200,.btn-outline-gray-200:focus {
    box-shadow: 0 0 0 .2rem rgba(243,244,246,.5)
}

.btn-check:active+.btn-outline-gray-200,.btn-check:checked+.btn-outline-gray-200,.btn-outline-gray-200.active,.btn-outline-gray-200.dropdown-toggle.show,.btn-outline-gray-200:active {
    color: #1c2540;
    background-color: #f3f4f6;
    border-color: #f3f4f6
}

.btn-check:active+.btn-outline-gray-200:focus,.btn-check:checked+.btn-outline-gray-200:focus,.btn-outline-gray-200.active:focus,.btn-outline-gray-200.dropdown-toggle.show:focus,.btn-outline-gray-200:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(243,244,246,.5)
}

.btn-outline-gray-200.disabled,.btn-outline-gray-200:disabled {
    color: #f3f4f6;
    background-color: transparent
}

.btn-outline-gray-300 {
    color: #e5e7eb;
    border-color: #e5e7eb
}

.btn-outline-gray-300:hover {
    color: #1c2540;
    background-color: #e5e7eb;
    border-color: #e5e7eb
}

.btn-check:focus+.btn-outline-gray-300,.btn-outline-gray-300:focus {
    box-shadow: 0 0 0 .2rem rgba(229,231,235,.5)
}

.btn-check:active+.btn-outline-gray-300,.btn-check:checked+.btn-outline-gray-300,.btn-outline-gray-300.active,.btn-outline-gray-300.dropdown-toggle.show,.btn-outline-gray-300:active {
    color: #1c2540;
    background-color: #e5e7eb;
    border-color: #e5e7eb
}

.btn-check:active+.btn-outline-gray-300:focus,.btn-check:checked+.btn-outline-gray-300:focus,.btn-outline-gray-300.active:focus,.btn-outline-gray-300.dropdown-toggle.show:focus,.btn-outline-gray-300:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(229,231,235,.5)
}

.btn-outline-gray-300.disabled,.btn-outline-gray-300:disabled {
    color: #e5e7eb;
    background-color: transparent
}

.btn-outline-gray-400 {
    color: #d1d5db;
    border-color: #d1d5db
}

.btn-outline-gray-400:hover {
    color: #1c2540;
    background-color: #d1d5db;
    border-color: #d1d5db
}

.btn-check:focus+.btn-outline-gray-400,.btn-outline-gray-400:focus {
    box-shadow: 0 0 0 .2rem rgba(209,213,219,.5)
}

.btn-check:active+.btn-outline-gray-400,.btn-check:checked+.btn-outline-gray-400,.btn-outline-gray-400.active,.btn-outline-gray-400.dropdown-toggle.show,.btn-outline-gray-400:active {
    color: #1c2540;
    background-color: #d1d5db;
    border-color: #d1d5db
}

.btn-check:active+.btn-outline-gray-400:focus,.btn-check:checked+.btn-outline-gray-400:focus,.btn-outline-gray-400.active:focus,.btn-outline-gray-400.dropdown-toggle.show:focus,.btn-outline-gray-400:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(209,213,219,.5)
}

.btn-outline-gray-400.disabled,.btn-outline-gray-400:disabled {
    color: #d1d5db;
    background-color: transparent
}

.btn-outline-gray-500 {
    color: #9ca3af;
    border-color: #9ca3af
}

.btn-outline-gray-500:hover {
    color: #1c2540;
    background-color: #9ca3af;
    border-color: #9ca3af
}

.btn-check:focus+.btn-outline-gray-500,.btn-outline-gray-500:focus {
    box-shadow: 0 0 0 .2rem rgba(156,163,175,.5)
}

.btn-check:active+.btn-outline-gray-500,.btn-check:checked+.btn-outline-gray-500,.btn-outline-gray-500.active,.btn-outline-gray-500.dropdown-toggle.show,.btn-outline-gray-500:active {
    color: #1c2540;
    background-color: #9ca3af;
    border-color: #9ca3af
}

.btn-check:active+.btn-outline-gray-500:focus,.btn-check:checked+.btn-outline-gray-500:focus,.btn-outline-gray-500.active:focus,.btn-outline-gray-500.dropdown-toggle.show:focus,.btn-outline-gray-500:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(156,163,175,.5)
}

.btn-outline-gray-500.disabled,.btn-outline-gray-500:disabled {
    color: #9ca3af;
    background-color: transparent
}

.btn-outline-gray-600 {
    color: #6b7280;
    border-color: #6b7280
}

.btn-outline-gray-600:hover {
    color: #fff;
    background-color: #6b7280;
    border-color: #6b7280
}

.btn-check:focus+.btn-outline-gray-600,.btn-outline-gray-600:focus {
    box-shadow: 0 0 0 .2rem rgba(107,114,128,.5)
}

.btn-check:active+.btn-outline-gray-600,.btn-check:checked+.btn-outline-gray-600,.btn-outline-gray-600.active,.btn-outline-gray-600.dropdown-toggle.show,.btn-outline-gray-600:active {
    color: #fff;
    background-color: #6b7280;
    border-color: #6b7280
}

.btn-check:active+.btn-outline-gray-600:focus,.btn-check:checked+.btn-outline-gray-600:focus,.btn-outline-gray-600.active:focus,.btn-outline-gray-600.dropdown-toggle.show:focus,.btn-outline-gray-600:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(107,114,128,.5)
}

.btn-outline-gray-600.disabled,.btn-outline-gray-600:disabled {
    color: #6b7280;
    background-color: transparent
}

.btn-outline-gray-700 {
    color: #4b5563;
    border-color: #4b5563
}

.btn-outline-gray-700:hover {
    color: #fff;
    background-color: #4b5563;
    border-color: #4b5563
}

.btn-check:focus+.btn-outline-gray-700,.btn-outline-gray-700:focus {
    box-shadow: 0 0 0 .2rem rgba(75,85,99,.5)
}

.btn-check:active+.btn-outline-gray-700,.btn-check:checked+.btn-outline-gray-700,.btn-outline-gray-700.active,.btn-outline-gray-700.dropdown-toggle.show,.btn-outline-gray-700:active {
    color: #fff;
    background-color: #4b5563;
    border-color: #4b5563
}

.btn-check:active+.btn-outline-gray-700:focus,.btn-check:checked+.btn-outline-gray-700:focus,.btn-outline-gray-700.active:focus,.btn-outline-gray-700.dropdown-toggle.show:focus,.btn-outline-gray-700:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(75,85,99,.5)
}

.btn-outline-gray-700.disabled,.btn-outline-gray-700:disabled {
    color: #4b5563;
    background-color: transparent
}

.btn-outline-gray-800 {
    color: #374151;
    border-color: #374151
}

.btn-outline-gray-800:hover {
    color: #fff;
    background-color: #374151;
    border-color: #374151
}

.btn-check:focus+.btn-outline-gray-800,.btn-outline-gray-800:focus {
    box-shadow: 0 0 0 .2rem rgba(55,65,81,.5)
}

.btn-check:active+.btn-outline-gray-800,.btn-check:checked+.btn-outline-gray-800,.btn-outline-gray-800.active,.btn-outline-gray-800.dropdown-toggle.show,.btn-outline-gray-800:active {
    color: #fff;
    background-color: #374151;
    border-color: #374151
}

.btn-check:active+.btn-outline-gray-800:focus,.btn-check:checked+.btn-outline-gray-800:focus,.btn-outline-gray-800.active:focus,.btn-outline-gray-800.dropdown-toggle.show:focus,.btn-outline-gray-800:active:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(55,65,81,.5)
}

.btn-outline-gray-800.disabled,.btn-outline-gray-800:disabled {
    color: #374151;
    background-color: transparent
}

.btn-link {
    font-weight: 400;
    color: #ff6200;
    text-decoration: none
}

.btn-link:hover {
    color: #0b0f18
}

.btn-link.disabled,.btn-link:disabled {
    color: #6b7280
}

.btn-group-lg>.btn,.btn-lg {
    padding: .5rem 1rem;
    font-size: 1.25rem;
    border-radius: .3rem
}

.btn-group-sm>.btn,.btn-sm {
    padding: .25rem .5rem;
    font-size: .875rem;
    border-radius: .6rem
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion:reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion:reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown,.dropend,.dropstart,.dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid;
    border-right: .3em solid transparent;
    border-bottom: 0;
    border-left: .3em solid transparent
}

.dropdown-toggle:empty::after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #1f2937;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: .0625rem solid #e5e7eb;
    border-radius: 1rem;
    box-shadow: rgba(0,0,0,.1) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px
}

.dropdown-menu[data-bs-popper] {
    top: 100%;
    left: 0;
    margin-top: .125rem
}

.dropdown-menu-start {
    --bs-position: start
}

.dropdown-menu-start[data-bs-popper] {
    right: auto;
    left: 0
}

.dropdown-menu-end {
    --bs-position: end
}

.dropdown-menu-end[data-bs-popper] {
    right: 0;
    left: auto
}

@media (min-width: 576px) {
    .dropdown-menu-sm-start {
        --bs-position:start
    }

    .dropdown-menu-sm-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-end {
        --bs-position: end
    }

    .dropdown-menu-sm-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-start {
        --bs-position:start
    }

    .dropdown-menu-md-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-end {
        --bs-position: end
    }

    .dropdown-menu-md-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-start {
        --bs-position:start
    }

    .dropdown-menu-lg-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-end {
        --bs-position: end
    }

    .dropdown-menu-lg-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-start {
        --bs-position:start
    }

    .dropdown-menu-xl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-end {
        --bs-position: end
    }

    .dropdown-menu-xl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

@media (min-width: 1400px) {
    .dropdown-menu-xxl-start {
        --bs-position:start
    }

    .dropdown-menu-xxl-start[data-bs-popper] {
        right: auto;
        left: 0
    }

    .dropdown-menu-xxl-end {
        --bs-position: end
    }

    .dropdown-menu-xxl-end[data-bs-popper] {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu[data-bs-popper] {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropup .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: 0;
    border-right: .3em solid transparent;
    border-bottom: .3em solid;
    border-left: .3em solid transparent
}

.dropup .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-menu[data-bs-popper] {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropend .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: 0;
    border-bottom: .3em solid transparent;
    border-left: .3em solid
}

.dropend .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropend .dropdown-toggle::after {
    vertical-align: 0
}

.dropstart .dropdown-menu[data-bs-popper] {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropstart .dropdown-toggle::after {
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: ""
}

.dropstart .dropdown-toggle::after {
    display: none
}

.dropstart .dropdown-toggle::before {
    display: inline-block;
    margin-right: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid transparent;
    border-right: .3em solid;
    border-bottom: .3em solid transparent
}

.dropstart .dropdown-toggle:empty::after {
    margin-left: 0
}

.dropstart .dropdown-toggle::before {
    vertical-align: 0
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #f3f4f6
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1rem;
    clear: both;
    font-weight: 400;
    color: #1f2937;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus,.dropdown-item:hover {
    color: #161d27;
    background-color: #f3f4f6
}

.dropdown-item.active,.dropdown-item:active {
    color: #1f2937;
    text-decoration: none;
    background-color: #d1d5db
}

.dropdown-item.disabled,.dropdown-item:disabled {
    color: #6b7280;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: .875rem;
    color: #1f2937;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .25rem 1rem;
    color: #1f2937
}

.dropdown-menu-dark {
    color: #e5e7eb;
    background-color: #374151;
    border-color: #e5e7eb
}

.dropdown-menu-dark .dropdown-item {
    color: #e5e7eb
}

.dropdown-menu-dark .dropdown-item:focus,.dropdown-menu-dark .dropdown-item:hover {
    color: #fff;
    background-color: rgba(255,255,255,.15)
}

.dropdown-menu-dark .dropdown-item.active,.dropdown-menu-dark .dropdown-item:active {
    color: #1f2937;
    background-color: #d1d5db
}

.dropdown-menu-dark .dropdown-item.disabled,.dropdown-menu-dark .dropdown-item:disabled {
    color: #9ca3af
}

.dropdown-menu-dark .dropdown-divider {
    border-color: #f3f4f6
}

.dropdown-menu-dark .dropdown-item-text {
    color: #e5e7eb
}

.dropdown-menu-dark .dropdown-header {
    color: #9ca3af
}

.btn-group,.btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical>.btn,.btn-group>.btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical>.btn-check:checked+.btn,.btn-group-vertical>.btn-check:focus+.btn,.btn-group-vertical>.btn.active,.btn-group-vertical>.btn:active,.btn-group-vertical>.btn:focus,.btn-group-vertical>.btn:hover,.btn-group>.btn-check:checked+.btn,.btn-group>.btn-check:focus+.btn,.btn-group>.btn.active,.btn-group>.btn:active,.btn-group>.btn:focus,.btn-group>.btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group>.btn-group:not(:first-child),.btn-group>.btn:not(:first-child) {
    margin-left: -.0625rem
}

.btn-group>.btn-group:not(:last-child)>.btn,.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group>.btn-group:not(:first-child)>.btn,.btn-group>.btn:nth-child(n+3),.btn-group>:not(.btn-check)+.btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .5625rem;
    padding-left: .5625rem
}

.dropdown-toggle-split::after,.dropend .dropdown-toggle-split::after,.dropup .dropdown-toggle-split::after {
    margin-left: 0
}

.dropstart .dropdown-toggle-split::before {
    margin-right: 0
}

.btn-group-sm>.btn+.dropdown-toggle-split,.btn-sm+.dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

.btn-group-lg>.btn+.dropdown-toggle-split,.btn-lg+.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.btn-group.show .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125)
}

.btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical>.btn,.btn-group-vertical>.btn-group {
    width: 100%
}

.btn-group-vertical>.btn-group:not(:first-child),.btn-group-vertical>.btn:not(:first-child) {
    margin-top: -.0625rem
}

.btn-group-vertical>.btn-group:not(:last-child)>.btn,.btn-group-vertical>.btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical>.btn-group:not(:first-child)>.btn,.btn-group-vertical>.btn~.btn {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem;
    color: #374151;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .nav-link {
        transition: none
    }
}

.nav-link:focus,.nav-link:hover {
    color: #1c2540
}

.nav-link.disabled {
    color: #6b7280;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: .0625rem solid #e5e7eb
}

.nav-tabs .nav-link {
    margin-bottom: -.0625rem;
    background: 0 0;
    border: .0625rem solid transparent;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
}

.nav-tabs .nav-link:focus,.nav-tabs .nav-link:hover {
    border-color: #f3f4f6 #f3f4f6 #e5e7eb;
    isolation: isolate
}

.nav-tabs .nav-link.disabled {
    color: #6b7280;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link,.nav-tabs .nav-link.active {
    color: #4b5563;
    background-color: #e5e7eb;
    border-color: #e5e7eb
}

.nav-tabs .dropdown-menu {
    margin-top: -.0625rem;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    background: 0 0;
    border: 0;
    border-radius: 1rem
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
    color: #ff6200;
    background-color: #f8f8f8
}

.nav-fill .nav-item,.nav-fill>.nav-link {
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item,.nav-justified>.nav-link {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center
}

.nav-fill .nav-item .nav-link,.nav-justified .nav-item .nav-link {
    width: 100%
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    padding-bottom: 1rem
}

.navbar>.container,.navbar>.container-fluid,.navbar>.container-lg,.navbar>.container-md,.navbar>.container-sm,.navbar>.container-xl {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    padding-top: .8125rem;
    padding-bottom: .8125rem;
    margin-right: 1rem;
    font-size: 1.25rem;
    white-space: nowrap
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

.navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    padding: .4rem .6rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: .0625rem solid transparent;
    border-radius: 1rem;
    transition: box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .navbar-toggler {
        transition: none
    }
}

.navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler:focus {
    text-decoration: none;
    outline: 0;
    box-shadow: 0 0 0 .2rem
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%
}

.navbar-nav-scroll {
    max-height: var(--bs-scroll-height,75vh);
    overflow-y: auto
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-wrap:nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .navbar-expand-sm .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-wrap:nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .navbar-expand-md .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-md .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-wrap:nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .navbar-expand-lg .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-wrap:nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .navbar-expand-xl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

@media (min-width: 1400px) {
    .navbar-expand-xxl {
        flex-wrap:nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .8rem;
        padding-left: .8rem
    }

    .navbar-expand-xxl .navbar-nav-scroll {
        overflow: visible
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex!important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-wrap: nowrap;
    justify-content: flex-start
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .8rem;
    padding-left: .8rem
}

.navbar-expand .navbar-nav-scroll {
    overflow: visible
}

.navbar-expand .navbar-collapse {
    display: flex!important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand {
    color: #171f38
}

.navbar-light .navbar-brand:focus,.navbar-light .navbar-brand:hover {
    color: #171f38
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(23,31,56,.9)
}

.navbar-light .navbar-nav .nav-link:focus,.navbar-light .navbar-nav .nav-link:hover {
    color: #171f38
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(23,31,56,.9)
}

.navbar-light .navbar-nav .nav-link.active,.navbar-light .navbar-nav .show>.nav-link {
    color: #171f38
}

.navbar-light .navbar-toggler {
    color: rgba(23,31,56,.9);
    border-color: rgba(23,31,56,.1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2823, 31, 56, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-light .navbar-text {
    color: rgba(23,31,56,.9)
}

.navbar-light .navbar-text a,.navbar-light .navbar-text a:focus,.navbar-light .navbar-text a:hover {
    color: #171f38
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .navbar-brand:focus,.navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: rgba(255,255,255,.9)
}

.navbar-dark .navbar-nav .nav-link:focus,.navbar-dark .navbar-nav .nav-link:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255,255,255,.9)
}

.navbar-dark .navbar-nav .nav-link.active,.navbar-dark .navbar-nav .show>.nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: rgba(255,255,255,.9);
    border-color: rgba(255,255,255,.1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.9%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e")
}

.navbar-dark .navbar-text {
    color: rgba(255,255,255,.9)
}

.navbar-dark .navbar-text a,.navbar-dark .navbar-text a:focus,.navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: .0625rem solid #e5e7eb;
    border-radius: 1rem
}

.card>hr {
    margin-right: 0;
    margin-left: 0
}

.card>.list-group {
    border-top: inherit;
    border-bottom: inherit
}

.card>.list-group:first-child {
    border-top-width: 0;
    border-top-left-radius: .9375rem;
    border-top-right-radius: .9375rem
}

.card>.list-group:last-child {
    border-bottom-width: 0;
    border-bottom-right-radius: .9375rem;
    border-bottom-left-radius: .9375rem
}

.card>.card-header+.list-group,.card>.list-group+.card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.5rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem;
    margin-bottom: 0
}

.card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link+.card-link {
    margin-left: 1.5rem
}

.card-header {
    padding: 1.25rem 1.5rem;
    margin-bottom: 0;
    background-color: rgba(23,31,56,.03);
    border-bottom: .0625rem solid #e5e7eb
}

.card-header:first-child {
    border-radius: .9375rem .9375rem 0 0
}

.card-footer {
    padding: 1.25rem 1.5rem;
    background-color: rgba(23,31,56,.03);
    border-top: .0625rem solid #e5e7eb
}

.card-footer:last-child {
    border-radius: 0 0 .9375rem .9375rem
}

.card-header-tabs {
    margin-right: -.75rem;
    margin-bottom: -1.25rem;
    margin-left: -.75rem;
    border-bottom: 0
}

.card-header-tabs .nav-link.active {
    background-color: #fff;
    border-bottom-color: #fff
}

.card-header-pills {
    margin-right: -.75rem;
    margin-left: -.75rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1rem;
    border-radius: .9375rem
}

.card-img,.card-img-bottom,.card-img-top {
    width: 100%
}

.card-img,.card-img-top {
    border-top-left-radius: .9375rem;
    border-top-right-radius: .9375rem
}

.card-img,.card-img-bottom {
    border-bottom-right-radius: .9375rem;
    border-bottom-left-radius: .9375rem
}

.card-group>.card {
    margin-bottom: .75rem
}

@media (min-width: 576px) {
    .card-group {
        display:flex;
        flex-flow: row wrap
    }

    .card-group>.card {
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group>.card+.card {
        margin-left: 0;
        border-left: 0
    }

    .card-group>.card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-header,.card-group>.card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group>.card:not(:last-child) .card-footer,.card-group>.card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group>.card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-header,.card-group>.card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group>.card:not(:first-child) .card-footer,.card-group>.card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1.5rem 1.5rem;
    font-size: 1rem;
    color: #1f2937;
    text-align: left;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button {
        transition: none
    }
}

.accordion-button:not(.collapsed) {
    color: #202944;
    background-color: #f3f4f6;
    box-shadow: inset 0 -.0625rem 0 #e5e7eb
}

.accordion-button:not(.collapsed)::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23202944'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    transform: rotate(180deg)
}

.accordion-button::after {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%231F2937'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .accordion-button::after {
        transition: none
    }
}

.accordion-button:hover {
    z-index: 2
}

.accordion-button:focus {
    z-index: 3;
    border-color: #6b7280;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(36,46,76,.25)
}

.accordion-header {
    margin-bottom: 0
}

.accordion-item {
    background-color: transparent;
    border: .0625rem solid #e5e7eb
}

.accordion-item:first-of-type {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: .9375rem;
    border-top-right-radius: .9375rem
}

.accordion-item:not(:first-of-type) {
    border-top: 0
}

.accordion-item:last-of-type {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: .9375rem;
    border-bottom-left-radius: .9375rem
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
}

.accordion-body {
    padding: 1.5rem 1.5rem
}

.accordion-flush .accordion-collapse {
    border-width: 0
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.accordion-flush .accordion-item:first-child {
    border-top: 0
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0
}

.accordion-flush .accordion-item .accordion-button {
    border-radius: 0
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    padding: .5rem 1rem;
    margin-bottom: 1rem;
    list-style: none;
    background-color: #f3f4f6;
    border-radius: 1rem
}

.breadcrumb-item+.breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item+.breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6b7280;
    content: var(--bs-breadcrumb-divider, "/")
}

.breadcrumb-item.active {
    color: #6b7280
}

.pagination {
    display: flex;
    padding-left: 0;
    list-style: none
}

.page-link {
    position: relative;
    display: block;
    color: #ff6200;
    background-color: #fff;
    border: .0625rem solid #e5e7eb;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .page-link {
        transition: none
    }
}

.page-link:hover {
    z-index: 2;
    color: #0b0f18;
    background-color: #f3f4f6;
    border-color: #e5e7eb
}

.page-link:focus {
    z-index: 3;
    color: #0b0f18;
    background-color: #f3f4f6;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(36,46,76,.25)
}

.page-item:not(:first-child) .page-link {
    margin-left: -.0625rem
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #ff6200;
    border-color: #ff6200
}

.page-item.disabled .page-link {
    color: #6b7280;
    pointer-events: none;
    background-color: #fff;
    border-color: #e5e7eb
}

.page-link {
    padding: .375rem .75rem
}

.page-item:first-child .page-link {
    border-top-left-radius: 1rem;
    border-bottom-left-radius: 1rem
}

.page-item:last-child .page-link {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.25rem
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .3rem;
    border-bottom-left-radius: .3rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .3rem;
    border-bottom-right-radius: .3rem
}

.pagination-sm .page-link {
    padding: .25rem .5rem;
    font-size: .875rem
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .6rem;
    border-bottom-left-radius: .6rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .6rem;
    border-bottom-right-radius: .6rem
}

.badge {
    display: inline-block;
    padding: .25rem .45rem;
    font-size: .75rem;
    font-weight: 600;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .6rem
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: .0625rem solid transparent;
    border-radius: 1rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 600
}

.alert-dismissible {
    padding-right: 3rem
}

.alert-dismissible .btn-close {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 1.25rem 1rem
}

.alert-primary {
    color: #161c2e;
    background-color: #d3d5db;
    border-color: #bdc0c9
}

.alert-primary .alert-link {
    color: #121625
}

.alert-secondary {
    color: #111626;
    background-color: #d2d3d9;
    border-color: #bbbec6
}

.alert-secondary .alert-link {
    color: #0e121e
}

.alert-tertiary {
    color: #521d3e;
    background-color: #e7d6e1;
    border-color: #dcc1d2
}

.alert-tertiary .alert-link {
    color: #421732
}

.alert-primary-app {
    color: #8d9294;
    background-color: #fbfdfd;
    border-color: #f9fcfc
}

.alert-primary-app .alert-link {
    color: #717576
}

.alert-secondary-app {
    color: #282c60;
    background-color: #d9dbec;
    border-color: #c6c9e3
}

.alert-secondary-app .alert-link {
    color: #20234d
}

.alert-success {
    color: #236950;
    background-color: #d8efe7;
    border-color: #c4e7da
}

.alert-success .alert-link {
    color: #1c5440
}

.alert-info {
    color: #084298;
    background-color: #cfe2ff;
    border-color: #b6d4fe
}

.alert-info .alert-link {
    color: #06357a
}

.alert-warning {
    color: #997404;
    background-color: #fff3cd;
    border-color: #ffecb5
}

.alert-warning .alert-link {
    color: #7a5d03
}

.alert-danger {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7
}

.alert-danger .alert-link {
    color: #6a1a21
}

.alert-white {
    color: #777c8c;
    background-color: #fff;
    border-color: #fff
}

.alert-white .alert-link {
    color: #5f6370
}

.alert-gray {
    color: #131921;
    background-color: #d2d4d7;
    border-color: #bcbfc3
}

.alert-gray .alert-link {
    color: #0f141a
}

.alert-dark {
    color: #111626;
    background-color: #d2d3d9;
    border-color: #bbbec6
}

.alert-dark .alert-link {
    color: #0e121e
}

.alert-black {
    color: #0e1322;
    background-color: #d1d2d7;
    border-color: #b9bcc3
}

.alert-black .alert-link {
    color: #0b0f1b
}

.alert-purple {
    color: #432874;
    background-color: #e2d9f3;
    border-color: #d4c6ec
}

.alert-purple .alert-link {
    color: #36205d
}

.alert-orange {
    color: #8b2914;
    background-color: #fadad3;
    border-color: #f8c7bd
}

.alert-orange .alert-link {
    color: #6f2110
}

.alert-gray-100 {
    color: #747a8b;
    background-color: #fefefe;
    border-color: #fdfefe
}

.alert-gray-100 .alert-link {
    color: #5d626f
}

.alert-gray-200 {
    color: #929294;
    background-color: #fdfdfd;
    border-color: #fbfcfc
}

.alert-gray-200 .alert-link {
    color: #757576
}

.alert-gray-300 {
    color: #898b8d;
    background-color: #fafafb;
    border-color: #f7f8f9
}

.alert-gray-300 .alert-link {
    color: #6e6f71
}

.alert-gray-400 {
    color: #7d8083;
    background-color: #f6f7f8;
    border-color: #f1f2f4
}

.alert-gray-400 .alert-link {
    color: #646669
}

.alert-gray-500 {
    color: #5e6269;
    background-color: #ebedef;
    border-color: #e1e3e7
}

.alert-gray-500 .alert-link {
    color: #4b4e54
}

.alert-gray-600 {
    color: #40444d;
    background-color: #e1e3e6;
    border-color: #d3d5d9
}

.alert-gray-600 .alert-link {
    color: #33363e
}

.alert-gray-700 {
    color: #2d333b;
    background-color: #dbdde0;
    border-color: #c9ccd0
}

.alert-gray-700 .alert-link {
    color: #24292f
}

.alert-gray-800 {
    color: #212731;
    background-color: #d7d9dc;
    border-color: #c3c6cb
}

.alert-gray-800 .alert-link {
    color: #1a1f27
}

@keyframes progress-bar-stripes {
    0% {
        background-position-x: 1rem
    }
}

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: .75rem;
    background-color: #f3f4f6;
    border-radius: 1rem;
    box-shadow: inset 0 1px 2px rgba(23,31,56,.075)
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #ff6200;
    transition: width .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: 1s linear infinite progress-bar-stripes
}

@media (prefers-reduced-motion:reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.list-group {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    border-radius: 1rem
}

.list-group-numbered {
    list-style-type: none;
    counter-reset: section
}

.list-group-numbered>li::before {
    content: counters(section, ".") ". ";
    counter-increment: section
}

.list-group-item-action {
    width: 100%;
    color: #4b5563;
    text-align: inherit
}

.list-group-item-action:focus,.list-group-item-action:hover {
    z-index: 1;
    color: #4b5563;
    text-decoration: none;
    background-color: #f9fafb
}

.list-group-item-action:active {
    color: #1f2937;
    background-color: #f3f4f6
}

.list-group-item {
    position: relative;
    display: block;
    padding: .5rem 1rem;
    color: #1f2937;
    background-color: #fff;
    border: .0625rem solid rgba(23,31,56,.125)
}

.list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

.list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled,.list-group-item:disabled {
    color: #6b7280;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #ff6200;
    border-color: #ff6200
}

.list-group-item+.list-group-item {
    border-top-width: 0
}

.list-group-item+.list-group-item.active {
    margin-top: -.0625rem;
    border-top-width: .0625rem
}

.list-group-horizontal {
    flex-direction: row
}

.list-group-horizontal>.list-group-item:first-child {
    border-bottom-left-radius: 1rem;
    border-top-right-radius: 0
}

.list-group-horizontal>.list-group-item:last-child {
    border-top-right-radius: 1rem;
    border-bottom-left-radius: 0
}

.list-group-horizontal>.list-group-item.active {
    margin-top: 0
}

.list-group-horizontal>.list-group-item+.list-group-item {
    border-top-width: .0625rem;
    border-left-width: 0
}

.list-group-horizontal>.list-group-item+.list-group-item.active {
    margin-left: -.0625rem;
    border-left-width: .0625rem
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction:row
    }

    .list-group-horizontal-sm>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-sm>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item {
        border-top-width: .0625rem;
        border-left-width: 0
    }

    .list-group-horizontal-sm>.list-group-item+.list-group-item.active {
        margin-left: -.0625rem;
        border-left-width: .0625rem
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction:row
    }

    .list-group-horizontal-md>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-md>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item {
        border-top-width: .0625rem;
        border-left-width: 0
    }

    .list-group-horizontal-md>.list-group-item+.list-group-item.active {
        margin-left: -.0625rem;
        border-left-width: .0625rem
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction:row
    }

    .list-group-horizontal-lg>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-lg>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item {
        border-top-width: .0625rem;
        border-left-width: 0
    }

    .list-group-horizontal-lg>.list-group-item+.list-group-item.active {
        margin-left: -.0625rem;
        border-left-width: .0625rem
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction:row
    }

    .list-group-horizontal-xl>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item {
        border-top-width: .0625rem;
        border-left-width: 0
    }

    .list-group-horizontal-xl>.list-group-item+.list-group-item.active {
        margin-left: -.0625rem;
        border-left-width: .0625rem
    }
}

@media (min-width: 1400px) {
    .list-group-horizontal-xxl {
        flex-direction:row
    }

    .list-group-horizontal-xxl>.list-group-item:first-child {
        border-bottom-left-radius: 1rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item:last-child {
        border-top-right-radius: 1rem;
        border-bottom-left-radius: 0
    }

    .list-group-horizontal-xxl>.list-group-item.active {
        margin-top: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item {
        border-top-width: .0625rem;
        border-left-width: 0
    }

    .list-group-horizontal-xxl>.list-group-item+.list-group-item.active {
        margin-left: -.0625rem;
        border-left-width: .0625rem
    }
}

.list-group-flush {
    border-radius: 0
}

.list-group-flush>.list-group-item {
    border-width: 0 0 .0625rem
}

.list-group-flush>.list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-primary {
    color: #161c2e;
    background-color: #d3d5db
}

.list-group-item-primary.list-group-item-action:focus,.list-group-item-primary.list-group-item-action:hover {
    color: #161c2e;
    background-color: #bec0c5
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #161c2e;
    border-color: #161c2e
}

.list-group-item-secondary {
    color: #111626;
    background-color: #d2d3d9
}

.list-group-item-secondary.list-group-item-action:focus,.list-group-item-secondary.list-group-item-action:hover {
    color: #111626;
    background-color: #bdbec3
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #111626;
    border-color: #111626
}

.list-group-item-tertiary {
    color: #521d3e;
    background-color: #e7d6e1
}

.list-group-item-tertiary.list-group-item-action:focus,.list-group-item-tertiary.list-group-item-action:hover {
    color: #521d3e;
    background-color: #d0c1cb
}

.list-group-item-tertiary.list-group-item-action.active {
    color: #fff;
    background-color: #521d3e;
    border-color: #521d3e
}

.list-group-item-primary-app {
    color: #8d9294;
    background-color: #fbfdfd
}

.list-group-item-primary-app.list-group-item-action:focus,.list-group-item-primary-app.list-group-item-action:hover {
    color: #8d9294;
    background-color: #e2e4e4
}

.list-group-item-primary-app.list-group-item-action.active {
    color: #fff;
    background-color: #8d9294;
    border-color: #8d9294
}

.list-group-item-secondary-app {
    color: #282c60;
    background-color: #d9dbec
}

.list-group-item-secondary-app.list-group-item-action:focus,.list-group-item-secondary-app.list-group-item-action:hover {
    color: #282c60;
    background-color: #c3c5d4
}

.list-group-item-secondary-app.list-group-item-action.active {
    color: #fff;
    background-color: #282c60;
    border-color: #282c60
}

.list-group-item-success {
    color: #236950;
    background-color: #d8efe7
}

.list-group-item-success.list-group-item-action:focus,.list-group-item-success.list-group-item-action:hover {
    color: #236950;
    background-color: #c2d7d0
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #236950;
    border-color: #236950
}

.list-group-item-info {
    color: #084298;
    background-color: #cfe2ff
}

.list-group-item-info.list-group-item-action:focus,.list-group-item-info.list-group-item-action:hover {
    color: #084298;
    background-color: #bacbe6
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #084298;
    border-color: #084298
}

.list-group-item-warning {
    color: #997404;
    background-color: #fff3cd
}

.list-group-item-warning.list-group-item-action:focus,.list-group-item-warning.list-group-item-action:hover {
    color: #997404;
    background-color: #e6dbb9
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #997404;
    border-color: #997404
}

.list-group-item-danger {
    color: #842029;
    background-color: #f8d7da
}

.list-group-item-danger.list-group-item-action:focus,.list-group-item-danger.list-group-item-action:hover {
    color: #842029;
    background-color: #dfc2c4
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #842029;
    border-color: #842029
}

.list-group-item-white {
    color: #777c8c;
    background-color: #fff
}

.list-group-item-white.list-group-item-action:focus,.list-group-item-white.list-group-item-action:hover {
    color: #777c8c;
    background-color: #e6e6e6
}

.list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #777c8c;
    border-color: #777c8c
}

.list-group-item-gray {
    color: #131921;
    background-color: #d2d4d7
}

.list-group-item-gray.list-group-item-action:focus,.list-group-item-gray.list-group-item-action:hover {
    color: #131921;
    background-color: #bdbfc2
}

.list-group-item-gray.list-group-item-action.active {
    color: #fff;
    background-color: #131921;
    border-color: #131921
}

.list-group-item-dark {
    color: #111626;
    background-color: #d2d3d9
}

.list-group-item-dark.list-group-item-action:focus,.list-group-item-dark.list-group-item-action:hover {
    color: #111626;
    background-color: #bdbec3
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #111626;
    border-color: #111626
}

.list-group-item-black {
    color: #0e1322;
    background-color: #d1d2d7
}

.list-group-item-black.list-group-item-action:focus,.list-group-item-black.list-group-item-action:hover {
    color: #0e1322;
    background-color: #bcbdc2
}

.list-group-item-black.list-group-item-action.active {
    color: #fff;
    background-color: #0e1322;
    border-color: #0e1322
}

.list-group-item-purple {
    color: #432874;
    background-color: #e2d9f3
}

.list-group-item-purple.list-group-item-action:focus,.list-group-item-purple.list-group-item-action:hover {
    color: #432874;
    background-color: #cbc3db
}

.list-group-item-purple.list-group-item-action.active {
    color: #fff;
    background-color: #432874;
    border-color: #432874
}

.list-group-item-orange {
    color: #8b2914;
    background-color: #fadad3
}

.list-group-item-orange.list-group-item-action:focus,.list-group-item-orange.list-group-item-action:hover {
    color: #8b2914;
    background-color: #e1c4be
}

.list-group-item-orange.list-group-item-action.active {
    color: #fff;
    background-color: #8b2914;
    border-color: #8b2914
}

.list-group-item-gray-100 {
    color: #747a8b;
    background-color: #fefefe
}

.list-group-item-gray-100.list-group-item-action:focus,.list-group-item-gray-100.list-group-item-action:hover {
    color: #747a8b;
    background-color: #e5e5e5
}

.list-group-item-gray-100.list-group-item-action.active {
    color: #fff;
    background-color: #747a8b;
    border-color: #747a8b
}

.list-group-item-gray-200 {
    color: #929294;
    background-color: #fdfdfd
}

.list-group-item-gray-200.list-group-item-action:focus,.list-group-item-gray-200.list-group-item-action:hover {
    color: #929294;
    background-color: #e4e4e4
}

.list-group-item-gray-200.list-group-item-action.active {
    color: #fff;
    background-color: #929294;
    border-color: #929294
}

.list-group-item-gray-300 {
    color: #898b8d;
    background-color: #fafafb
}

.list-group-item-gray-300.list-group-item-action:focus,.list-group-item-gray-300.list-group-item-action:hover {
    color: #898b8d;
    background-color: #e1e1e2
}

.list-group-item-gray-300.list-group-item-action.active {
    color: #fff;
    background-color: #898b8d;
    border-color: #898b8d
}

.list-group-item-gray-400 {
    color: #7d8083;
    background-color: #f6f7f8
}

.list-group-item-gray-400.list-group-item-action:focus,.list-group-item-gray-400.list-group-item-action:hover {
    color: #7d8083;
    background-color: #dddedf
}

.list-group-item-gray-400.list-group-item-action.active {
    color: #fff;
    background-color: #7d8083;
    border-color: #7d8083
}

.list-group-item-gray-500 {
    color: #5e6269;
    background-color: #ebedef
}

.list-group-item-gray-500.list-group-item-action:focus,.list-group-item-gray-500.list-group-item-action:hover {
    color: #5e6269;
    background-color: #d4d5d7
}

.list-group-item-gray-500.list-group-item-action.active {
    color: #fff;
    background-color: #5e6269;
    border-color: #5e6269
}

.list-group-item-gray-600 {
    color: #40444d;
    background-color: #e1e3e6
}

.list-group-item-gray-600.list-group-item-action:focus,.list-group-item-gray-600.list-group-item-action:hover {
    color: #40444d;
    background-color: #cbcccf
}

.list-group-item-gray-600.list-group-item-action.active {
    color: #fff;
    background-color: #40444d;
    border-color: #40444d
}

.list-group-item-gray-700 {
    color: #2d333b;
    background-color: #dbdde0
}

.list-group-item-gray-700.list-group-item-action:focus,.list-group-item-gray-700.list-group-item-action:hover {
    color: #2d333b;
    background-color: #c5c7ca
}

.list-group-item-gray-700.list-group-item-action.active {
    color: #fff;
    background-color: #2d333b;
    border-color: #2d333b
}

.list-group-item-gray-800 {
    color: #212731;
    background-color: #d7d9dc
}

.list-group-item-gray-800.list-group-item-action:focus,.list-group-item-gray-800.list-group-item-action:hover {
    color: #212731;
    background-color: #c2c3c6
}

.list-group-item-gray-800.list-group-item-action.active {
    color: #fff;
    background-color: #212731;
    border-color: #212731
}

.btn-close {
    box-sizing: content-box;
    width: .75em;
    height: .75em;
    padding: .25em .25em;
    color: #171f38;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23171f38'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/.75em auto no-repeat;
    border: 0;
    border-radius: 1rem;
    opacity: .5
}

.btn-close:hover {
    color: #171f38;
    text-decoration: none;
    opacity: .75
}

.btn-close:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(36,46,76,.25);
    opacity: 1
}

.btn-close.disabled,.btn-close:disabled {
    pointer-events: none;
    -webkit-user-select: none;
    user-select: none;
    opacity: .25
}

.btn-close-white {
    filter: invert(1) grayscale(100%) brightness(200%)
}

.toast {
    width: 350px;
    max-width: 100%;
    font-size: .875rem;
    pointer-events: auto;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.1);
    box-shadow: rgba(0,0,0,.1) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px;
    border-radius: 1rem
}

.toast:not(.showing):not(.show) {
    opacity: 0
}

.toast.hide {
    display: none
}

.toast-container {
    width: max-content;
    max-width: 100%;
    pointer-events: none
}

.toast-container>:not(:last-child) {
    margin-bottom: 1rem
}

.toast-header {
    display: flex;
    align-items: center;
    padding: .25rem .75rem;
    color: #6b7280;
    background-color: rgba(255,255,255,.85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0,0,0,.05);
    border-top-left-radius: calc(1rem - 1px);
    border-top-right-radius: calc(1rem - 1px)
}

.toast-header .btn-close {
    margin-right: -.375rem;
    margin-left: .75rem
}

.toast-body {
    padding: .75rem;
    word-wrap: break-word
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translate(0,-50px)
}

@media (prefers-reduced-motion:reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: 100%;
    overflow: hidden
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: .0625rem solid rgba(23,31,56,.2);
    border-radius: .3rem;
    box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #171f38
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-bottom: .0625rem solid #e5e7eb;
    border-top-left-radius: .2375rem;
    border-top-right-radius: .2375rem
}

.modal-header .btn-close {
    padding: .5rem .5rem;
    margin: -.5rem -.5rem -.5rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    padding: .75rem;
    border-top: .0625rem solid #e5e7eb;
    border-bottom-right-radius: .2375rem;
    border-bottom-left-radius: .2375rem
}

.modal-footer>* {
    margin: .25rem
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width:500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-content {
        box-shadow: rgba(0,0,0,.1) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width: 992px) {
    .modal-lg,.modal-xl {
        max-width:800px
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width:1140px
    }
}

.modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0
}

.modal-fullscreen .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0
}

.modal-fullscreen .modal-header {
    border-radius: 0
}

.modal-fullscreen .modal-body {
    overflow-y: auto
}

.modal-fullscreen .modal-footer {
    border-radius: 0
}

@media (max-width: 575.98px) {
    .modal-fullscreen-sm-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-sm-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-sm-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-sm-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width: 767.98px) {
    .modal-fullscreen-md-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-md-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-md-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-md-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width: 991.98px) {
    .modal-fullscreen-lg-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-lg-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-lg-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-lg-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width: 1199.98px) {
    .modal-fullscreen-xl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xl-down .modal-footer {
        border-radius: 0
    }
}

@media (max-width: 1399.98px) {
    .modal-fullscreen-xxl-down {
        width:100vw;
        max-width: none;
        height: 100%;
        margin: 0
    }

    .modal-fullscreen-xxl-down .modal-content {
        height: 100%;
        border: 0;
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-header {
        border-radius: 0
    }

    .modal-fullscreen-xxl-down .modal-body {
        overflow-y: auto
    }

    .modal-fullscreen-xxl-down .modal-footer {
        border-radius: 0
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: .9
}

.tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[data-popper-placement^=top],.bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow,.bs-tooltip-top .tooltip-arrow {
    bottom: 0
}

.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before,.bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #fff
}

.bs-tooltip-auto[data-popper-placement^=right],.bs-tooltip-end {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow,.bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before,.bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #fff
}

.bs-tooltip-auto[data-popper-placement^=bottom],.bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow,.bs-tooltip-bottom .tooltip-arrow {
    top: 0
}

.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before,.bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #fff
}

.bs-tooltip-auto[data-popper-placement^=left],.bs-tooltip-start {
    padding: 0 .4rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow,.bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before,.bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #fff
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #171f38;
    text-align: center;
    background-color: #fff;
    border-radius: 1rem
}

.popover {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1060;
    display: block;
    max-width: 276px;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: .0625rem solid rgba(23,31,56,.2);
    border-radius: .3rem;
    box-shadow: rgba(0,0,0,.1) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px
}

.popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

.popover .popover-arrow::after,.popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow,.bs-popover-top>.popover-arrow {
    bottom: -.5625rem
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before,.bs-popover-top>.popover-arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(23,31,56,.25)
}

.bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after,.bs-popover-top>.popover-arrow::after {
    bottom: .0625rem;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow,.bs-popover-end>.popover-arrow {
    left: -.5625rem;
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before,.bs-popover-end>.popover-arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(23,31,56,.25)
}

.bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after,.bs-popover-end>.popover-arrow::after {
    left: .0625rem;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow,.bs-popover-bottom>.popover-arrow {
    top: -.5625rem
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before,.bs-popover-bottom>.popover-arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(23,31,56,.25)
}

.bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after,.bs-popover-bottom>.popover-arrow::after {
    top: .0625rem;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[data-popper-placement^=bottom] .popover-header::before,.bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: .0625rem solid #f7f7f7
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow,.bs-popover-start>.popover-arrow {
    right: -.5625rem;
    width: .5rem;
    height: 1rem
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before,.bs-popover-start>.popover-arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(23,31,56,.25)
}

.bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after,.bs-popover-start>.popover-arrow::after {
    right: .0625rem;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    color: #1c2540;
    background-color: #f7f7f7;
    border-bottom: .0625rem solid #dfdfdf;
    border-top-left-radius: .2375rem;
    border-top-right-radius: .2375rem
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: 1rem 1rem;
    color: #1f2937
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner::after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next,.carousel-item-prev,.carousel-item.active {
    display: block
}

.active.carousel-item-end,.carousel-item-next:not(.carousel-item-start) {
    transform: translateX(100%)
}

.active.carousel-item-start,.carousel-item-prev:not(.carousel-item-end) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-start,.carousel-fade .carousel-item-prev.carousel-item-end,.carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion:reduce) {
    .carousel-fade .active.carousel-item-end,.carousel-fade .active.carousel-item-start {
        transition: none
    }
}

.carousel-control-next,.carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    padding: 0;
    color: #fff;
    text-align: center;
    background: 0 0;
    border: 0;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-control-next,.carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus,.carousel-control-next:hover,.carousel-control-prev:focus,.carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon,.carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: 100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3e%3c/svg%3e")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23ffffff' viewBox='0 0 8 8'%3e%3cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3e%3c/svg%3e")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    padding: 0;
    margin-right: 15%;
    margin-bottom: 1rem;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators [data-bs-target] {
    box-sizing: content-box;
    flex: 0 1 auto;
    width: 10px;
    height: 10px;
    padding: 0;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion:reduce) {
    .carousel-indicators [data-bs-target] {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 1.25rem;
    left: 15%;
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: center
}

.carousel-dark .carousel-control-next-icon,.carousel-dark .carousel-control-prev-icon {
    filter: invert(1) grayscale(100)
}

.carousel-dark .carousel-indicators [data-bs-target] {
    background-color: #171f38
}

.carousel-dark .carousel-caption {
    color: #171f38
}

.offcanvas {
    position: fixed;
    bottom: 0;
    z-index: 1050;
    display: flex;
    flex-direction: column;
    max-width: 100%;
    visibility: hidden;
    background-color: #fff;
    background-clip: padding-box;
    outline: 0;
    box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0;
    transition: transform .3s ease-in-out
}

@media (prefers-reduced-motion:reduce) {
    .offcanvas {
        transition: none
    }
}

.offcanvas-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 1rem
}

.offcanvas-header .btn-close {
    padding: .5rem .5rem;
    margin: -.5rem -.5rem -.5rem auto
}

.offcanvas-title {
    margin-bottom: 0;
    line-height: 1.5
}

.offcanvas-body {
    flex-grow: 1;
    padding: 1rem 1rem;
    overflow-y: auto
}

.offcanvas-start {
    top: 0;
    left: 0;
    width: 400px;
    border-right: .0625rem solid rgba(23,31,56,.2);
    transform: translateX(-100%)
}

.offcanvas-end {
    top: 0;
    right: 0;
    width: 400px;
    border-left: .0625rem solid rgba(23,31,56,.2);
    transform: translateX(100%)
}

.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-bottom: .0625rem solid rgba(23,31,56,.2);
    transform: translateY(-100%)
}

.offcanvas-bottom {
    right: 0;
    left: 0;
    height: 30vh;
    max-height: 100%;
    border-top: .0625rem solid rgba(23,31,56,.2);
    transform: translateY(100%)
}

.offcanvas.show {
    transform: none
}

@keyframes spinner-border {
    to {
        transform: rotate(360deg)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -.125em;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: .75s linear infinite spinner-border
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }

    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: -.125em;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: .75s linear infinite spinner-grow
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

@media (prefers-reduced-motion:reduce) {
    .spinner-border,.spinner-grow {
        animation-duration: 1.5s
    }
}

.clearfix::after {
    display: block;
    clear: both;
    content: ""
}

.link-primary {
    color: #ff6200
}

.link-primary:focus,.link-primary:hover {
    color: #1d253d
}

.link-secondary {
    color: #1c2540
}

.link-secondary:focus,.link-secondary:hover {
    color: #161e33
}

.link-tertiary {
    color: #893168
}

.link-tertiary:focus,.link-tertiary:hover {
    color: #6e2753
}

.link-primary-app {
    color: #ebf4f6
}

.link-primary-app:focus,.link-primary-app:hover {
    color: #eff6f8
}

.link-secondary-app {
    color: #424aa0
}

.link-secondary-app:focus,.link-secondary-app:hover {
    color: #353b80
}

.link-success {
    color: #3aaf85
}

.link-success:focus,.link-success:hover {
    color: #61bf9d
}

.link-info {
    color: #0d6efd
}

.link-info:focus,.link-info:hover {
    color: #0a58ca
}

.link-warning {
    color: #ffc107
}

.link-warning:focus,.link-warning:hover {
    color: #ffcd39
}

.link-danger {
    color: #dc3545
}

.link-danger:focus,.link-danger:hover {
    color: #b02a37
}

.link-white {
    color: #fff
}

.link-white:focus,.link-white:hover {
    color: #fff
}

.link-gray {
    color: #1f2937
}

.link-gray:focus,.link-gray:hover {
    color: #19212c
}

.link-dark {
    color: #1c2540
}

.link-dark:focus,.link-dark:hover {
    color: #161e33
}

.link-black {
    color: #171f38
}

.link-black:focus,.link-black:hover {
    color: #12192d
}

.link-purple {
    color: #6f42c1
}

.link-purple:focus,.link-purple:hover {
    color: #59359a
}

.link-orange {
    color: #e84422
}

.link-orange:focus,.link-orange:hover {
    color: #ba361b
}

.link-gray-100 {
    color: #f9fafb
}

.link-gray-100:focus,.link-gray-100:hover {
    color: #fafbfc
}

.link-gray-200 {
    color: #f3f4f6
}

.link-gray-200:focus,.link-gray-200:hover {
    color: #f5f6f8
}

.link-gray-300 {
    color: #e5e7eb
}

.link-gray-300:focus,.link-gray-300:hover {
    color: #eaecef
}

.link-gray-400 {
    color: #d1d5db
}

.link-gray-400:focus,.link-gray-400:hover {
    color: #dadde2
}

.link-gray-500 {
    color: #9ca3af
}

.link-gray-500:focus,.link-gray-500:hover {
    color: #b0b5bf
}

.link-gray-600 {
    color: #6b7280
}

.link-gray-600:focus,.link-gray-600:hover {
    color: #565b66
}

.link-gray-700 {
    color: #4b5563
}

.link-gray-700:focus,.link-gray-700:hover {
    color: #3c444f
}

.link-gray-800 {
    color: #374151
}

.link-gray-800:focus,.link-gray-800:hover {
    color: #2c3441
}

.ratio {
    position: relative;
    width: 100%
}

.ratio::before {
    display: block;
    padding-top: var(--bs-aspect-ratio);
    content: ""
}

.ratio>* {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.ratio-1x1 {
    --bs-aspect-ratio: 100%
}

.ratio-4x3 {
    --bs-aspect-ratio: calc(3 / 4 * 100%)
}

.ratio-16x9 {
    --bs-aspect-ratio: calc(9 / 16 * 100%)
}

.ratio-21x9 {
    --bs-aspect-ratio: calc(9 / 21 * 100%)
}

.fixed-top {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1030
}

.sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020
}

@media (min-width: 576px) {
    .sticky-sm-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width: 768px) {
    .sticky-md-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width: 992px) {
    .sticky-lg-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width: 1200px) {
    .sticky-xl-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }
}

@media (min-width: 1400px) {
    .sticky-xxl-top {
        position:sticky;
        top: 0;
        z-index: 1020
    }
}

.visually-hidden,.visually-hidden-focusable:not(:focus):not(:focus-within) {
    position: absolute!important;
    width: 1px!important;
    height: 1px!important;
    padding: 0!important;
    margin: -1px!important;
    overflow: hidden!important;
    clip: rect(0,0,0,0)!important;
    white-space: nowrap!important;
    border: 0!important
}

.stretched-link::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    content: ""
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.align-baseline {
    vertical-align: baseline!important
}

.align-top {
    vertical-align: top!important
}

.align-middle {
    vertical-align: middle!important
}

.align-bottom {
    vertical-align: bottom!important
}

.align-text-bottom {
    vertical-align: text-bottom!important
}

.align-text-top {
    vertical-align: text-top!important
}

.float-start {
    float: left!important
}

.float-end {
    float: right!important
}

.float-none {
    float: none!important
}

.overflow-auto {
    overflow: auto!important
}

.overflow-hidden {
    overflow: hidden!important
}

.overflow-visible {
    overflow: visible!important
}

.overflow-scroll {
    overflow: scroll!important
}

.d-inline {
    display: inline!important
}

.d-inline-block {
    display: inline-block!important
}

.d-block {
    display: block!important
}

.d-grid {
    display: grid!important
}

.d-table {
    display: table!important
}

.d-table-row {
    display: table-row!important
}

.d-table-cell {
    display: table-cell!important
}

.d-flex {
    display: flex!important
}

.d-inline-flex {
    display: inline-flex!important
}

.d-none {
    display: none!important
}

.shadow {
    box-shadow: rgba(0,0,0,.1) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px!important
}

.shadow-sm {
    box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0!important
}

.shadow-lg {
    box-shadow: rgba(0,0,0,.1) 0 10px 15px -3px,rgba(0,0,0,.05) 0 4px 6px -2px!important
}

.shadow-none {
    box-shadow: none!important
}

.position-static {
    position: static!important
}

.position-relative {
    position: relative!important
}

.position-absolute {
    position: absolute!important
}

.headroom--pinned,.headroom--unpinned,.position-fixed {
    position: fixed!important
}

.position-sticky {
    position: sticky!important
}

.top-0 {
    top: 0!important
}

.top-50 {
    top: 50%!important
}

.top-100 {
    top: 100%!important
}

.bottom-0 {
    bottom: 0!important
}

.bottom-50 {
    bottom: 50%!important
}

.bottom-100 {
    bottom: 100%!important
}

.start-0 {
    left: 0!important
}

.start-50 {
    left: 50%!important
}

.start-100 {
    left: 100%!important
}

.end-0 {
    right: 0!important
}

.end-50 {
    right: 50%!important
}

.end-100 {
    right: 100%!important
}

.translate-middle {
    transform: translate(-50%,-50%)!important
}

.translate-middle-x {
    transform: translateX(-50%)!important
}

.translate-middle-y {
    transform: translateY(-50%)!important
}

.border {
    border: .0625rem solid #e5e7eb!important
}

.border-0 {
    border: 0!important
}

.border-top {
    border-top: .0625rem solid #e5e7eb!important
}

.border-top-0 {
    border-top: 0!important
}

.border-end {
    border-right: .0625rem solid #e5e7eb!important
}

.border-end-0 {
    border-right: 0!important
}

.border-bottom {
    border-bottom: .0625rem solid #e5e7eb!important
}

.border-bottom-0 {
    border-bottom: 0!important
}

.border-start {
    border-left: .0625rem solid #e5e7eb!important
}

.border-start-0 {
    border-left: 0!important
}

.border-primary {
    border-color: #ff6200!important
}

.border-secondary {
    border-color: #1c2540!important
}

.border-tertiary {
    border-color: #893168!important
}

.border-primary-app {
    border-color: #ebf4f6!important
}

.border-secondary-app {
    border-color: #424aa0!important
}

.border-success {
    border-color: #3aaf85!important
}

.border-info {
    border-color: #0d6efd!important
}

.border-warning {
    border-color: #ffc107!important
}

.border-danger {
    border-color: #dc3545!important
}

.border-white {
    border-color: #fff!important
}

.border-gray {
    border-color: #1f2937!important
}

.border-dark {
    border-color: #1c2540!important
}

.border-black {
    border-color: #171f38!important
}

.border-purple {
    border-color: #6f42c1!important
}

.border-orange {
    border-color: #e84422!important
}

.border-gray-100 {
    border-color: #f9fafb!important
}

.border-gray-200 {
    border-color: #f3f4f6!important
}

.border-gray-300 {
    border-color: #e5e7eb!important
}

.border-gray-400 {
    border-color: #d1d5db!important
}

.border-gray-500 {
    border-color: #9ca3af!important
}

.border-gray-600 {
    border-color: #6b7280!important
}

.border-gray-700 {
    border-color: #4b5563!important
}

.border-gray-800 {
    border-color: #374151!important
}

.border-1 {
    border-width: 1px!important
}

.border-2 {
    border-width: 2px!important
}

.border-3 {
    border-width: 3px!important
}

.border-4 {
    border-width: 4px!important
}

.border-5 {
    border-width: 5px!important
}

.w-25 {
    width: 25%!important
}

.w-50 {
    width: 50%!important
}

.w-75 {
    width: 75%!important
}

.w-100 {
    width: 100%!important
}

.w-auto {
    width: auto!important
}

.mw-100 {
    max-width: 100%!important
}

.vw-100 {
    width: 100vw!important
}

.min-vw-100 {
    min-width: 100vw!important
}

.h-25 {
    height: 25%!important
}

.h-50 {
    height: 50%!important
}

.h-75 {
    height: 75%!important
}

.h-100 {
    height: 100%!important
}

.h-auto {
    height: auto!important
}

.mh-100 {
    max-height: 100%!important
}

.vh-100 {
    height: 100vh!important
}

.min-vh-100 {
    min-height: 100vh!important
}

.flex-fill {
    flex: 1 1 auto!important
}

.flex-row {
    flex-direction: row!important
}

.flex-column {
    flex-direction: column!important
}

.flex-row-reverse {
    flex-direction: row-reverse!important
}

.flex-column-reverse {
    flex-direction: column-reverse!important
}

.flex-grow-0 {
    flex-grow: 0!important
}

.flex-grow-1 {
    flex-grow: 1!important
}

.flex-shrink-0 {
    flex-shrink: 0!important
}

.flex-shrink-1 {
    flex-shrink: 1!important
}

.flex-wrap {
    flex-wrap: wrap!important
}

.flex-nowrap {
    flex-wrap: nowrap!important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse!important
}

.gap-0 {
    gap: 0!important
}

.gap-1 {
    gap: .25rem!important
}

.gap-2 {
    gap: .5rem!important
}

.gap-3 {
    gap: 1rem!important
}

.gap-4 {
    gap: 1.5rem!important
}

.gap-5 {
    gap: 3rem!important
}

.gap-6 {
    gap: 5rem!important
}

.gap-7 {
    gap: 8rem!important
}

.gap-8 {
    gap: 10rem!important
}

.gap-9 {
    gap: 11rem!important
}

.gap-10 {
    gap: 14rem!important
}

.gap-11 {
    gap: 16rem!important
}

.gap-12 {
    gap: 20rem!important
}

.gap-sm {
    gap: 1rem!important
}

.gap-md {
    gap: 2rem!important
}

.gap-lg {
    gap: 4rem!important
}

.gap-xl {
    gap: 8rem!important
}

.justify-content-start {
    justify-content: flex-start!important
}

.justify-content-end {
    justify-content: flex-end!important
}

.justify-content-center {
    justify-content: center!important
}

.justify-content-between {
    justify-content: space-between!important
}

.justify-content-around {
    justify-content: space-around!important
}

.justify-content-evenly {
    justify-content: space-evenly!important
}

.align-items-start {
    align-items: flex-start!important
}

.align-items-end {
    align-items: flex-end!important
}

.align-items-center {
    align-items: center!important
}

.align-items-baseline {
    align-items: baseline!important
}

.align-items-stretch {
    align-items: stretch!important
}

.align-content-start {
    align-content: flex-start!important
}

.align-content-end {
    align-content: flex-end!important
}

.align-content-center {
    align-content: center!important
}

.align-content-between {
    align-content: space-between!important
}

.align-content-around {
    align-content: space-around!important
}

.align-content-stretch {
    align-content: stretch!important
}

.align-self-auto {
    align-self: auto!important
}

.align-self-start {
    align-self: flex-start!important
}

.align-self-end {
    align-self: flex-end!important
}

.align-self-center {
    align-self: center!important
}

.align-self-baseline {
    align-self: baseline!important
}

.align-self-stretch {
    align-self: stretch!important
}

.order-first {
    order: -1!important
}

.order-0 {
    order: 0!important
}

.order-1 {
    order: 1!important
}

.order-2 {
    order: 2!important
}

.order-3 {
    order: 3!important
}

.order-4 {
    order: 4!important
}

.order-5 {
    order: 5!important
}

.order-last {
    order: 6!important
}

.m-0 {
    margin: 0!important
}

.m-1 {
    margin: .25rem!important
}

.m-2 {
    margin: .5rem!important
}

.m-3 {
    margin: 1rem!important
}

.m-4 {
    margin: 1.5rem!important
}

.m-5 {
    margin: 3rem!important
}

.m-6 {
    margin: 5rem!important
}

.m-7 {
    margin: 8rem!important
}

.m-8 {
    margin: 10rem!important
}

.m-9 {
    margin: 11rem!important
}

.m-10 {
    margin: 14rem!important
}

.m-11 {
    margin: 16rem!important
}

.m-12 {
    margin: 20rem!important
}

.m-sm {
    margin: 1rem!important
}

.m-md {
    margin: 2rem!important
}

.m-lg {
    margin: 4rem!important
}

.m-xl {
    margin: 8rem!important
}

.m-auto {
    margin: auto!important
}

.mx-0 {
    margin-right: 0!important;
    margin-left: 0!important
}

.mx-1 {
    margin-right: .25rem!important;
    margin-left: .25rem!important
}

.mx-2 {
    margin-right: .5rem!important;
    margin-left: .5rem!important
}

.mx-3 {
    margin-right: 1rem!important;
    margin-left: 1rem!important
}

.mx-4 {
    margin-right: 1.5rem!important;
    margin-left: 1.5rem!important
}

.mx-5 {
    margin-right: 3rem!important;
    margin-left: 3rem!important
}

.mx-6 {
    margin-right: 5rem!important;
    margin-left: 5rem!important
}

.mx-7 {
    margin-right: 8rem!important;
    margin-left: 8rem!important
}

.mx-8 {
    margin-right: 10rem!important;
    margin-left: 10rem!important
}

.mx-9 {
    margin-right: 11rem!important;
    margin-left: 11rem!important
}

.mx-10 {
    margin-right: 14rem!important;
    margin-left: 14rem!important
}

.mx-11 {
    margin-right: 16rem!important;
    margin-left: 16rem!important
}

.mx-12 {
    margin-right: 20rem!important;
    margin-left: 20rem!important
}

.mx-sm {
    margin-right: 1rem!important;
    margin-left: 1rem!important
}

.mx-md {
    margin-right: 2rem!important;
    margin-left: 2rem!important
}

.mx-lg {
    margin-right: 4rem!important;
    margin-left: 4rem!important
}

.mx-xl {
    margin-right: 8rem!important;
    margin-left: 8rem!important
}

.mx-auto {
    margin-right: auto!important;
    margin-left: auto!important
}

.my-0 {
    margin-top: 0!important;
    margin-bottom: 0!important
}

.my-1 {
    margin-top: .25rem!important;
    margin-bottom: .25rem!important
}

.my-2 {
    margin-top: .5rem!important;
    margin-bottom: .5rem!important
}

.my-3 {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important
}

.my-4 {
    margin-top: 1.5rem!important;
    margin-bottom: 1.5rem!important
}

.my-5 {
    margin-top: 3rem!important;
    margin-bottom: 3rem!important
}

.my-6 {
    margin-top: 5rem!important;
    margin-bottom: 5rem!important
}

.my-7 {
    margin-top: 8rem!important;
    margin-bottom: 8rem!important
}

.my-8 {
    margin-top: 10rem!important;
    margin-bottom: 10rem!important
}

.my-9 {
    margin-top: 11rem!important;
    margin-bottom: 11rem!important
}

.my-10 {
    margin-top: 14rem!important;
    margin-bottom: 14rem!important
}

.my-11 {
    margin-top: 16rem!important;
    margin-bottom: 16rem!important
}

.my-12 {
    margin-top: 20rem!important;
    margin-bottom: 20rem!important
}

.my-sm {
    margin-top: 1rem!important;
    margin-bottom: 1rem!important
}

.my-md {
    margin-top: 2rem!important;
    margin-bottom: 2rem!important
}

.my-lg {
    margin-top: 4rem!important;
    margin-bottom: 4rem!important
}

.my-xl {
    margin-top: 8rem!important;
    margin-bottom: 8rem!important
}

.my-auto {
    margin-top: auto!important;
    margin-bottom: auto!important
}

.mt-0 {
    margin-top: 0!important
}

.mt-1 {
    margin-top: .25rem!important
}

.mt-2 {
    margin-top: .5rem!important
}

.mt-3 {
    margin-top: 1rem!important
}

.mt-4 {
    margin-top: 1.5rem!important
}

.mt-5 {
    margin-top: 3rem!important
}

.mt-6 {
    margin-top: 5rem!important
}

.mt-7 {
    margin-top: 8rem!important
}

.mt-8 {
    margin-top: 10rem!important
}

.mt-9 {
    margin-top: 11rem!important
}

.mt-10 {
    margin-top: 14rem!important
}

.mt-11 {
    margin-top: 16rem!important
}

.mt-12 {
    margin-top: 20rem!important
}

.mt-sm {
    margin-top: 1rem!important
}

.mt-md {
    margin-top: 2rem!important
}

.mt-lg {
    margin-top: 4rem!important
}

.mt-xl {
    margin-top: 8rem!important
}

.mt-auto {
    margin-top: auto!important
}

.me-0 {
    margin-right: 0!important
}

.me-1 {
    margin-right: .25rem!important
}

.me-2 {
    margin-right: .5rem!important
}

.me-3 {
    margin-right: 1rem!important
}

.me-4 {
    margin-right: 1.5rem!important
}

.me-5 {
    margin-right: 3rem!important
}

.me-6 {
    margin-right: 5rem!important
}

.me-7 {
    margin-right: 8rem!important
}

.me-8 {
    margin-right: 10rem!important
}

.me-9 {
    margin-right: 11rem!important
}

.me-10 {
    margin-right: 14rem!important
}

.me-11 {
    margin-right: 16rem!important
}

.me-12 {
    margin-right: 20rem!important
}

.me-sm {
    margin-right: 1rem!important
}

.me-md {
    margin-right: 2rem!important
}

.me-lg {
    margin-right: 4rem!important
}

.me-xl {
    margin-right: 8rem!important
}

.me-auto {
    margin-right: auto!important
}

.mb-0 {
    margin-bottom: 0!important
}

.mb-1 {
    margin-bottom: .25rem!important
}

.mb-2 {
    margin-bottom: .5rem!important
}

.mb-3 {
    margin-bottom: 1rem!important
}

.mb-4 {
    margin-bottom: 1.5rem!important
}

.mb-5 {
    margin-bottom: 3rem!important
}

.mb-6 {
    margin-bottom: 5rem!important
}

.mb-7 {
    margin-bottom: 8rem!important
}

.mb-8 {
    margin-bottom: 10rem!important
}

.mb-9 {
    margin-bottom: 11rem!important
}

.mb-10 {
    margin-bottom: 14rem!important
}

.mb-11 {
    margin-bottom: 16rem!important
}

.mb-12 {
    margin-bottom: 20rem!important
}

.mb-sm {
    margin-bottom: 1rem!important
}

.mb-md {
    margin-bottom: 2rem!important
}

.mb-lg {
    margin-bottom: 4rem!important
}

.mb-xl {
    margin-bottom: 8rem!important
}

.mb-auto {
    margin-bottom: auto!important
}

.ms-0 {
    margin-left: 0!important
}

.ms-1 {
    margin-left: .25rem!important
}

.ms-2 {
    margin-left: .5rem!important
}

.ms-3 {
    margin-left: 1rem!important
}

.ms-4 {
    margin-left: 1.5rem!important
}

.ms-5 {
    margin-left: 3rem!important
}

.ms-6 {
    margin-left: 5rem!important
}

.ms-7 {
    margin-left: 8rem!important
}

.ms-8 {
    margin-left: 10rem!important
}

.ms-9 {
    margin-left: 11rem!important
}

.ms-10 {
    margin-left: 14rem!important
}

.ms-11 {
    margin-left: 16rem!important
}

.ms-12 {
    margin-left: 20rem!important
}

.ms-sm {
    margin-left: 1rem!important
}

.ms-md {
    margin-left: 2rem!important
}

.ms-lg {
    margin-left: 4rem!important
}

.ms-xl {
    margin-left: 8rem!important
}

.ms-auto {
    margin-left: auto!important
}

.m-n1 {
    margin: -.25rem!important
}

.m-n2 {
    margin: -.5rem!important
}

.m-n3 {
    margin: -1rem!important
}

.m-n4 {
    margin: -1.5rem!important
}

.m-n5 {
    margin: -3rem!important
}

.m-n6 {
    margin: -5rem!important
}

.m-n7 {
    margin: -8rem!important
}

.m-n8 {
    margin: -10rem!important
}

.m-n9 {
    margin: -11rem!important
}

.m-n10 {
    margin: -14rem!important
}

.m-n11 {
    margin: -16rem!important
}

.m-n12 {
    margin: -20rem!important
}

.m-nsm {
    margin: -1rem!important
}

.m-nmd {
    margin: -2rem!important
}

.m-nlg {
    margin: -4rem!important
}

.m-nxl {
    margin: -8rem!important
}

.mx-n1 {
    margin-right: -.25rem!important;
    margin-left: -.25rem!important
}

.mx-n2 {
    margin-right: -.5rem!important;
    margin-left: -.5rem!important
}

.mx-n3 {
    margin-right: -1rem!important;
    margin-left: -1rem!important
}

.mx-n4 {
    margin-right: -1.5rem!important;
    margin-left: -1.5rem!important
}

.mx-n5 {
    margin-right: -3rem!important;
    margin-left: -3rem!important
}

.mx-n6 {
    margin-right: -5rem!important;
    margin-left: -5rem!important
}

.mx-n7 {
    margin-right: -8rem!important;
    margin-left: -8rem!important
}

.mx-n8 {
    margin-right: -10rem!important;
    margin-left: -10rem!important
}

.mx-n9 {
    margin-right: -11rem!important;
    margin-left: -11rem!important
}

.mx-n10 {
    margin-right: -14rem!important;
    margin-left: -14rem!important
}

.mx-n11 {
    margin-right: -16rem!important;
    margin-left: -16rem!important
}

.mx-n12 {
    margin-right: -20rem!important;
    margin-left: -20rem!important
}

.mx-nsm {
    margin-right: -1rem!important;
    margin-left: -1rem!important
}

.mx-nmd {
    margin-right: -2rem!important;
    margin-left: -2rem!important
}

.mx-nlg {
    margin-right: -4rem!important;
    margin-left: -4rem!important
}

.mx-nxl {
    margin-right: -8rem!important;
    margin-left: -8rem!important
}

.my-n1 {
    margin-top: -.25rem!important;
    margin-bottom: -.25rem!important
}

.my-n2 {
    margin-top: -.5rem!important;
    margin-bottom: -.5rem!important
}

.my-n3 {
    margin-top: -1rem!important;
    margin-bottom: -1rem!important
}

.my-n4 {
    margin-top: -1.5rem!important;
    margin-bottom: -1.5rem!important
}

.my-n5 {
    margin-top: -3rem!important;
    margin-bottom: -3rem!important
}

.my-n6 {
    margin-top: -5rem!important;
    margin-bottom: -5rem!important
}

.my-n7 {
    margin-top: -8rem!important;
    margin-bottom: -8rem!important
}

.my-n8 {
    margin-top: -10rem!important;
    margin-bottom: -10rem!important
}

.my-n9 {
    margin-top: -11rem!important;
    margin-bottom: -11rem!important
}

.my-n10 {
    margin-top: -14rem!important;
    margin-bottom: -14rem!important
}

.my-n11 {
    margin-top: -16rem!important;
    margin-bottom: -16rem!important
}

.my-n12 {
    margin-top: -20rem!important;
    margin-bottom: -20rem!important
}

.my-nsm {
    margin-top: -1rem!important;
    margin-bottom: -1rem!important
}

.my-nmd {
    margin-top: -2rem!important;
    margin-bottom: -2rem!important
}

.my-nlg {
    margin-top: -4rem!important;
    margin-bottom: -4rem!important
}

.my-nxl {
    margin-top: -8rem!important;
    margin-bottom: -8rem!important
}

.mt-n1 {
    margin-top: -.25rem!important
}

.mt-n2 {
    margin-top: -.5rem!important
}

.mt-n3 {
    margin-top: -1rem!important
}

.mt-n4 {
    margin-top: -1.5rem!important
}

.mt-n5 {
    margin-top: -3rem!important
}

.mt-n6 {
    margin-top: -5rem!important
}

.mt-n7 {
    margin-top: -8rem!important
}

.mt-n8 {
    margin-top: -10rem!important
}

.mt-n9 {
    margin-top: -11rem!important
}

.mt-n10 {
    margin-top: -14rem!important
}

.mt-n11 {
    margin-top: -16rem!important
}

.mt-n12 {
    margin-top: -20rem!important
}

.mt-nsm {
    margin-top: -1rem!important
}

.mt-nmd {
    margin-top: -2rem!important
}

.mt-nlg {
    margin-top: -4rem!important
}

.mt-nxl {
    margin-top: -8rem!important
}

.me-n1 {
    margin-right: -.25rem!important
}

.me-n2 {
    margin-right: -.5rem!important
}

.me-n3 {
    margin-right: -1rem!important
}

.me-n4 {
    margin-right: -1.5rem!important
}

.me-n5 {
    margin-right: -3rem!important
}

.me-n6 {
    margin-right: -5rem!important
}

.me-n7 {
    margin-right: -8rem!important
}

.me-n8 {
    margin-right: -10rem!important
}

.me-n9 {
    margin-right: -11rem!important
}

.me-n10 {
    margin-right: -14rem!important
}

.me-n11 {
    margin-right: -16rem!important
}

.me-n12 {
    margin-right: -20rem!important
}

.me-nsm {
    margin-right: -1rem!important
}

.me-nmd {
    margin-right: -2rem!important
}

.me-nlg {
    margin-right: -4rem!important
}

.me-nxl {
    margin-right: -8rem!important
}

.mb-n1 {
    margin-bottom: -.25rem!important
}

.mb-n2 {
    margin-bottom: -.5rem!important
}

.mb-n3 {
    margin-bottom: -1rem!important
}

.mb-n4 {
    margin-bottom: -1.5rem!important
}

.mb-n5 {
    margin-bottom: -3rem!important
}

.mb-n6 {
    margin-bottom: -5rem!important
}

.mb-n7 {
    margin-bottom: -8rem!important
}

.mb-n8 {
    margin-bottom: -10rem!important
}

.mb-n9 {
    margin-bottom: -11rem!important
}

.mb-n10 {
    margin-bottom: -14rem!important
}

.mb-n11 {
    margin-bottom: -16rem!important
}

.mb-n12 {
    margin-bottom: -20rem!important
}

.mb-nsm {
    margin-bottom: -1rem!important
}

.mb-nmd {
    margin-bottom: -2rem!important
}

.mb-nlg {
    margin-bottom: -4rem!important
}

.mb-nxl {
    margin-bottom: -8rem!important
}

.ms-n1 {
    margin-left: -.25rem!important
}

.ms-n2 {
    margin-left: -.5rem!important
}

.ms-n3 {
    margin-left: -1rem!important
}

.ms-n4 {
    margin-left: -1.5rem!important
}

.ms-n5 {
    margin-left: -3rem!important
}

.ms-n6 {
    margin-left: -5rem!important
}

.ms-n7 {
    margin-left: -8rem!important
}

.ms-n8 {
    margin-left: -10rem!important
}

.ms-n9 {
    margin-left: -11rem!important
}

.ms-n10 {
    margin-left: -14rem!important
}

.ms-n11 {
    margin-left: -16rem!important
}

.ms-n12 {
    margin-left: -20rem!important
}

.ms-nsm {
    margin-left: -1rem!important
}

.ms-nmd {
    margin-left: -2rem!important
}

.ms-nlg {
    margin-left: -4rem!important
}

.ms-nxl {
    margin-left: -8rem!important
}

.p-0 {
    padding: 0!important
}

.p-1 {
    padding: .25rem!important
}

.p-2 {
    padding: .5rem!important
}

.p-3 {
    padding: 1rem!important
}

.p-4 {
    padding: 1.5rem!important
}

.p-5 {
    padding: 3rem!important
}

.p-6 {
    padding: 5rem!important
}

.p-7 {
    padding: 8rem!important
}

.p-8 {
    padding: 10rem!important
}

.p-9 {
    padding: 11rem!important
}

.p-10 {
    padding: 14rem!important
}

.p-11 {
    padding: 16rem!important
}

.p-12 {
    padding: 20rem!important
}

.p-sm {
    padding: 1rem!important
}

.p-md {
    padding: 2rem!important
}

.p-lg {
    padding: 4rem!important
}

.p-xl {
    padding: 8rem!important
}

.px-0 {
    padding-right: 0!important;
    padding-left: 0!important
}

.px-1 {
    padding-right: .25rem!important;
    padding-left: .25rem!important
}

.px-2 {
    padding-right: .5rem!important;
    padding-left: .5rem!important
}

.px-3 {
    padding-right: 1rem!important;
    padding-left: 1rem!important
}

.px-4 {
    padding-right: 1.5rem!important;
    padding-left: 1.5rem!important
}

.px-5 {
    padding-right: 3rem!important;
    padding-left: 3rem!important
}

.px-6 {
    padding-right: 5rem!important;
    padding-left: 5rem!important
}

.px-7 {
    padding-right: 8rem!important;
    padding-left: 8rem!important
}

.px-8 {
    padding-right: 10rem!important;
    padding-left: 10rem!important
}

.px-9 {
    padding-right: 11rem!important;
    padding-left: 11rem!important
}

.px-10 {
    padding-right: 14rem!important;
    padding-left: 14rem!important
}

.px-11 {
    padding-right: 16rem!important;
    padding-left: 16rem!important
}

.px-12 {
    padding-right: 20rem!important;
    padding-left: 20rem!important
}

.px-sm {
    padding-right: 1rem!important;
    padding-left: 1rem!important
}

.px-md {
    padding-right: 2rem!important;
    padding-left: 2rem!important
}

.px-lg {
    padding-right: 4rem!important;
    padding-left: 4rem!important
}

.px-xl {
    padding-right: 8rem!important;
    padding-left: 8rem!important
}

.py-0 {
    padding-top: 0!important;
    padding-bottom: 0!important
}

.py-1 {
    padding-top: .25rem!important;
    padding-bottom: .25rem!important
}

.py-2 {
    padding-top: .5rem!important;
    padding-bottom: .5rem!important
}

.py-3 {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important
}

.py-4 {
    padding-top: 1.5rem!important;
    padding-bottom: 1.5rem!important
}

.py-5 {
    padding-top: 3rem!important;
    padding-bottom: 3rem!important
}

.py-6 {
    padding-top: 5rem!important;
    padding-bottom: 5rem!important
}

.py-7 {
    padding-top: 8rem!important;
    padding-bottom: 8rem!important
}

.py-8 {
    padding-top: 10rem!important;
    padding-bottom: 10rem!important
}

.py-9 {
    padding-top: 11rem!important;
    padding-bottom: 11rem!important
}

.py-10 {
    padding-top: 14rem!important;
    padding-bottom: 14rem!important
}

.py-11 {
    padding-top: 16rem!important;
    padding-bottom: 16rem!important
}

.py-12 {
    padding-top: 20rem!important;
    padding-bottom: 20rem!important
}

.py-sm {
    padding-top: 1rem!important;
    padding-bottom: 1rem!important
}

.py-md {
    padding-top: 2rem!important;
    padding-bottom: 2rem!important
}

.py-lg {
    padding-top: 4rem!important;
    padding-bottom: 4rem!important
}

.py-xl {
    padding-top: 8rem!important;
    padding-bottom: 8rem!important
}

.pt-0 {
    padding-top: 0!important
}

.pt-1 {
    padding-top: .25rem!important
}

.pt-2 {
    padding-top: .5rem!important
}

.pt-3 {
    padding-top: 1rem!important
}

.pt-4 {
    padding-top: 1.5rem!important
}

.pt-5 {
    padding-top: 3rem!important
}

.pt-6 {
    padding-top: 5rem!important
}

.pt-7 {
    padding-top: 8rem!important
}

.pt-8 {
    padding-top: 10rem!important
}

.pt-9 {
    padding-top: 11rem!important
}

.pt-10 {
    padding-top: 14rem!important
}

.pt-11 {
    padding-top: 16rem!important
}

.pt-12 {
    padding-top: 20rem!important
}

.pt-sm {
    padding-top: 1rem!important
}

.pt-md {
    padding-top: 2rem!important
}

.pt-lg {
    padding-top: 4rem!important
}

.pt-xl {
    padding-top: 8rem!important
}

.pe-0 {
    padding-right: 0!important
}

.pe-1 {
    padding-right: .25rem!important
}

.pe-2 {
    padding-right: .5rem!important
}

.pe-3 {
    padding-right: 1rem!important
}

.pe-4 {
    padding-right: 1.5rem!important
}

.pe-5 {
    padding-right: 3rem!important
}

.pe-6 {
    padding-right: 5rem!important
}

.pe-7 {
    padding-right: 8rem!important
}

.pe-8 {
    padding-right: 10rem!important
}

.pe-9 {
    padding-right: 11rem!important
}

.pe-10 {
    padding-right: 14rem!important
}

.pe-11 {
    padding-right: 16rem!important
}

.pe-12 {
    padding-right: 20rem!important
}

.pe-sm {
    padding-right: 1rem!important
}

.pe-md {
    padding-right: 2rem!important
}

.pe-lg {
    padding-right: 4rem!important
}

.pe-xl {
    padding-right: 8rem!important
}

.pb-0 {
    padding-bottom: 0!important
}

.pb-1 {
    padding-bottom: .25rem!important
}

.pb-2 {
    padding-bottom: .5rem!important
}

.pb-3 {
    padding-bottom: 1rem!important
}

.pb-4 {
    padding-bottom: 1.5rem!important
}

.pb-5 {
    padding-bottom: 3rem!important
}

.pb-6 {
    padding-bottom: 5rem!important
}

.pb-7 {
    padding-bottom: 8rem!important
}

.pb-8 {
    padding-bottom: 10rem!important
}

.pb-9 {
    padding-bottom: 11rem!important
}

.pb-10 {
    padding-bottom: 14rem!important
}

.pb-11 {
    padding-bottom: 16rem!important
}

.pb-12 {
    padding-bottom: 20rem!important
}

.pb-sm {
    padding-bottom: 1rem!important
}

.pb-md {
    padding-bottom: 2rem!important
}

.pb-lg {
    padding-bottom: 4rem!important
}

.pb-xl {
    padding-bottom: 8rem!important
}

.ps-0 {
    padding-left: 0!important
}

.ps-1 {
    padding-left: .25rem!important
}

.ps-2 {
    padding-left: .5rem!important
}

.ps-3 {
    padding-left: 1rem!important
}

.ps-4 {
    padding-left: 1.5rem!important
}

.ps-5 {
    padding-left: 3rem!important
}

.ps-6 {
    padding-left: 5rem!important
}

.ps-7 {
    padding-left: 8rem!important
}

.ps-8 {
    padding-left: 10rem!important
}

.ps-9 {
    padding-left: 11rem!important
}

.ps-10 {
    padding-left: 14rem!important
}

.ps-11 {
    padding-left: 16rem!important
}

.ps-12 {
    padding-left: 20rem!important
}

.ps-sm {
    padding-left: 1rem!important
}

.ps-md {
    padding-left: 2rem!important
}

.ps-lg {
    padding-left: 4rem!important
}

.ps-xl {
    padding-left: 8rem!important
}

.divider {
    width: 100%;
    border-bottom: 1px solid white;
    display: flex;
    margin-bottom: 10px;
}

.font-monospace {
    font-family: var(--bs-font-monospace)!important
}

.fs-1 {
    font-size: calc(1.375rem + 1.5vw)!important
}

.fs-2 {
    font-size: calc(1.325rem + .9vw)!important
}

.fs-3 {
    font-size: calc(1.3rem + .6vw)!important
}

.fs-4 {
    font-size: calc(1.275rem + .3vw)!important
}

.fs-5 {
    font-size: 1.25rem!important
}

.fs-6 {
    font-size: 1rem!important
}

.fst-italic {
    font-style: italic!important
}

.fst-normal {
    font-style: normal!important
}

.fw-light {
    font-weight: 300!important
}

.fw-lighter {
    font-weight: lighter!important
}

.fw-normal {
    font-weight: 400!important
}

.fw-bold {
    font-weight: 600!important
}

.fw-bolder {
    font-weight: 700!important
}

.lh-1 {
    line-height: 1!important
}

.lh-sm {
    line-height: 1.25!important
}

.lh-base {
    line-height: 1.5!important
}

.lh-lg {
    line-height: 2!important
}

.text-start {
    text-align: left!important
}

.text-end {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

.text-decoration-none {
    text-decoration: none!important
}

.text-decoration-underline {
    text-decoration: underline!important
}

.text-decoration-line-through {
    text-decoration: line-through!important
}

.text-lowercase {
    text-transform: lowercase!important
}

.text-uppercase {
    text-transform: uppercase!important
}

.text-capitalize {
    text-transform: capitalize!important
}

.text-wrap {
    white-space: normal!important
}

.text-nowrap {
    white-space: nowrap!important
}

.text-break {
    word-wrap: break-word!important;
    word-break: break-word!important
}

.text-primary {
    color: #ff6200!important
}

.text-secondary {
    color: #1c2540!important
}

.text-tertiary {
    color: #893168!important
}

.text-primary-app {
    color: #ebf4f6!important
}

.text-secondary-app {
    color: #424aa0!important
}

.text-success {
    color: #3aaf85!important
}

.text-info {
    color: #0d6efd!important
}

.text-warning {
    color: #ffc107!important
}

.text-danger {
    color: #dc3545!important
}

.text-white {
    color: #fff!important
}

.text-gray {
    color: #1f2937!important
}

.text-dark {
    color: #1c2540!important
}

.text-black {
    color: #171f38!important
}

.text-purple {
    color: #6f42c1!important
}

.text-orange {
    color: #e84422!important
}

.text-gray-100 {
    color: #f9fafb!important
}

.text-gray-200 {
    color: #f3f4f6!important
}

.text-gray-300 {
    color: #e5e7eb!important
}

.text-gray-400 {
    color: #d1d5db!important
}

.text-gray-500 {
    color: #9ca3af!important
}

.text-gray-600 {
    color: #6b7280!important
}

.text-gray-700 {
    color: #4b5563!important
}

.text-gray-800 {
    color: #374151!important
}

.text-body {
    color: #1f2937!important
}

.text-muted {
    color: #58677d!important
}

.text-black-50 {
    color: rgba(23,31,56,.5)!important
}

.text-white-50 {
    color: rgba(255,255,255,.5)!important
}

.text-reset {
    color: inherit!important
}

.bg-primary {
    background-color: #ff6200!important
}

.bg-secondary {
    background-color: #1c2540!important
}

.bg-tertiary {
    background-color: #893168!important
}

.bg-primary-app {
    background-color: #ebf4f6!important
}

.bg-secondary-app {
    background-color: #424aa0!important
}

.bg-success {
    background-color: #3aaf85!important
}

.bg-info {
    background-color: #0d6efd!important
}

.bg-warning {
    background-color: #ffc107!important
}

.bg-danger {
    background-color: #dc3545!important
}

.bg-white {
    background-color: #fff!important
}

.bg-gray {
    background-color: #1f2937!important
}

.bg-dark {
    background-color: #1c2540!important
}

.bg-black {
    background-color: #171f38!important
}

.bg-purple {
    background-color: #6f42c1!important
}

.bg-orange {
    background-color: #e84422!important
}

.bg-gray-100 {
    background-color: #f9fafb!important
}

.bg-gray-200 {
    background-color: #f3f4f6!important
}

.bg-gray-300 {
    background-color: #e5e7eb!important
}

.bg-gray-400 {
    background-color: #d1d5db!important
}

.bg-gray-500 {
    background-color: #9ca3af!important
}

.bg-gray-600 {
    background-color: #6b7280!important
}

.bg-gray-700 {
    background-color: #4b5563!important
}

.bg-gray-800 {
    background-color: #374151!important
}

.bg-body {
    background-color: #fff!important
}

.bg-transparent {
    background-color: transparent!important
}

.bg-gradient {
    background-image: var(--bs-gradient)!important
}

.user-select-all {
    -webkit-user-select: all!important;
    user-select: all!important
}

.user-select-auto {
    -webkit-user-select: auto!important;
    user-select: auto!important
}

.user-select-none {
    -webkit-user-select: none!important;
    user-select: none!important
}

.pe-none {
    pointer-events: none!important
}

.pe-auto {
    pointer-events: auto!important
}

.rounded {
    border-radius: 1rem!important
}

.rounded-0 {
    border-radius: 0!important
}

.rounded-1 {
    border-radius: .6rem!important
}

.rounded-2 {
    border-radius: 1rem!important
}

.rounded-3 {
    border-radius: .3rem!important
}

.rounded-circle {
    border-radius: 50%!important
}

.rounded-pill {
    border-radius: 2rem!important
}

.rounded-top {
    border-top-left-radius: 1rem!important;
    border-top-right-radius: 1rem!important
}

.rounded-end {
    border-top-right-radius: 1rem!important;
    border-bottom-right-radius: 1rem!important
}

.rounded-bottom {
    border-bottom-right-radius: 1rem!important;
    border-bottom-left-radius: 1rem!important
}

.rounded-start {
    border-bottom-left-radius: 1rem!important;
    border-top-left-radius: 1rem!important
}

.visible {
    visibility: visible!important
}

.invisible {
    visibility: hidden!important
}

@media (min-width: 576px) {
    .float-sm-start {
        float:left!important
    }

    .float-sm-end {
        float: right!important
    }

    .float-sm-none {
        float: none!important
    }

    .d-sm-inline {
        display: inline!important
    }

    .d-sm-inline-block {
        display: inline-block!important
    }

    .d-sm-block {
        display: block!important
    }

    .d-sm-grid {
        display: grid!important
    }

    .d-sm-table {
        display: table!important
    }

    .d-sm-table-row {
        display: table-row!important
    }

    .d-sm-table-cell {
        display: table-cell!important
    }

    .d-sm-flex {
        display: flex!important
    }

    .d-sm-inline-flex {
        display: inline-flex!important
    }

    .d-sm-none {
        display: none!important
    }

    .flex-sm-fill {
        flex: 1 1 auto!important
    }

    .flex-sm-row {
        flex-direction: row!important
    }

    .flex-sm-column {
        flex-direction: column!important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-sm-grow-0 {
        flex-grow: 0!important
    }

    .flex-sm-grow-1 {
        flex-grow: 1!important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-sm-wrap {
        flex-wrap: wrap!important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .gap-sm-0 {
        gap: 0!important
    }

    .gap-sm-1 {
        gap: .25rem!important
    }

    .gap-sm-2 {
        gap: .5rem!important
    }

    .gap-sm-3 {
        gap: 1rem!important
    }

    .gap-sm-4 {
        gap: 1.5rem!important
    }

    .gap-sm-5 {
        gap: 3rem!important
    }

    .gap-sm-6 {
        gap: 5rem!important
    }

    .gap-sm-7 {
        gap: 8rem!important
    }

    .gap-sm-8 {
        gap: 10rem!important
    }

    .gap-sm-9 {
        gap: 11rem!important
    }

    .gap-sm-10 {
        gap: 14rem!important
    }

    .gap-sm-11 {
        gap: 16rem!important
    }

    .gap-sm-12 {
        gap: 20rem!important
    }

    .gap-sm-sm {
        gap: 1rem!important
    }

    .gap-sm-md {
        gap: 2rem!important
    }

    .gap-sm-lg {
        gap: 4rem!important
    }

    .gap-sm-xl {
        gap: 8rem!important
    }

    .justify-content-sm-start {
        justify-content: flex-start!important
    }

    .justify-content-sm-end {
        justify-content: flex-end!important
    }

    .justify-content-sm-center {
        justify-content: center!important
    }

    .justify-content-sm-between {
        justify-content: space-between!important
    }

    .justify-content-sm-around {
        justify-content: space-around!important
    }

    .justify-content-sm-evenly {
        justify-content: space-evenly!important
    }

    .align-items-sm-start {
        align-items: flex-start!important
    }

    .align-items-sm-end {
        align-items: flex-end!important
    }

    .align-items-sm-center {
        align-items: center!important
    }

    .align-items-sm-baseline {
        align-items: baseline!important
    }

    .align-items-sm-stretch {
        align-items: stretch!important
    }

    .align-content-sm-start {
        align-content: flex-start!important
    }

    .align-content-sm-end {
        align-content: flex-end!important
    }

    .align-content-sm-center {
        align-content: center!important
    }

    .align-content-sm-between {
        align-content: space-between!important
    }

    .align-content-sm-around {
        align-content: space-around!important
    }

    .align-content-sm-stretch {
        align-content: stretch!important
    }

    .align-self-sm-auto {
        align-self: auto!important
    }

    .align-self-sm-start {
        align-self: flex-start!important
    }

    .align-self-sm-end {
        align-self: flex-end!important
    }

    .align-self-sm-center {
        align-self: center!important
    }

    .align-self-sm-baseline {
        align-self: baseline!important
    }

    .align-self-sm-stretch {
        align-self: stretch!important
    }

    .order-sm-first {
        order: -1!important
    }

    .order-sm-0 {
        order: 0!important
    }

    .order-sm-1 {
        order: 1!important
    }

    .order-sm-2 {
        order: 2!important
    }

    .order-sm-3 {
        order: 3!important
    }

    .order-sm-4 {
        order: 4!important
    }

    .order-sm-5 {
        order: 5!important
    }

    .order-sm-last {
        order: 6!important
    }

    .m-sm-0 {
        margin: 0!important
    }

    .m-sm-1 {
        margin: .25rem!important
    }

    .m-sm-2 {
        margin: .5rem!important
    }

    .m-sm-3 {
        margin: 1rem!important
    }

    .m-sm-4 {
        margin: 1.5rem!important
    }

    .m-sm-5 {
        margin: 3rem!important
    }

    .m-sm-6 {
        margin: 5rem!important
    }

    .m-sm-7 {
        margin: 8rem!important
    }

    .m-sm-8 {
        margin: 10rem!important
    }

    .m-sm-9 {
        margin: 11rem!important
    }

    .m-sm-10 {
        margin: 14rem!important
    }

    .m-sm-11 {
        margin: 16rem!important
    }

    .m-sm-12 {
        margin: 20rem!important
    }

    .m-sm-sm {
        margin: 1rem!important
    }

    .m-sm-md {
        margin: 2rem!important
    }

    .m-sm-lg {
        margin: 4rem!important
    }

    .m-sm-xl {
        margin: 8rem!important
    }

    .m-sm-auto {
        margin: auto!important
    }

    .mx-sm-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-sm-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-sm-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-sm-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-sm-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-sm-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-sm-6 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-sm-7 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-sm-8 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-sm-9 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-sm-10 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-sm-11 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-sm-12 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-sm-sm {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-sm-md {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-sm-lg {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-sm-xl {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-sm-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-sm-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-sm-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-sm-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-sm-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-sm-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-sm-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-sm-6 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-sm-7 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-sm-8 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-sm-9 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-sm-10 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-sm-11 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-sm-12 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-sm-sm {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-sm-md {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-sm-lg {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-sm-xl {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-sm-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-sm-0 {
        margin-top: 0!important
    }

    .mt-sm-1 {
        margin-top: .25rem!important
    }

    .mt-sm-2 {
        margin-top: .5rem!important
    }

    .mt-sm-3 {
        margin-top: 1rem!important
    }

    .mt-sm-4 {
        margin-top: 1.5rem!important
    }

    .mt-sm-5 {
        margin-top: 3rem!important
    }

    .mt-sm-6 {
        margin-top: 5rem!important
    }

    .mt-sm-7 {
        margin-top: 8rem!important
    }

    .mt-sm-8 {
        margin-top: 10rem!important
    }

    .mt-sm-9 {
        margin-top: 11rem!important
    }

    .mt-sm-10 {
        margin-top: 14rem!important
    }

    .mt-sm-11 {
        margin-top: 16rem!important
    }

    .mt-sm-12 {
        margin-top: 20rem!important
    }

    .mt-sm-sm {
        margin-top: 1rem!important
    }

    .mt-sm-md {
        margin-top: 2rem!important
    }

    .mt-sm-lg {
        margin-top: 4rem!important
    }

    .mt-sm-xl {
        margin-top: 8rem!important
    }

    .mt-sm-auto {
        margin-top: auto!important
    }

    .me-sm-0 {
        margin-right: 0!important
    }

    .me-sm-1 {
        margin-right: .25rem!important
    }

    .me-sm-2 {
        margin-right: .5rem!important
    }

    .me-sm-3 {
        margin-right: 1rem!important
    }

    .me-sm-4 {
        margin-right: 1.5rem!important
    }

    .me-sm-5 {
        margin-right: 3rem!important
    }

    .me-sm-6 {
        margin-right: 5rem!important
    }

    .me-sm-7 {
        margin-right: 8rem!important
    }

    .me-sm-8 {
        margin-right: 10rem!important
    }

    .me-sm-9 {
        margin-right: 11rem!important
    }

    .me-sm-10 {
        margin-right: 14rem!important
    }

    .me-sm-11 {
        margin-right: 16rem!important
    }

    .me-sm-12 {
        margin-right: 20rem!important
    }

    .me-sm-sm {
        margin-right: 1rem!important
    }

    .me-sm-md {
        margin-right: 2rem!important
    }

    .me-sm-lg {
        margin-right: 4rem!important
    }

    .me-sm-xl {
        margin-right: 8rem!important
    }

    .me-sm-auto {
        margin-right: auto!important
    }

    .mb-sm-0 {
        margin-bottom: 0!important
    }

    .mb-sm-1 {
        margin-bottom: .25rem!important
    }

    .mb-sm-2 {
        margin-bottom: .5rem!important
    }

    .mb-sm-3 {
        margin-bottom: 1rem!important
    }

    .mb-sm-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-sm-5 {
        margin-bottom: 3rem!important
    }

    .mb-sm-6 {
        margin-bottom: 5rem!important
    }

    .mb-sm-7 {
        margin-bottom: 8rem!important
    }

    .mb-sm-8 {
        margin-bottom: 10rem!important
    }

    .mb-sm-9 {
        margin-bottom: 11rem!important
    }

    .mb-sm-10 {
        margin-bottom: 14rem!important
    }

    .mb-sm-11 {
        margin-bottom: 16rem!important
    }

    .mb-sm-12 {
        margin-bottom: 20rem!important
    }

    .mb-sm-sm {
        margin-bottom: 1rem!important
    }

    .mb-sm-md {
        margin-bottom: 2rem!important
    }

    .mb-sm-lg {
        margin-bottom: 4rem!important
    }

    .mb-sm-xl {
        margin-bottom: 8rem!important
    }

    .mb-sm-auto {
        margin-bottom: auto!important
    }

    .ms-sm-0 {
        margin-left: 0!important
    }

    .ms-sm-1 {
        margin-left: .25rem!important
    }

    .ms-sm-2 {
        margin-left: .5rem!important
    }

    .ms-sm-3 {
        margin-left: 1rem!important
    }

    .ms-sm-4 {
        margin-left: 1.5rem!important
    }

    .ms-sm-5 {
        margin-left: 3rem!important
    }

    .ms-sm-6 {
        margin-left: 5rem!important
    }

    .ms-sm-7 {
        margin-left: 8rem!important
    }

    .ms-sm-8 {
        margin-left: 10rem!important
    }

    .ms-sm-9 {
        margin-left: 11rem!important
    }

    .ms-sm-10 {
        margin-left: 14rem!important
    }

    .ms-sm-11 {
        margin-left: 16rem!important
    }

    .ms-sm-12 {
        margin-left: 20rem!important
    }

    .ms-sm-sm {
        margin-left: 1rem!important
    }

    .ms-sm-md {
        margin-left: 2rem!important
    }

    .ms-sm-lg {
        margin-left: 4rem!important
    }

    .ms-sm-xl {
        margin-left: 8rem!important
    }

    .ms-sm-auto {
        margin-left: auto!important
    }

    .m-sm-n1 {
        margin: -.25rem!important
    }

    .m-sm-n2 {
        margin: -.5rem!important
    }

    .m-sm-n3 {
        margin: -1rem!important
    }

    .m-sm-n4 {
        margin: -1.5rem!important
    }

    .m-sm-n5 {
        margin: -3rem!important
    }

    .m-sm-n6 {
        margin: -5rem!important
    }

    .m-sm-n7 {
        margin: -8rem!important
    }

    .m-sm-n8 {
        margin: -10rem!important
    }

    .m-sm-n9 {
        margin: -11rem!important
    }

    .m-sm-n10 {
        margin: -14rem!important
    }

    .m-sm-n11 {
        margin: -16rem!important
    }

    .m-sm-n12 {
        margin: -20rem!important
    }

    .m-sm-nsm {
        margin: -1rem!important
    }

    .m-sm-nmd {
        margin: -2rem!important
    }

    .m-sm-nlg {
        margin: -4rem!important
    }

    .m-sm-nxl {
        margin: -8rem!important
    }

    .mx-sm-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-sm-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-sm-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-sm-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-sm-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-sm-n6 {
        margin-right: -5rem!important;
        margin-left: -5rem!important
    }

    .mx-sm-n7 {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .mx-sm-n8 {
        margin-right: -10rem!important;
        margin-left: -10rem!important
    }

    .mx-sm-n9 {
        margin-right: -11rem!important;
        margin-left: -11rem!important
    }

    .mx-sm-n10 {
        margin-right: -14rem!important;
        margin-left: -14rem!important
    }

    .mx-sm-n11 {
        margin-right: -16rem!important;
        margin-left: -16rem!important
    }

    .mx-sm-n12 {
        margin-right: -20rem!important;
        margin-left: -20rem!important
    }

    .mx-sm-nsm {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-sm-nmd {
        margin-right: -2rem!important;
        margin-left: -2rem!important
    }

    .mx-sm-nlg {
        margin-right: -4rem!important;
        margin-left: -4rem!important
    }

    .mx-sm-nxl {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .my-sm-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-sm-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-sm-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-sm-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-sm-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-sm-n6 {
        margin-top: -5rem!important;
        margin-bottom: -5rem!important
    }

    .my-sm-n7 {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .my-sm-n8 {
        margin-top: -10rem!important;
        margin-bottom: -10rem!important
    }

    .my-sm-n9 {
        margin-top: -11rem!important;
        margin-bottom: -11rem!important
    }

    .my-sm-n10 {
        margin-top: -14rem!important;
        margin-bottom: -14rem!important
    }

    .my-sm-n11 {
        margin-top: -16rem!important;
        margin-bottom: -16rem!important
    }

    .my-sm-n12 {
        margin-top: -20rem!important;
        margin-bottom: -20rem!important
    }

    .my-sm-nsm {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-sm-nmd {
        margin-top: -2rem!important;
        margin-bottom: -2rem!important
    }

    .my-sm-nlg {
        margin-top: -4rem!important;
        margin-bottom: -4rem!important
    }

    .my-sm-nxl {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .mt-sm-n1 {
        margin-top: -.25rem!important
    }

    .mt-sm-n2 {
        margin-top: -.5rem!important
    }

    .mt-sm-n3 {
        margin-top: -1rem!important
    }

    .mt-sm-n4 {
        margin-top: -1.5rem!important
    }

    .mt-sm-n5 {
        margin-top: -3rem!important
    }

    .mt-sm-n6 {
        margin-top: -5rem!important
    }

    .mt-sm-n7 {
        margin-top: -8rem!important
    }

    .mt-sm-n8 {
        margin-top: -10rem!important
    }

    .mt-sm-n9 {
        margin-top: -11rem!important
    }

    .mt-sm-n10 {
        margin-top: -14rem!important
    }

    .mt-sm-n11 {
        margin-top: -16rem!important
    }

    .mt-sm-n12 {
        margin-top: -20rem!important
    }

    .mt-sm-nsm {
        margin-top: -1rem!important
    }

    .mt-sm-nmd {
        margin-top: -2rem!important
    }

    .mt-sm-nlg {
        margin-top: -4rem!important
    }

    .mt-sm-nxl {
        margin-top: -8rem!important
    }

    .me-sm-n1 {
        margin-right: -.25rem!important
    }

    .me-sm-n2 {
        margin-right: -.5rem!important
    }

    .me-sm-n3 {
        margin-right: -1rem!important
    }

    .me-sm-n4 {
        margin-right: -1.5rem!important
    }

    .me-sm-n5 {
        margin-right: -3rem!important
    }

    .me-sm-n6 {
        margin-right: -5rem!important
    }

    .me-sm-n7 {
        margin-right: -8rem!important
    }

    .me-sm-n8 {
        margin-right: -10rem!important
    }

    .me-sm-n9 {
        margin-right: -11rem!important
    }

    .me-sm-n10 {
        margin-right: -14rem!important
    }

    .me-sm-n11 {
        margin-right: -16rem!important
    }

    .me-sm-n12 {
        margin-right: -20rem!important
    }

    .me-sm-nsm {
        margin-right: -1rem!important
    }

    .me-sm-nmd {
        margin-right: -2rem!important
    }

    .me-sm-nlg {
        margin-right: -4rem!important
    }

    .me-sm-nxl {
        margin-right: -8rem!important
    }

    .mb-sm-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-sm-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-sm-n3 {
        margin-bottom: -1rem!important
    }

    .mb-sm-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-sm-n5 {
        margin-bottom: -3rem!important
    }

    .mb-sm-n6 {
        margin-bottom: -5rem!important
    }

    .mb-sm-n7 {
        margin-bottom: -8rem!important
    }

    .mb-sm-n8 {
        margin-bottom: -10rem!important
    }

    .mb-sm-n9 {
        margin-bottom: -11rem!important
    }

    .mb-sm-n10 {
        margin-bottom: -14rem!important
    }

    .mb-sm-n11 {
        margin-bottom: -16rem!important
    }

    .mb-sm-n12 {
        margin-bottom: -20rem!important
    }

    .mb-sm-nsm {
        margin-bottom: -1rem!important
    }

    .mb-sm-nmd {
        margin-bottom: -2rem!important
    }

    .mb-sm-nlg {
        margin-bottom: -4rem!important
    }

    .mb-sm-nxl {
        margin-bottom: -8rem!important
    }

    .ms-sm-n1 {
        margin-left: -.25rem!important
    }

    .ms-sm-n2 {
        margin-left: -.5rem!important
    }

    .ms-sm-n3 {
        margin-left: -1rem!important
    }

    .ms-sm-n4 {
        margin-left: -1.5rem!important
    }

    .ms-sm-n5 {
        margin-left: -3rem!important
    }

    .ms-sm-n6 {
        margin-left: -5rem!important
    }

    .ms-sm-n7 {
        margin-left: -8rem!important
    }

    .ms-sm-n8 {
        margin-left: -10rem!important
    }

    .ms-sm-n9 {
        margin-left: -11rem!important
    }

    .ms-sm-n10 {
        margin-left: -14rem!important
    }

    .ms-sm-n11 {
        margin-left: -16rem!important
    }

    .ms-sm-n12 {
        margin-left: -20rem!important
    }

    .ms-sm-nsm {
        margin-left: -1rem!important
    }

    .ms-sm-nmd {
        margin-left: -2rem!important
    }

    .ms-sm-nlg {
        margin-left: -4rem!important
    }

    .ms-sm-nxl {
        margin-left: -8rem!important
    }

    .p-sm-0 {
        padding: 0!important
    }

    .p-sm-1 {
        padding: .25rem!important
    }

    .p-sm-2 {
        padding: .5rem!important
    }

    .p-sm-3 {
        padding: 1rem!important
    }

    .p-sm-4 {
        padding: 1.5rem!important
    }

    .p-sm-5 {
        padding: 3rem!important
    }

    .p-sm-6 {
        padding: 5rem!important
    }

    .p-sm-7 {
        padding: 8rem!important
    }

    .p-sm-8 {
        padding: 10rem!important
    }

    .p-sm-9 {
        padding: 11rem!important
    }

    .p-sm-10 {
        padding: 14rem!important
    }

    .p-sm-11 {
        padding: 16rem!important
    }

    .p-sm-12 {
        padding: 20rem!important
    }

    .p-sm-sm {
        padding: 1rem!important
    }

    .p-sm-md {
        padding: 2rem!important
    }

    .p-sm-lg {
        padding: 4rem!important
    }

    .p-sm-xl {
        padding: 8rem!important
    }

    .px-sm-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-sm-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-sm-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-sm-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-sm-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-sm-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-sm-6 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-sm-7 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-sm-8 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-sm-9 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-sm-10 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-sm-11 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-sm-12 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .px-sm-sm {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-sm-md {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-sm-lg {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-sm-xl {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .py-sm-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-sm-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-sm-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-sm-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-sm-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-sm-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-sm-6 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-sm-7 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-sm-8 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-sm-9 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-sm-10 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-sm-11 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-sm-12 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .py-sm-sm {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-sm-md {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-sm-lg {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-sm-xl {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .pt-sm-0 {
        padding-top: 0!important
    }

    .pt-sm-1 {
        padding-top: .25rem!important
    }

    .pt-sm-2 {
        padding-top: .5rem!important
    }

    .pt-sm-3 {
        padding-top: 1rem!important
    }

    .pt-sm-4 {
        padding-top: 1.5rem!important
    }

    .pt-sm-5 {
        padding-top: 3rem!important
    }

    .pt-sm-6 {
        padding-top: 5rem!important
    }

    .pt-sm-7 {
        padding-top: 8rem!important
    }

    .pt-sm-8 {
        padding-top: 10rem!important
    }

    .pt-sm-9 {
        padding-top: 11rem!important
    }

    .pt-sm-10 {
        padding-top: 14rem!important
    }

    .pt-sm-11 {
        padding-top: 16rem!important
    }

    .pt-sm-12 {
        padding-top: 20rem!important
    }

    .pt-sm-sm {
        padding-top: 1rem!important
    }

    .pt-sm-md {
        padding-top: 2rem!important
    }

    .pt-sm-lg {
        padding-top: 4rem!important
    }

    .pt-sm-xl {
        padding-top: 8rem!important
    }

    .pe-sm-0 {
        padding-right: 0!important
    }

    .pe-sm-1 {
        padding-right: .25rem!important
    }

    .pe-sm-2 {
        padding-right: .5rem!important
    }

    .pe-sm-3 {
        padding-right: 1rem!important
    }

    .pe-sm-4 {
        padding-right: 1.5rem!important
    }

    .pe-sm-5 {
        padding-right: 3rem!important
    }

    .pe-sm-6 {
        padding-right: 5rem!important
    }

    .pe-sm-7 {
        padding-right: 8rem!important
    }

    .pe-sm-8 {
        padding-right: 10rem!important
    }

    .pe-sm-9 {
        padding-right: 11rem!important
    }

    .pe-sm-10 {
        padding-right: 14rem!important
    }

    .pe-sm-11 {
        padding-right: 16rem!important
    }

    .pe-sm-12 {
        padding-right: 20rem!important
    }

    .pe-sm-sm {
        padding-right: 1rem!important
    }

    .pe-sm-md {
        padding-right: 2rem!important
    }

    .pe-sm-lg {
        padding-right: 4rem!important
    }

    .pe-sm-xl {
        padding-right: 8rem!important
    }

    .pb-sm-0 {
        padding-bottom: 0!important
    }

    .pb-sm-1 {
        padding-bottom: .25rem!important
    }

    .pb-sm-2 {
        padding-bottom: .5rem!important
    }

    .pb-sm-3 {
        padding-bottom: 1rem!important
    }

    .pb-sm-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-sm-5 {
        padding-bottom: 3rem!important
    }

    .pb-sm-6 {
        padding-bottom: 5rem!important
    }

    .pb-sm-7 {
        padding-bottom: 8rem!important
    }

    .pb-sm-8 {
        padding-bottom: 10rem!important
    }

    .pb-sm-9 {
        padding-bottom: 11rem!important
    }

    .pb-sm-10 {
        padding-bottom: 14rem!important
    }

    .pb-sm-11 {
        padding-bottom: 16rem!important
    }

    .pb-sm-12 {
        padding-bottom: 20rem!important
    }

    .pb-sm-sm {
        padding-bottom: 1rem!important
    }

    .pb-sm-md {
        padding-bottom: 2rem!important
    }

    .pb-sm-lg {
        padding-bottom: 4rem!important
    }

    .pb-sm-xl {
        padding-bottom: 8rem!important
    }

    .ps-sm-0 {
        padding-left: 0!important
    }

    .ps-sm-1 {
        padding-left: .25rem!important
    }

    .ps-sm-2 {
        padding-left: .5rem!important
    }

    .ps-sm-3 {
        padding-left: 1rem!important
    }

    .ps-sm-4 {
        padding-left: 1.5rem!important
    }

    .ps-sm-5 {
        padding-left: 3rem!important
    }

    .ps-sm-6 {
        padding-left: 5rem!important
    }

    .ps-sm-7 {
        padding-left: 8rem!important
    }

    .ps-sm-8 {
        padding-left: 10rem!important
    }

    .ps-sm-9 {
        padding-left: 11rem!important
    }

    .ps-sm-10 {
        padding-left: 14rem!important
    }

    .ps-sm-11 {
        padding-left: 16rem!important
    }

    .ps-sm-12 {
        padding-left: 20rem!important
    }

    .ps-sm-sm {
        padding-left: 1rem!important
    }

    .ps-sm-md {
        padding-left: 2rem!important
    }

    .ps-sm-lg {
        padding-left: 4rem!important
    }

    .ps-sm-xl {
        padding-left: 8rem!important
    }

    .text-sm-start {
        text-align: left!important
    }

    .text-sm-end {
        text-align: right!important
    }

    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width: 768px) {
    .float-md-start {
        float:left!important
    }

    .float-md-end {
        float: right!important
    }

    .float-md-none {
        float: none!important
    }

    .d-md-inline {
        display: inline!important
    }

    .d-md-inline-block {
        display: inline-block!important
    }

    .d-md-block {
        display: block!important
    }

    .d-md-grid {
        display: grid!important
    }

    .d-md-table {
        display: table!important
    }

    .d-md-table-row {
        display: table-row!important
    }

    .d-md-table-cell {
        display: table-cell!important
    }

    .d-md-flex {
        display: flex!important
    }

    .d-md-inline-flex {
        display: inline-flex!important
    }

    .d-md-none {
        display: none!important
    }

    .flex-md-fill {
        flex: 1 1 auto!important
    }

    .flex-md-row {
        flex-direction: row!important
    }

    .flex-md-column {
        flex-direction: column!important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-md-grow-0 {
        flex-grow: 0!important
    }

    .flex-md-grow-1 {
        flex-grow: 1!important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-md-wrap {
        flex-wrap: wrap!important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .gap-md-0 {
        gap: 0!important
    }

    .gap-md-1 {
        gap: .25rem!important
    }

    .gap-md-2 {
        gap: .5rem!important
    }

    .gap-md-3 {
        gap: 1rem!important
    }

    .gap-md-4 {
        gap: 1.5rem!important
    }

    .gap-md-5 {
        gap: 3rem!important
    }

    .gap-md-6 {
        gap: 5rem!important
    }

    .gap-md-7 {
        gap: 8rem!important
    }

    .gap-md-8 {
        gap: 10rem!important
    }

    .gap-md-9 {
        gap: 11rem!important
    }

    .gap-md-10 {
        gap: 14rem!important
    }

    .gap-md-11 {
        gap: 16rem!important
    }

    .gap-md-12 {
        gap: 20rem!important
    }

    .gap-md-sm {
        gap: 1rem!important
    }

    .gap-md-md {
        gap: 2rem!important
    }

    .gap-md-lg {
        gap: 4rem!important
    }

    .gap-md-xl {
        gap: 8rem!important
    }

    .justify-content-md-start {
        justify-content: flex-start!important
    }

    .justify-content-md-end {
        justify-content: flex-end!important
    }

    .justify-content-md-center {
        justify-content: center!important
    }

    .justify-content-md-between {
        justify-content: space-between!important
    }

    .justify-content-md-around {
        justify-content: space-around!important
    }

    .justify-content-md-evenly {
        justify-content: space-evenly!important
    }

    .align-items-md-start {
        align-items: flex-start!important
    }

    .align-items-md-end {
        align-items: flex-end!important
    }

    .align-items-md-center {
        align-items: center!important
    }

    .align-items-md-baseline {
        align-items: baseline!important
    }

    .align-items-md-stretch {
        align-items: stretch!important
    }

    .align-content-md-start {
        align-content: flex-start!important
    }

    .align-content-md-end {
        align-content: flex-end!important
    }

    .align-content-md-center {
        align-content: center!important
    }

    .align-content-md-between {
        align-content: space-between!important
    }

    .align-content-md-around {
        align-content: space-around!important
    }

    .align-content-md-stretch {
        align-content: stretch!important
    }

    .align-self-md-auto {
        align-self: auto!important
    }

    .align-self-md-start {
        align-self: flex-start!important
    }

    .align-self-md-end {
        align-self: flex-end!important
    }

    .align-self-md-center {
        align-self: center!important
    }

    .align-self-md-baseline {
        align-self: baseline!important
    }

    .align-self-md-stretch {
        align-self: stretch!important
    }

    .order-md-first {
        order: -1!important
    }

    .order-md-0 {
        order: 0!important
    }

    .order-md-1 {
        order: 1!important
    }

    .order-md-2 {
        order: 2!important
    }

    .order-md-3 {
        order: 3!important
    }

    .order-md-4 {
        order: 4!important
    }

    .order-md-5 {
        order: 5!important
    }

    .order-md-last {
        order: 6!important
    }

    .m-md-0 {
        margin: 0!important
    }

    .m-md-1 {
        margin: .25rem!important
    }

    .m-md-2 {
        margin: .5rem!important
    }

    .m-md-3 {
        margin: 1rem!important
    }

    .m-md-4 {
        margin: 1.5rem!important
    }

    .m-md-5 {
        margin: 3rem!important
    }

    .m-md-6 {
        margin: 5rem!important
    }

    .m-md-7 {
        margin: 8rem!important
    }

    .m-md-8 {
        margin: 10rem!important
    }

    .m-md-9 {
        margin: 11rem!important
    }

    .m-md-10 {
        margin: 14rem!important
    }

    .m-md-11 {
        margin: 16rem!important
    }

    .m-md-12 {
        margin: 20rem!important
    }

    .m-md-sm {
        margin: 1rem!important
    }

    .m-md-md {
        margin: 2rem!important
    }

    .m-md-lg {
        margin: 4rem!important
    }

    .m-md-xl {
        margin: 8rem!important
    }

    .m-md-auto {
        margin: auto!important
    }

    .mx-md-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-md-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-md-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-md-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-md-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-md-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-md-6 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-md-7 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-md-8 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-md-9 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-md-10 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-md-11 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-md-12 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-md-sm {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-md-md {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-md-lg {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-md-xl {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-md-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-md-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-md-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-md-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-md-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-md-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-md-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-md-6 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-md-7 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-md-8 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-md-9 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-md-10 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-md-11 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-md-12 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-md-sm {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-md-md {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-md-lg {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-md-xl {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-md-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-md-0 {
        margin-top: 0!important
    }

    .mt-md-1 {
        margin-top: .25rem!important
    }

    .mt-md-2 {
        margin-top: .5rem!important
    }

    .mt-md-3 {
        margin-top: 1rem!important
    }

    .mt-md-4 {
        margin-top: 1.5rem!important
    }

    .mt-md-5 {
        margin-top: 3rem!important
    }

    .mt-md-6 {
        margin-top: 5rem!important
    }

    .mt-md-7 {
        margin-top: 8rem!important
    }

    .mt-md-8 {
        margin-top: 10rem!important
    }

    .mt-md-9 {
        margin-top: 11rem!important
    }

    .mt-md-10 {
        margin-top: 14rem!important
    }

    .mt-md-11 {
        margin-top: 16rem!important
    }

    .mt-md-12 {
        margin-top: 20rem!important
    }

    .mt-md-sm {
        margin-top: 1rem!important
    }

    .mt-md-md {
        margin-top: 2rem!important
    }

    .mt-md-lg {
        margin-top: 4rem!important
    }

    .mt-md-xl {
        margin-top: 8rem!important
    }

    .mt-md-auto {
        margin-top: auto!important
    }

    .me-md-0 {
        margin-right: 0!important
    }

    .me-md-1 {
        margin-right: .25rem!important
    }

    .me-md-2 {
        margin-right: .5rem!important
    }

    .me-md-3 {
        margin-right: 1rem!important
    }

    .me-md-4 {
        margin-right: 1.5rem!important
    }

    .me-md-5 {
        margin-right: 3rem!important
    }

    .me-md-6 {
        margin-right: 5rem!important
    }

    .me-md-7 {
        margin-right: 8rem!important
    }

    .me-md-8 {
        margin-right: 10rem!important
    }

    .me-md-9 {
        margin-right: 11rem!important
    }

    .me-md-10 {
        margin-right: 14rem!important
    }

    .me-md-11 {
        margin-right: 16rem!important
    }

    .me-md-12 {
        margin-right: 20rem!important
    }

    .me-md-sm {
        margin-right: 1rem!important
    }

    .me-md-md {
        margin-right: 2rem!important
    }

    .me-md-lg {
        margin-right: 4rem!important
    }

    .me-md-xl {
        margin-right: 8rem!important
    }

    .me-md-auto {
        margin-right: auto!important
    }

    .mb-md-0 {
        margin-bottom: 0!important
    }

    .mb-md-1 {
        margin-bottom: .25rem!important
    }

    .mb-md-2 {
        margin-bottom: .5rem!important
    }

    .mb-md-3 {
        margin-bottom: 1rem!important
    }

    .mb-md-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-md-5 {
        margin-bottom: 3rem!important
    }

    .mb-md-6 {
        margin-bottom: 5rem!important
    }

    .mb-md-7 {
        margin-bottom: 8rem!important
    }

    .mb-md-8 {
        margin-bottom: 10rem!important
    }

    .mb-md-9 {
        margin-bottom: 11rem!important
    }

    .mb-md-10 {
        margin-bottom: 14rem!important
    }

    .mb-md-11 {
        margin-bottom: 16rem!important
    }

    .mb-md-12 {
        margin-bottom: 20rem!important
    }

    .mb-md-sm {
        margin-bottom: 1rem!important
    }

    .mb-md-md {
        margin-bottom: 2rem!important
    }

    .mb-md-lg {
        margin-bottom: 4rem!important
    }

    .mb-md-xl {
        margin-bottom: 8rem!important
    }

    .mb-md-auto {
        margin-bottom: auto!important
    }

    .ms-md-0 {
        margin-left: 0!important
    }

    .ms-md-1 {
        margin-left: .25rem!important
    }

    .ms-md-2 {
        margin-left: .5rem!important
    }

    .ms-md-3 {
        margin-left: 1rem!important
    }

    .ms-md-4 {
        margin-left: 1.5rem!important
    }

    .ms-md-5 {
        margin-left: 3rem!important
    }

    .ms-md-6 {
        margin-left: 5rem!important
    }

    .ms-md-7 {
        margin-left: 8rem!important
    }

    .ms-md-8 {
        margin-left: 10rem!important
    }

    .ms-md-9 {
        margin-left: 11rem!important
    }

    .ms-md-10 {
        margin-left: 14rem!important
    }

    .ms-md-11 {
        margin-left: 16rem!important
    }

    .ms-md-12 {
        margin-left: 20rem!important
    }

    .ms-md-sm {
        margin-left: 1rem!important
    }

    .ms-md-md {
        margin-left: 2rem!important
    }

    .ms-md-lg {
        margin-left: 4rem!important
    }

    .ms-md-xl {
        margin-left: 8rem!important
    }

    .ms-md-auto {
        margin-left: auto!important
    }

    .m-md-n1 {
        margin: -.25rem!important
    }

    .m-md-n2 {
        margin: -.5rem!important
    }

    .m-md-n3 {
        margin: -1rem!important
    }

    .m-md-n4 {
        margin: -1.5rem!important
    }

    .m-md-n5 {
        margin: -3rem!important
    }

    .m-md-n6 {
        margin: -5rem!important
    }

    .m-md-n7 {
        margin: -8rem!important
    }

    .m-md-n8 {
        margin: -10rem!important
    }

    .m-md-n9 {
        margin: -11rem!important
    }

    .m-md-n10 {
        margin: -14rem!important
    }

    .m-md-n11 {
        margin: -16rem!important
    }

    .m-md-n12 {
        margin: -20rem!important
    }

    .m-md-nsm {
        margin: -1rem!important
    }

    .m-md-nmd {
        margin: -2rem!important
    }

    .m-md-nlg {
        margin: -4rem!important
    }

    .m-md-nxl {
        margin: -8rem!important
    }

    .mx-md-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-md-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-md-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-md-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-md-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-md-n6 {
        margin-right: -5rem!important;
        margin-left: -5rem!important
    }

    .mx-md-n7 {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .mx-md-n8 {
        margin-right: -10rem!important;
        margin-left: -10rem!important
    }

    .mx-md-n9 {
        margin-right: -11rem!important;
        margin-left: -11rem!important
    }

    .mx-md-n10 {
        margin-right: -14rem!important;
        margin-left: -14rem!important
    }

    .mx-md-n11 {
        margin-right: -16rem!important;
        margin-left: -16rem!important
    }

    .mx-md-n12 {
        margin-right: -20rem!important;
        margin-left: -20rem!important
    }

    .mx-md-nsm {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-md-nmd {
        margin-right: -2rem!important;
        margin-left: -2rem!important
    }

    .mx-md-nlg {
        margin-right: -4rem!important;
        margin-left: -4rem!important
    }

    .mx-md-nxl {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .my-md-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-md-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-md-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-md-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-md-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-md-n6 {
        margin-top: -5rem!important;
        margin-bottom: -5rem!important
    }

    .my-md-n7 {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .my-md-n8 {
        margin-top: -10rem!important;
        margin-bottom: -10rem!important
    }

    .my-md-n9 {
        margin-top: -11rem!important;
        margin-bottom: -11rem!important
    }

    .my-md-n10 {
        margin-top: -14rem!important;
        margin-bottom: -14rem!important
    }

    .my-md-n11 {
        margin-top: -16rem!important;
        margin-bottom: -16rem!important
    }

    .my-md-n12 {
        margin-top: -20rem!important;
        margin-bottom: -20rem!important
    }

    .my-md-nsm {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-md-nmd {
        margin-top: -2rem!important;
        margin-bottom: -2rem!important
    }

    .my-md-nlg {
        margin-top: -4rem!important;
        margin-bottom: -4rem!important
    }

    .my-md-nxl {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .mt-md-n1 {
        margin-top: -.25rem!important
    }

    .mt-md-n2 {
        margin-top: -.5rem!important
    }

    .mt-md-n3 {
        margin-top: -1rem!important
    }

    .mt-md-n4 {
        margin-top: -1.5rem!important
    }

    .mt-md-n5 {
        margin-top: -3rem!important
    }

    .mt-md-n6 {
        margin-top: -5rem!important
    }

    .mt-md-n7 {
        margin-top: -8rem!important
    }

    .mt-md-n8 {
        margin-top: -10rem!important
    }

    .mt-md-n9 {
        margin-top: -11rem!important
    }

    .mt-md-n10 {
        margin-top: -14rem!important
    }

    .mt-md-n11 {
        margin-top: -16rem!important
    }

    .mt-md-n12 {
        margin-top: -20rem!important
    }

    .mt-md-nsm {
        margin-top: -1rem!important
    }

    .mt-md-nmd {
        margin-top: -2rem!important
    }

    .mt-md-nlg {
        margin-top: -4rem!important
    }

    .mt-md-nxl {
        margin-top: -8rem!important
    }

    .me-md-n1 {
        margin-right: -.25rem!important
    }

    .me-md-n2 {
        margin-right: -.5rem!important
    }

    .me-md-n3 {
        margin-right: -1rem!important
    }

    .me-md-n4 {
        margin-right: -1.5rem!important
    }

    .me-md-n5 {
        margin-right: -3rem!important
    }

    .me-md-n6 {
        margin-right: -5rem!important
    }

    .me-md-n7 {
        margin-right: -8rem!important
    }

    .me-md-n8 {
        margin-right: -10rem!important
    }

    .me-md-n9 {
        margin-right: -11rem!important
    }

    .me-md-n10 {
        margin-right: -14rem!important
    }

    .me-md-n11 {
        margin-right: -16rem!important
    }

    .me-md-n12 {
        margin-right: -20rem!important
    }

    .me-md-nsm {
        margin-right: -1rem!important
    }

    .me-md-nmd {
        margin-right: -2rem!important
    }

    .me-md-nlg {
        margin-right: -4rem!important
    }

    .me-md-nxl {
        margin-right: -8rem!important
    }

    .mb-md-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-md-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-md-n3 {
        margin-bottom: -1rem!important
    }

    .mb-md-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-md-n5 {
        margin-bottom: -3rem!important
    }

    .mb-md-n6 {
        margin-bottom: -5rem!important
    }

    .mb-md-n7 {
        margin-bottom: -8rem!important
    }

    .mb-md-n8 {
        margin-bottom: -10rem!important
    }

    .mb-md-n9 {
        margin-bottom: -11rem!important
    }

    .mb-md-n10 {
        margin-bottom: -14rem!important
    }

    .mb-md-n11 {
        margin-bottom: -16rem!important
    }

    .mb-md-n12 {
        margin-bottom: -20rem!important
    }

    .mb-md-nsm {
        margin-bottom: -1rem!important
    }

    .mb-md-nmd {
        margin-bottom: -2rem!important
    }

    .mb-md-nlg {
        margin-bottom: -4rem!important
    }

    .mb-md-nxl {
        margin-bottom: -8rem!important
    }

    .ms-md-n1 {
        margin-left: -.25rem!important
    }

    .ms-md-n2 {
        margin-left: -.5rem!important
    }

    .ms-md-n3 {
        margin-left: -1rem!important
    }

    .ms-md-n4 {
        margin-left: -1.5rem!important
    }

    .ms-md-n5 {
        margin-left: -3rem!important
    }

    .ms-md-n6 {
        margin-left: -5rem!important
    }

    .ms-md-n7 {
        margin-left: -8rem!important
    }

    .ms-md-n8 {
        margin-left: -10rem!important
    }

    .ms-md-n9 {
        margin-left: -11rem!important
    }

    .ms-md-n10 {
        margin-left: -14rem!important
    }

    .ms-md-n11 {
        margin-left: -16rem!important
    }

    .ms-md-n12 {
        margin-left: -20rem!important
    }

    .ms-md-nsm {
        margin-left: -1rem!important
    }

    .ms-md-nmd {
        margin-left: -2rem!important
    }

    .ms-md-nlg {
        margin-left: -4rem!important
    }

    .ms-md-nxl {
        margin-left: -8rem!important
    }

    .p-md-0 {
        padding: 0!important
    }

    .p-md-1 {
        padding: .25rem!important
    }

    .p-md-2 {
        padding: .5rem!important
    }

    .p-md-3 {
        padding: 1rem!important
    }

    .p-md-4 {
        padding: 1.5rem!important
    }

    .p-md-5 {
        padding: 3rem!important
    }

    .p-md-6 {
        padding: 5rem!important
    }

    .p-md-7 {
        padding: 8rem!important
    }

    .p-md-8 {
        padding: 10rem!important
    }

    .p-md-9 {
        padding: 11rem!important
    }

    .p-md-10 {
        padding: 14rem!important
    }

    .p-md-11 {
        padding: 16rem!important
    }

    .p-md-12 {
        padding: 20rem!important
    }

    .p-md-sm {
        padding: 1rem!important
    }

    .p-md-md {
        padding: 2rem!important
    }

    .p-md-lg {
        padding: 4rem!important
    }

    .p-md-xl {
        padding: 8rem!important
    }

    .px-md-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-md-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-md-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-md-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-md-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-md-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-md-6 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-md-7 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-md-8 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-md-9 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-md-10 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-md-11 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-md-12 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .px-md-sm {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-md-md {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-md-lg {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-md-xl {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .py-md-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-md-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-md-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-md-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-md-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-md-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-md-6 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-md-7 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-md-8 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-md-9 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-md-10 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-md-11 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-md-12 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .py-md-sm {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-md-md {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-md-lg {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-md-xl {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .pt-md-0 {
        padding-top: 0!important
    }

    .pt-md-1 {
        padding-top: .25rem!important
    }

    .pt-md-2 {
        padding-top: .5rem!important
    }

    .pt-md-3 {
        padding-top: 1rem!important
    }

    .pt-md-4 {
        padding-top: 1.5rem!important
    }

    .pt-md-5 {
        padding-top: 3rem!important
    }

    .pt-md-6 {
        padding-top: 5rem!important
    }

    .pt-md-7 {
        padding-top: 8rem!important
    }

    .pt-md-8 {
        padding-top: 10rem!important
    }

    .pt-md-9 {
        padding-top: 11rem!important
    }

    .pt-md-10 {
        padding-top: 14rem!important
    }

    .pt-md-11 {
        padding-top: 16rem!important
    }

    .pt-md-12 {
        padding-top: 20rem!important
    }

    .pt-md-sm {
        padding-top: 1rem!important
    }

    .pt-md-md {
        padding-top: 2rem!important
    }

    .pt-md-lg {
        padding-top: 4rem!important
    }

    .pt-md-xl {
        padding-top: 8rem!important
    }

    .pe-md-0 {
        padding-right: 0!important
    }

    .pe-md-1 {
        padding-right: .25rem!important
    }

    .pe-md-2 {
        padding-right: .5rem!important
    }

    .pe-md-3 {
        padding-right: 1rem!important
    }

    .pe-md-4 {
        padding-right: 1.5rem!important
    }

    .pe-md-5 {
        padding-right: 3rem!important
    }

    .pe-md-6 {
        padding-right: 5rem!important
    }

    .pe-md-7 {
        padding-right: 8rem!important
    }

    .pe-md-8 {
        padding-right: 10rem!important
    }

    .pe-md-9 {
        padding-right: 11rem!important
    }

    .pe-md-10 {
        padding-right: 14rem!important
    }

    .pe-md-11 {
        padding-right: 16rem!important
    }

    .pe-md-12 {
        padding-right: 20rem!important
    }

    .pe-md-sm {
        padding-right: 1rem!important
    }

    .pe-md-md {
        padding-right: 2rem!important
    }

    .pe-md-lg {
        padding-right: 4rem!important
    }

    .pe-md-xl {
        padding-right: 8rem!important
    }

    .pb-md-0 {
        padding-bottom: 0!important
    }

    .pb-md-1 {
        padding-bottom: .25rem!important
    }

    .pb-md-2 {
        padding-bottom: .5rem!important
    }

    .pb-md-3 {
        padding-bottom: 1rem!important
    }

    .pb-md-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-md-5 {
        padding-bottom: 3rem!important
    }

    .pb-md-6 {
        padding-bottom: 5rem!important
    }

    .pb-md-7 {
        padding-bottom: 8rem!important
    }

    .pb-md-8 {
        padding-bottom: 10rem!important
    }

    .pb-md-9 {
        padding-bottom: 11rem!important
    }

    .pb-md-10 {
        padding-bottom: 14rem!important
    }

    .pb-md-11 {
        padding-bottom: 16rem!important
    }

    .pb-md-12 {
        padding-bottom: 20rem!important
    }

    .pb-md-sm {
        padding-bottom: 1rem!important
    }

    .pb-md-md {
        padding-bottom: 2rem!important
    }

    .pb-md-lg {
        padding-bottom: 4rem!important
    }

    .pb-md-xl {
        padding-bottom: 8rem!important
    }

    .ps-md-0 {
        padding-left: 0!important
    }

    .ps-md-1 {
        padding-left: .25rem!important
    }

    .ps-md-2 {
        padding-left: .5rem!important
    }

    .ps-md-3 {
        padding-left: 1rem!important
    }

    .ps-md-4 {
        padding-left: 1.5rem!important
    }

    .ps-md-5 {
        padding-left: 3rem!important
    }

    .ps-md-6 {
        padding-left: 5rem!important
    }

    .ps-md-7 {
        padding-left: 8rem!important
    }

    .ps-md-8 {
        padding-left: 10rem!important
    }

    .ps-md-9 {
        padding-left: 11rem!important
    }

    .ps-md-10 {
        padding-left: 14rem!important
    }

    .ps-md-11 {
        padding-left: 16rem!important
    }

    .ps-md-12 {
        padding-left: 20rem!important
    }

    .ps-md-sm {
        padding-left: 1rem!important
    }

    .ps-md-md {
        padding-left: 2rem!important
    }

    .ps-md-lg {
        padding-left: 4rem!important
    }

    .ps-md-xl {
        padding-left: 8rem!important
    }

    .text-md-start {
        text-align: left!important
    }

    .text-md-end {
        text-align: right!important
    }

    .text-md-center {
        text-align: center!important
    }
}

@media (min-width: 992px) {
    .float-lg-start {
        float:left!important
    }

    .float-lg-end {
        float: right!important
    }

    .float-lg-none {
        float: none!important
    }

    .d-lg-inline {
        display: inline!important
    }

    .d-lg-inline-block {
        display: inline-block!important
    }

    .d-lg-block {
        display: block!important
    }

    .d-lg-grid {
        display: grid!important
    }

    .d-lg-table {
        display: table!important
    }

    .d-lg-table-row {
        display: table-row!important
    }

    .d-lg-table-cell {
        display: table-cell!important
    }

    .d-lg-flex {
        display: flex!important
    }

    .d-lg-inline-flex {
        display: inline-flex!important
    }

    .d-lg-none {
        display: none!important
    }

    .flex-lg-fill {
        flex: 1 1 auto!important
    }

    .flex-lg-row {
        flex-direction: row!important
    }

    .flex-lg-column {
        flex-direction: column!important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-lg-grow-0 {
        flex-grow: 0!important
    }

    .flex-lg-grow-1 {
        flex-grow: 1!important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-lg-wrap {
        flex-wrap: wrap!important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .gap-lg-0 {
        gap: 0!important
    }

    .gap-lg-1 {
        gap: .25rem!important
    }

    .gap-lg-2 {
        gap: .5rem!important
    }

    .gap-lg-3 {
        gap: 1rem!important
    }

    .gap-lg-4 {
        gap: 1.5rem!important
    }

    .gap-lg-5 {
        gap: 3rem!important
    }

    .gap-lg-6 {
        gap: 5rem!important
    }

    .gap-lg-7 {
        gap: 8rem!important
    }

    .gap-lg-8 {
        gap: 10rem!important
    }

    .gap-lg-9 {
        gap: 11rem!important
    }

    .gap-lg-10 {
        gap: 14rem!important
    }

    .gap-lg-11 {
        gap: 16rem!important
    }

    .gap-lg-12 {
        gap: 20rem!important
    }

    .gap-lg-sm {
        gap: 1rem!important
    }

    .gap-lg-md {
        gap: 2rem!important
    }

    .gap-lg-lg {
        gap: 4rem!important
    }

    .gap-lg-xl {
        gap: 8rem!important
    }

    .justify-content-lg-start {
        justify-content: flex-start!important
    }

    .justify-content-lg-end {
        justify-content: flex-end!important
    }

    .justify-content-lg-center {
        justify-content: center!important
    }

    .justify-content-lg-between {
        justify-content: space-between!important
    }

    .justify-content-lg-around {
        justify-content: space-around!important
    }

    .justify-content-lg-evenly {
        justify-content: space-evenly!important
    }

    .align-items-lg-start {
        align-items: flex-start!important
    }

    .align-items-lg-end {
        align-items: flex-end!important
    }

    .align-items-lg-center {
        align-items: center!important
    }

    .align-items-lg-baseline {
        align-items: baseline!important
    }

    .align-items-lg-stretch {
        align-items: stretch!important
    }

    .align-content-lg-start {
        align-content: flex-start!important
    }

    .align-content-lg-end {
        align-content: flex-end!important
    }

    .align-content-lg-center {
        align-content: center!important
    }

    .align-content-lg-between {
        align-content: space-between!important
    }

    .align-content-lg-around {
        align-content: space-around!important
    }

    .align-content-lg-stretch {
        align-content: stretch!important
    }

    .align-self-lg-auto {
        align-self: auto!important
    }

    .align-self-lg-start {
        align-self: flex-start!important
    }

    .align-self-lg-end {
        align-self: flex-end!important
    }

    .align-self-lg-center {
        align-self: center!important
    }

    .align-self-lg-baseline {
        align-self: baseline!important
    }

    .align-self-lg-stretch {
        align-self: stretch!important
    }

    .order-lg-first {
        order: -1!important
    }

    .order-lg-0 {
        order: 0!important
    }

    .order-lg-1 {
        order: 1!important
    }

    .order-lg-2 {
        order: 2!important
    }

    .order-lg-3 {
        order: 3!important
    }

    .order-lg-4 {
        order: 4!important
    }

    .order-lg-5 {
        order: 5!important
    }

    .order-lg-last {
        order: 6!important
    }

    .m-lg-0 {
        margin: 0!important
    }

    .m-lg-1 {
        margin: .25rem!important
    }

    .m-lg-2 {
        margin: .5rem!important
    }

    .m-lg-3 {
        margin: 1rem!important
    }

    .m-lg-4 {
        margin: 1.5rem!important
    }

    .m-lg-5 {
        margin: 3rem!important
    }

    .m-lg-6 {
        margin: 5rem!important
    }

    .m-lg-7 {
        margin: 8rem!important
    }

    .m-lg-8 {
        margin: 10rem!important
    }

    .m-lg-9 {
        margin: 11rem!important
    }

    .m-lg-10 {
        margin: 14rem!important
    }

    .m-lg-11 {
        margin: 16rem!important
    }

    .m-lg-12 {
        margin: 20rem!important
    }

    .m-lg-sm {
        margin: 1rem!important
    }

    .m-lg-md {
        margin: 2rem!important
    }

    .m-lg-lg {
        margin: 4rem!important
    }

    .m-lg-xl {
        margin: 8rem!important
    }

    .m-lg-auto {
        margin: auto!important
    }

    .mx-lg-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-lg-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-lg-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-lg-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-lg-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-lg-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-lg-6 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-lg-7 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-lg-8 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-lg-9 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-lg-10 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-lg-11 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-lg-12 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-lg-sm {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-lg-md {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-lg-lg {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-lg-xl {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-lg-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-lg-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-lg-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-lg-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-lg-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-lg-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-lg-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-lg-6 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-lg-7 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-lg-8 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-lg-9 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-lg-10 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-lg-11 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-lg-12 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-lg-sm {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-lg-md {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-lg-lg {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-lg-xl {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-lg-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-lg-0 {
        margin-top: 0!important
    }

    .mt-lg-1 {
        margin-top: .25rem!important
    }

    .mt-lg-2 {
        margin-top: .5rem!important
    }

    .mt-lg-3 {
        margin-top: 1rem!important
    }

    .mt-lg-4 {
        margin-top: 1.5rem!important
    }

    .mt-lg-5 {
        margin-top: 3rem!important
    }

    .mt-lg-6 {
        margin-top: 5rem!important
    }

    .mt-lg-7 {
        margin-top: 8rem!important
    }

    .mt-lg-8 {
        margin-top: 10rem!important
    }

    .mt-lg-9 {
        margin-top: 11rem!important
    }

    .mt-lg-10 {
        margin-top: 14rem!important
    }

    .mt-lg-11 {
        margin-top: 16rem!important
    }

    .mt-lg-12 {
        margin-top: 20rem!important
    }

    .mt-lg-sm {
        margin-top: 1rem!important
    }

    .mt-lg-md {
        margin-top: 2rem!important
    }

    .mt-lg-lg {
        margin-top: 4rem!important
    }

    .mt-lg-xl {
        margin-top: 8rem!important
    }

    .mt-lg-auto {
        margin-top: auto!important
    }

    .me-lg-0 {
        margin-right: 0!important
    }

    .me-lg-1 {
        margin-right: .25rem!important
    }

    .me-lg-2 {
        margin-right: .5rem!important
    }

    .me-lg-3 {
        margin-right: 1rem!important
    }

    .me-lg-4 {
        margin-right: 1.5rem!important
    }

    .me-lg-5 {
        margin-right: 3rem!important
    }

    .me-lg-6 {
        margin-right: 5rem!important
    }

    .me-lg-7 {
        margin-right: 8rem!important
    }

    .me-lg-8 {
        margin-right: 10rem!important
    }

    .me-lg-9 {
        margin-right: 11rem!important
    }

    .me-lg-10 {
        margin-right: 14rem!important
    }

    .me-lg-11 {
        margin-right: 16rem!important
    }

    .me-lg-12 {
        margin-right: 20rem!important
    }

    .me-lg-sm {
        margin-right: 1rem!important
    }

    .me-lg-md {
        margin-right: 2rem!important
    }

    .me-lg-lg {
        margin-right: 4rem!important
    }

    .me-lg-xl {
        margin-right: 8rem!important
    }

    .me-lg-auto {
        margin-right: auto!important
    }

    .mb-lg-0 {
        margin-bottom: 0!important
    }

    .mb-lg-1 {
        margin-bottom: .25rem!important
    }

    .mb-lg-2 {
        margin-bottom: .5rem!important
    }

    .mb-lg-3 {
        margin-bottom: 1rem!important
    }

    .mb-lg-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-lg-5 {
        margin-bottom: 3rem!important
    }

    .mb-lg-6 {
        margin-bottom: 5rem!important
    }

    .mb-lg-7 {
        margin-bottom: 8rem!important
    }

    .mb-lg-8 {
        margin-bottom: 10rem!important
    }

    .mb-lg-9 {
        margin-bottom: 11rem!important
    }

    .mb-lg-10 {
        margin-bottom: 14rem!important
    }

    .mb-lg-11 {
        margin-bottom: 16rem!important
    }

    .mb-lg-12 {
        margin-bottom: 20rem!important
    }

    .mb-lg-sm {
        margin-bottom: 1rem!important
    }

    .mb-lg-md {
        margin-bottom: 2rem!important
    }

    .mb-lg-lg {
        margin-bottom: 4rem!important
    }

    .mb-lg-xl {
        margin-bottom: 8rem!important
    }

    .mb-lg-auto {
        margin-bottom: auto!important
    }

    .ms-lg-0 {
        margin-left: 0!important
    }

    .ms-lg-1 {
        margin-left: .25rem!important
    }

    .ms-lg-2 {
        margin-left: .5rem!important
    }

    .ms-lg-3 {
        margin-left: 1rem!important
    }

    .ms-lg-4 {
        margin-left: 1.5rem!important
    }

    .ms-lg-5 {
        margin-left: 3rem!important
    }

    .ms-lg-6 {
        margin-left: 5rem!important
    }

    .ms-lg-7 {
        margin-left: 8rem!important
    }

    .ms-lg-8 {
        margin-left: 10rem!important
    }

    .ms-lg-9 {
        margin-left: 11rem!important
    }

    .ms-lg-10 {
        margin-left: 14rem!important
    }

    .ms-lg-11 {
        margin-left: 16rem!important
    }

    .ms-lg-12 {
        margin-left: 20rem!important
    }

    .ms-lg-sm {
        margin-left: 1rem!important
    }

    .ms-lg-md {
        margin-left: 2rem!important
    }

    .ms-lg-lg {
        margin-left: 4rem!important
    }

    .ms-lg-xl {
        margin-left: 8rem!important
    }

    .ms-lg-auto {
        margin-left: auto!important
    }

    .m-lg-n1 {
        margin: -.25rem!important
    }

    .m-lg-n2 {
        margin: -.5rem!important
    }

    .m-lg-n3 {
        margin: -1rem!important
    }

    .m-lg-n4 {
        margin: -1.5rem!important
    }

    .m-lg-n5 {
        margin: -3rem!important
    }

    .m-lg-n6 {
        margin: -5rem!important
    }

    .m-lg-n7 {
        margin: -8rem!important
    }

    .m-lg-n8 {
        margin: -10rem!important
    }

    .m-lg-n9 {
        margin: -11rem!important
    }

    .m-lg-n10 {
        margin: -14rem!important
    }

    .m-lg-n11 {
        margin: -16rem!important
    }

    .m-lg-n12 {
        margin: -20rem!important
    }

    .m-lg-nsm {
        margin: -1rem!important
    }

    .m-lg-nmd {
        margin: -2rem!important
    }

    .m-lg-nlg {
        margin: -4rem!important
    }

    .m-lg-nxl {
        margin: -8rem!important
    }

    .mx-lg-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-lg-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-lg-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-lg-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-lg-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-lg-n6 {
        margin-right: -5rem!important;
        margin-left: -5rem!important
    }

    .mx-lg-n7 {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .mx-lg-n8 {
        margin-right: -10rem!important;
        margin-left: -10rem!important
    }

    .mx-lg-n9 {
        margin-right: -11rem!important;
        margin-left: -11rem!important
    }

    .mx-lg-n10 {
        margin-right: -14rem!important;
        margin-left: -14rem!important
    }

    .mx-lg-n11 {
        margin-right: -16rem!important;
        margin-left: -16rem!important
    }

    .mx-lg-n12 {
        margin-right: -20rem!important;
        margin-left: -20rem!important
    }

    .mx-lg-nsm {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-lg-nmd {
        margin-right: -2rem!important;
        margin-left: -2rem!important
    }

    .mx-lg-nlg {
        margin-right: -4rem!important;
        margin-left: -4rem!important
    }

    .mx-lg-nxl {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .my-lg-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-lg-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-lg-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-lg-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-lg-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-lg-n6 {
        margin-top: -5rem!important;
        margin-bottom: -5rem!important
    }

    .my-lg-n7 {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .my-lg-n8 {
        margin-top: -10rem!important;
        margin-bottom: -10rem!important
    }

    .my-lg-n9 {
        margin-top: -11rem!important;
        margin-bottom: -11rem!important
    }

    .my-lg-n10 {
        margin-top: -14rem!important;
        margin-bottom: -14rem!important
    }

    .my-lg-n11 {
        margin-top: -16rem!important;
        margin-bottom: -16rem!important
    }

    .my-lg-n12 {
        margin-top: -20rem!important;
        margin-bottom: -20rem!important
    }

    .my-lg-nsm {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-lg-nmd {
        margin-top: -2rem!important;
        margin-bottom: -2rem!important
    }

    .my-lg-nlg {
        margin-top: -4rem!important;
        margin-bottom: -4rem!important
    }

    .my-lg-nxl {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .mt-lg-n1 {
        margin-top: -.25rem!important
    }

    .mt-lg-n2 {
        margin-top: -.5rem!important
    }

    .mt-lg-n3 {
        margin-top: -1rem!important
    }

    .mt-lg-n4 {
        margin-top: -1.5rem!important
    }

    .mt-lg-n5 {
        margin-top: -3rem!important
    }

    .mt-lg-n6 {
        margin-top: -5rem!important
    }

    .mt-lg-n7 {
        margin-top: -8rem!important
    }

    .mt-lg-n8 {
        margin-top: -10rem!important
    }

    .mt-lg-n9 {
        margin-top: -11rem!important
    }

    .mt-lg-n10 {
        margin-top: -14rem!important
    }

    .mt-lg-n11 {
        margin-top: -16rem!important
    }

    .mt-lg-n12 {
        margin-top: -20rem!important
    }

    .mt-lg-nsm {
        margin-top: -1rem!important
    }

    .mt-lg-nmd {
        margin-top: -2rem!important
    }

    .mt-lg-nlg {
        margin-top: -4rem!important
    }

    .mt-lg-nxl {
        margin-top: -8rem!important
    }

    .me-lg-n1 {
        margin-right: -.25rem!important
    }

    .me-lg-n2 {
        margin-right: -.5rem!important
    }

    .me-lg-n3 {
        margin-right: -1rem!important
    }

    .me-lg-n4 {
        margin-right: -1.5rem!important
    }

    .me-lg-n5 {
        margin-right: -3rem!important
    }

    .me-lg-n6 {
        margin-right: -5rem!important
    }

    .me-lg-n7 {
        margin-right: -8rem!important
    }

    .me-lg-n8 {
        margin-right: -10rem!important
    }

    .me-lg-n9 {
        margin-right: -11rem!important
    }

    .me-lg-n10 {
        margin-right: -14rem!important
    }

    .me-lg-n11 {
        margin-right: -16rem!important
    }

    .me-lg-n12 {
        margin-right: -20rem!important
    }

    .me-lg-nsm {
        margin-right: -1rem!important
    }

    .me-lg-nmd {
        margin-right: -2rem!important
    }

    .me-lg-nlg {
        margin-right: -4rem!important
    }

    .me-lg-nxl {
        margin-right: -8rem!important
    }

    .mb-lg-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-lg-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-lg-n3 {
        margin-bottom: -1rem!important
    }

    .mb-lg-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-lg-n5 {
        margin-bottom: -3rem!important
    }

    .mb-lg-n6 {
        margin-bottom: -5rem!important
    }

    .mb-lg-n7 {
        margin-bottom: -8rem!important
    }

    .mb-lg-n8 {
        margin-bottom: -10rem!important
    }

    .mb-lg-n9 {
        margin-bottom: -11rem!important
    }

    .mb-lg-n10 {
        margin-bottom: -14rem!important
    }

    .mb-lg-n11 {
        margin-bottom: -16rem!important
    }

    .mb-lg-n12 {
        margin-bottom: -20rem!important
    }

    .mb-lg-nsm {
        margin-bottom: -1rem!important
    }

    .mb-lg-nmd {
        margin-bottom: -2rem!important
    }

    .mb-lg-nlg {
        margin-bottom: -4rem!important
    }

    .mb-lg-nxl {
        margin-bottom: -8rem!important
    }

    .ms-lg-n1 {
        margin-left: -.25rem!important
    }

    .ms-lg-n2 {
        margin-left: -.5rem!important
    }

    .ms-lg-n3 {
        margin-left: -1rem!important
    }

    .ms-lg-n4 {
        margin-left: -1.5rem!important
    }

    .ms-lg-n5 {
        margin-left: -3rem!important
    }

    .ms-lg-n6 {
        margin-left: -5rem!important
    }

    .ms-lg-n7 {
        margin-left: -8rem!important
    }

    .ms-lg-n8 {
        margin-left: -10rem!important
    }

    .ms-lg-n9 {
        margin-left: -11rem!important
    }

    .ms-lg-n10 {
        margin-left: -14rem!important
    }

    .ms-lg-n11 {
        margin-left: -16rem!important
    }

    .ms-lg-n12 {
        margin-left: -20rem!important
    }

    .ms-lg-nsm {
        margin-left: -1rem!important
    }

    .ms-lg-nmd {
        margin-left: -2rem!important
    }

    .ms-lg-nlg {
        margin-left: -4rem!important
    }

    .ms-lg-nxl {
        margin-left: -8rem!important
    }

    .p-lg-0 {
        padding: 0!important
    }

    .p-lg-1 {
        padding: .25rem!important
    }

    .p-lg-2 {
        padding: .5rem!important
    }

    .p-lg-3 {
        padding: 1rem!important
    }

    .p-lg-4 {
        padding: 1.5rem!important
    }

    .p-lg-5 {
        padding: 3rem!important
    }

    .p-lg-6 {
        padding: 5rem!important
    }

    .p-lg-7 {
        padding: 8rem!important
    }

    .p-lg-8 {
        padding: 10rem!important
    }

    .p-lg-9 {
        padding: 11rem!important
    }

    .p-lg-10 {
        padding: 14rem!important
    }

    .p-lg-11 {
        padding: 16rem!important
    }

    .p-lg-12 {
        padding: 20rem!important
    }

    .p-lg-sm {
        padding: 1rem!important
    }

    .p-lg-md {
        padding: 2rem!important
    }

    .p-lg-lg {
        padding: 4rem!important
    }

    .p-lg-xl {
        padding: 8rem!important
    }

    .px-lg-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-lg-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-lg-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-lg-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-lg-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-lg-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-lg-6 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-lg-7 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-lg-8 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-lg-9 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-lg-10 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-lg-11 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-lg-12 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .px-lg-sm {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-lg-md {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-lg-lg {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-lg-xl {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .py-lg-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-lg-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-lg-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-lg-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-lg-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-lg-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-lg-6 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-lg-7 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-lg-8 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-lg-9 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-lg-10 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-lg-11 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-lg-12 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .py-lg-sm {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-lg-md {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-lg-lg {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-lg-xl {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .pt-lg-0 {
        padding-top: 0!important
    }

    .pt-lg-1 {
        padding-top: .25rem!important
    }

    .pt-lg-2 {
        padding-top: .5rem!important
    }

    .pt-lg-3 {
        padding-top: 1rem!important
    }

    .pt-lg-4 {
        padding-top: 1.5rem!important
    }

    .pt-lg-5 {
        padding-top: 3rem!important
    }

    .pt-lg-6 {
        padding-top: 5rem!important
    }

    .pt-lg-7 {
        padding-top: 8rem!important
    }

    .pt-lg-8 {
        padding-top: 10rem!important
    }

    .pt-lg-9 {
        padding-top: 11rem!important
    }

    .pt-lg-10 {
        padding-top: 14rem!important
    }

    .pt-lg-11 {
        padding-top: 16rem!important
    }

    .pt-lg-12 {
        padding-top: 20rem!important
    }

    .pt-lg-sm {
        padding-top: 1rem!important
    }

    .pt-lg-md {
        padding-top: 2rem!important
    }

    .pt-lg-lg {
        padding-top: 4rem!important
    }

    .pt-lg-xl {
        padding-top: 8rem!important
    }

    .pe-lg-0 {
        padding-right: 0!important
    }

    .pe-lg-1 {
        padding-right: .25rem!important
    }

    .pe-lg-2 {
        padding-right: .5rem!important
    }

    .pe-lg-3 {
        padding-right: 1rem!important
    }

    .pe-lg-4 {
        padding-right: 1.5rem!important
    }

    .pe-lg-5 {
        padding-right: 3rem!important
    }

    .pe-lg-6 {
        padding-right: 5rem!important
    }

    .pe-lg-7 {
        padding-right: 8rem!important
    }

    .pe-lg-8 {
        padding-right: 10rem!important
    }

    .pe-lg-9 {
        padding-right: 11rem!important
    }

    .pe-lg-10 {
        padding-right: 14rem!important
    }

    .pe-lg-11 {
        padding-right: 16rem!important
    }

    .pe-lg-12 {
        padding-right: 20rem!important
    }

    .pe-lg-sm {
        padding-right: 1rem!important
    }

    .pe-lg-md {
        padding-right: 2rem!important
    }

    .pe-lg-lg {
        padding-right: 4rem!important
    }

    .pe-lg-xl {
        padding-right: 8rem!important
    }

    .pb-lg-0 {
        padding-bottom: 0!important
    }

    .pb-lg-1 {
        padding-bottom: .25rem!important
    }

    .pb-lg-2 {
        padding-bottom: .5rem!important
    }

    .pb-lg-3 {
        padding-bottom: 1rem!important
    }

    .pb-lg-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-lg-5 {
        padding-bottom: 3rem!important
    }

    .pb-lg-6 {
        padding-bottom: 5rem!important
    }

    .pb-lg-7 {
        padding-bottom: 8rem!important
    }

    .pb-lg-8 {
        padding-bottom: 10rem!important
    }

    .pb-lg-9 {
        padding-bottom: 11rem!important
    }

    .pb-lg-10 {
        padding-bottom: 14rem!important
    }

    .pb-lg-11 {
        padding-bottom: 16rem!important
    }

    .pb-lg-12 {
        padding-bottom: 20rem!important
    }

    .pb-lg-sm {
        padding-bottom: 1rem!important
    }

    .pb-lg-md {
        padding-bottom: 2rem!important
    }

    .pb-lg-lg {
        padding-bottom: 4rem!important
    }

    .pb-lg-xl {
        padding-bottom: 8rem!important
    }

    .ps-lg-0 {
        padding-left: 0!important
    }

    .ps-lg-1 {
        padding-left: .25rem!important
    }

    .ps-lg-2 {
        padding-left: .5rem!important
    }

    .ps-lg-3 {
        padding-left: 1rem!important
    }

    .ps-lg-4 {
        padding-left: 1.5rem!important
    }

    .ps-lg-5 {
        padding-left: 3rem!important
    }

    .ps-lg-6 {
        padding-left: 5rem!important
    }

    .ps-lg-7 {
        padding-left: 8rem!important
    }

    .ps-lg-8 {
        padding-left: 10rem!important
    }

    .ps-lg-9 {
        padding-left: 11rem!important
    }

    .ps-lg-10 {
        padding-left: 14rem!important
    }

    .ps-lg-11 {
        padding-left: 16rem!important
    }

    .ps-lg-12 {
        padding-left: 20rem!important
    }

    .ps-lg-sm {
        padding-left: 1rem!important
    }

    .ps-lg-md {
        padding-left: 2rem!important
    }

    .ps-lg-lg {
        padding-left: 4rem!important
    }

    .ps-lg-xl {
        padding-left: 8rem!important
    }

    .text-lg-start {
        text-align: left!important
    }

    .text-lg-end {
        text-align: right!important
    }

    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width: 1200px) {
    .float-xl-start {
        float:left!important
    }

    .float-xl-end {
        float: right!important
    }

    .float-xl-none {
        float: none!important
    }

    .d-xl-inline {
        display: inline!important
    }

    .d-xl-inline-block {
        display: inline-block!important
    }

    .d-xl-block {
        display: block!important
    }

    .d-xl-grid {
        display: grid!important
    }

    .d-xl-table {
        display: table!important
    }

    .d-xl-table-row {
        display: table-row!important
    }

    .d-xl-table-cell {
        display: table-cell!important
    }

    .d-xl-flex {
        display: flex!important
    }

    .d-xl-inline-flex {
        display: inline-flex!important
    }

    .d-xl-none {
        display: none!important
    }

    .flex-xl-fill {
        flex: 1 1 auto!important
    }

    .flex-xl-row {
        flex-direction: row!important
    }

    .flex-xl-column {
        flex-direction: column!important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-xl-grow-0 {
        flex-grow: 0!important
    }

    .flex-xl-grow-1 {
        flex-grow: 1!important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-xl-wrap {
        flex-wrap: wrap!important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .gap-xl-0 {
        gap: 0!important
    }

    .gap-xl-1 {
        gap: .25rem!important
    }

    .gap-xl-2 {
        gap: .5rem!important
    }

    .gap-xl-3 {
        gap: 1rem!important
    }

    .gap-xl-4 {
        gap: 1.5rem!important
    }

    .gap-xl-5 {
        gap: 3rem!important
    }

    .gap-xl-6 {
        gap: 5rem!important
    }

    .gap-xl-7 {
        gap: 8rem!important
    }

    .gap-xl-8 {
        gap: 10rem!important
    }

    .gap-xl-9 {
        gap: 11rem!important
    }

    .gap-xl-10 {
        gap: 14rem!important
    }

    .gap-xl-11 {
        gap: 16rem!important
    }

    .gap-xl-12 {
        gap: 20rem!important
    }

    .gap-xl-sm {
        gap: 1rem!important
    }

    .gap-xl-md {
        gap: 2rem!important
    }

    .gap-xl-lg {
        gap: 4rem!important
    }

    .gap-xl-xl {
        gap: 8rem!important
    }

    .justify-content-xl-start {
        justify-content: flex-start!important
    }

    .justify-content-xl-end {
        justify-content: flex-end!important
    }

    .justify-content-xl-center {
        justify-content: center!important
    }

    .justify-content-xl-between {
        justify-content: space-between!important
    }

    .justify-content-xl-around {
        justify-content: space-around!important
    }

    .justify-content-xl-evenly {
        justify-content: space-evenly!important
    }

    .align-items-xl-start {
        align-items: flex-start!important
    }

    .align-items-xl-end {
        align-items: flex-end!important
    }

    .align-items-xl-center {
        align-items: center!important
    }

    .align-items-xl-baseline {
        align-items: baseline!important
    }

    .align-items-xl-stretch {
        align-items: stretch!important
    }

    .align-content-xl-start {
        align-content: flex-start!important
    }

    .align-content-xl-end {
        align-content: flex-end!important
    }

    .align-content-xl-center {
        align-content: center!important
    }

    .align-content-xl-between {
        align-content: space-between!important
    }

    .align-content-xl-around {
        align-content: space-around!important
    }

    .align-content-xl-stretch {
        align-content: stretch!important
    }

    .align-self-xl-auto {
        align-self: auto!important
    }

    .align-self-xl-start {
        align-self: flex-start!important
    }

    .align-self-xl-end {
        align-self: flex-end!important
    }

    .align-self-xl-center {
        align-self: center!important
    }

    .align-self-xl-baseline {
        align-self: baseline!important
    }

    .align-self-xl-stretch {
        align-self: stretch!important
    }

    .order-xl-first {
        order: -1!important
    }

    .order-xl-0 {
        order: 0!important
    }

    .order-xl-1 {
        order: 1!important
    }

    .order-xl-2 {
        order: 2!important
    }

    .order-xl-3 {
        order: 3!important
    }

    .order-xl-4 {
        order: 4!important
    }

    .order-xl-5 {
        order: 5!important
    }

    .order-xl-last {
        order: 6!important
    }

    .m-xl-0 {
        margin: 0!important
    }

    .m-xl-1 {
        margin: .25rem!important
    }

    .m-xl-2 {
        margin: .5rem!important
    }

    .m-xl-3 {
        margin: 1rem!important
    }

    .m-xl-4 {
        margin: 1.5rem!important
    }

    .m-xl-5 {
        margin: 3rem!important
    }

    .m-xl-6 {
        margin: 5rem!important
    }

    .m-xl-7 {
        margin: 8rem!important
    }

    .m-xl-8 {
        margin: 10rem!important
    }

    .m-xl-9 {
        margin: 11rem!important
    }

    .m-xl-10 {
        margin: 14rem!important
    }

    .m-xl-11 {
        margin: 16rem!important
    }

    .m-xl-12 {
        margin: 20rem!important
    }

    .m-xl-sm {
        margin: 1rem!important
    }

    .m-xl-md {
        margin: 2rem!important
    }

    .m-xl-lg {
        margin: 4rem!important
    }

    .m-xl-xl {
        margin: 8rem!important
    }

    .m-xl-auto {
        margin: auto!important
    }

    .mx-xl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xl-6 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-xl-7 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-xl-8 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-xl-9 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-xl-10 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-xl-11 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-xl-12 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-xl-sm {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xl-md {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-xl-lg {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-xl-xl {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-xl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xl-6 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-xl-7 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-xl-8 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-xl-9 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-xl-10 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-xl-11 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-xl-12 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-xl-sm {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xl-md {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-xl-lg {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-xl-xl {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-xl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xl-0 {
        margin-top: 0!important
    }

    .mt-xl-1 {
        margin-top: .25rem!important
    }

    .mt-xl-2 {
        margin-top: .5rem!important
    }

    .mt-xl-3 {
        margin-top: 1rem!important
    }

    .mt-xl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xl-5 {
        margin-top: 3rem!important
    }

    .mt-xl-6 {
        margin-top: 5rem!important
    }

    .mt-xl-7 {
        margin-top: 8rem!important
    }

    .mt-xl-8 {
        margin-top: 10rem!important
    }

    .mt-xl-9 {
        margin-top: 11rem!important
    }

    .mt-xl-10 {
        margin-top: 14rem!important
    }

    .mt-xl-11 {
        margin-top: 16rem!important
    }

    .mt-xl-12 {
        margin-top: 20rem!important
    }

    .mt-xl-sm {
        margin-top: 1rem!important
    }

    .mt-xl-md {
        margin-top: 2rem!important
    }

    .mt-xl-lg {
        margin-top: 4rem!important
    }

    .mt-xl-xl {
        margin-top: 8rem!important
    }

    .mt-xl-auto {
        margin-top: auto!important
    }

    .me-xl-0 {
        margin-right: 0!important
    }

    .me-xl-1 {
        margin-right: .25rem!important
    }

    .me-xl-2 {
        margin-right: .5rem!important
    }

    .me-xl-3 {
        margin-right: 1rem!important
    }

    .me-xl-4 {
        margin-right: 1.5rem!important
    }

    .me-xl-5 {
        margin-right: 3rem!important
    }

    .me-xl-6 {
        margin-right: 5rem!important
    }

    .me-xl-7 {
        margin-right: 8rem!important
    }

    .me-xl-8 {
        margin-right: 10rem!important
    }

    .me-xl-9 {
        margin-right: 11rem!important
    }

    .me-xl-10 {
        margin-right: 14rem!important
    }

    .me-xl-11 {
        margin-right: 16rem!important
    }

    .me-xl-12 {
        margin-right: 20rem!important
    }

    .me-xl-sm {
        margin-right: 1rem!important
    }

    .me-xl-md {
        margin-right: 2rem!important
    }

    .me-xl-lg {
        margin-right: 4rem!important
    }

    .me-xl-xl {
        margin-right: 8rem!important
    }

    .me-xl-auto {
        margin-right: auto!important
    }

    .mb-xl-0 {
        margin-bottom: 0!important
    }

    .mb-xl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xl-6 {
        margin-bottom: 5rem!important
    }

    .mb-xl-7 {
        margin-bottom: 8rem!important
    }

    .mb-xl-8 {
        margin-bottom: 10rem!important
    }

    .mb-xl-9 {
        margin-bottom: 11rem!important
    }

    .mb-xl-10 {
        margin-bottom: 14rem!important
    }

    .mb-xl-11 {
        margin-bottom: 16rem!important
    }

    .mb-xl-12 {
        margin-bottom: 20rem!important
    }

    .mb-xl-sm {
        margin-bottom: 1rem!important
    }

    .mb-xl-md {
        margin-bottom: 2rem!important
    }

    .mb-xl-lg {
        margin-bottom: 4rem!important
    }

    .mb-xl-xl {
        margin-bottom: 8rem!important
    }

    .mb-xl-auto {
        margin-bottom: auto!important
    }

    .ms-xl-0 {
        margin-left: 0!important
    }

    .ms-xl-1 {
        margin-left: .25rem!important
    }

    .ms-xl-2 {
        margin-left: .5rem!important
    }

    .ms-xl-3 {
        margin-left: 1rem!important
    }

    .ms-xl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xl-5 {
        margin-left: 3rem!important
    }

    .ms-xl-6 {
        margin-left: 5rem!important
    }

    .ms-xl-7 {
        margin-left: 8rem!important
    }

    .ms-xl-8 {
        margin-left: 10rem!important
    }

    .ms-xl-9 {
        margin-left: 11rem!important
    }

    .ms-xl-10 {
        margin-left: 14rem!important
    }

    .ms-xl-11 {
        margin-left: 16rem!important
    }

    .ms-xl-12 {
        margin-left: 20rem!important
    }

    .ms-xl-sm {
        margin-left: 1rem!important
    }

    .ms-xl-md {
        margin-left: 2rem!important
    }

    .ms-xl-lg {
        margin-left: 4rem!important
    }

    .ms-xl-xl {
        margin-left: 8rem!important
    }

    .ms-xl-auto {
        margin-left: auto!important
    }

    .m-xl-n1 {
        margin: -.25rem!important
    }

    .m-xl-n2 {
        margin: -.5rem!important
    }

    .m-xl-n3 {
        margin: -1rem!important
    }

    .m-xl-n4 {
        margin: -1.5rem!important
    }

    .m-xl-n5 {
        margin: -3rem!important
    }

    .m-xl-n6 {
        margin: -5rem!important
    }

    .m-xl-n7 {
        margin: -8rem!important
    }

    .m-xl-n8 {
        margin: -10rem!important
    }

    .m-xl-n9 {
        margin: -11rem!important
    }

    .m-xl-n10 {
        margin: -14rem!important
    }

    .m-xl-n11 {
        margin: -16rem!important
    }

    .m-xl-n12 {
        margin: -20rem!important
    }

    .m-xl-nsm {
        margin: -1rem!important
    }

    .m-xl-nmd {
        margin: -2rem!important
    }

    .m-xl-nlg {
        margin: -4rem!important
    }

    .m-xl-nxl {
        margin: -8rem!important
    }

    .mx-xl-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-xl-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-xl-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-xl-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-xl-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-xl-n6 {
        margin-right: -5rem!important;
        margin-left: -5rem!important
    }

    .mx-xl-n7 {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .mx-xl-n8 {
        margin-right: -10rem!important;
        margin-left: -10rem!important
    }

    .mx-xl-n9 {
        margin-right: -11rem!important;
        margin-left: -11rem!important
    }

    .mx-xl-n10 {
        margin-right: -14rem!important;
        margin-left: -14rem!important
    }

    .mx-xl-n11 {
        margin-right: -16rem!important;
        margin-left: -16rem!important
    }

    .mx-xl-n12 {
        margin-right: -20rem!important;
        margin-left: -20rem!important
    }

    .mx-xl-nsm {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-xl-nmd {
        margin-right: -2rem!important;
        margin-left: -2rem!important
    }

    .mx-xl-nlg {
        margin-right: -4rem!important;
        margin-left: -4rem!important
    }

    .mx-xl-nxl {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .my-xl-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-xl-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-xl-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-xl-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-xl-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-xl-n6 {
        margin-top: -5rem!important;
        margin-bottom: -5rem!important
    }

    .my-xl-n7 {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .my-xl-n8 {
        margin-top: -10rem!important;
        margin-bottom: -10rem!important
    }

    .my-xl-n9 {
        margin-top: -11rem!important;
        margin-bottom: -11rem!important
    }

    .my-xl-n10 {
        margin-top: -14rem!important;
        margin-bottom: -14rem!important
    }

    .my-xl-n11 {
        margin-top: -16rem!important;
        margin-bottom: -16rem!important
    }

    .my-xl-n12 {
        margin-top: -20rem!important;
        margin-bottom: -20rem!important
    }

    .my-xl-nsm {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-xl-nmd {
        margin-top: -2rem!important;
        margin-bottom: -2rem!important
    }

    .my-xl-nlg {
        margin-top: -4rem!important;
        margin-bottom: -4rem!important
    }

    .my-xl-nxl {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .mt-xl-n1 {
        margin-top: -.25rem!important
    }

    .mt-xl-n2 {
        margin-top: -.5rem!important
    }

    .mt-xl-n3 {
        margin-top: -1rem!important
    }

    .mt-xl-n4 {
        margin-top: -1.5rem!important
    }

    .mt-xl-n5 {
        margin-top: -3rem!important
    }

    .mt-xl-n6 {
        margin-top: -5rem!important
    }

    .mt-xl-n7 {
        margin-top: -8rem!important
    }

    .mt-xl-n8 {
        margin-top: -10rem!important
    }

    .mt-xl-n9 {
        margin-top: -11rem!important
    }

    .mt-xl-n10 {
        margin-top: -14rem!important
    }

    .mt-xl-n11 {
        margin-top: -16rem!important
    }

    .mt-xl-n12 {
        margin-top: -20rem!important
    }

    .mt-xl-nsm {
        margin-top: -1rem!important
    }

    .mt-xl-nmd {
        margin-top: -2rem!important
    }

    .mt-xl-nlg {
        margin-top: -4rem!important
    }

    .mt-xl-nxl {
        margin-top: -8rem!important
    }

    .me-xl-n1 {
        margin-right: -.25rem!important
    }

    .me-xl-n2 {
        margin-right: -.5rem!important
    }

    .me-xl-n3 {
        margin-right: -1rem!important
    }

    .me-xl-n4 {
        margin-right: -1.5rem!important
    }

    .me-xl-n5 {
        margin-right: -3rem!important
    }

    .me-xl-n6 {
        margin-right: -5rem!important
    }

    .me-xl-n7 {
        margin-right: -8rem!important
    }

    .me-xl-n8 {
        margin-right: -10rem!important
    }

    .me-xl-n9 {
        margin-right: -11rem!important
    }

    .me-xl-n10 {
        margin-right: -14rem!important
    }

    .me-xl-n11 {
        margin-right: -16rem!important
    }

    .me-xl-n12 {
        margin-right: -20rem!important
    }

    .me-xl-nsm {
        margin-right: -1rem!important
    }

    .me-xl-nmd {
        margin-right: -2rem!important
    }

    .me-xl-nlg {
        margin-right: -4rem!important
    }

    .me-xl-nxl {
        margin-right: -8rem!important
    }

    .mb-xl-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-xl-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-xl-n3 {
        margin-bottom: -1rem!important
    }

    .mb-xl-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-xl-n5 {
        margin-bottom: -3rem!important
    }

    .mb-xl-n6 {
        margin-bottom: -5rem!important
    }

    .mb-xl-n7 {
        margin-bottom: -8rem!important
    }

    .mb-xl-n8 {
        margin-bottom: -10rem!important
    }

    .mb-xl-n9 {
        margin-bottom: -11rem!important
    }

    .mb-xl-n10 {
        margin-bottom: -14rem!important
    }

    .mb-xl-n11 {
        margin-bottom: -16rem!important
    }

    .mb-xl-n12 {
        margin-bottom: -20rem!important
    }

    .mb-xl-nsm {
        margin-bottom: -1rem!important
    }

    .mb-xl-nmd {
        margin-bottom: -2rem!important
    }

    .mb-xl-nlg {
        margin-bottom: -4rem!important
    }

    .mb-xl-nxl {
        margin-bottom: -8rem!important
    }

    .ms-xl-n1 {
        margin-left: -.25rem!important
    }

    .ms-xl-n2 {
        margin-left: -.5rem!important
    }

    .ms-xl-n3 {
        margin-left: -1rem!important
    }

    .ms-xl-n4 {
        margin-left: -1.5rem!important
    }

    .ms-xl-n5 {
        margin-left: -3rem!important
    }

    .ms-xl-n6 {
        margin-left: -5rem!important
    }

    .ms-xl-n7 {
        margin-left: -8rem!important
    }

    .ms-xl-n8 {
        margin-left: -10rem!important
    }

    .ms-xl-n9 {
        margin-left: -11rem!important
    }

    .ms-xl-n10 {
        margin-left: -14rem!important
    }

    .ms-xl-n11 {
        margin-left: -16rem!important
    }

    .ms-xl-n12 {
        margin-left: -20rem!important
    }

    .ms-xl-nsm {
        margin-left: -1rem!important
    }

    .ms-xl-nmd {
        margin-left: -2rem!important
    }

    .ms-xl-nlg {
        margin-left: -4rem!important
    }

    .ms-xl-nxl {
        margin-left: -8rem!important
    }

    .p-xl-0 {
        padding: 0!important
    }

    .p-xl-1 {
        padding: .25rem!important
    }

    .p-xl-2 {
        padding: .5rem!important
    }

    .p-xl-3 {
        padding: 1rem!important
    }

    .p-xl-4 {
        padding: 1.5rem!important
    }

    .p-xl-5 {
        padding: 3rem!important
    }

    .p-xl-6 {
        padding: 5rem!important
    }

    .p-xl-7 {
        padding: 8rem!important
    }

    .p-xl-8 {
        padding: 10rem!important
    }

    .p-xl-9 {
        padding: 11rem!important
    }

    .p-xl-10 {
        padding: 14rem!important
    }

    .p-xl-11 {
        padding: 16rem!important
    }

    .p-xl-12 {
        padding: 20rem!important
    }

    .p-xl-sm {
        padding: 1rem!important
    }

    .p-xl-md {
        padding: 2rem!important
    }

    .p-xl-lg {
        padding: 4rem!important
    }

    .p-xl-xl {
        padding: 8rem!important
    }

    .px-xl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-xl-6 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-xl-7 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-xl-8 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-xl-9 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-xl-10 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-xl-11 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-xl-12 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .px-xl-sm {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xl-md {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-xl-lg {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-xl-xl {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .py-xl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-xl-6 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-xl-7 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-xl-8 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-xl-9 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-xl-10 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-xl-11 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-xl-12 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .py-xl-sm {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xl-md {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-xl-lg {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-xl-xl {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .pt-xl-0 {
        padding-top: 0!important
    }

    .pt-xl-1 {
        padding-top: .25rem!important
    }

    .pt-xl-2 {
        padding-top: .5rem!important
    }

    .pt-xl-3 {
        padding-top: 1rem!important
    }

    .pt-xl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xl-5 {
        padding-top: 3rem!important
    }

    .pt-xl-6 {
        padding-top: 5rem!important
    }

    .pt-xl-7 {
        padding-top: 8rem!important
    }

    .pt-xl-8 {
        padding-top: 10rem!important
    }

    .pt-xl-9 {
        padding-top: 11rem!important
    }

    .pt-xl-10 {
        padding-top: 14rem!important
    }

    .pt-xl-11 {
        padding-top: 16rem!important
    }

    .pt-xl-12 {
        padding-top: 20rem!important
    }

    .pt-xl-sm {
        padding-top: 1rem!important
    }

    .pt-xl-md {
        padding-top: 2rem!important
    }

    .pt-xl-lg {
        padding-top: 4rem!important
    }

    .pt-xl-xl {
        padding-top: 8rem!important
    }

    .pe-xl-0 {
        padding-right: 0!important
    }

    .pe-xl-1 {
        padding-right: .25rem!important
    }

    .pe-xl-2 {
        padding-right: .5rem!important
    }

    .pe-xl-3 {
        padding-right: 1rem!important
    }

    .pe-xl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xl-5 {
        padding-right: 3rem!important
    }

    .pe-xl-6 {
        padding-right: 5rem!important
    }

    .pe-xl-7 {
        padding-right: 8rem!important
    }

    .pe-xl-8 {
        padding-right: 10rem!important
    }

    .pe-xl-9 {
        padding-right: 11rem!important
    }

    .pe-xl-10 {
        padding-right: 14rem!important
    }

    .pe-xl-11 {
        padding-right: 16rem!important
    }

    .pe-xl-12 {
        padding-right: 20rem!important
    }

    .pe-xl-sm {
        padding-right: 1rem!important
    }

    .pe-xl-md {
        padding-right: 2rem!important
    }

    .pe-xl-lg {
        padding-right: 4rem!important
    }

    .pe-xl-xl {
        padding-right: 8rem!important
    }

    .pb-xl-0 {
        padding-bottom: 0!important
    }

    .pb-xl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xl-5 {
        padding-bottom: 3rem!important
    }

    .pb-xl-6 {
        padding-bottom: 5rem!important
    }

    .pb-xl-7 {
        padding-bottom: 8rem!important
    }

    .pb-xl-8 {
        padding-bottom: 10rem!important
    }

    .pb-xl-9 {
        padding-bottom: 11rem!important
    }

    .pb-xl-10 {
        padding-bottom: 14rem!important
    }

    .pb-xl-11 {
        padding-bottom: 16rem!important
    }

    .pb-xl-12 {
        padding-bottom: 20rem!important
    }

    .pb-xl-sm {
        padding-bottom: 1rem!important
    }

    .pb-xl-md {
        padding-bottom: 2rem!important
    }

    .pb-xl-lg {
        padding-bottom: 4rem!important
    }

    .pb-xl-xl {
        padding-bottom: 8rem!important
    }

    .ps-xl-0 {
        padding-left: 0!important
    }

    .ps-xl-1 {
        padding-left: .25rem!important
    }

    .ps-xl-2 {
        padding-left: .5rem!important
    }

    .ps-xl-3 {
        padding-left: 1rem!important
    }

    .ps-xl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xl-5 {
        padding-left: 3rem!important
    }

    .ps-xl-6 {
        padding-left: 5rem!important
    }

    .ps-xl-7 {
        padding-left: 8rem!important
    }

    .ps-xl-8 {
        padding-left: 10rem!important
    }

    .ps-xl-9 {
        padding-left: 11rem!important
    }

    .ps-xl-10 {
        padding-left: 14rem!important
    }

    .ps-xl-11 {
        padding-left: 16rem!important
    }

    .ps-xl-12 {
        padding-left: 20rem!important
    }

    .ps-xl-sm {
        padding-left: 1rem!important
    }

    .ps-xl-md {
        padding-left: 2rem!important
    }

    .ps-xl-lg {
        padding-left: 4rem!important
    }

    .ps-xl-xl {
        padding-left: 8rem!important
    }

    .text-xl-start {
        text-align: left!important
    }

    .text-xl-end {
        text-align: right!important
    }

    .text-xl-center {
        text-align: center!important
    }
}

@media (min-width: 1400px) {
    .float-xxl-start {
        float:left!important
    }

    .float-xxl-end {
        float: right!important
    }

    .float-xxl-none {
        float: none!important
    }

    .d-xxl-inline {
        display: inline!important
    }

    .d-xxl-inline-block {
        display: inline-block!important
    }

    .d-xxl-block {
        display: block!important
    }

    .d-xxl-grid {
        display: grid!important
    }

    .d-xxl-table {
        display: table!important
    }

    .d-xxl-table-row {
        display: table-row!important
    }

    .d-xxl-table-cell {
        display: table-cell!important
    }

    .d-xxl-flex {
        display: flex!important
    }

    .d-xxl-inline-flex {
        display: inline-flex!important
    }

    .d-xxl-none {
        display: none!important
    }

    .flex-xxl-fill {
        flex: 1 1 auto!important
    }

    .flex-xxl-row {
        flex-direction: row!important
    }

    .flex-xxl-column {
        flex-direction: column!important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse!important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse!important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0!important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1!important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0!important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1!important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap!important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap!important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse!important
    }

    .gap-xxl-0 {
        gap: 0!important
    }

    .gap-xxl-1 {
        gap: .25rem!important
    }

    .gap-xxl-2 {
        gap: .5rem!important
    }

    .gap-xxl-3 {
        gap: 1rem!important
    }

    .gap-xxl-4 {
        gap: 1.5rem!important
    }

    .gap-xxl-5 {
        gap: 3rem!important
    }

    .gap-xxl-6 {
        gap: 5rem!important
    }

    .gap-xxl-7 {
        gap: 8rem!important
    }

    .gap-xxl-8 {
        gap: 10rem!important
    }

    .gap-xxl-9 {
        gap: 11rem!important
    }

    .gap-xxl-10 {
        gap: 14rem!important
    }

    .gap-xxl-11 {
        gap: 16rem!important
    }

    .gap-xxl-12 {
        gap: 20rem!important
    }

    .gap-xxl-sm {
        gap: 1rem!important
    }

    .gap-xxl-md {
        gap: 2rem!important
    }

    .gap-xxl-lg {
        gap: 4rem!important
    }

    .gap-xxl-xl {
        gap: 8rem!important
    }

    .justify-content-xxl-start {
        justify-content: flex-start!important
    }

    .justify-content-xxl-end {
        justify-content: flex-end!important
    }

    .justify-content-xxl-center {
        justify-content: center!important
    }

    .justify-content-xxl-between {
        justify-content: space-between!important
    }

    .justify-content-xxl-around {
        justify-content: space-around!important
    }

    .justify-content-xxl-evenly {
        justify-content: space-evenly!important
    }

    .align-items-xxl-start {
        align-items: flex-start!important
    }

    .align-items-xxl-end {
        align-items: flex-end!important
    }

    .align-items-xxl-center {
        align-items: center!important
    }

    .align-items-xxl-baseline {
        align-items: baseline!important
    }

    .align-items-xxl-stretch {
        align-items: stretch!important
    }

    .align-content-xxl-start {
        align-content: flex-start!important
    }

    .align-content-xxl-end {
        align-content: flex-end!important
    }

    .align-content-xxl-center {
        align-content: center!important
    }

    .align-content-xxl-between {
        align-content: space-between!important
    }

    .align-content-xxl-around {
        align-content: space-around!important
    }

    .align-content-xxl-stretch {
        align-content: stretch!important
    }

    .align-self-xxl-auto {
        align-self: auto!important
    }

    .align-self-xxl-start {
        align-self: flex-start!important
    }

    .align-self-xxl-end {
        align-self: flex-end!important
    }

    .align-self-xxl-center {
        align-self: center!important
    }

    .align-self-xxl-baseline {
        align-self: baseline!important
    }

    .align-self-xxl-stretch {
        align-self: stretch!important
    }

    .order-xxl-first {
        order: -1!important
    }

    .order-xxl-0 {
        order: 0!important
    }

    .order-xxl-1 {
        order: 1!important
    }

    .order-xxl-2 {
        order: 2!important
    }

    .order-xxl-3 {
        order: 3!important
    }

    .order-xxl-4 {
        order: 4!important
    }

    .order-xxl-5 {
        order: 5!important
    }

    .order-xxl-last {
        order: 6!important
    }

    .m-xxl-0 {
        margin: 0!important
    }

    .m-xxl-1 {
        margin: .25rem!important
    }

    .m-xxl-2 {
        margin: .5rem!important
    }

    .m-xxl-3 {
        margin: 1rem!important
    }

    .m-xxl-4 {
        margin: 1.5rem!important
    }

    .m-xxl-5 {
        margin: 3rem!important
    }

    .m-xxl-6 {
        margin: 5rem!important
    }

    .m-xxl-7 {
        margin: 8rem!important
    }

    .m-xxl-8 {
        margin: 10rem!important
    }

    .m-xxl-9 {
        margin: 11rem!important
    }

    .m-xxl-10 {
        margin: 14rem!important
    }

    .m-xxl-11 {
        margin: 16rem!important
    }

    .m-xxl-12 {
        margin: 20rem!important
    }

    .m-xxl-sm {
        margin: 1rem!important
    }

    .m-xxl-md {
        margin: 2rem!important
    }

    .m-xxl-lg {
        margin: 4rem!important
    }

    .m-xxl-xl {
        margin: 8rem!important
    }

    .m-xxl-auto {
        margin: auto!important
    }

    .mx-xxl-0 {
        margin-right: 0!important;
        margin-left: 0!important
    }

    .mx-xxl-1 {
        margin-right: .25rem!important;
        margin-left: .25rem!important
    }

    .mx-xxl-2 {
        margin-right: .5rem!important;
        margin-left: .5rem!important
    }

    .mx-xxl-3 {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xxl-4 {
        margin-right: 1.5rem!important;
        margin-left: 1.5rem!important
    }

    .mx-xxl-5 {
        margin-right: 3rem!important;
        margin-left: 3rem!important
    }

    .mx-xxl-6 {
        margin-right: 5rem!important;
        margin-left: 5rem!important
    }

    .mx-xxl-7 {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-xxl-8 {
        margin-right: 10rem!important;
        margin-left: 10rem!important
    }

    .mx-xxl-9 {
        margin-right: 11rem!important;
        margin-left: 11rem!important
    }

    .mx-xxl-10 {
        margin-right: 14rem!important;
        margin-left: 14rem!important
    }

    .mx-xxl-11 {
        margin-right: 16rem!important;
        margin-left: 16rem!important
    }

    .mx-xxl-12 {
        margin-right: 20rem!important;
        margin-left: 20rem!important
    }

    .mx-xxl-sm {
        margin-right: 1rem!important;
        margin-left: 1rem!important
    }

    .mx-xxl-md {
        margin-right: 2rem!important;
        margin-left: 2rem!important
    }

    .mx-xxl-lg {
        margin-right: 4rem!important;
        margin-left: 4rem!important
    }

    .mx-xxl-xl {
        margin-right: 8rem!important;
        margin-left: 8rem!important
    }

    .mx-xxl-auto {
        margin-right: auto!important;
        margin-left: auto!important
    }

    .my-xxl-0 {
        margin-top: 0!important;
        margin-bottom: 0!important
    }

    .my-xxl-1 {
        margin-top: .25rem!important;
        margin-bottom: .25rem!important
    }

    .my-xxl-2 {
        margin-top: .5rem!important;
        margin-bottom: .5rem!important
    }

    .my-xxl-3 {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xxl-4 {
        margin-top: 1.5rem!important;
        margin-bottom: 1.5rem!important
    }

    .my-xxl-5 {
        margin-top: 3rem!important;
        margin-bottom: 3rem!important
    }

    .my-xxl-6 {
        margin-top: 5rem!important;
        margin-bottom: 5rem!important
    }

    .my-xxl-7 {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-xxl-8 {
        margin-top: 10rem!important;
        margin-bottom: 10rem!important
    }

    .my-xxl-9 {
        margin-top: 11rem!important;
        margin-bottom: 11rem!important
    }

    .my-xxl-10 {
        margin-top: 14rem!important;
        margin-bottom: 14rem!important
    }

    .my-xxl-11 {
        margin-top: 16rem!important;
        margin-bottom: 16rem!important
    }

    .my-xxl-12 {
        margin-top: 20rem!important;
        margin-bottom: 20rem!important
    }

    .my-xxl-sm {
        margin-top: 1rem!important;
        margin-bottom: 1rem!important
    }

    .my-xxl-md {
        margin-top: 2rem!important;
        margin-bottom: 2rem!important
    }

    .my-xxl-lg {
        margin-top: 4rem!important;
        margin-bottom: 4rem!important
    }

    .my-xxl-xl {
        margin-top: 8rem!important;
        margin-bottom: 8rem!important
    }

    .my-xxl-auto {
        margin-top: auto!important;
        margin-bottom: auto!important
    }

    .mt-xxl-0 {
        margin-top: 0!important
    }

    .mt-xxl-1 {
        margin-top: .25rem!important
    }

    .mt-xxl-2 {
        margin-top: .5rem!important
    }

    .mt-xxl-3 {
        margin-top: 1rem!important
    }

    .mt-xxl-4 {
        margin-top: 1.5rem!important
    }

    .mt-xxl-5 {
        margin-top: 3rem!important
    }

    .mt-xxl-6 {
        margin-top: 5rem!important
    }

    .mt-xxl-7 {
        margin-top: 8rem!important
    }

    .mt-xxl-8 {
        margin-top: 10rem!important
    }

    .mt-xxl-9 {
        margin-top: 11rem!important
    }

    .mt-xxl-10 {
        margin-top: 14rem!important
    }

    .mt-xxl-11 {
        margin-top: 16rem!important
    }

    .mt-xxl-12 {
        margin-top: 20rem!important
    }

    .mt-xxl-sm {
        margin-top: 1rem!important
    }

    .mt-xxl-md {
        margin-top: 2rem!important
    }

    .mt-xxl-lg {
        margin-top: 4rem!important
    }

    .mt-xxl-xl {
        margin-top: 8rem!important
    }

    .mt-xxl-auto {
        margin-top: auto!important
    }

    .me-xxl-0 {
        margin-right: 0!important
    }

    .me-xxl-1 {
        margin-right: .25rem!important
    }

    .me-xxl-2 {
        margin-right: .5rem!important
    }

    .me-xxl-3 {
        margin-right: 1rem!important
    }

    .me-xxl-4 {
        margin-right: 1.5rem!important
    }

    .me-xxl-5 {
        margin-right: 3rem!important
    }

    .me-xxl-6 {
        margin-right: 5rem!important
    }

    .me-xxl-7 {
        margin-right: 8rem!important
    }

    .me-xxl-8 {
        margin-right: 10rem!important
    }

    .me-xxl-9 {
        margin-right: 11rem!important
    }

    .me-xxl-10 {
        margin-right: 14rem!important
    }

    .me-xxl-11 {
        margin-right: 16rem!important
    }

    .me-xxl-12 {
        margin-right: 20rem!important
    }

    .me-xxl-sm {
        margin-right: 1rem!important
    }

    .me-xxl-md {
        margin-right: 2rem!important
    }

    .me-xxl-lg {
        margin-right: 4rem!important
    }

    .me-xxl-xl {
        margin-right: 8rem!important
    }

    .me-xxl-auto {
        margin-right: auto!important
    }

    .mb-xxl-0 {
        margin-bottom: 0!important
    }

    .mb-xxl-1 {
        margin-bottom: .25rem!important
    }

    .mb-xxl-2 {
        margin-bottom: .5rem!important
    }

    .mb-xxl-3 {
        margin-bottom: 1rem!important
    }

    .mb-xxl-4 {
        margin-bottom: 1.5rem!important
    }

    .mb-xxl-5 {
        margin-bottom: 3rem!important
    }

    .mb-xxl-6 {
        margin-bottom: 5rem!important
    }

    .mb-xxl-7 {
        margin-bottom: 8rem!important
    }

    .mb-xxl-8 {
        margin-bottom: 10rem!important
    }

    .mb-xxl-9 {
        margin-bottom: 11rem!important
    }

    .mb-xxl-10 {
        margin-bottom: 14rem!important
    }

    .mb-xxl-11 {
        margin-bottom: 16rem!important
    }

    .mb-xxl-12 {
        margin-bottom: 20rem!important
    }

    .mb-xxl-sm {
        margin-bottom: 1rem!important
    }

    .mb-xxl-md {
        margin-bottom: 2rem!important
    }

    .mb-xxl-lg {
        margin-bottom: 4rem!important
    }

    .mb-xxl-xl {
        margin-bottom: 8rem!important
    }

    .mb-xxl-auto {
        margin-bottom: auto!important
    }

    .ms-xxl-0 {
        margin-left: 0!important
    }

    .ms-xxl-1 {
        margin-left: .25rem!important
    }

    .ms-xxl-2 {
        margin-left: .5rem!important
    }

    .ms-xxl-3 {
        margin-left: 1rem!important
    }

    .ms-xxl-4 {
        margin-left: 1.5rem!important
    }

    .ms-xxl-5 {
        margin-left: 3rem!important
    }

    .ms-xxl-6 {
        margin-left: 5rem!important
    }

    .ms-xxl-7 {
        margin-left: 8rem!important
    }

    .ms-xxl-8 {
        margin-left: 10rem!important
    }

    .ms-xxl-9 {
        margin-left: 11rem!important
    }

    .ms-xxl-10 {
        margin-left: 14rem!important
    }

    .ms-xxl-11 {
        margin-left: 16rem!important
    }

    .ms-xxl-12 {
        margin-left: 20rem!important
    }

    .ms-xxl-sm {
        margin-left: 1rem!important
    }

    .ms-xxl-md {
        margin-left: 2rem!important
    }

    .ms-xxl-lg {
        margin-left: 4rem!important
    }

    .ms-xxl-xl {
        margin-left: 8rem!important
    }

    .ms-xxl-auto {
        margin-left: auto!important
    }

    .m-xxl-n1 {
        margin: -.25rem!important
    }

    .m-xxl-n2 {
        margin: -.5rem!important
    }

    .m-xxl-n3 {
        margin: -1rem!important
    }

    .m-xxl-n4 {
        margin: -1.5rem!important
    }

    .m-xxl-n5 {
        margin: -3rem!important
    }

    .m-xxl-n6 {
        margin: -5rem!important
    }

    .m-xxl-n7 {
        margin: -8rem!important
    }

    .m-xxl-n8 {
        margin: -10rem!important
    }

    .m-xxl-n9 {
        margin: -11rem!important
    }

    .m-xxl-n10 {
        margin: -14rem!important
    }

    .m-xxl-n11 {
        margin: -16rem!important
    }

    .m-xxl-n12 {
        margin: -20rem!important
    }

    .m-xxl-nsm {
        margin: -1rem!important
    }

    .m-xxl-nmd {
        margin: -2rem!important
    }

    .m-xxl-nlg {
        margin: -4rem!important
    }

    .m-xxl-nxl {
        margin: -8rem!important
    }

    .mx-xxl-n1 {
        margin-right: -.25rem!important;
        margin-left: -.25rem!important
    }

    .mx-xxl-n2 {
        margin-right: -.5rem!important;
        margin-left: -.5rem!important
    }

    .mx-xxl-n3 {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-xxl-n4 {
        margin-right: -1.5rem!important;
        margin-left: -1.5rem!important
    }

    .mx-xxl-n5 {
        margin-right: -3rem!important;
        margin-left: -3rem!important
    }

    .mx-xxl-n6 {
        margin-right: -5rem!important;
        margin-left: -5rem!important
    }

    .mx-xxl-n7 {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .mx-xxl-n8 {
        margin-right: -10rem!important;
        margin-left: -10rem!important
    }

    .mx-xxl-n9 {
        margin-right: -11rem!important;
        margin-left: -11rem!important
    }

    .mx-xxl-n10 {
        margin-right: -14rem!important;
        margin-left: -14rem!important
    }

    .mx-xxl-n11 {
        margin-right: -16rem!important;
        margin-left: -16rem!important
    }

    .mx-xxl-n12 {
        margin-right: -20rem!important;
        margin-left: -20rem!important
    }

    .mx-xxl-nsm {
        margin-right: -1rem!important;
        margin-left: -1rem!important
    }

    .mx-xxl-nmd {
        margin-right: -2rem!important;
        margin-left: -2rem!important
    }

    .mx-xxl-nlg {
        margin-right: -4rem!important;
        margin-left: -4rem!important
    }

    .mx-xxl-nxl {
        margin-right: -8rem!important;
        margin-left: -8rem!important
    }

    .my-xxl-n1 {
        margin-top: -.25rem!important;
        margin-bottom: -.25rem!important
    }

    .my-xxl-n2 {
        margin-top: -.5rem!important;
        margin-bottom: -.5rem!important
    }

    .my-xxl-n3 {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-xxl-n4 {
        margin-top: -1.5rem!important;
        margin-bottom: -1.5rem!important
    }

    .my-xxl-n5 {
        margin-top: -3rem!important;
        margin-bottom: -3rem!important
    }

    .my-xxl-n6 {
        margin-top: -5rem!important;
        margin-bottom: -5rem!important
    }

    .my-xxl-n7 {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .my-xxl-n8 {
        margin-top: -10rem!important;
        margin-bottom: -10rem!important
    }

    .my-xxl-n9 {
        margin-top: -11rem!important;
        margin-bottom: -11rem!important
    }

    .my-xxl-n10 {
        margin-top: -14rem!important;
        margin-bottom: -14rem!important
    }

    .my-xxl-n11 {
        margin-top: -16rem!important;
        margin-bottom: -16rem!important
    }

    .my-xxl-n12 {
        margin-top: -20rem!important;
        margin-bottom: -20rem!important
    }

    .my-xxl-nsm {
        margin-top: -1rem!important;
        margin-bottom: -1rem!important
    }

    .my-xxl-nmd {
        margin-top: -2rem!important;
        margin-bottom: -2rem!important
    }

    .my-xxl-nlg {
        margin-top: -4rem!important;
        margin-bottom: -4rem!important
    }

    .my-xxl-nxl {
        margin-top: -8rem!important;
        margin-bottom: -8rem!important
    }

    .mt-xxl-n1 {
        margin-top: -.25rem!important
    }

    .mt-xxl-n2 {
        margin-top: -.5rem!important
    }

    .mt-xxl-n3 {
        margin-top: -1rem!important
    }

    .mt-xxl-n4 {
        margin-top: -1.5rem!important
    }

    .mt-xxl-n5 {
        margin-top: -3rem!important
    }

    .mt-xxl-n6 {
        margin-top: -5rem!important
    }

    .mt-xxl-n7 {
        margin-top: -8rem!important
    }

    .mt-xxl-n8 {
        margin-top: -10rem!important
    }

    .mt-xxl-n9 {
        margin-top: -11rem!important
    }

    .mt-xxl-n10 {
        margin-top: -14rem!important
    }

    .mt-xxl-n11 {
        margin-top: -16rem!important
    }

    .mt-xxl-n12 {
        margin-top: -20rem!important
    }

    .mt-xxl-nsm {
        margin-top: -1rem!important
    }

    .mt-xxl-nmd {
        margin-top: -2rem!important
    }

    .mt-xxl-nlg {
        margin-top: -4rem!important
    }

    .mt-xxl-nxl {
        margin-top: -8rem!important
    }

    .me-xxl-n1 {
        margin-right: -.25rem!important
    }

    .me-xxl-n2 {
        margin-right: -.5rem!important
    }

    .me-xxl-n3 {
        margin-right: -1rem!important
    }

    .me-xxl-n4 {
        margin-right: -1.5rem!important
    }

    .me-xxl-n5 {
        margin-right: -3rem!important
    }

    .me-xxl-n6 {
        margin-right: -5rem!important
    }

    .me-xxl-n7 {
        margin-right: -8rem!important
    }

    .me-xxl-n8 {
        margin-right: -10rem!important
    }

    .me-xxl-n9 {
        margin-right: -11rem!important
    }

    .me-xxl-n10 {
        margin-right: -14rem!important
    }

    .me-xxl-n11 {
        margin-right: -16rem!important
    }

    .me-xxl-n12 {
        margin-right: -20rem!important
    }

    .me-xxl-nsm {
        margin-right: -1rem!important
    }

    .me-xxl-nmd {
        margin-right: -2rem!important
    }

    .me-xxl-nlg {
        margin-right: -4rem!important
    }

    .me-xxl-nxl {
        margin-right: -8rem!important
    }

    .mb-xxl-n1 {
        margin-bottom: -.25rem!important
    }

    .mb-xxl-n2 {
        margin-bottom: -.5rem!important
    }

    .mb-xxl-n3 {
        margin-bottom: -1rem!important
    }

    .mb-xxl-n4 {
        margin-bottom: -1.5rem!important
    }

    .mb-xxl-n5 {
        margin-bottom: -3rem!important
    }

    .mb-xxl-n6 {
        margin-bottom: -5rem!important
    }

    .mb-xxl-n7 {
        margin-bottom: -8rem!important
    }

    .mb-xxl-n8 {
        margin-bottom: -10rem!important
    }

    .mb-xxl-n9 {
        margin-bottom: -11rem!important
    }

    .mb-xxl-n10 {
        margin-bottom: -14rem!important
    }

    .mb-xxl-n11 {
        margin-bottom: -16rem!important
    }

    .mb-xxl-n12 {
        margin-bottom: -20rem!important
    }

    .mb-xxl-nsm {
        margin-bottom: -1rem!important
    }

    .mb-xxl-nmd {
        margin-bottom: -2rem!important
    }

    .mb-xxl-nlg {
        margin-bottom: -4rem!important
    }

    .mb-xxl-nxl {
        margin-bottom: -8rem!important
    }

    .ms-xxl-n1 {
        margin-left: -.25rem!important
    }

    .ms-xxl-n2 {
        margin-left: -.5rem!important
    }

    .ms-xxl-n3 {
        margin-left: -1rem!important
    }

    .ms-xxl-n4 {
        margin-left: -1.5rem!important
    }

    .ms-xxl-n5 {
        margin-left: -3rem!important
    }

    .ms-xxl-n6 {
        margin-left: -5rem!important
    }

    .ms-xxl-n7 {
        margin-left: -8rem!important
    }

    .ms-xxl-n8 {
        margin-left: -10rem!important
    }

    .ms-xxl-n9 {
        margin-left: -11rem!important
    }

    .ms-xxl-n10 {
        margin-left: -14rem!important
    }

    .ms-xxl-n11 {
        margin-left: -16rem!important
    }

    .ms-xxl-n12 {
        margin-left: -20rem!important
    }

    .ms-xxl-nsm {
        margin-left: -1rem!important
    }

    .ms-xxl-nmd {
        margin-left: -2rem!important
    }

    .ms-xxl-nlg {
        margin-left: -4rem!important
    }

    .ms-xxl-nxl {
        margin-left: -8rem!important
    }

    .p-xxl-0 {
        padding: 0!important
    }

    .p-xxl-1 {
        padding: .25rem!important
    }

    .p-xxl-2 {
        padding: .5rem!important
    }

    .p-xxl-3 {
        padding: 1rem!important
    }

    .p-xxl-4 {
        padding: 1.5rem!important
    }

    .p-xxl-5 {
        padding: 3rem!important
    }

    .p-xxl-6 {
        padding: 5rem!important
    }

    .p-xxl-7 {
        padding: 8rem!important
    }

    .p-xxl-8 {
        padding: 10rem!important
    }

    .p-xxl-9 {
        padding: 11rem!important
    }

    .p-xxl-10 {
        padding: 14rem!important
    }

    .p-xxl-11 {
        padding: 16rem!important
    }

    .p-xxl-12 {
        padding: 20rem!important
    }

    .p-xxl-sm {
        padding: 1rem!important
    }

    .p-xxl-md {
        padding: 2rem!important
    }

    .p-xxl-lg {
        padding: 4rem!important
    }

    .p-xxl-xl {
        padding: 8rem!important
    }

    .px-xxl-0 {
        padding-right: 0!important;
        padding-left: 0!important
    }

    .px-xxl-1 {
        padding-right: .25rem!important;
        padding-left: .25rem!important
    }

    .px-xxl-2 {
        padding-right: .5rem!important;
        padding-left: .5rem!important
    }

    .px-xxl-3 {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xxl-4 {
        padding-right: 1.5rem!important;
        padding-left: 1.5rem!important
    }

    .px-xxl-5 {
        padding-right: 3rem!important;
        padding-left: 3rem!important
    }

    .px-xxl-6 {
        padding-right: 5rem!important;
        padding-left: 5rem!important
    }

    .px-xxl-7 {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .px-xxl-8 {
        padding-right: 10rem!important;
        padding-left: 10rem!important
    }

    .px-xxl-9 {
        padding-right: 11rem!important;
        padding-left: 11rem!important
    }

    .px-xxl-10 {
        padding-right: 14rem!important;
        padding-left: 14rem!important
    }

    .px-xxl-11 {
        padding-right: 16rem!important;
        padding-left: 16rem!important
    }

    .px-xxl-12 {
        padding-right: 20rem!important;
        padding-left: 20rem!important
    }

    .px-xxl-sm {
        padding-right: 1rem!important;
        padding-left: 1rem!important
    }

    .px-xxl-md {
        padding-right: 2rem!important;
        padding-left: 2rem!important
    }

    .px-xxl-lg {
        padding-right: 4rem!important;
        padding-left: 4rem!important
    }

    .px-xxl-xl {
        padding-right: 8rem!important;
        padding-left: 8rem!important
    }

    .py-xxl-0 {
        padding-top: 0!important;
        padding-bottom: 0!important
    }

    .py-xxl-1 {
        padding-top: .25rem!important;
        padding-bottom: .25rem!important
    }

    .py-xxl-2 {
        padding-top: .5rem!important;
        padding-bottom: .5rem!important
    }

    .py-xxl-3 {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xxl-4 {
        padding-top: 1.5rem!important;
        padding-bottom: 1.5rem!important
    }

    .py-xxl-5 {
        padding-top: 3rem!important;
        padding-bottom: 3rem!important
    }

    .py-xxl-6 {
        padding-top: 5rem!important;
        padding-bottom: 5rem!important
    }

    .py-xxl-7 {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .py-xxl-8 {
        padding-top: 10rem!important;
        padding-bottom: 10rem!important
    }

    .py-xxl-9 {
        padding-top: 11rem!important;
        padding-bottom: 11rem!important
    }

    .py-xxl-10 {
        padding-top: 14rem!important;
        padding-bottom: 14rem!important
    }

    .py-xxl-11 {
        padding-top: 16rem!important;
        padding-bottom: 16rem!important
    }

    .py-xxl-12 {
        padding-top: 20rem!important;
        padding-bottom: 20rem!important
    }

    .py-xxl-sm {
        padding-top: 1rem!important;
        padding-bottom: 1rem!important
    }

    .py-xxl-md {
        padding-top: 2rem!important;
        padding-bottom: 2rem!important
    }

    .py-xxl-lg {
        padding-top: 4rem!important;
        padding-bottom: 4rem!important
    }

    .py-xxl-xl {
        padding-top: 8rem!important;
        padding-bottom: 8rem!important
    }

    .pt-xxl-0 {
        padding-top: 0!important
    }

    .pt-xxl-1 {
        padding-top: .25rem!important
    }

    .pt-xxl-2 {
        padding-top: .5rem!important
    }

    .pt-xxl-3 {
        padding-top: 1rem!important
    }

    .pt-xxl-4 {
        padding-top: 1.5rem!important
    }

    .pt-xxl-5 {
        padding-top: 3rem!important
    }

    .pt-xxl-6 {
        padding-top: 5rem!important
    }

    .pt-xxl-7 {
        padding-top: 8rem!important
    }

    .pt-xxl-8 {
        padding-top: 10rem!important
    }

    .pt-xxl-9 {
        padding-top: 11rem!important
    }

    .pt-xxl-10 {
        padding-top: 14rem!important
    }

    .pt-xxl-11 {
        padding-top: 16rem!important
    }

    .pt-xxl-12 {
        padding-top: 20rem!important
    }

    .pt-xxl-sm {
        padding-top: 1rem!important
    }

    .pt-xxl-md {
        padding-top: 2rem!important
    }

    .pt-xxl-lg {
        padding-top: 4rem!important
    }

    .pt-xxl-xl {
        padding-top: 8rem!important
    }

    .pe-xxl-0 {
        padding-right: 0!important
    }

    .pe-xxl-1 {
        padding-right: .25rem!important
    }

    .pe-xxl-2 {
        padding-right: .5rem!important
    }

    .pe-xxl-3 {
        padding-right: 1rem!important
    }

    .pe-xxl-4 {
        padding-right: 1.5rem!important
    }

    .pe-xxl-5 {
        padding-right: 3rem!important
    }

    .pe-xxl-6 {
        padding-right: 5rem!important
    }

    .pe-xxl-7 {
        padding-right: 8rem!important
    }

    .pe-xxl-8 {
        padding-right: 10rem!important
    }

    .pe-xxl-9 {
        padding-right: 11rem!important
    }

    .pe-xxl-10 {
        padding-right: 14rem!important
    }

    .pe-xxl-11 {
        padding-right: 16rem!important
    }

    .pe-xxl-12 {
        padding-right: 20rem!important
    }

    .pe-xxl-sm {
        padding-right: 1rem!important
    }

    .pe-xxl-md {
        padding-right: 2rem!important
    }

    .pe-xxl-lg {
        padding-right: 4rem!important
    }

    .pe-xxl-xl {
        padding-right: 8rem!important
    }

    .pb-xxl-0 {
        padding-bottom: 0!important
    }

    .pb-xxl-1 {
        padding-bottom: .25rem!important
    }

    .pb-xxl-2 {
        padding-bottom: .5rem!important
    }

    .pb-xxl-3 {
        padding-bottom: 1rem!important
    }

    .pb-xxl-4 {
        padding-bottom: 1.5rem!important
    }

    .pb-xxl-5 {
        padding-bottom: 3rem!important
    }

    .pb-xxl-6 {
        padding-bottom: 5rem!important
    }

    .pb-xxl-7 {
        padding-bottom: 8rem!important
    }

    .pb-xxl-8 {
        padding-bottom: 10rem!important
    }

    .pb-xxl-9 {
        padding-bottom: 11rem!important
    }

    .pb-xxl-10 {
        padding-bottom: 14rem!important
    }

    .pb-xxl-11 {
        padding-bottom: 16rem!important
    }

    .pb-xxl-12 {
        padding-bottom: 20rem!important
    }

    .pb-xxl-sm {
        padding-bottom: 1rem!important
    }

    .pb-xxl-md {
        padding-bottom: 2rem!important
    }

    .pb-xxl-lg {
        padding-bottom: 4rem!important
    }

    .pb-xxl-xl {
        padding-bottom: 8rem!important
    }

    .ps-xxl-0 {
        padding-left: 0!important
    }

    .ps-xxl-1 {
        padding-left: .25rem!important
    }

    .ps-xxl-2 {
        padding-left: .5rem!important
    }

    .ps-xxl-3 {
        padding-left: 1rem!important
    }

    .ps-xxl-4 {
        padding-left: 1.5rem!important
    }

    .ps-xxl-5 {
        padding-left: 3rem!important
    }

    .ps-xxl-6 {
        padding-left: 5rem!important
    }

    .ps-xxl-7 {
        padding-left: 8rem!important
    }

    .ps-xxl-8 {
        padding-left: 10rem!important
    }

    .ps-xxl-9 {
        padding-left: 11rem!important
    }

    .ps-xxl-10 {
        padding-left: 14rem!important
    }

    .ps-xxl-11 {
        padding-left: 16rem!important
    }

    .ps-xxl-12 {
        padding-left: 20rem!important
    }

    .ps-xxl-sm {
        padding-left: 1rem!important
    }

    .ps-xxl-md {
        padding-left: 2rem!important
    }

    .ps-xxl-lg {
        padding-left: 4rem!important
    }

    .ps-xxl-xl {
        padding-left: 8rem!important
    }

    .text-xxl-start {
        text-align: left!important
    }

    .text-xxl-end {
        text-align: right!important
    }

    .text-xxl-center {
        text-align: center!important
    }
}

@media (min-width: 1200px) {
    .fs-1 {
        font-size:2.5rem!important
    }

    .fs-2 {
        font-size: 2rem!important
    }

    .fs-3 {
        font-size: 1.75rem!important
    }

    .fs-4 {
        font-size: 1.5rem!important
    }
}

@media print {
    .d-print-inline {
        display: inline!important
    }

    .d-print-inline-block {
        display: inline-block!important
    }

    .d-print-block {
        display: block!important
    }

    .d-print-grid {
        display: grid!important
    }

    .d-print-table {
        display: table!important
    }

    .d-print-table-row {
        display: table-row!important
    }

    .d-print-table-cell {
        display: table-cell!important
    }

    .d-print-flex {
        display: flex!important
    }

    .d-print-inline-flex {
        display: inline-flex!important
    }

    .d-print-none {
        display: none!important
    }
}

.bg-gradient-primary {
    background: linear-gradient(87deg,#ff62000,#1e2b52 100%)!important
}

.bg-gradient-secondary {
    background: linear-gradient(87deg,#1c2540 0,#172345 100%)!important
}

.bg-gradient-tertiary {
    background: linear-gradient(87deg,#893168 0,#92286a 100%)!important
}

.bg-gradient-primary-app {
    background: linear-gradient(87deg,#ebf4f6 0,#eaf5f7 100%)!important
}

.bg-gradient-secondary-app {
    background: linear-gradient(87deg,#424aa0 0,#3741ab 100%)!important
}

.bg-gradient-success {
    background: linear-gradient(87deg,#3aaf85 0,#2ebb88 100%)!important
}

.bg-gradient-info {
    background: linear-gradient(87deg,#0d6efd 0,#0b6eff 100%)!important
}

.bg-gradient-warning {
    background: linear-gradient(87deg,#ffc107 0,#ffc107 100%)!important
}

.bg-gradient-danger {
    background: linear-gradient(87deg,#dc3545 0,#e8293b 100%)!important
}

.bg-gradient-white {
    background: linear-gradient(87deg,#fff 0,#fff 100%)!important
}

.bg-gradient-gray {
    background: linear-gradient(87deg,#1f2937 0,#1b283b 100%)!important
}

.bg-gradient-dark {
    background: linear-gradient(87deg,#1c2540 0,#172345 100%)!important
}

.bg-gradient-black {
    background: linear-gradient(87deg,#171f38 0,#131d3c 100%)!important
}

.bg-gradient-purple {
    background: linear-gradient(87deg,#6f42c1 0,#6b35ce 100%)!important
}

.bg-gradient-orange {
    background: linear-gradient(87deg,#e84422 0,#f43c16 100%)!important
}

.bg-gradient-gray-100 {
    background: linear-gradient(87deg,#f9fafb 0,#f9fafc 100%)!important
}

.bg-gradient-gray-200 {
    background: linear-gradient(87deg,#f3f4f6 0,#f2f4f7 100%)!important
}

.bg-gradient-gray-300 {
    background: linear-gradient(87deg,#e5e7eb 0,#e3e6ed 100%)!important
}

.bg-gradient-gray-400 {
    background: linear-gradient(87deg,#d1d5db 0,#cdd4df 100%)!important
}

.bg-gradient-gray-500 {
    background: linear-gradient(87deg,#9ca3af 0,#93a1b8 100%)!important
}

.bg-gradient-gray-600 {
    background: linear-gradient(87deg,#6b7280 0,#5f6e8c 100%)!important
}

.bg-gradient-gray-700 {
    background: linear-gradient(87deg,#4b5563 0,#42546c 100%)!important
}

.bg-gradient-gray-800 {
    background: linear-gradient(87deg,#374151 0,#303f58 100%)!important
}

.overlay-primary:before {
    position: absolute;
    content: "";
    background: #ff6200;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-secondary:before {
    position: absolute;
    content: "";
    background: #1c2540;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-tertiary:before {
    position: absolute;
    content: "";
    background: #893168;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-primary-app:before {
    position: absolute;
    content: "";
    background: #ebf4f6;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-secondary-app:before {
    position: absolute;
    content: "";
    background: #424aa0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-success:before {
    position: absolute;
    content: "";
    background: #3aaf85;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-info:before {
    position: absolute;
    content: "";
    background: #0d6efd;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-warning:before {
    position: absolute;
    content: "";
    background: #ffc107;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-danger:before {
    position: absolute;
    content: "";
    background: #dc3545;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-white:before {
    position: absolute;
    content: "";
    background: #fff;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray:before {
    position: absolute;
    content: "";
    background: #1f2937;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-dark:before {
    position: absolute;
    content: "";
    background: #1c2540;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-black:before {
    position: absolute;
    content: "";
    background: #171f38;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-purple:before {
    position: absolute;
    content: "";
    background: #6f42c1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-orange:before {
    position: absolute;
    content: "";
    background: #e84422;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray-100:before {
    position: absolute;
    content: "";
    background: #f9fafb;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray-200:before {
    position: absolute;
    content: "";
    background: #f3f4f6;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray-300:before {
    position: absolute;
    content: "";
    background: #e5e7eb;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray-400:before {
    position: absolute;
    content: "";
    background: #d1d5db;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray-500:before {
    position: absolute;
    content: "";
    background: #9ca3af;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray-600:before {
    position: absolute;
    content: "";
    background: #6b7280;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray-700:before {
    position: absolute;
    content: "";
    background: #4b5563;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.overlay-gray-800:before {
    position: absolute;
    content: "";
    background: #374151;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: .8;
    z-index: 0
}

.section-image {
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover
}

.floating-xs {
    animation: floating-xs 3s ease infinite;
    will-change: transform
}

.floating-xs:hover {
    animation-play-state: paused
}

@media (min-width: 576px) {
    .floating-sm {
        animation:floating-sm 3s ease infinite;
        will-change: transform
    }

    .floating-sm:hover {
        animation-play-state: paused
    }
}

@media (min-width: 768px) {
    .floating-md {
        animation:floating-md 3s ease infinite;
        will-change: transform
    }

    .floating-md:hover {
        animation-play-state: paused
    }
}

@media (min-width: 992px) {
    .floating-lg {
        animation:floating-lg 3s ease infinite;
        will-change: transform
    }

    .floating-lg:hover {
        animation-play-state: paused
    }
}

@media (min-width: 1200px) {
    .floating-xl {
        animation:floating-xl 3s ease infinite;
        will-change: transform
    }

    .floating-xl:hover {
        animation-play-state: paused
    }
}

@media (min-width: 1400px) {
    .floating-xxl {
        animation:floating-xxl 3s ease infinite;
        will-change: transform
    }

    .floating-xxl:hover {
        animation-play-state: paused
    }
}

@keyframes floating-lg {
    0% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(15px)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes floating-md {
    0% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(10px)
    }

    100% {
        transform: translateY(0)
    }
}

@keyframes floating-sm {
    0% {
        transform: translateY(0)
    }

    50% {
        transform: translateY(5px)
    }

    100% {
        transform: translateY(0)
    }
}

.overflow-visible {
    overflow: visible!important
}

.opacity-0 {
    opacity: 0!important
}

.opacity-1 {
    opacity: .1!important
}

.opacity-2 {
    opacity: .2!important
}

.opacity-3 {
    opacity: .3!important
}

.opacity-4 {
    opacity: .4!important
}

.opacity-5 {
    opacity: .5!important
}

.opacity-6 {
    opacity: .6!important
}

.opacity-7 {
    opacity: .7!important
}

.opacity-8 {
    opacity: .8!important
}

.opacity-9 {
    opacity: .9!important
}

.fill-opacity-0 {
    fill-opacity: 0!important
}

.fill-opacity-1 {
    fill-opacity: .1!important
}

.fill-opacity-2 {
    fill-opacity: .2!important
}

.fill-opacity-3 {
    fill-opacity: .3!important
}

.fill-opacity-4 {
    fill-opacity: .4!important
}

.fill-opacity-5 {
    fill-opacity: .5!important
}

.fill-opacity-6 {
    fill-opacity: .6!important
}

.fill-opacity-7 {
    fill-opacity: .7!important
}

.fill-opacity-8 {
    fill-opacity: .8!important
}

.fill-opacity-9 {
    fill-opacity: .9!important
}

.z-0 {
    position: relative;
    z-index: 0!important
}

.z-1 {
    position: relative;
    z-index: 1!important
}

.z-2 {
    position: relative;
    z-index: 2!important
}

.z-3 {
    position: relative;
    z-index: 3!important
}

.z-4 {
    position: relative;
    z-index: 4!important
}

.z-5 {
    position: relative;
    z-index: 5!important
}

.z-6 {
    position: relative;
    z-index: 6!important
}

.z-7 {
    position: relative;
    z-index: 7!important
}

.z-8 {
    position: relative;
    z-index: 8!important
}

.z-9 {
    position: relative;
    z-index: 9!important
}

.bw-md {
    border-width: .125rem!important
}

.bw-lg {
    border-width: .25rem!important
}

.bw-xl {
    border-width: .375rem!important
}

.border-top-left-radius-0 {
    border-top-left-radius: 0!important
}

.border-top-right-radius-0 {
    border-top-right-radius: 0!important
}

.border-bottom-left-radius-0 {
    border-bottom-left-radius: 0!important
}

.border-bottom-right-radius-0 {
    border-bottom-right-radius: 0!important
}

.rounded-xl {
    border-radius: .875rem!important
}

.top-0 {
    top: 0
}

.right-0 {
    right: 0
}

.bottom-0 {
    bottom: 0
}

.left-0 {
    left: 0
}

.top-1 {
    top: .25rem
}

.right-1 {
    right: .25rem
}

.bottom-1 {
    bottom: .25rem
}

.left-1 {
    left: .25rem
}

.top-2 {
    top: .5rem
}

.right-2 {
    right: .5rem
}

.bottom-2 {
    bottom: .5rem
}

.left-2 {
    left: .5rem
}

.top-3 {
    top: 1rem
}

.right-3 {
    right: 1rem
}

.bottom-3 {
    bottom: 1rem
}

.left-3 {
    left: 1rem
}

.top-4 {
    top: 1.5rem
}

.right-4 {
    right: 1.5rem
}

.bottom-4 {
    bottom: 1.5rem
}

.left-4 {
    left: 1.5rem
}

.top-5 {
    top: 3rem
}

.right-5 {
    right: 3rem
}

.bottom-5 {
    bottom: 3rem
}

.left-5 {
    left: 3rem
}

.top-6 {
    top: 5rem
}

.right-6 {
    right: 5rem
}

.bottom-6 {
    bottom: 5rem
}

.left-6 {
    left: 5rem
}

.top-7 {
    top: 8rem
}

.right-7 {
    right: 8rem
}

.bottom-7 {
    bottom: 8rem
}

.left-7 {
    left: 8rem
}

.top-8 {
    top: 10rem
}

.right-8 {
    right: 10rem
}

.bottom-8 {
    bottom: 10rem
}

.left-8 {
    left: 10rem
}

.top-9 {
    top: 11rem
}

.right-9 {
    right: 11rem
}

.bottom-9 {
    bottom: 11rem
}

.left-9 {
    left: 11rem
}

.top-10 {
    top: 14rem
}

.right-10 {
    right: 14rem
}

.bottom-10 {
    bottom: 14rem
}

.left-10 {
    left: 14rem
}

.top-11 {
    top: 16rem
}

.right-11 {
    right: 16rem
}

.bottom-11 {
    bottom: 16rem
}

.left-11 {
    left: 16rem
}

.top-12 {
    top: 20rem
}

.right-12 {
    right: 20rem
}

.bottom-12 {
    bottom: 20rem
}

.left-12 {
    left: 20rem
}

.top-sm {
    top: 1rem
}

.right-sm {
    right: 1rem
}

.bottom-sm {
    bottom: 1rem
}

.left-sm {
    left: 1rem
}

.top-md {
    top: 2rem
}

.right-md {
    right: 2rem
}

.bottom-md {
    bottom: 2rem
}

.left-md {
    left: 2rem
}

.top-lg {
    top: 4rem
}

.right-lg {
    right: 4rem
}

.bottom-lg {
    bottom: 4rem
}

.left-lg {
    left: 4rem
}

.top-xl {
    top: 8rem
}

.right-xl {
    right: 8rem
}

.bottom-xl {
    bottom: 8rem
}

.left-xl {
    left: 8rem
}

.center-horizontal-absolute,.center-vertical-absolute {
    position: absolute
}

.center-vertical-absolute {
    top: 50%;
    transform: translateY(-50%)
}

.center-horizontal-absolute {
    left: 50%;
    transform: translateX(-50%)
}

.h-100vh {
    height: 100vh!important
}

.fh-50 {
    height: 50px!important
}

.fh-100 {
    height: 100px!important
}

.fh-150 {
    height: 150px!important
}

.fh-200 {
    height: 200px!important
}

.fh-250 {
    height: 250px!important
}

.fh-300 {
    height: 300px!important
}

.fh-350 {
    height: 350px!important
}

.fh-400 {
    height: 400px!important
}

.fh-450 {
    height: 450px!important
}

.fh-500 {
    height: 500px!important
}

.fh-550 {
    height: 550px!important
}

.fh-600 {
    height: 600px!important
}

.fh-650 {
    height: 650px!important
}

.fh-700 {
    height: 700px!important
}

.fh-750 {
    height: 750px!important
}

.fh-800 {
    height: 800px!important
}

.fh-850 {
    height: 850px!important
}

.fh-900 {
    height: 900px!important
}

.fh-950 {
    height: 950px!important
}

.fh-1000 {
    height: 1000px!important
}

.fh-1050 {
    height: 1050px!important
}

.fh-1100 {
    height: 1100px!important
}

.fh-1150 {
    height: 1150px!important
}

.fh-1200 {
    height: 1200px!important
}

.fh-1250 {
    height: 1250px!important
}

.fh-1300 {
    height: 1300px!important
}

.fh-1350 {
    height: 1350px!important
}

.fh-1400 {
    height: 1400px!important
}

.fh-1450 {
    height: 1450px!important
}

.fh-1500 {
    height: 1500px!important
}

.fh-1550 {
    height: 1550px!important
}

.fh-1600 {
    height: 1600px!important
}

.fh-1650 {
    height: 1650px!important
}

.fh-1700 {
    height: 1700px!important
}

.fh-1750 {
    height: 1750px!important
}

.fh-1800 {
    height: 1800px!important
}

.fh-1850 {
    height: 1850px!important
}

.fh-1900 {
    height: 1900px!important
}

.fh-1950 {
    height: 1950px!important
}


.fw-950 {
    width: 950px!important
}

.fw-1000 {
    width: 1000px!important
}

.fw-1050 {
    width: 1050px!important
}

.fw-1100 {
    width: 1100px!important
}

.fw-1150 {
    width: 1150px!important
}

.fw-1200 {
    width: 1200px!important
}

.fw-1250 {
    width: 1250px!important
}

.fw-1300 {
    width: 1300px!important
}

.fw-1350 {
    width: 1350px!important
}

.fw-1400 {
    width: 1400px!important
}

.fw-1450 {
    width: 1450px!important
}

.fw-1500 {
    width: 1500px!important
}

.fw-1550 {
    width: 1550px!important
}

.fw-1600 {
    width: 1600px!important
}

.fw-1650 {
    width: 1650px!important
}

.fw-1700 {
    width: 1700px!important
}

.fw-1750 {
    width: 1750px!important
}

.fw-1800 {
    width: 1800px!important
}

.fw-1850 {
    width: 1850px!important
}

.fw-1900 {
    width: 1900px!important
}

.fw-1950 {
    width: 1950px!important
}

.fmh-50 {
    min-height: 50px!important
}

.fmh-100 {
    min-height: 100px!important
}

.fmh-150 {
    min-height: 150px!important
}

.fmh-200 {
    min-height: 200px!important
}

.fmh-250 {
    min-height: 250px!important
}

.fmh-300 {
    min-height: 300px!important
}

.fmh-350 {
    min-height: 350px!important
}

.fmh-400 {
    min-height: 400px!important
}

.fmh-450 {
    min-height: 450px!important
}

.fmh-500 {
    min-height: 500px!important
}

.fmh-550 {
    min-height: 550px!important
}

.fmh-600 {
    min-height: 600px!important
}

.fmh-650 {
    min-height: 650px!important
}

.fmh-700 {
    min-height: 700px!important
}

.fmh-750 {
    min-height: 750px!important
}

.fmh-800 {
    min-height: 800px!important
}

.fmh-850 {
    min-height: 850px!important
}

.fmh-900 {
    min-height: 900px!important
}

.fmh-950 {
    min-height: 950px!important
}

.fmh-1000 {
    min-height: 1000px!important
}

.fmh-1050 {
    min-height: 1050px!important
}

.fmh-1100 {
    min-height: 1100px!important
}

.fmh-1150 {
    min-height: 1150px!important
}

.fmh-1200 {
    min-height: 1200px!important
}

.fmh-1250 {
    min-height: 1250px!important
}

.fmh-1300 {
    min-height: 1300px!important
}

.fmh-1350 {
    min-height: 1350px!important
}

.fmh-1400 {
    min-height: 1400px!important
}

.fmh-1450 {
    min-height: 1450px!important
}

.fmh-1500 {
    min-height: 1500px!important
}

.fmh-1550 {
    min-height: 1550px!important
}

.fmh-1600 {
    min-height: 1600px!important
}

.fmh-1650 {
    min-height: 1650px!important
}

.fmh-1700 {
    min-height: 1700px!important
}

.fmh-1750 {
    min-height: 1750px!important
}

.fmh-1800 {
    min-height: 1800px!important
}

.fmh-1850 {
    min-height: 1850px!important
}

.fmh-1900 {
    min-height: 1900px!important
}

.fmh-1950 {
    min-height: 1950px!important
}

.fmw-50 {
    min-width: 50px!important
}

.fmw-100 {
    min-width: 100px!important
}

.fmw-150 {
    min-width: 150px!important
}

.fmw-200 {
    min-width: 200px!important
}

.fmw-250 {
    min-width: 250px!important
}

.fmw-300 {
    min-width: 300px!important
}

.fmw-350 {
    min-width: 350px!important
}

.fmw-400 {
    min-width: 400px!important
}

.fmw-450 {
    min-width: 450px!important
}

.fmw-500 {
    min-width: 500px!important
}

.fmw-550 {
    min-width: 550px!important
}

.fmw-600 {
    min-width: 600px!important
}

.fmw-650 {
    min-width: 650px!important
}

.fmw-700 {
    min-width: 700px!important
}

.fmw-750 {
    min-width: 750px!important
}

.fmw-800 {
    min-width: 800px!important
}

.fmw-850 {
    min-width: 850px!important
}

.fmw-900 {
    min-width: 900px!important
}

.fmw-950 {
    min-width: 950px!important
}

.fmw-1000 {
    min-width: 1000px!important
}

.fmw-1050 {
    min-width: 1050px!important
}

.fmw-1100 {
    min-width: 1100px!important
}

.fmw-1150 {
    min-width: 1150px!important
}

.fmw-1200 {
    min-width: 1200px!important
}

.fmw-1250 {
    min-width: 1250px!important
}

.fmw-1300 {
    min-width: 1300px!important
}

.fmw-1350 {
    min-width: 1350px!important
}

.fmw-1400 {
    min-width: 1400px!important
}

.fmw-1450 {
    min-width: 1450px!important
}

.fmw-1500 {
    min-width: 1500px!important
}

.fmw-1550 {
    min-width: 1550px!important
}

.fmw-1600 {
    min-width: 1600px!important
}

.fmw-1650 {
    min-width: 1650px!important
}

.fmw-1700 {
    min-width: 1700px!important
}

.fmw-1750 {
    min-width: 1750px!important
}

.fmw-1800 {
    min-width: 1800px!important
}

.fmw-1850 {
    min-width: 1850px!important
}

.fmw-1900 {
    min-width: 1900px!important
}

.fmw-1950 {
    min-width: 1950px!important
}

.fmxh-50 {
    max-height: 50px!important
}

.fmxh-100 {
    max-height: 100px!important
}

.fmxh-150 {
    max-height: 150px!important
}

.fmxh-200 {
    max-height: 200px!important
}

.fmxh-250 {
    max-height: 250px!important
}

.fmxh-300 {
    max-height: 300px!important
}

.fmxh-350 {
    max-height: 350px!important
}

.fmxh-400 {
    max-height: 400px!important
}

.fmxh-450 {
    max-height: 450px!important
}

.fmxh-500 {
    max-height: 500px!important
}

.fmxh-550 {
    max-height: 550px!important
}

.fmxh-600 {
    max-height: 600px!important
}

.fmxh-650 {
    max-height: 650px!important
}

.fmxh-700 {
    max-height: 700px!important
}

.fmxh-750 {
    max-height: 750px!important
}

.fmxh-800 {
    max-height: 800px!important
}

.fmxh-850 {
    max-height: 850px!important
}

.fmxh-900 {
    max-height: 900px!important
}

.fmxh-950 {
    max-height: 950px!important
}

.fmxh-1000 {
    max-height: 1000px!important
}

.fmxh-1050 {
    max-height: 1050px!important
}

.fmxh-1100 {
    max-height: 1100px!important
}

.fmxh-1150 {
    max-height: 1150px!important
}

.fmxh-1200 {
    max-height: 1200px!important
}

.fmxh-1250 {
    max-height: 1250px!important
}

.fmxh-1300 {
    max-height: 1300px!important
}

.fmxh-1350 {
    max-height: 1350px!important
}

.fmxh-1400 {
    max-height: 1400px!important
}

.fmxh-1450 {
    max-height: 1450px!important
}

.fmxh-1500 {
    max-height: 1500px!important
}

.fmxh-1550 {
    max-height: 1550px!important
}

.fmxh-1600 {
    max-height: 1600px!important
}

.fmxh-1650 {
    max-height: 1650px!important
}

.fmxh-1700 {
    max-height: 1700px!important
}

.fmxh-1750 {
    max-height: 1750px!important
}

.fmxh-1800 {
    max-height: 1800px!important
}

.fmxh-1850 {
    max-height: 1850px!important
}

.fmxh-1900 {
    max-height: 1900px!important
}

.fmxh-1950 {
    max-height: 1950px!important
}

.fmxw-50 {
    max-width: 50px!important
}

.fmxw-100 {
    max-width: 100px!important
}

.fmxw-150 {
    max-width: 150px!important
}

.fmxw-200 {
    max-width: 200px!important
}

.fmxw-250 {
    max-width: 250px!important
}

.fmxw-300 {
    max-width: 300px!important
}

.fmxw-350 {
    max-width: 350px!important
}

.fmxw-400 {
    max-width: 400px!important
}

.fmxw-450 {
    max-width: 450px!important
}

.fmxw-500 {
    max-width: 500px!important
}

.fmxw-550 {
    max-width: 550px!important
}

.fmxw-600 {
    max-width: 600px!important
}

.fmxw-650 {
    max-width: 650px!important
}

.fmxw-700 {
    max-width: 700px!important
}

.fmxw-750 {
    max-width: 750px!important
}

.fmxw-800 {
    max-width: 800px!important
}

.fmxw-850 {
    max-width: 850px!important
}

.fmxw-900 {
    max-width: 900px!important
}

.fmxw-950 {
    max-width: 950px!important
}

.fmxw-1000 {
    max-width: 1000px!important
}

.fmxw-1050 {
    max-width: 1050px!important
}

.fmxw-1100 {
    max-width: 1100px!important
}

.fmxw-1150 {
    max-width: 1150px!important
}

.fmxw-1200 {
    max-width: 1200px!important
}

.fmxw-1250 {
    max-width: 1250px!important
}

.fmxw-1300 {
    max-width: 1300px!important
}

.fmxw-1350 {
    max-width: 1350px!important
}

.fmxw-1400 {
    max-width: 1400px!important
}

.fmxw-1450 {
    max-width: 1450px!important
}

.fmxw-1500 {
    max-width: 1500px!important
}

.fmxw-1550 {
    max-width: 1550px!important
}

.fmxw-1600 {
    max-width: 1600px!important
}

.fmxw-1650 {
    max-width: 1650px!important
}

.fmxw-1700 {
    max-width: 1700px!important
}

.fmxw-1750 {
    max-width: 1750px!important
}

.fmxw-1800 {
    max-width: 1800px!important
}

.fmxw-1850 {
    max-width: 1850px!important
}

.fmxw-1900 {
    max-width: 1900px!important
}

.fmxw-1950 {
    max-width: 1950px!important
}

[class*=shadow] {
    transition: all .2s ease
}

.shadow-soft {
    box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0!important
}

.shadow-hover:hover {
    box-shadow: rgba(0,0,0,.1) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px!important
}

.shadow-sm-hover:hover {
    box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0!important
}

.shadow-lg-hover:hover {
    box-shadow: rgba(0,0,0,.1) 0 10px 15px -3px,rgba(0,0,0,.05) 0 4px 6px -2px!important
}

.shadow-none-hover:hover {
    box-shadow: none!important
}

.text-underline {
    text-decoration: underline!important
}

.text-through {
    text-decoration: line-through!important
}

.display-1-xs {
    font-size: 5rem
}

.display-2-xs {
    font-size: 3.5rem
}

.display-3-xs {
    font-size: 2.5rem
}

.display-4-xs {
    font-size: 1.875rem
}

@media (min-width: 576px) {
    .display-1-sm {
        font-size:5rem
    }

    .display-2-sm {
        font-size: 3.5rem
    }

    .display-3-sm {
        font-size: 2.5rem
    }

    .display-4-sm {
        font-size: 1.875rem
    }
}

@media (min-width: 768px) {
    .display-1-md {
        font-size:5rem
    }

    .display-2-md {
        font-size: 3.5rem
    }

    .display-3-md {
        font-size: 2.5rem
    }

    .display-4-md {
        font-size: 1.875rem
    }
}

@media (min-width: 992px) {
    .display-1-lg {
        font-size:5rem
    }

    .display-2-lg {
        font-size: 3.5rem
    }

    .display-3-lg {
        font-size: 2.5rem
    }

    .display-4-lg {
        font-size: 1.875rem
    }
}

@media (min-width: 1200px) {
    .display-1-xl {
        font-size:5rem
    }

    .display-2-xl {
        font-size: 3.5rem
    }

    .display-3-xl {
        font-size: 2.5rem
    }

    .display-4-xl {
        font-size: 1.875rem
    }
}

@media (min-width: 1400px) {
    .display-1-xxl {
        font-size:5rem
    }

    .display-2-xxl {
        font-size: 3.5rem
    }

    .display-3-xxl {
        font-size: 2.5rem
    }

    .display-4-xxl {
        font-size: 1.875rem
    }
}

.lh-100 {
    line-height: 1
}

.lh-110 {
    line-height: 1.1
}

.lh-120 {
    line-height: 1.2
}

.lh-130 {
    line-height: 1.3
}

.lh-140 {
    line-height: 1.4
}

.lh-150 {
    line-height: 1.5
}

.lh-160 {
    line-height: 1.6
}

.lh-170 {
    line-height: 1.7
}

.lh-180 {
    line-height: 1.8
}

.lh-190 {
    line-height: 1.9
}

.lh-200 {
    line-height: 2
}

.lh-210 {
    line-height: 2.1
}

.lh-220 {
    line-height: 2.2
}

.lh-230 {
    line-height: 2.3
}

.lh-240 {
    line-height: 2.4
}

.lh-250 {
    line-height: 2.5
}

.lh-260 {
    line-height: 2.6
}

.lh-270 {
    line-height: 2.7
}

.lh-280 {
    line-height: 2.8
}

.lh-290 {
    line-height: 2.9
}

.lh-300 {
    line-height: 3
}

.ls-1 {
    letter-spacing: .0625rem
}

.ls-2 {
    letter-spacing: .09375rem
}

.ls-3 {
    letter-spacing: .125rem
}

.text-left {
    text-align: left!important
}

.text-right {
    text-align: right!important
}

.text-center {
    text-align: center!important
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align:left!important
    }

    .text-sm-right {
        text-align: right!important
    }

    .text-sm-center {
        text-align: center!important
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align:left!important
    }

    .text-md-right {
        text-align: right!important
    }

    .text-md-center {
        text-align: center!important
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align:left!important
    }

    .text-lg-right {
        text-align: right!important
    }

    .text-lg-center {
        text-align: center!important
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align:left!important
    }

    .text-xl-right {
        text-align: right!important
    }

    .text-xl-center {
        text-align: center!important
    }
}

@media (min-width: 1400px) {
    .text-xxl-left {
        text-align:left!important
    }

    .text-xxl-right {
        text-align: right!important
    }

    .text-xxl-center {
        text-align: center!important
    }
}

.text-body {
    color: #1f2937!important
}

.text-black-50 {
    color: rgba(23,31,56,.5)!important
}

.text-white-50 {
    color: rgba(255,255,255,.5)!important
}

.list-style-none {
    margin: 0;
    padding: 0;
    list-style: none
}

@media (min-width: 992px) {
    .transform-perspective-right {
        transform:scale(1) perspective(1040px) rotateY(-11deg) rotateX(2deg) rotate(2deg)
    }

    .transform-perspective-left {
        transform: scale(1) perspective(900px) rotateY(27deg) rotateX(2deg) rotate(-2deg)
    }
}

.t-none {
    transform: none!important
}

.animate-down-1,.animate-left-1,.animate-right-1,.animate-up-1,.scale-down-1,.scale-up-1 {
    transition: all .2s ease
}

.scale-up-1:hover {
    transform: scale(.55556)
}

.scale-up-hover-1:hover .scale {
    transform: scale(.55556)
}

.scale-down-1:hover {
    transform: scale(.4)
}

.animate-hover:hover .animate-up-1,.animate-up-1:hover {
    transform: translate(0,-1px)
}

.animate-hover:hover .animate-right-1,.animate-right-1:hover {
    transform: translate(1px,0)
}

.animate-down-1:hover,.animate-hover:hover .animate-down-1 {
    transform: translate(0,1px)
}

.animate-hover:hover .animate-left-1,.animate-left-1:hover {
    transform: translate(-1px,0)
}

.animate-down-2,.animate-left-2,.animate-right-2,.animate-up-2,.scale-down-2,.scale-up-2 {
    transition: all .2s ease
}

.scale-up-2:hover {
    transform: scale(1.11111)
}

.scale-up-hover-2:hover .scale {
    transform: scale(1.11111)
}

.scale-down-2:hover {
    transform: scale(.8)
}

.animate-hover:hover .animate-up-2,.animate-up-2:hover {
    transform: translate(0,-2px)
}

.animate-hover:hover .animate-right-2,.animate-right-2:hover {
    transform: translate(2px,0)
}

.animate-down-2:hover,.animate-hover:hover .animate-down-2 {
    transform: translate(0,2px)
}

.animate-hover:hover .animate-left-2,.animate-left-2:hover {
    transform: translate(-2px,0)
}

.animate-down-3,.animate-left-3,.animate-right-3,.animate-up-3,.scale-down-3,.scale-up-3 {
    transition: all .2s ease
}

.scale-up-3:hover {
    transform: scale(1.66667)
}

.scale-up-hover-3:hover .scale {
    transform: scale(1.66667)
}

.scale-down-3:hover {
    transform: scale(1.2)
}

.animate-hover:hover .animate-up-3,.animate-up-3:hover {
    transform: translate(0,-3px)
}

.animate-hover:hover .animate-right-3,.animate-right-3:hover {
    transform: translate(3px,0)
}

.animate-down-3:hover,.animate-hover:hover .animate-down-3 {
    transform: translate(0,3px)
}

.animate-hover:hover .animate-left-3,.animate-left-3:hover {
    transform: translate(-3px,0)
}

.animate-down-4,.animate-left-4,.animate-right-4,.animate-up-4,.scale-down-4,.scale-up-4 {
    transition: all .2s ease
}

.scale-up-4:hover {
    transform: scale(2.22222)
}

.scale-up-hover-4:hover .scale {
    transform: scale(2.22222)
}

.scale-down-4:hover {
    transform: scale(1.6)
}

.animate-hover:hover .animate-up-4,.animate-up-4:hover {
    transform: translate(0,-4px)
}

.animate-hover:hover .animate-right-4,.animate-right-4:hover {
    transform: translate(4px,0)
}

.animate-down-4:hover,.animate-hover:hover .animate-down-4 {
    transform: translate(0,4px)
}

.animate-hover:hover .animate-left-4,.animate-left-4:hover {
    transform: translate(-4px,0)
}

.animate-down-5,.animate-left-5,.animate-right-5,.animate-up-5,.scale-down-5,.scale-up-5 {
    transition: all .2s ease
}

.scale-up-5:hover {
    transform: scale(2.77778)
}

.scale-up-hover-5:hover .scale {
    transform: scale(2.77778)
}

.scale-down-5:hover {
    transform: scale(2)
}

.animate-hover:hover .animate-up-5,.animate-up-5:hover {
    transform: translate(0,-5px)
}

.animate-hover:hover .animate-right-5,.animate-right-5:hover {
    transform: translate(5px,0)
}

.animate-down-5:hover,.animate-hover:hover .animate-down-5 {
    transform: translate(0,5px)
}

.animate-hover:hover .animate-left-5,.animate-left-5:hover {
    transform: translate(-5px,0)
}

.animate-down-6,.animate-left-6,.animate-right-6,.animate-up-6,.scale-down-6,.scale-up-6 {
    transition: all .2s ease
}

.scale-up-6:hover {
    transform: scale(3.33333)
}

.scale-up-hover-6:hover .scale {
    transform: scale(3.33333)
}

.scale-down-6:hover {
    transform: scale(2.4)
}

.animate-hover:hover .animate-up-6,.animate-up-6:hover {
    transform: translate(0,-6px)
}

.animate-hover:hover .animate-right-6,.animate-right-6:hover {
    transform: translate(6px,0)
}

.animate-down-6:hover,.animate-hover:hover .animate-down-6 {
    transform: translate(0,6px)
}

.animate-hover:hover .animate-left-6,.animate-left-6:hover {
    transform: translate(-6px,0)
}

.animate-down-7,.animate-left-7,.animate-right-7,.animate-up-7,.scale-down-7,.scale-up-7 {
    transition: all .2s ease
}

.scale-up-7:hover {
    transform: scale(3.88889)
}

.scale-up-hover-7:hover .scale {
    transform: scale(3.88889)
}

.scale-down-7:hover {
    transform: scale(2.8)
}

.animate-hover:hover .animate-up-7,.animate-up-7:hover {
    transform: translate(0,-7px)
}

.animate-hover:hover .animate-right-7,.animate-right-7:hover {
    transform: translate(7px,0)
}

.animate-down-7:hover,.animate-hover:hover .animate-down-7 {
    transform: translate(0,7px)
}

.animate-hover:hover .animate-left-7,.animate-left-7:hover {
    transform: translate(-7px,0)
}

.animate-down-8,.animate-left-8,.animate-right-8,.animate-up-8,.scale-down-8,.scale-up-8 {
    transition: all .2s ease
}

.scale-up-8:hover {
    transform: scale(4.44444)
}

.scale-up-hover-8:hover .scale {
    transform: scale(4.44444)
}

.scale-down-8:hover {
    transform: scale(3.2)
}

.animate-hover:hover .animate-up-8,.animate-up-8:hover {
    transform: translate(0,-8px)
}

.animate-hover:hover .animate-right-8,.animate-right-8:hover {
    transform: translate(8px,0)
}

.animate-down-8:hover,.animate-hover:hover .animate-down-8 {
    transform: translate(0,8px)
}

.animate-hover:hover .animate-left-8,.animate-left-8:hover {
    transform: translate(-8px,0)
}

.animate-down-9,.animate-left-9,.animate-right-9,.animate-up-9,.scale-down-9,.scale-up-9 {
    transition: all .2s ease
}

.scale-up-9:hover {
    transform: scale(5)
}

.scale-up-hover-9:hover .scale {
    transform: scale(5)
}

.scale-down-9:hover {
    transform: scale(3.6)
}

.animate-hover:hover .animate-up-9,.animate-up-9:hover {
    transform: translate(0,-9px)
}

.animate-hover:hover .animate-right-9,.animate-right-9:hover {
    transform: translate(9px,0)
}

.animate-down-9:hover,.animate-hover:hover .animate-down-9 {
    transform: translate(0,9px)
}

.animate-hover:hover .animate-left-9,.animate-left-9:hover {
    transform: translate(-9px,0)
}

@keyframes show-navbar-collapse {
    0% {
        opacity: 0;
        transform: scale(.95);
        transform-origin: 100% 0
    }

    100% {
        opacity: 1;
        transform: scale(1)
    }
}

@keyframes hide-navbar-collapse {
    from {
        opacity: 1;
        transform: scale(1);
        transform-origin: 100% 0
    }

    to {
        opacity: 0;
        transform: scale(.95)
    }
}

@keyframes show-navbar-dropdown {
    0% {
        opacity: 0;
        transform: translate(0,10px) perspective(200px) rotateX(-2deg);
        transition: visibility .45s,opacity .45s,transform .45s
    }

    100% {
        transform: translate(0,0);
        opacity: 1
    }
}

@keyframes hide-navbar-dropdown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        transform: translate(0,10px)
    }
}

@keyframes show-dropdown {
    0% {
        opacity: 0;
        transform-origin: perspective(200px) rotateX(-2deg);
        transition: visibility .45s,opacity .5s
    }

    100% {
        opacity: 1
    }
}

@keyframes hide-dropdown {
    from {
        opacity: 1
    }

    to {
        opacity: 0;
        transform: translate(0,10px)
    }
}

.headroom {
    will-change: transform;
    background-color: inherit;
    transition: all .2s ease
}

@media (prefers-reduced-motion:reduce) {
    .headroom {
        transition: none
    }
}

.headroom--pinned {
    transform: translateY(0)
}

.headroom--unpinned {
    transform: translateY(-100%)
}

.headroom--not-top {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.headroom--not-top.navbar-theme-primary {
    background-color: #ff6200
}

.headroom--not-top.navbar-theme-primary .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-primary .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-primary .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-primary .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-primary .nav-link:focus,.headroom--not-top.navbar-theme-primary .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-secondary {
    background-color: #1c2540
}

.headroom--not-top.navbar-theme-secondary .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-secondary .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-secondary .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-secondary .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-secondary .nav-link:focus,.headroom--not-top.navbar-theme-secondary .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-tertiary {
    background-color: #893168
}

.headroom--not-top.navbar-theme-tertiary .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-tertiary .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-tertiary .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-tertiary .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-tertiary .nav-link:focus,.headroom--not-top.navbar-theme-tertiary .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-primary-app {
    background-color: #ebf4f6
}

.headroom--not-top.navbar-theme-primary-app .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-primary-app .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-primary-app .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-primary-app .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-primary-app .nav-link:focus,.headroom--not-top.navbar-theme-primary-app .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-secondary-app {
    background-color: #424aa0
}

.headroom--not-top.navbar-theme-secondary-app .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-secondary-app .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-secondary-app .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-secondary-app .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-secondary-app .nav-link:focus,.headroom--not-top.navbar-theme-secondary-app .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-success {
    background-color: #3aaf85
}

.headroom--not-top.navbar-theme-success .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-success .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-success .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-success .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-success .nav-link:focus,.headroom--not-top.navbar-theme-success .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-info {
    background-color: #0d6efd
}

.headroom--not-top.navbar-theme-info .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-info .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-info .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-info .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-info .nav-link:focus,.headroom--not-top.navbar-theme-info .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-warning {
    background-color: #ffc107
}

.headroom--not-top.navbar-theme-warning .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-warning .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-warning .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-warning .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-warning .nav-link:focus,.headroom--not-top.navbar-theme-warning .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-danger {
    background-color: #dc3545
}

.headroom--not-top.navbar-theme-danger .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-danger .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-danger .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-danger .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-danger .nav-link:focus,.headroom--not-top.navbar-theme-danger .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-white {
    background-color: #fff
}

.headroom--not-top.navbar-theme-white .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-white .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-white .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-white .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-white .nav-link:focus,.headroom--not-top.navbar-theme-white .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray {
    background-color: #1f2937
}

.headroom--not-top.navbar-theme-gray .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray .nav-link:focus,.headroom--not-top.navbar-theme-gray .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-dark {
    background-color: #1c2540
}

.headroom--not-top.navbar-theme-dark .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-dark .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-dark .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-dark .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-dark .nav-link:focus,.headroom--not-top.navbar-theme-dark .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-black {
    background-color: #171f38
}

.headroom--not-top.navbar-theme-black .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-black .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-black .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-black .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-black .nav-link:focus,.headroom--not-top.navbar-theme-black .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-purple {
    background-color: #6f42c1
}

.headroom--not-top.navbar-theme-purple .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-purple .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-purple .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-purple .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-purple .nav-link:focus,.headroom--not-top.navbar-theme-purple .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-orange {
    background-color: #e84422
}

.headroom--not-top.navbar-theme-orange .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-orange .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-orange .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-orange .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-orange .nav-link:focus,.headroom--not-top.navbar-theme-orange .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray-100 {
    background-color: #f9fafb
}

.headroom--not-top.navbar-theme-gray-100 .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray-100 .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray-100 .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-100 .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-100 .nav-link:focus,.headroom--not-top.navbar-theme-gray-100 .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray-200 {
    background-color: #f3f4f6
}

.headroom--not-top.navbar-theme-gray-200 .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray-200 .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray-200 .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-200 .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-200 .nav-link:focus,.headroom--not-top.navbar-theme-gray-200 .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray-300 {
    background-color: #e5e7eb
}

.headroom--not-top.navbar-theme-gray-300 .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray-300 .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray-300 .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-300 .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-300 .nav-link:focus,.headroom--not-top.navbar-theme-gray-300 .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray-400 {
    background-color: #d1d5db
}

.headroom--not-top.navbar-theme-gray-400 .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray-400 .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray-400 .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-400 .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-400 .nav-link:focus,.headroom--not-top.navbar-theme-gray-400 .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray-500 {
    background-color: #9ca3af
}

.headroom--not-top.navbar-theme-gray-500 .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray-500 .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray-500 .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-500 .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-500 .nav-link:focus,.headroom--not-top.navbar-theme-gray-500 .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray-600 {
    background-color: #6b7280
}

.headroom--not-top.navbar-theme-gray-600 .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray-600 .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray-600 .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-600 .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-600 .nav-link:focus,.headroom--not-top.navbar-theme-gray-600 .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray-700 {
    background-color: #4b5563
}

.headroom--not-top.navbar-theme-gray-700 .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray-700 .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray-700 .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-700 .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-700 .nav-link:focus,.headroom--not-top.navbar-theme-gray-700 .nav-link:hover {
    color: #fff
}

.headroom--not-top.navbar-theme-gray-800 {
    background-color: #374151
}

.headroom--not-top.navbar-theme-gray-800 .navbar-brand-light {
    display: none
}

.headroom--not-top.navbar-theme-gray-800 .navbar-brand-dark {
    display: block
}

.headroom--not-top.navbar-theme-gray-800 .nav-link {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-800 .nav-link .nav-link-arrow {
    color: rgba(255,255,255,.9)
}

.headroom--not-top.navbar-theme-gray-800 .nav-link:focus,.headroom--not-top.navbar-theme-gray-800 .nav-link:hover {
    color: #fff
}

@media (max-width: 991.98px) {
    .headroom--not-top.headroom--pinned .nav-link {
        color:rgba(23,31,56,.9)
    }

    .headroom--not-top.headroom--pinned .nav-link .nav-link-arrow {
        color: rgba(23,31,56,.9)
    }

    .headroom--not-top.headroom--pinned .nav-link:focus,.headroom--not-top.headroom--pinned .nav-link:hover {
        color: #171f38
    }

    .headroom--not-top.headroom--pinned .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
    }
}

.navbar-main {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100
}

.navbar-main .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.navbar .navbar-nav .nav-link {
    transition: all .8s all .2s ease
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav .nav-link {
        font-size:1.2rem;
        display: flex;
        -moz-justify-content: space-between;
        -ms-justify-content: space-between;
        justify-content: space-between;
        -ms-flex-pack: space-between;
        -moz-align-items: center;
        -ms-align-items: center;
        align-items: center
    }
}

.navbar .navbar-nav .dropdown-item {
    display: flex;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center
}

.navbar .navbar-nav .nav-item .media:not(:last-child) {
    margin-bottom: 1.5rem
}

.navbar .navbar-nav .nav-item .nav-link:focus,.navbar .navbar-nav .nav-item:focus {
    outline: 0!important
}

.navbar .navbar-nav .dropdown .dropdown-menu {
    top: 100%;
    border: 0
}

.navbar .navbar-nav .dropdown .dropdown-item {
    font-size: .875rem
}

@media (max-width: 991.98px) {
    .navbar .navbar-nav .dropdown .dropdown-item {
        font-size:.9rem
    }
}

.navbar .navbar-nav .dropdown-megamenu,.navbar .navbar-nav .dropdown-megamenu-md,.navbar .navbar-nav .dropdown-megamenu-sm {
    border: 0
}

@media (min-width: 992px) {
    .navbar .navbar-nav .dropdown-megamenu,.navbar .navbar-nav .dropdown-megamenu-md,.navbar .navbar-nav .dropdown-megamenu-sm {
        min-width:580px
    }
}

.navbar .navbar-nav .dropdown-megamenu .dropdown-item,.navbar .navbar-nav .dropdown-megamenu-md .dropdown-item,.navbar .navbar-nav .dropdown-megamenu-sm .dropdown-item {
    border-radius: 1rem
}

.navbar .navbar-nav .dropdown-megamenu .megamenu-link,.navbar .navbar-nav .dropdown-megamenu-md .megamenu-link,.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-link {
    color: #1f2937;
    font-weight: 400;
    font-size: .875rem
}

.navbar .navbar-nav .dropdown-megamenu .megamenu-item,.navbar .navbar-nav .dropdown-megamenu-md .megamenu-item,.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-item {
    position: relative
}

.navbar .navbar-nav .dropdown-megamenu .megamenu-item:hover .megamenu-link,.navbar .navbar-nav .dropdown-megamenu-md .megamenu-item:hover .megamenu-link,.navbar .navbar-nav .dropdown-megamenu-sm .megamenu-item:hover .megamenu-link {
    color: #893168
}

@media (min-width: 992px) {
    .navbar .navbar-nav .dropdown-megamenu-sm {
        min-width:420px
    }
}

@media (min-width: 992px) {
    .navbar .navbar-nav .dropdown-megamenu-md {
        min-width:960px
    }
}

@media (min-width: 992px) {
    .navbar .navbar-nav .dropdown-toggle.show .nav-link-arrow {
        transform:rotate(180deg)
    }

    .navbar .navbar-nav .dropdown-toggle:hover .nav-link-arrow {
        transform: rotate(180deg)
    }
}

.navbar .navbar-nav .dropdown-submenu {
    position: relative
}

.navbar .navbar-nav .dropdown-submenu .dropdown-menu {
    top: 0;
    left: calc(100% - 2px);
    margin-left: .1rem;
    margin-right: .1rem;
    opacity: 0;
    display: none
}

.navbar .navbar-nav .dropdown-submenu .dropdown-menu.show {
    opacity: 1;
    pointer-events: all;
    display: block
}

.navbar .navbar-nav .nav-link-arrow {
    transition: transform .2s ease
}

.navbar-brand {
    font-size: .875rem;
    font-weight: 600;
    text-transform: uppercase
}

.navbar-brand img {
    height: 37px;
    max-width: none
}

.navbar-dark .navbar-brand-light {
    display: none
}

.navbar-dark .navbar-brand {
    color: #fff
}

.navbar-dark .nav-item .nav-link,.navbar-dark .nav-item .nav-link:focus,.navbar-dark .nav-item .nav-link:hover,.navbar-dark .nav-item .nav-link>span {
    color: #fff
}

@media (max-width: 991.98px) {
    .navbar-dark .nav-item .nav-link,.navbar-dark .nav-item .nav-link:focus,.navbar-dark .nav-item .nav-link:hover,.navbar-dark .nav-item .nav-link>span {
        color:#1c2540
    }
}

.navbar-light:not(.headroom--not-top) .btn-outline-soft {
    border-color: #1c2540;
    color: #1c2540
}

.navbar-light:not(.headroom--not-top) .btn-outline-soft:hover {
    background: #1c2540;
    color: #fff
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(23, 31, 56, 0.9)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
}

.navbar-light .navbar-brand-dark {
    display: none
}

.navbar-light .navbar-brand {
    color: #374151
}

.navbar-transparent {
    background-color: transparent;
    border: 0;
    box-shadow: none
}

@media (min-width: 992px) {
    .navbar-nav .nav-item [data-bs-toggle=dropdown]::after {
        transition:all .2s ease
    }

    .navbar-nav .nav-item.show [data-bs-toggle=dropdown]::after {
        transform: rotate(180deg)
    }

    .navbar-nav .nav-link span {
        margin-right: .3rem;
        font-size: .75rem
    }

    .navbar-nav .nav-link-icon {
        padding-left: .5rem;
        padding-right: .5rem;
        font-size: 1rem
    }

    .navbar-nav .nav-link-icon span {
        margin-right: 0
    }

    .navbar-nav .dropdown-menu {
        opacity: 0;
        pointer-events: none;
        margin: 0;
        border-radius: 1rem
    }

    .navbar-nav .dropdown-menu-right:before {
        right: 20px;
        left: auto
    }

    .navbar-nav .dropdown-megamenu,.navbar-nav .dropdown-megamenu-md,.navbar-nav .dropdown-menu {
        left: 50%;
        transform: translateX(-50%)
    }

    .navbar-nav .dropdown-megamenu-md.show,.navbar-nav .dropdown-megamenu.show,.navbar-nav .dropdown-menu.show {
        opacity: 1;
        pointer-events: auto
    }

    .navbar-nav .dropdown-megamenu-md.close,.navbar-nav .dropdown-megamenu.close,.navbar-nav .dropdown-menu.close {
        display: block
    }

    .navbar-nav .dropdown-submenu .dropdown-menu {
        transform: none
    }

    .navbar-nav .dropdown-menu {
        position: relative;
        display: block;
        opacity: 0;
        pointer-events: none;
        transition: all .2s ease
    }

    .navbar-nav .dropdown-submenu.show>.dropdown-menu,.navbar-nav .dropdown.show>.dropdown-menu {
        display: block;
        opacity: 1;
        pointer-events: auto
    }

    .navbar-nav .dropdown.show>.dropdown-menu {
        left: 50%;
        transform: translate(-50%,0)
    }

    .navbar-nav .dropdown-menu-inner {
        position: relative;
        padding: 1rem
    }

    .navbar-transparent .navbar-nav .nav-link.disabled {
        color: rgba(255,255,255,.9)
    }

    .navbar-transparent .navbar-brand {
        color: rgba(255,255,255,.9)
    }
}

.navbar-collapse-header {
    display: none
}

.navbar-collapse {
    width: auto
}

@media (max-width: 991.98px) {
    .navbar-nav .nav-link {
        padding:.625rem 0
    }

    .navbar-nav .dropdown-menu {
        box-shadow: none;
        min-width: auto
    }

    .navbar-nav .dropdown-menu .media svg {
        width: 30px
    }

    .navbar-collapse {
        width: calc(100% - 30px);
        position: absolute;
        top: 0;
        left: 15px;
        z-index: 1050;
        overflow-y: auto;
        height: calc(100vh - 30px)!important;
        opacity: 0
    }

    .navbar-collapse .navbar-toggler {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: 0
    }

    .navbar-collapse .navbar-toggler span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        border-radius: 2px;
        opacity: 1;
        background: #283448
    }

    .navbar-collapse .navbar-collapse-header {
        display: block;
        padding-bottom: 1rem;
        margin-bottom: 1rem
    }

    .navbar-collapse .collapse-brand img {
        height: 37px
    }

    .navbar-collapse .collapse-close {
        text-align: right
    }

    .navbar-collapse.collapsing,.navbar-collapse.show {
        padding: 1.5rem;
        border-radius: 1rem;
        background: #fff;
        animation: show-navbar-collapse .2s ease forwards;
        box-shadow: 0 0 20px rgba(0,0,0,.1)
    }

    .navbar-collapse.collapsing-out {
        animation: hide-navbar-collapse .2s ease forwards
    }
}

.section {
    position: relative;
    padding-top: 3rem;
    padding-bottom: 3rem
}

.section-header {
    position: relative;
    padding-bottom: 3rem
}

@media (min-width: 576px) {
    .section {
        position:relative;
        padding-top: 6rem;
        padding-bottom: 6rem
    }

    .section-header {
        position: relative;
        padding-bottom: 10rem
    }

    .section-header.section-sm {
        padding-top: 4rem;
        padding-bottom: 3rem
    }

    .section-xl {
        padding-top: 10rem;
        padding-bottom: 10rem
    }

    .section-lg {
        padding-top: 8rem;
        padding-bottom: 8rem
    }

    .section-sm {
        padding-top: 4rem;
        padding-bottom: 4rem
    }
}

@media (min-width: 768px) {
    .section-hero {
        height:100vh
    }
}

.card-box {
    -o-perspective: 900px;
    perspective: 900px;
    width: 250px;
    position: relative
}

.card-box .card-component {
    transform-style: preserve-3d;
    position: relative;
    height: 300px
}

@media (min-width: 992px) {
    .card-box .card-component {
        height:450px
    }
}

.card-box .card-component.card-component-lg {
    height: auto;
    max-height: 800px
}

@media (min-width: 768px) {
    .card-box .card-component.card-component-lg {
        max-height:410px
    }
}

.card-box .card-component.card-component-xs {
    height: auto;
    max-height: 230px
}

.card-box .card-component .front {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    box-shadow: rgba(0,0,0,.1) 0 10px 15px -3px,rgba(0,0,0,.05) 0 4px 6px -2px;
    border-radius: 1rem;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: rotateY(24deg)
}

.card-box .card-component .front:hover {
    cursor: pointer
}

.card-box .page-card {
    box-shadow: rgba(0,0,0,.1) 0 10px 15px -3px,rgba(0,0,0,.05) 0 4px 6px -2px;
    border-radius: 1rem
}

.card-box .page-card:hover {
    cursor: pointer
}

@media (max-width: 767.98px) {
    .card-box {
        width:200px
    }
}

@media (min-width: 992px) {
    .card-box {
        width:260px
    }
}

.footer {
    position: relative
}

.footer .h3,.footer .h5,.footer h3,.footer h5 {
    font-size: 1.15rem;
    margin-bottom: 1.25rem
}

.footer ul {
    margin-bottom: 0;
    padding: 0;
    list-style: none
}

.footer ul li {
    display: inline-block
}

.footer ul li a {
    text-decoration: none;
    position: relative;
    display: block
}

.footer ul li .btn {
    margin: 0
}

.footer ul.links-horizontal:first-child a {
    padding-left: 0
}

.footer ul.links-horizontal:last-child a {
    padding-right: 0
}

.footer ul.footer-links li {
    display: block;
    margin-left: -5px;
    margin-right: -5px
}

.footer ul.footer-links li a {
    padding: 5px
}

.footer ul.footer-links li a:hover {
    color: inherit!important
}

.footer ul.icon-box span {
    line-height: 1.7
}

.footer .social-buttons .btn,.footer .social-buttons a {
    margin-top: 5px;
    margin-bottom: 5px
}

.footer .footer-brand {
    font-size: 1.25rem;
    font-weight: 600
}

.footer .footer-brand img,.footer .footer-brand svg {
    width: 30px
}

.footer .footer-brand:focus,.footer .footer-brand:hover {
    color: #171f38
}

.footer .footer-brand.brand-sm img,.footer .footer-brand.brand-sm svg {
    width: 25px
}

.footer .copyright {
    font-size: .875rem
}

.footer .pull-center {
    display: inline-block;
    float: none
}

.footer-logo-text {
    font-size: 1.2rem;
    font-weight: 600!important
}

iframe {
    border: 0
}

figcaption,figure,main {
    display: block;
    margin: 0
}

main {
    overflow: hidden
}

img {
    max-width: 100%
}

strong {
    font-weight: 600
}

button:focus {
    outline: 0
}

label {
    font-weight: 600;
    margin-bottom: .5rem
}

.accordion-button {
    text-align: left
}

.accordion-button:not(.collapsed)::after {
    background-image: none;
    transform: rotateZ(45deg)
}

.accordion-button::after {
    background-image: none;
    content: '\f067';
    font-family: "Font Awesome 5 Free";
    font-size: 1rem;
    font-weight: 900
}

.accordion-button:hover {
    background-color: #f3f4f6
}

.accordion-flush .accordion-button {
    background-color: transparent
}

.accordion-flush .accordion-button:not(.collapsed) {
    color: #893168
}

.accordion-flush .accordion-button:focus {
    border-color: #e5e7eb;
    box-shadow: none
}

.accordion-flush .accordion-collapse {
    border: 0
}

.alert-heading {
    font-weight: 600
}

.alert-icon {
    margin-bottom: .5rem
}

.alert-icon span {
    font-size: 1.75rem
}

.avatar+.avatar-content {
    display: inline-block;
    margin-left: .75rem
}

.avatar-link img {
    width: 4rem;
    height: 4rem
}

.avatar-sm {
    width: 1.5rem;
    height: 1.5rem
}

.avatar-md {
    width: 2rem;
    height: 2rem
}

.avatar-lg {
    width: 3rem;
    height: 3rem
}

.avatar-group .avatar {
    position: relative;
    z-index: 2;
    border: 2px solid #fff
}

.avatar-group .avatar:hover {
    z-index: 3
}

.avatar-group .avatar+.avatar {
    margin-left: -1rem
}

.wi-tags a {
    display: inline-block;
    padding: .125rem .875rem;
    margin: .25rem;
    line-height: 2;
    font-size: .875rem;
    background-color: #fff;
    border: .0625rem solid #e5e7eb;
    border-radius: 1rem
}

.wi-tags a:hover {
    background-color: #ff6200;
    color: #fff
}

.pixel-pro-badge {
    position: relative;
    font-size: .875rem;
    text-transform: uppercase;
    font-weight: 600;
    right: -11px;
    padding: 4px 12px;
    top: -30px;
    background: #fff;
    border-radius: .6rem;
    box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0
}

@media (max-width: 575.98px) {
    .pixel-pro-badge {
        font-size:.7rem;
        right: -13px;
        padding: 3px 7px;
        top: -23px
    }
}

.btn {
    position: relative;
    transition: all .2s ease;
    font-size: 1rem;
    border-radius: 1rem
}

.btn-group .btn,.input-group .btn {
    margin-right: 0;
    transform: translateY(0)
}

.btn .toggle-arrow {
    transition: all .2s ease
}

.btn[aria-expanded=true] .toggle-arrow {
    transform: rotate(180deg)
}

.btn.btn-pill {
    border-radius: .875rem
}

.btn-xs {
    padding: .275rem .5rem
}

.btn-xs,.btn-xs span {
    font-size: .775rem!important
}

.btn-group-sm>.btn,.btn-group-sm>.btn span,.btn-sm,.btn-sm span {
    font-size: .875rem!important
}

.btn-md,.btn-md span {
    font-size: 1rem!important
}

.btn-icon-only {
    width: 2.575rem;
    height: 2.575rem
}

.btn-icon-onlya {
    line-height: 2.5
}

.btn-icon-only.btn-xs {
    width: 1.7rem;
    height: 1.7rem
}

.btn-group-sm>.btn-icon-only.btn,.btn-icon-only.btn-sm {
    width: 2rem;
    height: 2rem
}

.spinner-border,.spinner-brow {
    vertical-align: middle
}

.btn-loading-overlay .spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 0
}

.btn-loading-overlay .btn-inner-text,.btn-loading-overlay .spinner {
    transition: all .2s ease
}

.btn-loading-overlay .btn-inner-text {
    opacity: 1
}

.btn-loading-overlay.btn-loading .spinner {
    opacity: 1
}

.btn-loading-overlay.btn-loading .btn-inner-text {
    opacity: 0
}

.btn-facebook {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-facebook:hover {
    color: #fff;
    background-color: #324c82;
    border-color: #2f477a
}

.btn-check:focus+.btn-facebook,.btn-facebook:focus {
    color: #fff;
    background-color: #324c82;
    border-color: #2f477a;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(88,114,168,.5)
}

.btn-check:active+.btn-facebook,.btn-check:checked+.btn-facebook,.btn-facebook.active,.btn-facebook:active,.show>.btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2f477a;
    border-color: #2c4373
}

.btn-check:active+.btn-facebook:focus,.btn-check:checked+.btn-facebook:focus,.btn-facebook.active:focus,.btn-facebook:active:focus,.show>.btn-facebook.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(88,114,168,.5)
}

.btn-facebook.disabled,.btn-facebook:disabled {
    color: #fff;
    background-color: #3b5999;
    border-color: #3b5999
}

.btn-twitter {
    color: #1c2540;
    background-color: #1da1f2;
    border-color: #1da1f2;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-twitter:hover {
    color: #1c2540;
    background-color: #3faff4;
    border-color: #34aaf3
}

.btn-check:focus+.btn-twitter,.btn-twitter:focus {
    color: #1c2540;
    background-color: #3faff4;
    border-color: #34aaf3;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(29,142,215,.5)
}

.btn-check:active+.btn-twitter,.btn-check:checked+.btn-twitter,.btn-twitter.active,.btn-twitter:active,.show>.btn-twitter.dropdown-toggle {
    color: #1c2540;
    background-color: #4ab4f5;
    border-color: #34aaf3
}

.btn-check:active+.btn-twitter:focus,.btn-check:checked+.btn-twitter:focus,.btn-twitter.active:focus,.btn-twitter:active:focus,.show>.btn-twitter.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(29,142,215,.5)
}

.btn-twitter.disabled,.btn-twitter:disabled {
    color: #1c2540;
    background-color: #1da1f2;
    border-color: #1da1f2
}

.btn-google {
    color: #fff;
    background-color: #db4337;
    border-color: #db4337;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-google:hover {
    color: #fff;
    background-color: #ba392f;
    border-color: #af362c
}

.btn-check:focus+.btn-google,.btn-google:focus {
    color: #fff;
    background-color: #ba392f;
    border-color: #af362c;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(224,95,85,.5)
}

.btn-check:active+.btn-google,.btn-check:checked+.btn-google,.btn-google.active,.btn-google:active,.show>.btn-google.dropdown-toggle {
    color: #fff;
    background-color: #af362c;
    border-color: #a43229
}

.btn-check:active+.btn-google:focus,.btn-check:checked+.btn-google:focus,.btn-google.active:focus,.btn-google:active:focus,.show>.btn-google.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(224,95,85,.5)
}

.btn-google.disabled,.btn-google:disabled {
    color: #fff;
    background-color: #db4337;
    border-color: #db4337
}

.btn-instagram {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-instagram:hover {
    color: #fff;
    background-color: #c23651;
    border-color: #b6334c
}

.btn-check:focus+.btn-instagram,.btn-instagram:focus {
    color: #fff;
    background-color: #c23651;
    border-color: #b6334c;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(232,93,119,.5)
}

.btn-check:active+.btn-instagram,.btn-check:checked+.btn-instagram,.btn-instagram.active,.btn-instagram:active,.show>.btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #b6334c;
    border-color: #ab3047
}

.btn-check:active+.btn-instagram:focus,.btn-check:checked+.btn-instagram:focus,.btn-instagram.active:focus,.btn-instagram:active:focus,.show>.btn-instagram.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(232,93,119,.5)
}

.btn-instagram.disabled,.btn-instagram:disabled {
    color: #fff;
    background-color: #e4405f;
    border-color: #e4405f
}

.btn-slack {
    color: #1c2540;
    background-color: #3aaf85;
    border-color: #3aaf85;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-slack:hover {
    color: #1c2540;
    background-color: #58bb97;
    border-color: #4eb791
}

.btn-check:focus+.btn-slack,.btn-slack:focus {
    color: #1c2540;
    background-color: #58bb97;
    border-color: #4eb791;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(54,154,123,.5)
}

.btn-check:active+.btn-slack,.btn-check:checked+.btn-slack,.btn-slack.active,.btn-slack:active,.show>.btn-slack.dropdown-toggle {
    color: #1c2540;
    background-color: #61bf9d;
    border-color: #4eb791
}

.btn-check:active+.btn-slack:focus,.btn-check:checked+.btn-slack:focus,.btn-slack.active:focus,.btn-slack:active:focus,.show>.btn-slack.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(54,154,123,.5)
}

.btn-slack.disabled,.btn-slack:disabled {
    color: #1c2540;
    background-color: #3aaf85;
    border-color: #3aaf85
}

.btn-dribbble {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-dribbble:hover {
    color: #fff;
    background-color: #c74174;
    border-color: #bb3d6e
}

.btn-check:focus+.btn-dribbble,.btn-dribbble:focus {
    color: #fff;
    background-color: #c74174;
    border-color: #bb3d6e;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(237,103,155,.5)
}

.btn-check:active+.btn-dribbble,.btn-check:checked+.btn-dribbble,.btn-dribbble.active,.btn-dribbble:active,.show>.btn-dribbble.dropdown-toggle {
    color: #fff;
    background-color: #bb3d6e;
    border-color: #b03967
}

.btn-check:active+.btn-dribbble:focus,.btn-check:checked+.btn-dribbble:focus,.btn-dribbble.active:focus,.btn-dribbble:active:focus,.show>.btn-dribbble.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(237,103,155,.5)
}

.btn-dribbble.disabled,.btn-dribbble:disabled {
    color: #fff;
    background-color: #ea4c89;
    border-color: #ea4c89
}

.btn-dropbox {
    color: #fff;
    background-color: #1e90ff;
    border-color: #1e90ff;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-dropbox:hover {
    color: #fff;
    background-color: #1a7ad9;
    border-color: #1873cc
}

.btn-check:focus+.btn-dropbox,.btn-dropbox:focus {
    color: #fff;
    background-color: #1a7ad9;
    border-color: #1873cc;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(64,161,255,.5)
}

.btn-check:active+.btn-dropbox,.btn-check:checked+.btn-dropbox,.btn-dropbox.active,.btn-dropbox:active,.show>.btn-dropbox.dropdown-toggle {
    color: #fff;
    background-color: #1873cc;
    border-color: #176cbf
}

.btn-check:active+.btn-dropbox:focus,.btn-check:checked+.btn-dropbox:focus,.btn-dropbox.active:focus,.btn-dropbox:active:focus,.show>.btn-dropbox.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(64,161,255,.5)
}

.btn-dropbox.disabled,.btn-dropbox:disabled {
    color: #fff;
    background-color: #1e90ff;
    border-color: #1e90ff
}

.btn-github {
    color: #fff;
    background-color: #222;
    border-color: #222;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075)
}

.btn-github:hover {
    color: #fff;
    background-color: #1d1d1d;
    border-color: #1b1b1b
}

.btn-check:focus+.btn-github,.btn-github:focus {
    color: #fff;
    background-color: #1d1d1d;
    border-color: #1b1b1b;
    box-shadow: inset 0 1px 0 rgba(255,255,255,.15),0 1px 1px rgba(23,31,56,.075),0 0 0 .2rem rgba(67,67,67,.5)
}

.btn-check:active+.btn-github,.btn-check:checked+.btn-github,.btn-github.active,.btn-github:active,.show>.btn-github.dropdown-toggle {
    color: #fff;
    background-color: #1b1b1b;
    border-color: #1a1a1a
}

.btn-check:active+.btn-github:focus,.btn-check:checked+.btn-github:focus,.btn-github.active:focus,.btn-github:active:focus,.show>.btn-github.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(23,31,56,.125),0 0 0 .2rem rgba(67,67,67,.5)
}

.btn-github.disabled,.btn-github:disabled {
    color: #fff;
    background-color: #222;
    border-color: #222
}

.breadcrumb-transparent {
    background: 0 0;
    padding: 0
}

.breadcrumb-primary {
    background: #ff6200
}

.breadcrumb-primary .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-primary.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-primary.breadcrumb-transparent .breadcrumb-item.active {
    color: #ff6200
}

.breadcrumb-secondary {
    background: #1c2540
}

.breadcrumb-secondary .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-secondary.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-secondary.breadcrumb-transparent .breadcrumb-item.active {
    color: #1c2540
}

.breadcrumb-tertiary {
    background: #893168
}

.breadcrumb-tertiary .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-tertiary.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-tertiary.breadcrumb-transparent .breadcrumb-item.active {
    color: #893168
}

.breadcrumb-primary-app {
    background: #ebf4f6
}

.breadcrumb-primary-app .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-primary-app.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-primary-app.breadcrumb-transparent .breadcrumb-item.active {
    color: #ebf4f6
}

.breadcrumb-secondary-app {
    background: #424aa0
}

.breadcrumb-secondary-app .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-secondary-app.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-secondary-app.breadcrumb-transparent .breadcrumb-item.active {
    color: #424aa0
}

.breadcrumb-success {
    background: #3aaf85
}

.breadcrumb-success .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-success.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-success.breadcrumb-transparent .breadcrumb-item.active {
    color: #3aaf85
}

.breadcrumb-info {
    background: #0d6efd
}

.breadcrumb-info .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-info.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-info.breadcrumb-transparent .breadcrumb-item.active {
    color: #0d6efd
}

.breadcrumb-warning {
    background: #ffc107
}

.breadcrumb-warning .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-warning.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-warning.breadcrumb-transparent .breadcrumb-item.active {
    color: #ffc107
}

.breadcrumb-danger {
    background: #dc3545
}

.breadcrumb-danger .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-danger.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-danger.breadcrumb-transparent .breadcrumb-item.active {
    color: #dc3545
}

.breadcrumb-white {
    background: #fff
}

.breadcrumb-white .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-white.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-white.breadcrumb-transparent .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray {
    background: #1f2937
}

.breadcrumb-gray .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray.breadcrumb-transparent .breadcrumb-item.active {
    color: #1f2937
}

.breadcrumb-dark {
    background: #1c2540
}

.breadcrumb-dark .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-dark.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-dark.breadcrumb-transparent .breadcrumb-item.active {
    color: #1c2540
}

.breadcrumb-black {
    background: #171f38
}

.breadcrumb-black .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-black.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-black.breadcrumb-transparent .breadcrumb-item.active {
    color: #171f38
}

.breadcrumb-purple {
    background: #6f42c1
}

.breadcrumb-purple .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-purple.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-purple.breadcrumb-transparent .breadcrumb-item.active {
    color: #6f42c1
}

.breadcrumb-orange {
    background: #e84422
}

.breadcrumb-orange .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-orange.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-orange.breadcrumb-transparent .breadcrumb-item.active {
    color: #e84422
}

.breadcrumb-gray-100 {
    background: #f9fafb
}

.breadcrumb-gray-100 .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray-100.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray-100.breadcrumb-transparent .breadcrumb-item.active {
    color: #f9fafb
}

.breadcrumb-gray-200 {
    background: #f3f4f6
}

.breadcrumb-gray-200 .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray-200.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray-200.breadcrumb-transparent .breadcrumb-item.active {
    color: #f3f4f6
}

.breadcrumb-gray-300 {
    background: #e5e7eb
}

.breadcrumb-gray-300 .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray-300.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray-300.breadcrumb-transparent .breadcrumb-item.active {
    color: #e5e7eb
}

.breadcrumb-gray-400 {
    background: #d1d5db
}

.breadcrumb-gray-400 .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray-400.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray-400.breadcrumb-transparent .breadcrumb-item.active {
    color: #d1d5db
}

.breadcrumb-gray-500 {
    background: #9ca3af
}

.breadcrumb-gray-500 .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray-500.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray-500.breadcrumb-transparent .breadcrumb-item.active {
    color: #9ca3af
}

.breadcrumb-gray-600 {
    background: #6b7280
}

.breadcrumb-gray-600 .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray-600.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray-600.breadcrumb-transparent .breadcrumb-item.active {
    color: #6b7280
}

.breadcrumb-gray-700 {
    background: #4b5563
}

.breadcrumb-gray-700 .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray-700.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray-700.breadcrumb-transparent .breadcrumb-item.active {
    color: #4b5563
}

.breadcrumb-gray-800 {
    background: #374151
}

.breadcrumb-gray-800 .breadcrumb-item.active {
    color: #fff
}

.breadcrumb-gray-800.breadcrumb-transparent {
    background: 0 0
}

.breadcrumb-gray-800.breadcrumb-transparent .breadcrumb-item.active {
    color: #374151
}

.breadcrumb-text-light .breadcrumb-item,.breadcrumb-text-light .breadcrumb-item a {
    color: #fff
}

.breadcrumb-text-light .breadcrumb-item:before {
    color: #fff
}

.card-img,.card-img-top {
    border-radius: 0
}

.card-img,.card-img-bottom {
    border-radius: 0
}

.card .card-blockquote {
    position: relative;
    padding: 2rem
}

.card .card-blockquote .svg-bg {
    display: block;
    position: absolute;
    width: 100%;
    height: 95px;
    top: -94px;
    left: 0
}

.card .profile-thumbnail {
    width: 10rem;
    height: 10rem
}

.card .profile-thumbnail img {
    border: .125rem solid
}

.card .profile-thumbnail.small-thumbnail {
    width: 8rem;
    height: 8rem
}

@media (max-width: 991.98px) {
    .card .dashboard-avatar {
        width:65px;
        height: auto
    }
}

.card .profile-cover {
    height: 175px;
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover
}

.card .price-list .list-group-item span {
    width: 23px;
    font-size: .875rem
}

.card.rounded-right-0 {
    border-top-right-radius: 0!important;
    border-bottom-right-radius: 0!important
}

.card.rounded-left-0 {
    border-top-left-radius: 0!important;
    border-bottom-left-radius: 0!important
}

.card-lift-hover:hover {
    transform: translateY(-20px);
    transition: all .2s ease
}

@media (prefers-reduced-motion:reduce) {
    .card-lift-hover:hover {
        transition: none
    }
}

.rotating-card .card-rotate {
    background: 0 0;
    box-shadow: none
}

.rotating-card .card-rotate:after {
    display: none
}

.rotating-card .card {
    transition: all .8s cubic-bezier(.34,1.45,.7,1);
    transform-style: preserve-3d;
    position: relative
}

.rotating-card .card .back,.rotating-card .card .front {
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column;
    -ms-align-content: center;
    align-content: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    position: absolute;
    border-radius: 1rem;
    top: 0;
    left: 0
}

.rotating-card .card .back .card-body,.rotating-card .card .front .card-body {
    justify-content: center;
    align-content: center;
    display: flex;
    -moz-flex-direction: column;
    flex-direction: column
}

.rotating-card .card .back .card-body .card-subtitle,.rotating-card .card .front .card-body .card-subtitle {
    color: #ff6200
}

.rotating-card .card .back .card-body .social-buttons,.rotating-card .card .front .card-body .social-buttons {
    margin-bottom: 0;
    padding: 0;
    list-style: none
}

.rotating-card .card .back .card-body .social-buttons li,.rotating-card .card .front .card-body .social-buttons li {
    display: inline-block
}

.rotating-card .card .back .card-body .social-buttons li a.btn,.rotating-card .card .front .card-body .social-buttons li a.btn {
    padding: .25rem .375rem
}

.rotating-card .card .front {
    z-index: 2;
    position: relative
}

.rotating-card .card .front .profile-image {
    width: 9rem;
    height: 9rem;
    margin: 0 auto
}

.rotating-card .card .front .profile-image img {
    border-width: .125rem;
    border-style: solid;
    padding: .25rem
}

.rotating-card .card .back {
    transform: rotateY(180deg);
    z-index: 5;
    text-align: center;
    width: 100%;
    height: 100%
}

.rotating-card .card .back.back-background:after {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: block;
    left: 0;
    top: 0;
    content: "";
    background-color: rgba(0,0,0,.56);
    border-radius: 1rem
}

.rotating-card .card .back.back-background .card-body {
    position: relative;
    z-index: 2
}

.rotating-card .card .back .card-footer .btn {
    margin: 0
}

.rotating-card .card .back .card-body {
    padding-left: 15px;
    padding-right: 15px
}

.rotating-card:not(.manual-flip):hover .card {
    transform: rotateY(180deg)
}

.rotating-card.hover.manual-flip .card {
    transform: rotateY(180deg)
}

.card-profile .rotating-card .front {
    text-align: left
}

.back-background .card-body {
    min-height: auto;
    padding-top: 15px;
    padding-bottom: 15px
}

.back-background,.front-background {
    background-position: center center;
    background-size: cover
}

@media screen and (-ms-high-contrast:active),(-ms-high-contrast:none) {
    .rotating-card-container .card .back,.rotating-card-container .card .front {
        -webkit-backface-visibility: visible;
        backface-visibility: visible
    }

    .rotating-card-container .card .back {
        visibility: hidden;
        transition: visibility .3s cubic-bezier(.34,1.45,.7,1)
    }

    .rotating-card-container .card .front {
        z-index: 4
    }

    .rotating-card-container.manual-flip.hover .card .back,.rotating-card-container:not(.manual-flip):hover .card .back {
        z-index: 5;
        visibility: visible
    }
}

.page-preview {
    display: block;
    position: relative
}

.page-preview .show-on-hover {
    position: absolute;
    bottom: -25px;
    background: rgba(28,37,64,.85);
    padding: 10px 0;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    width: calc(100% + 28px);
    left: -14px;
    opacity: 0;
    transition: .2s
}

@media (prefers-reduced-motion:reduce) {
    .page-preview .show-on-hover {
        transition: none
    }
}

.page-preview:hover .show-on-hover {
    z-index: 99;
    opacity: 1
}

.carousel-caption,.carousel-caption .h5,.carousel-caption h5 {
    color: #fff
}

.carousel-indicators button {
    border-radius: 50%;
    background: 0 0;
    border: 2px solid #fff
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-control-next-icon,.carousel-control-prev-icon {
    width: auto;
    height: auto;
    font-weight: 900
}

.carousel-control-prev-icon {
    background-image: none
}

.carousel-control-prev-icon::before {
    content: '\f060';
    font-family: "Font Awesome 5 Free";
    font-size: 2rem
}

.carousel-control-next-icon {
    background-image: none
}

.carousel-control-next-icon:before {
    font-family: "Font Awesome 5 Free";
    content: "\f061";
    font-size: 2rem
}

.sr-only {
    width: 90px;
    height: 37px;
    padding: 4px;
    background-color: #3aaf85
}

.ct-bar {
    stroke-linecap: round
}

.ct-label {
    fill: #1f2937;
    color: #1f2937;
    font-size: .75rem;
    margin-top: 7px;
    font-weight: 600
}

.ct-slice-pie {
    stroke: #fff;
    stroke-width: 3px
}

.ct-chart-donut .ct-series {
    stroke: #fff;
    stroke-width: 3px
}

.ct-chart-donut .ct-label,.ct-chart-pie .ct-label {
    font-size: .75rem;
    font-weight: 600;
    color: #fff;
    fill: #fff
}

.close {
    transition: all .2s ease
}

.close>span:not(.sr-only) {
    display: block;
    height: 1.25rem;
    width: 1.25rem;
    background-color: transparent;
    color: rgba(0,0,0,.6);
    line-height: 22px;
    border-radius: 50%;
    font-size: 1.25rem;
    transition: all .2s ease
}

.close:focus,.close:hover {
    background-color: transparent;
    color: rgba(0,0,0,.9);
    outline: 0
}

.close:focus span:not(.sr-only),.close:hover span:not(.sr-only) {
    background-color: transparent
}

.datepicker .datepicker-cell.selected,.datepicker .datepicker-cell.selected:hover {
    background: #ff6200
}

.datepicker table {
    margin: 0;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none
}

.datepicker table td {
    border-radius: 0!important
}

.datepicker table th {
    border-radius: 0;
    font-weight: 500
}

.datepicker table td,.datepicker table th {
    width: 36px!important;
    height: 36px!important;
    border: none;
    text-align: center
}

.counter-alternate::after {
    content: '\f067';
    font-family: "Font Awesome 5 Free";
    position: absolute;
    font-size: 2.25rem;
    color: #1c2540;
    font-weight: 900
}

.ct-bar {
    stroke-linecap: round
}

.ct-label {
    fill: #1f2937;
    color: #1f2937;
    font-size: .75rem;
    margin-top: 7px;
    font-weight: 600
}

.ct-slice-pie {
    stroke: #fff;
    stroke-width: 3px
}

.ct-chart-donut .ct-series {
    stroke: #fff;
    stroke-width: 3px
}

.ct-chart-donut .ct-label,.ct-chart-pie .ct-label {
    font-size: .75rem;
    font-weight: 600;
    color: #fff;
    fill: #fff
}

.custom-control-label:before {
    box-shadow: none;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out
}

.custom-control-label span {
    position: relative;
    top: 2px
}

.custom-control-label {
    margin-bottom: 0
}

.custom-control-input:active~.custom-control-label::before {
    border-color: #ff6200
}

.rating-star label {
    position: relative;
    margin-bottom: 0;
    cursor: pointer;
    color: #e5e7eb
}

.rating-star label:before {
    content: "\f005";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    transition: all .2s ease;
    font-size: 1.0625rem
}

.rating-star input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.rating-star>input:checked~label {
    color: #ffc107
}

.rating-star>label:hover {
    color: #ffc107
}

.rating-star>label:hover~label {
    color: #ffc107
}

.dropdown-menu {
    min-width: 12rem
}

.dropdown-menu .dropdown-header,.dropdown-menu .dropdown-item {
    padding: .5rem 1rem;
    font-size: 1rem
}

.dropdown-menu .dropdown-header {
    color: #1f2937;
    font-weight: 600
}

.dropdown-menu .dropdown-item {
    color: #1f2937;
    transition: all .2s ease;
    font-weight: 300
}

.dropdown-menu .dropdown-itema:hover {
    color: #1f2937
}

.show .dropdown-menu {
    animation: show-dropdown .2s ease forwards
}

[data-toggle]:hover {
    cursor: pointer
}

.dropdown-toggle:after,.dropend .dropdown-toggle:after,.dropstart .dropdown-toggle:before,.dropup .dropdown-toggle:after {
    display: none
}

.dropdown-menu-sm {
    min-width: 100px;
    border: .3rem
}

.dropdown-menu-md {
    min-width: 180px;
    border: .3rem
}

.dropdown-menu-lg {
    min-width: 260px;
    border-radius: .3rem
}

.dropdown-menu-xl {
    min-width: 450px;
    border-radius: .3rem
}

.sr-only {
    background-color: #ff6200;
    color: #fff
}

.form-control {
    box-shadow: none
}

.form-control.form-control-lg {
    padding: 1rem .75rem
}

.input-group .input-group-text:focus {
    border-color: #ff6200;
    outline: 0
}

.focused .input-group .input-group-text {
    border-color: #ff6200;
    outline: 0
}

.focused .form-control {
    border-color: #4d62a3;
    background-color: #f9fafb
}

.form-switch {
    padding-left: 2.75em
}

.form-switch .form-check-input {
    height: 1.275em
}

.form-select .form-select-lg {
    padding: .55rem 1.75rem .55rem .75rem
}

.form-select-quantity {
    padding-right: 2rem
}

.file-field input[type=file] {
    position: absolute;
    cursor: pointer;
    opacity: 0;
    padding-bottom: 30px
}

.file-field span {
    cursor: pointer
}

.noUi-target,.noUi-target * {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-user-select: none;
    touch-action: none;
    user-select: none;
    box-sizing: border-box
}

.noUi-target {
    position: relative;
    direction: ltr
}

.noUi-base,.noUi-connects {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1
}

.noUi-connects {
    overflow: hidden;
    z-index: 0
}

.noUi-connect,.noUi-origin {
    will-change: transform;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    transform-origin: 0 0
}

html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
    left: auto;
    right: 0
}

.noUi-vertical .noUi-origin {
    width: 0
}

.noUi-horizontal .noUi-origin {
    height: 0
}

.noUi-handle {
    position: absolute
}

.noUi-state-tap .noUi-connect,.noUi-state-tap .noUi-origin {
    transition: transform .3s
}

.noUi-state-drag * {
    cursor: inherit!important
}

.noUi-horizontal {
    height: 5px
}

.noUi-horizontal .noUi-handle {
    left: -17px;
    top: -10px
}

.noUi-vertical {
    width: 5px;
    height: 340px
}

.noUi-vertical .noUi-handle {
    width: 18px;
    height: 34px;
    left: -6px;
    top: -17px
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
    right: -17px;
    left: auto
}

.noUi-connects {
    border-radius: 3px
}

.noUi-connect {
    background: #ff6200
}

.noUi-draggable {
    cursor: ew-resize
}

.noUi-vertical .noUi-draggable {
    cursor: ns-resize
}

.noUi-handle {
    border: 1px solid #f9fafb;
    border-radius: 3px;
    background: #fff;
    cursor: default;
    outline: 0
}

.noUi-handle:hover {
    cursor: grab;
    cursor: -moz-grab;
    background-color: #e5e7eb
}

.noUi-handle:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    background-color: #e5e7eb;
    box-shadow: 0 0 0 .2rem rgba(69,77,103,.5)
}

.noUi-handle:focus {
    box-shadow: 0 0 0 .2rem rgba(69,77,103,.5);
    background-color: #e5e7eb
}

.noUi-active {
    outline: 0
}

[disabled] .noUi-connect {
    background: #d1d5db
}

[disabled] .noUi-handle,[disabled].noUi-handle,[disabled].noUi-target {
    cursor: not-allowed
}

.noUi-pips,.noUi-pips * {
    box-sizing: border-box
}

.noUi-pips {
    position: absolute;
    color: #374151;
    font-size: .75rem
}

.noUi-value {
    margin-top: 5px;
    position: absolute;
    white-space: nowrap;
    text-align: center
}

.noUi-value-sub {
    color: #d1d5db;
    font-size: .75rem
}

.noUi-marker {
    position: absolute;
    background: #374151
}

.noUi-marker-sub {
    background: #374151
}

.noUi-marker-large {
    background: #374151
}

.noUi-pips-horizontal {
    padding: 12px 0;
    height: auto;
    top: 100%;
    left: 0;
    width: 100%
}

.noUi-value-horizontal {
    transform: translate(-50%,50%)
}

.noUi-rtl .noUi-value-horizontal {
    transform: translate(50%,50%)
}

.noUi-marker-horizontal.noUi-marker {
    margin-left: -1px;
    width: 2px;
    height: 5px
}

.noUi-marker-horizontal.noUi-marker-sub {
    height: 10px
}

.noUi-marker-horizontal.noUi-marker-large {
    height: 12px
}

.noUi-pips-vertical {
    padding: 0 10px;
    height: 100%;
    top: 0;
    left: 100%
}

.noUi-value-vertical {
    transform: translate(0,-50%,0);
    padding-left: 25px
}

.noUi-rtl .noUi-value-vertical {
    transform: translate(0,50%)
}

.noUi-marker-vertical.noUi-marker {
    width: 5px;
    height: 2px;
    margin-top: -1px
}

.noUi-marker-vertical.noUi-marker-sub {
    width: 10px
}

.noUi-marker-vertical.noUi-marker-large {
    width: 15px
}

.noUi-tooltip {
    background: #fff;
    color: #374151;
    font-size: .75rem;
    font-weight: 400;
    display: block;
    position: absolute;
    padding: 5px 10px;
    text-align: center;
    white-space: nowrap;
    border-radius: 1rem;
    border: .0625rem solid #e5e7eb
}

.noUi-horizontal .noUi-tooltip {
    transform: translate(-50%,0);
    left: 50%;
    bottom: 30px
}

.noUi-vertical .noUi-tooltip {
    transform: translate(0,-50%);
    top: 50%;
    right: 120%
}

.noUi-target {
    background: #d1d5db;
    border-radius: 5px;
    border: 0;
    box-shadow: inset rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0;
    margin: 15px 0;
    cursor: pointer
}

.noUi-horizontal {
    height: 5px
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
    right: -10px
}

.noUi-vertical {
    width: 5px
}

.noUi-connect {
    background: #ff6200;
    box-shadow: none
}

.noUi-handle {
    position: absolute;
    border: 1px solid #ff6200;
    border-radius: 50%;
    width: 23px;
    height: 23px;
    box-shadow: rgba(0,0,0,.1) 0 1px 3px 0,rgba(0,0,0,.06) 0 1px 2px 0;
    background: #fff;
    transition: all 150ms cubic-bezier(.17,.01,.28,1.06)
}

.noUi-vertical .noUi-handle {
    border: 1px solid #4b5563;
    border-radius: 3px;
    background: #fff;
    cursor: default;
    box-shadow: inset 0 0 1px #fff,inset 0 1px 7px #f9fafb,0 3px 6px -3px #f3f4f6;
    outline: 0
}

.noUi-vertical .noUi-handle:hover {
    cursor: grab;
    cursor: -moz-grab;
    background-color: #e5e7eb
}

.noUi-vertical .noUi-handle:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    background-color: #e5e7eb;
    box-shadow: 0 0 0 .2rem rgba(69,77,103,.5)
}

.noUi-vertical .noUi-handle:focus {
    box-shadow: 0 0 0 .2rem rgba(69,77,103,.5);
    background-color: #e5e7eb
}

.noUi-horizontal .noUi-handle.noUi-active,.noUi-vertical .noUi-handle.noUi-active {
    transform: scale(1.2)
}

.input-slider--cyan .noUi-connect {
    background: #17a2b8
}

.input-slider--cyan.noUi-horizontal .noUi-handle,.input-slider--cyan.noUi-vertical .noUi-handle {
    background-color: #17a2b8
}

.input-slider--red .noUi-connect {
    background: #dc3545
}

.input-slider--red.noUi-horizontal .noUi-handle,.input-slider--red.noUi-vertical .noUi-handle {
    background-color: #dc3545
}

.input-slider--green .noUi-connect {
    background: #3aaf85
}

.input-slider--green.noUi-horizontal .noUi-handle,.input-slider--green.noUi-vertical .noUi-handle {
    background-color: #3aaf85
}

.input-slider--yellow .noUi-connect {
    background: #ffc107
}

.input-slider--yellow.noUi-horizontal .noUi-handle,.input-slider--yellow.noUi-vertical .noUi-handle {
    background-color: #ffc107
}

.input-slider--pink .noUi-connect {
    background: #d63384
}

.input-slider--pink.noUi-horizontal .noUi-handle,.input-slider--pink.noUi-vertical .noUi-handle {
    background-color: #d63384
}

[disabled] .noUi-connect,[disabled].noUi-connect {
    background: #f3f4f6
}

[disabled] .noUi-handle,[disabled].noUi-origin {
    cursor: not-allowed
}

.range-slider-value {
    font-size: .75rem;
    font-weight: 500;
    background-color: rgba(28,37,64,.7);
    color: color-yiq(#1c2540);
    border-radius: 10px;
    padding: .4em .8em .3em .85em
}

.range-slider-wrapper .upper-info {
    font-weight: 400;
    margin-bottom: 5px
}

.input-slider-value-output {
    background: #1c2540;
    color: #fff;
    padding: 4px 8px;
    position: relative;
    top: 12px;
    font-size: 11px;
    border-radius: 2px
}

.input-slider-value-output:after {
    bottom: 100%;
    left: 10px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: #1c2540;
    border-bottom-color: #333;
    border-width: 4px;
    margin-left: -4px
}

.input-slider-value-output.left:after {
    left: 10px;
    right: auto
}

.input-slider-value-output.right:after {
    right: 10px;
    left: auto
}

.icon {
    text-align: center;
    display: inline-flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center
}

.icon span,.icon svg {
    font-size: 2.25rem
}

.icon.icon-xs span,.icon.icon-xs svg {
    font-size: .875rem
}

.icon.icon-sm span,.icon.icon-sm svg {
    font-size: 1.25rem
}

.icon.icon-md span,.icon.icon-md svg {
    font-size: 1.5rem
}

.icon.icon-lg span,.icon.icon-lg svg {
    font-size: 3rem
}

.icon.icon-xl span,.icon.icon-xl svg {
    font-size: 5rem
}

.icon.rounded-circle .icon-bordered {
    border-radius: 50%
}

.icon.organic-radius .icon-bordered {
    border-radius: 30% 70% 70% 1 39% 61% 70%
}

.shape-xs {
    width: 10px;
    height: 10px
}

.icon-shape {
    width: 4.25rem;
    height: 4.25rem;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 1rem
}

.icon-shape span,.icon-shape svg {
    font-size: 2.25rem
}

.icon-shape.icon-sm {
    width: 3rem;
    height: 3rem
}

.icon-shape.icon-sm span,.icon-shape.icon-sm svg {
    font-size: 1.25rem
}

.icon-shape.icon-md {
    width: 4.25rem;
    height: 4.25rem
}

.icon-shape.icon-md span,.icon-shape.icon-md svg {
    font-size: 1.75rem
}

.icon-shape.icon-lg {
    width: 6rem;
    height: 6rem
}

.icon-shape.icon-lg span,.icon-shape.icon-lg svg {
    font-size: 3rem
}

.github-big-icon {
    position: absolute;
    right: -35px;
    top: 165px
}

.github-big-icon span {
    font-size: 800px;
    opacity: .1
}

.icon-shape-primary {
    color: #ff6200;
    background-color: rgba(36,46,76,.1)
}

.icon-primary span {
    color: #ff6200
}

.fill-primary {
    fill: #ff6200
}

.icon-shape-secondary {
    color: #1c2540;
    background-color: rgba(28,37,64,.1)
}

.icon-secondary span {
    color: #1c2540
}

.fill-secondary {
    fill: #1c2540
}

.icon-shape-tertiary {
    color: #893168;
    background-color: rgba(137,49,104,.1)
}

.icon-tertiary span {
    color: #893168
}

.fill-tertiary {
    fill: #893168
}

.icon-shape-primary-app {
    color: #ebf4f6;
    background-color: rgba(235,244,246,.1)
}

.icon-primary-app span {
    color: #ebf4f6
}

.fill-primary-app {
    fill: #ebf4f6
}

.icon-shape-secondary-app {
    color: #424aa0;
    background-color: rgba(66,74,160,.1)
}

.icon-secondary-app span {
    color: #424aa0
}

.fill-secondary-app {
    fill: #424aa0
}

.icon-shape-success {
    color: #3aaf85;
    background-color: rgba(58,175,133,.1)
}

.icon-success span {
    color: #3aaf85
}

.fill-success {
    fill: #3aaf85
}

.icon-shape-info {
    color: #0d6efd;
    background-color: rgba(13,110,253,.1)
}

.icon-info span {
    color: #0d6efd
}

.fill-info {
    fill: #0d6efd
}

.icon-shape-warning {
    color: #ffc107;
    background-color: rgba(255,193,7,.1)
}

.icon-warning span {
    color: #ffc107
}

.fill-warning {
    fill: #ffc107
}

.icon-shape-danger {
    color: #dc3545;
    background-color: rgba(220,53,69,.1)
}

.icon-danger span {
    color: #dc3545
}

.fill-danger {
    fill: #dc3545
}

.icon-shape-white {
    color: #fff;
    background-color: rgba(255,255,255,.1)
}

.icon-white span {
    color: #fff
}

.fill-white {
    fill: #fff
}

.icon-shape-gray {
    color: #1f2937;
    background-color: rgba(31,41,55,.1)
}

.icon-gray span {
    color: #1f2937
}

.fill-gray {
    fill: #1f2937
}

.icon-shape-dark {
    color: #1c2540;
    background-color: rgba(28,37,64,.1)
}

.icon-dark span {
    color: #1c2540
}

.fill-dark {
    fill: #1c2540
}

.icon-shape-black {
    color: #171f38;
    background-color: rgba(23,31,56,.1)
}

.icon-black span {
    color: #171f38
}

.fill-black {
    fill: #171f38
}

.icon-shape-purple {
    color: #6f42c1;
    background-color: rgba(111,66,193,.1)
}

.icon-purple span {
    color: #6f42c1
}

.fill-purple {
    fill: #6f42c1
}

.icon-shape-orange {
    color: #e84422;
    background-color: rgba(232,68,34,.1)
}

.icon-orange span {
    color: #e84422
}

.fill-orange {
    fill: #e84422
}

.icon-shape-gray-100 {
    color: #f9fafb;
    background-color: rgba(249,250,251,.1)
}

.icon-gray-100 span {
    color: #f9fafb
}

.fill-gray-100 {
    fill: #f9fafb
}

.icon-shape-gray-200 {
    color: #f3f4f6;
    background-color: rgba(243,244,246,.1)
}

.icon-gray-200 span {
    color: #f3f4f6
}

.fill-gray-200 {
    fill: #f3f4f6
}

.icon-shape-gray-300 {
    color: #e5e7eb;
    background-color: rgba(229,231,235,.1)
}

.icon-gray-300 span {
    color: #e5e7eb
}

.fill-gray-300 {
    fill: #e5e7eb
}

.icon-shape-gray-400 {
    color: #d1d5db;
    background-color: rgba(209,213,219,.1)
}

.icon-gray-400 span {
    color: #d1d5db
}

.fill-gray-400 {
    fill: #d1d5db
}

.icon-shape-gray-500 {
    color: #9ca3af;
    background-color: rgba(156,163,175,.1)
}

.icon-gray-500 span {
    color: #9ca3af
}

.fill-gray-500 {
    fill: #9ca3af
}

.icon-shape-gray-600 {
    color: #6b7280;
    background-color: rgba(107,114,128,.1)
}

.icon-gray-600 span {
    color: #6b7280
}

.fill-gray-600 {
    fill: #6b7280
}

.icon-shape-gray-700 {
    color: #4b5563;
    background-color: rgba(75,85,99,.1)
}

.icon-gray-700 span {
    color: #4b5563
}

.fill-gray-700 {
    fill: #4b5563
}

.icon-shape-gray-800 {
    color: #374151;
    background-color: rgba(55,65,81,.1)
}

.icon-gray-800 span {
    color: #374151
}

.fill-gray-800 {
    fill: #374151
}

.bootstrap-big-icon {
    position: absolute;
    opacity: .05;
    transform: rotate(17deg)
}

@media (min-width: 992px) {
    .bootstrap-big-icon {
        right:-20%;
        bottom: 6%
    }
}

@media (min-width: 1200px) {
    .bootstrap-big-icon {
        right:-10%;
        bottom: 6%
    }
}

.image-lg {
    height: 12rem
}

.image-md {
    height: 5rem
}

.image-sm {
    height: 3rem
}

.image-xs {
    height: 2rem
}

.small-image {
    height: 4rem
}

.img-thumbnail {
    border-width: .125rem;
    box-shadow: none
}

.full-image {
    height: 100%
}

@media (min-width: 576px) {
    .effect-img-2 {
        position:absolute;
        right: 5rem;
        top: 19%;
        z-index: 2;
        margin: 0
    }

    .effect-img-1,.effect-img-2 {
        width: 350px;
        height: auto
    }
}

@media (max-width: 767.98px) {
    .effect-img-2 {
        right:.425rem;
        top: 0
    }
}

.input-group {
    border-radius: 1rem;
    transition: all .2s ease
}

.input-group .form-control.is-invalid {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem
}

.list-group-item {
    background: 0 0;
    border-radius: 1rem
}

.list-group-item:first-child,.list-group-item:last-child {
    border-radius: 1rem
}

.list-group.dashboard-menu .list-group-item:hover {
    background-color: #f5f5f5;
    border-radius: 1rem
}

.list-group.dashboard-menu .list-group-item.active {
    color: #893168;
    background: 0 0;
    font-weight: 600
}

.list-group-space .list-group-item {
    margin-bottom: 1.5rem;
    border-radius: 1rem
}

.list-group-img {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    vertical-align: top;
    margin: -.1rem 1.2rem 0 -.2rem
}

.list-group-sm .list-group-item {
    padding: .625rem .875rem;
    color: #1f2937;
    border-bottom: .0625rem solid #f9f7f7
}

.list-group-sm .list-group-item.active {
    border-color: #f9f7f7;
    border-left: 0;
    border-right: 0;
    font-weight: 600
}

.news-list .row>[class*=col] {
    padding: 0 .25rem
}

.news-list>li:not(:last-child) {
    margin-bottom: .75rem
}

.news-list img {
    min-height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.map {
    height: 500px;
    width: 100%;
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%)
}

.modal.static-example {
    position: relative;
    display: block
}

.modal-header .close {
    padding: .825rem
}

.modal-content {
    border: 0;
    border-radius: .3rem
}

.modal-fluid .modal-dialog {
    margin-top: 0;
    margin-bottom: 0
}

.modal-fluid .modal-content {
    border-radius: 0
}

.modal-primary .modal-title {
    color: color-yiq(#ff6200)
}

.modal-primary .modal-footer,.modal-primary .modal-header {
    border-color: rgba(36,46,76,.075)
}

.modal-primary .modal-content {
    background-color: #ff6200;
    color: color-yiq(#ff6200)
}

.modal-primary .modal-content .heading {
    color: color-yiq(#ff6200)
}

.modal-primary .close>span:not(.sr-only) {
    color: #fff
}

.modal-secondary .modal-title {
    color: color-yiq(#1c2540)
}

.modal-secondary .modal-footer,.modal-secondary .modal-header {
    border-color: rgba(28,37,64,.075)
}

.modal-secondary .modal-content {
    background-color: #1c2540;
    color: color-yiq(#1c2540)
}

.modal-secondary .modal-content .heading {
    color: color-yiq(#1c2540)
}

.modal-secondary .close>span:not(.sr-only) {
    color: #fff
}

.modal-tertiary .modal-title {
    color: color-yiq(#893168)
}

.modal-tertiary .modal-footer,.modal-tertiary .modal-header {
    border-color: rgba(137,49,104,.075)
}

.modal-tertiary .modal-content {
    background-color: #893168;
    color: color-yiq(#893168)
}

.modal-tertiary .modal-content .heading {
    color: color-yiq(#893168)
}

.modal-tertiary .close>span:not(.sr-only) {
    color: #fff
}

.modal-primary-app .modal-title {
    color: color-yiq(#ebf4f6)
}

.modal-primary-app .modal-footer,.modal-primary-app .modal-header {
    border-color: rgba(235,244,246,.075)
}

.modal-primary-app .modal-content {
    background-color: #ebf4f6;
    color: color-yiq(#ebf4f6)
}

.modal-primary-app .modal-content .heading {
    color: color-yiq(#ebf4f6)
}

.modal-primary-app .close>span:not(.sr-only) {
    color: #fff
}

.modal-secondary-app .modal-title {
    color: color-yiq(#424aa0)
}

.modal-secondary-app .modal-footer,.modal-secondary-app .modal-header {
    border-color: rgba(66,74,160,.075)
}

.modal-secondary-app .modal-content {
    background-color: #424aa0;
    color: color-yiq(#424aa0)
}

.modal-secondary-app .modal-content .heading {
    color: color-yiq(#424aa0)
}

.modal-secondary-app .close>span:not(.sr-only) {
    color: #fff
}

.modal-success .modal-title {
    color: color-yiq(#3aaf85)
}

.modal-success .modal-footer,.modal-success .modal-header {
    border-color: rgba(58,175,133,.075)
}

.modal-success .modal-content {
    background-color: #3aaf85;
    color: color-yiq(#3aaf85)
}

.modal-success .modal-content .heading {
    color: color-yiq(#3aaf85)
}

.modal-success .close>span:not(.sr-only) {
    color: #fff
}

.modal-info .modal-title {
    color: color-yiq(#0d6efd)
}

.modal-info .modal-footer,.modal-info .modal-header {
    border-color: rgba(13,110,253,.075)
}

.modal-info .modal-content {
    background-color: #0d6efd;
    color: color-yiq(#0d6efd)
}

.modal-info .modal-content .heading {
    color: color-yiq(#0d6efd)
}

.modal-info .close>span:not(.sr-only) {
    color: #fff
}

.modal-warning .modal-title {
    color: color-yiq(#ffc107)
}

.modal-warning .modal-footer,.modal-warning .modal-header {
    border-color: rgba(255,193,7,.075)
}

.modal-warning .modal-content {
    background-color: #ffc107;
    color: color-yiq(#ffc107)
}

.modal-warning .modal-content .heading {
    color: color-yiq(#ffc107)
}

.modal-warning .close>span:not(.sr-only) {
    color: #fff
}

.modal-danger .modal-title {
    color: color-yiq(#dc3545)
}

.modal-danger .modal-footer,.modal-danger .modal-header {
    border-color: rgba(220,53,69,.075)
}

.modal-danger .modal-content {
    background-color: #dc3545;
    color: color-yiq(#dc3545)
}

.modal-danger .modal-content .heading {
    color: color-yiq(#dc3545)
}

.modal-danger .close>span:not(.sr-only) {
    color: #fff
}

.modal-white .modal-title {
    color: color-yiq(#fff)
}

.modal-white .modal-footer,.modal-white .modal-header {
    border-color: rgba(255,255,255,.075)
}

.modal-white .modal-content {
    background-color: #fff;
    color: color-yiq(#fff)
}

.modal-white .modal-content .heading {
    color: color-yiq(#fff)
}

.modal-white .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray .modal-title {
    color: color-yiq(#1f2937)
}

.modal-gray .modal-footer,.modal-gray .modal-header {
    border-color: rgba(31,41,55,.075)
}

.modal-gray .modal-content {
    background-color: #1f2937;
    color: color-yiq(#1f2937)
}

.modal-gray .modal-content .heading {
    color: color-yiq(#1f2937)
}

.modal-gray .close>span:not(.sr-only) {
    color: #fff
}

.modal-dark .modal-title {
    color: color-yiq(#1c2540)
}

.modal-dark .modal-footer,.modal-dark .modal-header {
    border-color: rgba(28,37,64,.075)
}

.modal-dark .modal-content {
    background-color: #1c2540;
    color: color-yiq(#1c2540)
}

.modal-dark .modal-content .heading {
    color: color-yiq(#1c2540)
}

.modal-dark .close>span:not(.sr-only) {
    color: #fff
}

.modal-black .modal-title {
    color: color-yiq(#171f38)
}

.modal-black .modal-footer,.modal-black .modal-header {
    border-color: rgba(23,31,56,.075)
}

.modal-black .modal-content {
    background-color: #171f38;
    color: color-yiq(#171f38)
}

.modal-black .modal-content .heading {
    color: color-yiq(#171f38)
}

.modal-black .close>span:not(.sr-only) {
    color: #fff
}

.modal-purple .modal-title {
    color: color-yiq(#6f42c1)
}

.modal-purple .modal-footer,.modal-purple .modal-header {
    border-color: rgba(111,66,193,.075)
}

.modal-purple .modal-content {
    background-color: #6f42c1;
    color: color-yiq(#6f42c1)
}

.modal-purple .modal-content .heading {
    color: color-yiq(#6f42c1)
}

.modal-purple .close>span:not(.sr-only) {
    color: #fff
}

.modal-orange .modal-title {
    color: color-yiq(#e84422)
}

.modal-orange .modal-footer,.modal-orange .modal-header {
    border-color: rgba(232,68,34,.075)
}

.modal-orange .modal-content {
    background-color: #e84422;
    color: color-yiq(#e84422)
}

.modal-orange .modal-content .heading {
    color: color-yiq(#e84422)
}

.modal-orange .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray-100 .modal-title {
    color: color-yiq(#f9fafb)
}

.modal-gray-100 .modal-footer,.modal-gray-100 .modal-header {
    border-color: rgba(249,250,251,.075)
}

.modal-gray-100 .modal-content {
    background-color: #f9fafb;
    color: color-yiq(#f9fafb)
}

.modal-gray-100 .modal-content .heading {
    color: color-yiq(#f9fafb)
}

.modal-gray-100 .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray-200 .modal-title {
    color: color-yiq(#f3f4f6)
}

.modal-gray-200 .modal-footer,.modal-gray-200 .modal-header {
    border-color: rgba(243,244,246,.075)
}

.modal-gray-200 .modal-content {
    background-color: #f3f4f6;
    color: color-yiq(#f3f4f6)
}

.modal-gray-200 .modal-content .heading {
    color: color-yiq(#f3f4f6)
}

.modal-gray-200 .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray-300 .modal-title {
    color: color-yiq(#e5e7eb)
}

.modal-gray-300 .modal-footer,.modal-gray-300 .modal-header {
    border-color: rgba(229,231,235,.075)
}

.modal-gray-300 .modal-content {
    background-color: #e5e7eb;
    color: color-yiq(#e5e7eb)
}

.modal-gray-300 .modal-content .heading {
    color: color-yiq(#e5e7eb)
}

.modal-gray-300 .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray-400 .modal-title {
    color: color-yiq(#d1d5db)
}

.modal-gray-400 .modal-footer,.modal-gray-400 .modal-header {
    border-color: rgba(209,213,219,.075)
}

.modal-gray-400 .modal-content {
    background-color: #d1d5db;
    color: color-yiq(#d1d5db)
}

.modal-gray-400 .modal-content .heading {
    color: color-yiq(#d1d5db)
}

.modal-gray-400 .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray-500 .modal-title {
    color: color-yiq(#9ca3af)
}

.modal-gray-500 .modal-footer,.modal-gray-500 .modal-header {
    border-color: rgba(156,163,175,.075)
}

.modal-gray-500 .modal-content {
    background-color: #9ca3af;
    color: color-yiq(#9ca3af)
}

.modal-gray-500 .modal-content .heading {
    color: color-yiq(#9ca3af)
}

.modal-gray-500 .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray-600 .modal-title {
    color: color-yiq(#6b7280)
}

.modal-gray-600 .modal-footer,.modal-gray-600 .modal-header {
    border-color: rgba(107,114,128,.075)
}

.modal-gray-600 .modal-content {
    background-color: #6b7280;
    color: color-yiq(#6b7280)
}

.modal-gray-600 .modal-content .heading {
    color: color-yiq(#6b7280)
}

.modal-gray-600 .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray-700 .modal-title {
    color: color-yiq(#4b5563)
}

.modal-gray-700 .modal-footer,.modal-gray-700 .modal-header {
    border-color: rgba(75,85,99,.075)
}

.modal-gray-700 .modal-content {
    background-color: #4b5563;
    color: color-yiq(#4b5563)
}

.modal-gray-700 .modal-content .heading {
    color: color-yiq(#4b5563)
}

.modal-gray-700 .close>span:not(.sr-only) {
    color: #fff
}

.modal-gray-800 .modal-title {
    color: color-yiq(#374151)
}

.modal-gray-800 .modal-footer,.modal-gray-800 .modal-header {
    border-color: rgba(55,65,81,.075)
}

.modal-gray-800 .modal-content {
    background-color: #374151;
    color: color-yiq(#374151)
}

.modal-gray-800 .modal-content .heading {
    color: color-yiq(#374151)
}

.modal-gray-800 .close>span:not(.sr-only) {
    color: #fff
}

.nav-link {
    color: #374151
}

.nav-link.active,.nav-link:hover {
    color: #1c2540
}

.nav-link.active img,.nav-link:hover img {
    opacity: inherit;
    transition: all .2s ease
}

.nav-link span {
    position: relative
}

.nav-link span:hover {
    color: #1c2540
}

.nav-link img {
    opacity: .5
}

.nav-tabs .nav-item {
    margin-bottom: 0
}

.nav-tabs .nav-link {
    border: 0;
    padding: 1rem 1rem
}

.nav-pills .nav-item:not(:last-child) {
    padding-right: 1rem
}

.nav-pills .nav-link {
    padding: .75rem .85rem;
    transition: all .2s ease;
    box-shadow: none;
    border: 1px solid #e5e7eb
}

.nav-pills .nav-link.avatar-link {
    border: 0
}

.nav-pills .nav-link:hover {
    background-color: #f8f8f8
}

.nav-pills .nav-link.active,.nav-pills .show>.nav-link {
    color: #ff6200;
    background-color: #f8f8f8;
    border-color: #ff6200
}

.nav-pills.rounded .nav-link {
    border-radius: 30px
}

.nav-pills.rounded.vertical-tab .nav-link {
    margin-bottom: .625rem;
    min-width: 100px
}

.nav-pills.rounded.vertical-tab .nav-item:not(:last-child) {
    padding-right: 0
}

.nav-pills.bordered-pill-md .nav-link {
    border: .125rem solid #f3f4f6;
    font-weight: 600
}

.nav-pills.vertical-tab .nav-link {
    margin-bottom: .625rem
}

.nav-pills.vertical-tab .nav-item:not(:last-child) {
    padding-right: 0
}

.nav-pills .nav-item {
    margin-bottom: 1rem
}

@media (max-width: 575.98px) {
    .nav-pills:not(.nav-pills-circle) .nav-item {
        padding-right:0
    }
}

.nav-pill-circle .nav-link {
    display: flex;
    text-align: center;
    height: 80px;
    width: 80px;
    padding: 0;
    box-shadow: none;
    border-radius: 50%;
    align-items: center;
    justify-content: center
}

.nav-pill-circle .nav-link.avatar-link {
    box-shadow: none
}

.nav-pill-circle.vertical-tab .nav-item:not(:last-child) {
    padding-right: 0
}

.nav-pill-circle .nav-link-icon span,.nav-pill-circle .nav-link-icon svg {
    font-size: 1.25rem;
    margin: 0;
    display: block
}

.nav-wrapper {
    padding: 1rem 0;
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem
}

.nav-wrapper+.card {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 1rem;
    border-bottom-left-radius: 1rem
}

.tab-content>.tab-pane {
    display: none
}

.tab-content>.tab-pane pre {
    padding: 0;
    margin: 0
}

.tab-content>.active {
    display: block
}

.circle-pagination .page-link,.circle-pagination span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    padding: 0;
    border-radius: 50%
}

.popover-header {
    font-weight: 600
}

.progress-wrapper {
    position: relative
}

.progress-bar {
    box-shadow: none;
    border-radius: 0;
    height: auto
}

.progress {
    height: 4px;
    margin-bottom: 1rem;
    overflow: hidden;
    border-radius: .6rem;
    background-color: #f3f4f6;
    font-size: .75rem;
    font-weight: 600;
    box-shadow: inset 0 1px 3px rgba(0,0,0,.2)
}

.progress-xl {
    height: .8rem
}

.progress-lg {
    height: .6rem
}

.progress-sm {
    height: .2rem
}

.progress-info {
    display: flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: space-between;
    -ms-justify-content: space-between;
    justify-content: space-between;
    -ms-flex-pack: space-between;
    margin-bottom: .5rem
}

.progress-info .progress-label span {
    display: inline-block;
    color: #ff6200;
    font-size: .875rem;
    font-weight: 600
}

.progress-info .progress-percentage {
    text-align: right
}

.progress-info .progress-percentage span {
    display: inline-block;
    color: #1f2937;
    font-size: .75rem;
    font-weight: 600
}

.info-xl .progress-label span,.info-xl .progress-percentage span {
    font-size: .875rem
}

.info-xl .progress-percentage {
    text-align: right
}

.progress-tooltip {
    background: #ff6200;
    color: #fff;
    font-weight: 600;
    padding: .25rem .375rem;
    line-height: 1;
    font-size: .75rem;
    position: relative;
    border-radius: .6rem
}

.progress-tooltip:after {
    content: " ";
    position: absolute;
    top: 100%;
    left: 10px;
    border: solid transparent;
    height: 0;
    width: 0;
    pointer-events: none;
    border-top-color: #1f2937;
    border-width: 6px
}

@keyframes animate-positive {
    0% {
        width: 0%
    }
}

.progress-info .bg-primary::after {
    border-top-color: #ff6200
}

.progress-info .bg-secondary::after {
    border-top-color: #1c2540
}

.progress-info .bg-tertiary::after {
    border-top-color: #893168
}

.progress-info .bg-primary-app::after {
    border-top-color: #ebf4f6
}

.progress-info .bg-secondary-app::after {
    border-top-color: #424aa0
}

.progress-info .bg-success::after {
    border-top-color: #3aaf85
}

.progress-info .bg-info::after {
    border-top-color: #0d6efd
}

.progress-info .bg-warning::after {
    border-top-color: #ffc107
}

.progress-info .bg-danger::after {
    border-top-color: #dc3545
}

.progress-info .bg-white::after {
    border-top-color: #fff
}

.progress-info .bg-gray::after {
    border-top-color: #1f2937
}

.progress-info .bg-dark::after {
    border-top-color: #1c2540
}

.progress-info .bg-black::after {
    border-top-color: #171f38
}

.progress-info .bg-purple::after {
    border-top-color: #6f42c1
}

.progress-info .bg-orange::after {
    border-top-color: #e84422
}

.progress-info .bg-gray-100::after {
    border-top-color: #f9fafb
}

.progress-info .bg-gray-200::after {
    border-top-color: #f3f4f6
}

.progress-info .bg-gray-300::after {
    border-top-color: #e5e7eb
}

.progress-info .bg-gray-400::after {
    border-top-color: #d1d5db
}

.progress-info .bg-gray-500::after {
    border-top-color: #9ca3af
}

.progress-info .bg-gray-600::after {
    border-top-color: #6b7280
}

.progress-info .bg-gray-700::after {
    border-top-color: #4b5563
}

.progress-info .bg-gray-800::after {
    border-top-color: #374151
}

.preloader {
    display: flex;
    align-items: center;
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transition: height .3s linear;
    width: 100%;
    z-index: 9999
}

.preloader img {
    width: 30px;
    height: 30px
}

.preloader.show {
    height: 0
}

.shape-primary {
    background-color: #ff6200
}

.shape-primary .step-number {
    background-color: #ff6200
}

.shape-secondary {
    background-color: #1c2540
}

.shape-secondary .step-number {
    background-color: #1c2540
}

.shape-tertiary {
    background-color: #893168
}

.shape-tertiary .step-number {
    background-color: #893168
}

.shape-primary-app {
    background-color: #ebf4f6
}

.shape-primary-app .step-number {
    background-color: #ebf4f6
}

.shape-secondary-app {
    background-color: #424aa0
}

.shape-secondary-app .step-number {
    background-color: #424aa0
}

.shape-success {
    background-color: #3aaf85
}

.shape-success .step-number {
    background-color: #3aaf85
}

.shape-info {
    background-color: #0d6efd
}

.shape-info .step-number {
    background-color: #0d6efd
}

.shape-warning {
    background-color: #ffc107
}

.shape-warning .step-number {
    background-color: #ffc107
}

.shape-danger {
    background-color: #dc3545
}

.shape-danger .step-number {
    background-color: #dc3545
}

.shape-white {
    background-color: #fff
}

.shape-white .step-number {
    background-color: #fff
}

.shape-gray {
    background-color: #1f2937
}

.shape-gray .step-number {
    background-color: #1f2937
}

.shape-dark {
    background-color: #1c2540
}

.shape-dark .step-number {
    background-color: #1c2540
}

.shape-black {
    background-color: #171f38
}

.shape-black .step-number {
    background-color: #171f38
}

.shape-purple {
    background-color: #6f42c1
}

.shape-purple .step-number {
    background-color: #6f42c1
}

.shape-orange {
    background-color: #e84422
}

.shape-orange .step-number {
    background-color: #e84422
}

.shape-gray-100 {
    background-color: #f9fafb
}

.shape-gray-100 .step-number {
    background-color: #f9fafb
}

.shape-gray-200 {
    background-color: #f3f4f6
}

.shape-gray-200 .step-number {
    background-color: #f3f4f6
}

.shape-gray-300 {
    background-color: #e5e7eb
}

.shape-gray-300 .step-number {
    background-color: #e5e7eb
}

.shape-gray-400 {
    background-color: #d1d5db
}

.shape-gray-400 .step-number {
    background-color: #d1d5db
}

.shape-gray-500 {
    background-color: #9ca3af
}

.shape-gray-500 .step-number {
    background-color: #9ca3af
}

.shape-gray-600 {
    background-color: #6b7280
}

.shape-gray-600 .step-number {
    background-color: #6b7280
}

.shape-gray-700 {
    background-color: #4b5563
}

.shape-gray-700 .step-number {
    background-color: #4b5563
}

.shape-gray-800 {
    background-color: #374151
}

.shape-gray-800 .step-number {
    background-color: #374151
}

.organic-radius {
    border-radius: 63% 37% 30% 70%/50% 45% 55% 50%
}

.color-shape {
    width: 7rem;
    height: 7rem
}

.step {
    position: relative;
    margin-bottom: 4rem
}

.step .step-shape {
    display: inline-flex;
    width: 7rem;
    height: 7rem;
    text-align: center;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center
}

.step .step-shape .step-icon span,.step .step-shape .step-icon svg {
    font-size: 2.25rem
}

.step .step-shape .step-number {
    position: absolute;
    font-weight: 600;
    top: -10px;
    width: 32px;
    height: 32px;
    border-width: .125rem;
    border-style: solid;
    line-height: 1.7
}

.step .step-shape.bordered {
    border-width: .125rem;
    border-style: solid
}

.step .step-shape.bordered .step-number {
    background-color: #fff;
    top: -12px
}

.step .step-shape.rounded .step-number {
    border-radius: 1rem
}

.step .step-shape.organic-radius .step-number {
    border-radius: 30% 70% 70% 30%/30% 39% 61% 70%
}

.step .icon-lg {
    font-size: 3rem
}

.layer-primary {
    color: rgba(36,46,76,.15)
}

.layer-secondary {
    color: rgba(28,37,64,.15)
}

.layer-tertiary {
    color: rgba(137,49,104,.15)
}

.layer-primary-app {
    color: rgba(235,244,246,.15)
}

.layer-secondary-app {
    color: rgba(66,74,160,.15)
}

.layer-success {
    color: rgba(58,175,133,.15)
}

.layer-info {
    color: rgba(13,110,253,.15)
}

.layer-warning {
    color: rgba(255,193,7,.15)
}

.layer-danger {
    color: rgba(220,53,69,.15)
}

.layer-white {
    color: rgba(255,255,255,.15)
}

.layer-gray {
    color: rgba(31,41,55,.15)
}

.layer-dark {
    color: rgba(28,37,64,.15)
}

.layer-black {
    color: rgba(23,31,56,.15)
}

.layer-purple {
    color: rgba(111,66,193,.15)
}

.layer-orange {
    color: rgba(232,68,34,.15)
}

.layer-gray-100 {
    color: rgba(249,250,251,.15)
}

.layer-gray-200 {
    color: rgba(243,244,246,.15)
}

.layer-gray-300 {
    color: rgba(229,231,235,.15)
}

.layer-gray-400 {
    color: rgba(209,213,219,.15)
}

.layer-gray-500 {
    color: rgba(156,163,175,.15)
}

.layer-gray-600 {
    color: rgba(107,114,128,.15)
}

.layer-gray-700 {
    color: rgba(75,85,99,.15)
}

.layer-gray-800 {
    color: rgba(55,65,81,.15)
}

.h1,.h2,.h3,.h4,.h5,.h6,h1,h2,h3,h4,h5,h6 {
    font-weight: 600
}

ol li,p,ul li {
    font-family: var(--bs-font-sans-serif);
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.6
}

.text-action,a {
    color: #ff6200;
    font-weight: 400;
    transition: all .2s ease
}

.text-action:hover,a:hover {
    color: #0b0f18;
    cursor: pointer
}

article .h1,article .h2,article .h3,article .h4,article .h5,article .h6,article h1,article h2,article h3,article h4,article h5,article h6 {
    margin: 2.5rem 0 2rem 0
}

article blockquote,article p {
    font-size: 1.27rem;
    margin-bottom: 2rem
}

article ol li,article ul li {
    font-size: 1.27rem;
    margin-bottom: 1.5rem
}

article ol,article ul {
    margin-bottom: 2.5rem
}

article a {
    color: #893168;
    text-decoration: underline
}

article a:hover {
    color: #893168;
    text-decoration: none
}

blockquote {
    font-size: 1.27rem;
    background: #f5f9fc;
    border-radius: 10px;
    padding: 25px;
    font-style: italic
}

.lead+.btn-wrapper {
    margin-top: 3rem
}

@media (max-width: 767.98px) {
    .lead {
        font-size:1rem
    }
}

.text-italic {
    font-style: italic!important
}

.description {
    font-size: .875rem
}

.display-1,.display-2,.display-3,.display-4 {
    font-weight: 600;
    color: #1c2540
}

@media (max-width: 767.98px) {
    .display-2 {
        font-size:2.5rem
    }
}

@media (max-width: 991.98px) {
    .display-3 {
        font-size:1.875rem
    }
}

@media (max-width: 575.98px) {
    .display-4 {
        font-size:1.5rem
    }
}

.blockquote {
    font-family: Sanchez,serif;
    font-style: italic;
    font-weight: 400
}

.blockquote .blockquote-footer {
    font-size: 1.25rem;
    font-weight: 700
}

.font-small {
    font-size: .875rem;
    font-weight: 300
}

.font-base {
    font-size: 1rem;
    font-weight: 300
}

.font-medium {
    font-size: 1.25rem;
    font-weight: 300
}

.text-primary,.text-primary:hover {
    color: #ff6200!important
}

.text-primary .display-1,.text-primary .display-2,.text-primary .display-3,.text-primary .display-4,.text-primary .h1,.text-primary .h2,.text-primary .h3,.text-primary .h4,.text-primary .h5,.text-primary .h6,.text-primary a:not(.btn),.text-primary h1,.text-primary h2,.text-primary h3,.text-primary h4,.text-primary h5,.text-primary h6 {
    color: #ff6200
}

.text-primary .text-muted {
    color: rgba(36,46,76,.7)!important
}

.text-secondary,.text-secondary:hover {
    color: #1c2540!important
}

.text-secondary .display-1,.text-secondary .display-2,.text-secondary .display-3,.text-secondary .display-4,.text-secondary .h1,.text-secondary .h2,.text-secondary .h3,.text-secondary .h4,.text-secondary .h5,.text-secondary .h6,.text-secondary a:not(.btn),.text-secondary h1,.text-secondary h2,.text-secondary h3,.text-secondary h4,.text-secondary h5,.text-secondary h6 {
    color: #1c2540
}

.text-secondary .text-muted {
    color: rgba(28,37,64,.7)!important
}

.text-tertiary,.text-tertiary:hover {
    color: #893168!important
}

.text-tertiary .display-1,.text-tertiary .display-2,.text-tertiary .display-3,.text-tertiary .display-4,.text-tertiary .h1,.text-tertiary .h2,.text-tertiary .h3,.text-tertiary .h4,.text-tertiary .h5,.text-tertiary .h6,.text-tertiary a:not(.btn),.text-tertiary h1,.text-tertiary h2,.text-tertiary h3,.text-tertiary h4,.text-tertiary h5,.text-tertiary h6 {
    color: #893168
}

.text-tertiary .text-muted {
    color: rgba(137,49,104,.7)!important
}

.text-primary-app,.text-primary-app:hover {
    color: #ebf4f6!important
}

.text-primary-app .display-1,.text-primary-app .display-2,.text-primary-app .display-3,.text-primary-app .display-4,.text-primary-app .h1,.text-primary-app .h2,.text-primary-app .h3,.text-primary-app .h4,.text-primary-app .h5,.text-primary-app .h6,.text-primary-app a:not(.btn),.text-primary-app h1,.text-primary-app h2,.text-primary-app h3,.text-primary-app h4,.text-primary-app h5,.text-primary-app h6 {
    color: #ebf4f6
}

.text-primary-app .text-muted {
    color: rgba(235,244,246,.7)!important
}

.text-secondary-app,.text-secondary-app:hover {
    color: #424aa0!important
}

.text-secondary-app .display-1,.text-secondary-app .display-2,.text-secondary-app .display-3,.text-secondary-app .display-4,.text-secondary-app .h1,.text-secondary-app .h2,.text-secondary-app .h3,.text-secondary-app .h4,.text-secondary-app .h5,.text-secondary-app .h6,.text-secondary-app a:not(.btn),.text-secondary-app h1,.text-secondary-app h2,.text-secondary-app h3,.text-secondary-app h4,.text-secondary-app h5,.text-secondary-app h6 {
    color: #424aa0
}

.text-secondary-app .text-muted {
    color: rgba(66,74,160,.7)!important
}

.text-success,.text-success:hover {
    color: #3aaf85!important
}

.text-success .display-1,.text-success .display-2,.text-success .display-3,.text-success .display-4,.text-success .h1,.text-success .h2,.text-success .h3,.text-success .h4,.text-success .h5,.text-success .h6,.text-success a:not(.btn),.text-success h1,.text-success h2,.text-success h3,.text-success h4,.text-success h5,.text-success h6 {
    color: #3aaf85
}

.text-success .text-muted {
    color: rgba(58,175,133,.7)!important
}

.text-info,.text-info:hover {
    color: #0d6efd!important
}

.text-info .display-1,.text-info .display-2,.text-info .display-3,.text-info .display-4,.text-info .h1,.text-info .h2,.text-info .h3,.text-info .h4,.text-info .h5,.text-info .h6,.text-info a:not(.btn),.text-info h1,.text-info h2,.text-info h3,.text-info h4,.text-info h5,.text-info h6 {
    color: #0d6efd
}

.text-info .text-muted {
    color: rgba(13,110,253,.7)!important
}

.text-warning,.text-warning:hover {
    color: #ffc107!important
}

.text-warning .display-1,.text-warning .display-2,.text-warning .display-3,.text-warning .display-4,.text-warning .h1,.text-warning .h2,.text-warning .h3,.text-warning .h4,.text-warning .h5,.text-warning .h6,.text-warning a:not(.btn),.text-warning h1,.text-warning h2,.text-warning h3,.text-warning h4,.text-warning h5,.text-warning h6 {
    color: #ffc107
}

.text-warning .text-muted {
    color: rgba(255,193,7,.7)!important
}

.text-danger,.text-danger:hover {
    color: #dc3545!important
}

.text-danger .display-1,.text-danger .display-2,.text-danger .display-3,.text-danger .display-4,.text-danger .h1,.text-danger .h2,.text-danger .h3,.text-danger .h4,.text-danger .h5,.text-danger .h6,.text-danger a:not(.btn),.text-danger h1,.text-danger h2,.text-danger h3,.text-danger h4,.text-danger h5,.text-danger h6 {
    color: #dc3545
}

.text-danger .text-muted {
    color: rgba(220,53,69,.7)!important
}

.text-white,.text-white:hover {
    color: #fff!important
}

.text-white .display-1,.text-white .display-2,.text-white .display-3,.text-white .display-4,.text-white .h1,.text-white .h2,.text-white .h3,.text-white .h4,.text-white .h5,.text-white .h6,.text-white a:not(.btn),.text-white h1,.text-white h2,.text-white h3,.text-white h4,.text-white h5,.text-white h6 {
    color: #fff
}

.text-white .text-muted {
    color: rgba(255,255,255,.7)!important
}

.text-gray,.text-gray:hover {
    color: #1f2937!important
}

.text-gray .display-1,.text-gray .display-2,.text-gray .display-3,.text-gray .display-4,.text-gray .h1,.text-gray .h2,.text-gray .h3,.text-gray .h4,.text-gray .h5,.text-gray .h6,.text-gray a:not(.btn),.text-gray h1,.text-gray h2,.text-gray h3,.text-gray h4,.text-gray h5,.text-gray h6 {
    color: #1f2937
}

.text-gray .text-muted {
    color: rgba(31,41,55,.7)!important
}

.text-dark,.text-dark:hover {
    color: #1c2540!important
}

.text-dark .display-1,.text-dark .display-2,.text-dark .display-3,.text-dark .display-4,.text-dark .h1,.text-dark .h2,.text-dark .h3,.text-dark .h4,.text-dark .h5,.text-dark .h6,.text-dark a:not(.btn),.text-dark h1,.text-dark h2,.text-dark h3,.text-dark h4,.text-dark h5,.text-dark h6 {
    color: #1c2540
}

.text-dark .text-muted {
    color: rgba(28,37,64,.7)!important
}

.text-black,.text-black:hover {
    color: #171f38!important
}

.text-black .display-1,.text-black .display-2,.text-black .display-3,.text-black .display-4,.text-black .h1,.text-black .h2,.text-black .h3,.text-black .h4,.text-black .h5,.text-black .h6,.text-black a:not(.btn),.text-black h1,.text-black h2,.text-black h3,.text-black h4,.text-black h5,.text-black h6 {
    color: #171f38
}

.text-black .text-muted {
    color: rgba(23,31,56,.7)!important
}

.text-purple,.text-purple:hover {
    color: #6f42c1!important
}

.text-purple .display-1,.text-purple .display-2,.text-purple .display-3,.text-purple .display-4,.text-purple .h1,.text-purple .h2,.text-purple .h3,.text-purple .h4,.text-purple .h5,.text-purple .h6,.text-purple a:not(.btn),.text-purple h1,.text-purple h2,.text-purple h3,.text-purple h4,.text-purple h5,.text-purple h6 {
    color: #6f42c1
}

.text-purple .text-muted {
    color: rgba(111,66,193,.7)!important
}

.text-orange,.text-orange:hover {
    color: #e84422!important
}

.text-orange .display-1,.text-orange .display-2,.text-orange .display-3,.text-orange .display-4,.text-orange .h1,.text-orange .h2,.text-orange .h3,.text-orange .h4,.text-orange .h5,.text-orange .h6,.text-orange a:not(.btn),.text-orange h1,.text-orange h2,.text-orange h3,.text-orange h4,.text-orange h5,.text-orange h6 {
    color: #e84422
}

.text-orange .text-muted {
    color: rgba(232,68,34,.7)!important
}

.text-gray-100,.text-gray-100:hover {
    color: #f9fafb!important
}

.text-gray-100 .display-1,.text-gray-100 .display-2,.text-gray-100 .display-3,.text-gray-100 .display-4,.text-gray-100 .h1,.text-gray-100 .h2,.text-gray-100 .h3,.text-gray-100 .h4,.text-gray-100 .h5,.text-gray-100 .h6,.text-gray-100 a:not(.btn),.text-gray-100 h1,.text-gray-100 h2,.text-gray-100 h3,.text-gray-100 h4,.text-gray-100 h5,.text-gray-100 h6 {
    color: #f9fafb
}

.text-gray-100 .text-muted {
    color: rgba(249,250,251,.7)!important
}

.text-gray-200,.text-gray-200:hover {
    color: #f3f4f6!important
}

.text-gray-200 .display-1,.text-gray-200 .display-2,.text-gray-200 .display-3,.text-gray-200 .display-4,.text-gray-200 .h1,.text-gray-200 .h2,.text-gray-200 .h3,.text-gray-200 .h4,.text-gray-200 .h5,.text-gray-200 .h6,.text-gray-200 a:not(.btn),.text-gray-200 h1,.text-gray-200 h2,.text-gray-200 h3,.text-gray-200 h4,.text-gray-200 h5,.text-gray-200 h6 {
    color: #f3f4f6
}

.text-gray-200 .text-muted {
    color: rgba(243,244,246,.7)!important
}

.text-gray-300,.text-gray-300:hover {
    color: #e5e7eb!important
}

.text-gray-300 .display-1,.text-gray-300 .display-2,.text-gray-300 .display-3,.text-gray-300 .display-4,.text-gray-300 .h1,.text-gray-300 .h2,.text-gray-300 .h3,.text-gray-300 .h4,.text-gray-300 .h5,.text-gray-300 .h6,.text-gray-300 a:not(.btn),.text-gray-300 h1,.text-gray-300 h2,.text-gray-300 h3,.text-gray-300 h4,.text-gray-300 h5,.text-gray-300 h6 {
    color: #e5e7eb
}

.text-gray-300 .text-muted {
    color: rgba(229,231,235,.7)!important
}

.text-gray-400,.text-gray-400:hover {
    color: #d1d5db!important
}

.text-gray-400 .display-1,.text-gray-400 .display-2,.text-gray-400 .display-3,.text-gray-400 .display-4,.text-gray-400 .h1,.text-gray-400 .h2,.text-gray-400 .h3,.text-gray-400 .h4,.text-gray-400 .h5,.text-gray-400 .h6,.text-gray-400 a:not(.btn),.text-gray-400 h1,.text-gray-400 h2,.text-gray-400 h3,.text-gray-400 h4,.text-gray-400 h5,.text-gray-400 h6 {
    color: #d1d5db
}

.text-gray-400 .text-muted {
    color: rgba(209,213,219,.7)!important
}

.text-gray-500,.text-gray-500:hover {
    color: #9ca3af!important
}

.text-gray-500 .display-1,.text-gray-500 .display-2,.text-gray-500 .display-3,.text-gray-500 .display-4,.text-gray-500 .h1,.text-gray-500 .h2,.text-gray-500 .h3,.text-gray-500 .h4,.text-gray-500 .h5,.text-gray-500 .h6,.text-gray-500 a:not(.btn),.text-gray-500 h1,.text-gray-500 h2,.text-gray-500 h3,.text-gray-500 h4,.text-gray-500 h5,.text-gray-500 h6 {
    color: #9ca3af
}

.text-gray-500 .text-muted {
    color: rgba(156,163,175,.7)!important
}

.text-gray-600,.text-gray-600:hover {
    color: #6b7280!important
}

.text-gray-600 .display-1,.text-gray-600 .display-2,.text-gray-600 .display-3,.text-gray-600 .display-4,.text-gray-600 .h1,.text-gray-600 .h2,.text-gray-600 .h3,.text-gray-600 .h4,.text-gray-600 .h5,.text-gray-600 .h6,.text-gray-600 a:not(.btn),.text-gray-600 h1,.text-gray-600 h2,.text-gray-600 h3,.text-gray-600 h4,.text-gray-600 h5,.text-gray-600 h6 {
    color: #6b7280
}

.text-gray-600 .text-muted {
    color: rgba(107,114,128,.7)!important
}

.text-gray-700,.text-gray-700:hover {
    color: #4b5563!important
}

.text-gray-700 .display-1,.text-gray-700 .display-2,.text-gray-700 .display-3,.text-gray-700 .display-4,.text-gray-700 .h1,.text-gray-700 .h2,.text-gray-700 .h3,.text-gray-700 .h4,.text-gray-700 .h5,.text-gray-700 .h6,.text-gray-700 a:not(.btn),.text-gray-700 h1,.text-gray-700 h2,.text-gray-700 h3,.text-gray-700 h4,.text-gray-700 h5,.text-gray-700 h6 {
    color: #4b5563
}

.text-gray-700 .text-muted {
    color: rgba(75,85,99,.7)!important
}

.text-gray-800,.text-gray-800:hover {
    color: #374151!important
}

.text-gray-800 .display-1,.text-gray-800 .display-2,.text-gray-800 .display-3,.text-gray-800 .display-4,.text-gray-800 .h1,.text-gray-800 .h2,.text-gray-800 .h3,.text-gray-800 .h4,.text-gray-800 .h5,.text-gray-800 .h6,.text-gray-800 a:not(.btn),.text-gray-800 h1,.text-gray-800 h2,.text-gray-800 h3,.text-gray-800 h4,.text-gray-800 h5,.text-gray-800 h6 {
    color: #374151
}

.text-gray-800 .text-muted {
    color: rgba(55,65,81,.7)!important
}

.text-facebook {
    color: #3b5999
}

.text-twitter {
    color: #1da1f2
}

.text-google {
    color: #db4337
}

.text-instagram {
    color: #e4405f
}

.text-slack {
    color: #3aaf85
}

.text-dribbble {
    color: #ea4c89
}

.text-dropbox {
    color: #1e90ff
}

.text-github {
    color: #222
}

.folder-structure li {
    font-size: 1.2rem
}

.timeline {
    width: 100%
}

.timeline.timeline-one .timeline-item {
    position: relative;
    width: 50%;
    margin-left: 50%;
    padding-bottom: 1.5rem;
    padding-left: 1.5rem;
    text-align: left
}

@media (max-width: 575.98px) {
    .timeline.timeline-one .timeline-item {
        width:100%;
        margin-left: 0
    }
}

.timeline.timeline-one .timeline-item img {
    margin-bottom: 1.25rem
}

.timeline.timeline-one .timeline-item:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 16px;
    height: 16px;
    margin-top: .425rem;
    margin-left: -.5rem;
    border: .0625rem solid #e5e7eb;
    border-radius: 50%
}

.timeline.timeline-one .timeline-item:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: -.1875rem;
    width: .0625rem;
    margin-top: 1.875rem;
    margin-left: -.0625rem;
    background-color: #e5e7eb
}

.timeline.timeline-one .timeline-item:nth-child(2n+1) {
    margin-left: 0;
    padding-right: 1.5rem;
    padding-left: 0;
    text-align: right
}

@media (max-width: 575.98px) {
    .timeline.timeline-one .timeline-item:nth-child(2n+1) {
        padding-right:0;
        padding-left: 1.5rem;
        text-align: left
    }
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):after,.timeline.timeline-one .timeline-item:nth-child(2n+1):before {
    right: 0;
    left: auto
}

@media (max-width: 575.98px) {
    .timeline.timeline-one .timeline-item:nth-child(2n+1):after,.timeline.timeline-one .timeline-item:nth-child(2n+1):before {
        right:auto;
        left: 0
    }
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):before {
    margin-right: -.5rem
}

.timeline.timeline-one .timeline-item:nth-child(2n+1):after {
    margin-right: -.0625rem
}

.timeline.timeline-one.dark .timeline-item:before {
    border: .0625rem solid #9ca3af
}

.timeline.timeline-one.dark .timeline-item:after {
    background-color: #9ca3af
}

.timeline.timeline-two .timeline-item {
    position: relative;
    background-color: #fff;
    padding: 2rem;
    text-align: left;
    margin-bottom: 1.5rem
}

.timeline.timeline-two .timeline-item:after,.timeline.timeline-two .timeline-item:before {
    content: "";
    position: absolute;
    top: 0;
    right: 0
}

.timeline.timeline-two .timeline-item:before {
    border: 4px double #e5e7eb;
    content: "";
    left: auto;
    width: 16px;
    height: 16px;
    margin-right: -2rem;
    border-radius: 50%
}

.timeline.timeline-two .timeline-item:after {
    background-color: #e5e7eb;
    width: .0625rem;
    height: 100%;
    margin-top: 2rem;
    left: auto;
    margin-right: -1.575rem
}

.timeline.timeline-two .timeline-item .post-meta a,.timeline.timeline-two .timeline-item .post-meta span {
    font-size: .875rem;
    padding-right: .125rem
}

.timeline.timeline-four {
    margin: 1.25rem 0;
    position: relative
}

.timeline.timeline-four::before {
    content: "";
    display: block;
    width: .0625rem;
    height: 100%;
    background: #e5e7eb;
    margin: 0 auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0
}

@media (max-width: 575.98px) {
    .timeline.timeline-four::before {
        margin:0;
        left: 0
    }
}

.timeline.timeline-four .timeline-item {
    margin-bottom: 1.5rem;
    position: relative
}

.timeline.timeline-four .timeline-item::after {
    content: "";
    display: block;
    clear: both
}

.timeline.timeline-four .timeline-item:hover .date-outer::before {
    left: 6px
}

.timeline.timeline-four .timeline-item:hover .date-outer::after {
    left: -6px
}

.timeline.timeline-four .timeline-item:nth-child(2n) .date-content {
    float: right
}

.timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
    padding: .625rem 3.125rem .625rem 0;
    text-align: right
}

@media (max-width: 767.98px) {
    .timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
        padding:.5rem 1.75rem .5rem 0
    }
}

@media (max-width: 575.98px) {
    .timeline.timeline-four .timeline-item:nth-child(2n) .timeline-content {
        width:95%;
        padding: .5rem 0;
        text-align: center
    }
}

.timeline.timeline-four .icon {
    width: 16px;
    height: 16px;
    margin: auto;
    position: absolute;
    background-color: #fff;
    border-radius: 50%;
    border: .0625rem solid #e5e7eb;
    margin-top: 1rem;
    top: 0;
    left: 0;
    right: 0
}

@media (max-width: 575.98px) {
    .timeline.timeline-four .icon {
        margin:auto -7px;
        top: 3rem
    }
}

.timeline.timeline-four .date-content {
    width: 50%;
    float: left;
    position: relative
}

@media (max-width: 575.98px) {
    .timeline.timeline-four .date-content {
        width:95%;
        float: right
    }
}

.timeline.timeline-four .date-outer {
    width: 150px;
    height: 150px;
    text-align: center;
    margin: auto;
    z-index: 1
}

.timeline.timeline-four .date-outer::before {
    content: "";
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    background: #fff;
    border: .0625rem solid #893168;
    left: -6px;
    transition: all .9s all .2s ease
}

@media (max-width: 575.98px) {
    .timeline.timeline-four .date-outer::before {
        width:130px;
        height: 130px
    }
}

.timeline.timeline-four .date-outer::after {
    content: "";
    width: 150px;
    height: 150px;
    margin: 0 auto;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    border: .0625rem solid #ff6200;
    left: 6px;
    transition: all .9s all .2s ease
}

@media (max-width: 575.98px) {
    .timeline.timeline-four .date-outer::after {
        width:130px;
        height: 130px
    }
}

.timeline.timeline-four .date {
    width: 100%;
    margin: auto;
    position: absolute;
    top: 40%;
    left: 0
}

@media (max-width: 575.98px) {
    .timeline.timeline-four .date {
        top:27%
    }
}

.timeline.timeline-four .year {
    display: block;
    font-weight: 700;
    font-size: 1rem
}

.timeline.timeline-four .icon {
    font-size: 1.5rem
}

.timeline.timeline-four .timeline-content {
    width: 50%;
    padding: .625rem 0 .625rem 3.125rem;
    float: right
}

@media (max-width: 767.98px) {
    .timeline.timeline-four .timeline-content {
        padding:.5rem 0 .5rem 1.75rem
    }
}

@media (max-width: 575.98px) {
    .timeline.timeline-four .timeline-content {
        width:95%;
        padding: .5rem 0;
        text-align: center
    }
}

.timeline.timeline-five .row [class*=col-] {
    position: relative
}

.timeline.timeline-five .row .avatar-separator {
    width: 30px;
    height: 30px
}

.timeline.timeline-five .row .col.middle-line {
    border-right: .0625rem solid #e5e7eb
}

.timeline.timeline-six .row [class*=col-] {
    position: relative
}

.timeline.timeline-six .row .col.middle-line {
    border-right: .0625rem solid #e5e7eb
}

.timeline.timeline-six .row .avatar-separator img {
    border: .0625rem solid #e5e7eb;
    padding: .125rem
}

.vertical-timeline .row {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.vertical-timeline .row [class*=col-] {
    position: relative
}

.vertical-timeline .row.timeline-inner div {
    padding: 0;
    height: 38px
}

.vertical-timeline .row.timeline-inner hr {
    border-top: .0625rem solid #e5e7eb;
    opacity: 1;
    margin: 0;
    top: 18px;
    position: relative
}

.vertical-timeline .row.timeline-inner .col-md-2 {
    display: flex;
    overflow: hidden
}

.vertical-timeline .row.timeline-inner .corner {
    border: .0625rem solid #e5e7eb;
    width: 100%;
    position: relative
}

.vertical-timeline .row.timeline-inner .top-right {
    left: calc(50% - 1px);
    top: -50%
}

.vertical-timeline .row.timeline-inner .left-bottom {
    left: -50%;
    top: calc(50% - 2px)
}

.vertical-timeline .row.timeline-inner .top-left {
    left: -50%;
    top: -50%
}

.vertical-timeline .row.timeline-inner .right-bottom {
    left: 50%;
    top: calc(50% - 2px)
}

@media (max-width: 575.98px) {
    .vertical-timeline .row.timeline-inner .right-bottom {
        top:auto;
        bottom: calc(50% - 6px);
        left: calc(50% - 4px)
    }

    .vertical-timeline .row.timeline-inner .top-left {
        top: auto;
        bottom: -50%
    }
}

@media (max-width: 575.98px) {
    .vertical-timeline .row .col-md-6 {
        text-align:center!important;
        margin-top: 3rem
    }
}

.vertical-timeline .row .col-md-2 {
    display: inline-flex;
    align-self: stretch;
    align-items: center;
    justify-content: center
}

.vertical-timeline .row .col-md-2.bottom:after {
    height: 50%;
    top: 50%
}

.vertical-timeline .row .col-md-2.full:after {
    height: 100%;
    left: calc(50% - 1px)
}

.vertical-timeline .row .col-md-2.top:after {
    height: 50%;
    left: 50%;
    top: 0
}

.vertical-timeline .row .shape {
    background-color: #e5e7eb;
    width: 50px;
    height: 50px;
    z-index: 2
}

.vertical-timeline .row .right {
    margin-right: .1875rem
}

@media (max-width: 575.98px) {
    .vertical-timeline .row .rectangle {
        line-height:3;
        margin-bottom: 2rem
    }

    .vertical-timeline .row .right {
        margin-bottom: 0
    }
}

.tooltip-inner {
    box-shadow: rgba(0,0,0,.1) 0 4px 6px -1px,rgba(0,0,0,.06) 0 2px 4px -1px
}

.chartist-tooltip {
    position: absolute;
    display: inline-block;
    opacity: 0;
    min-width: 5em;
    padding: .5em;
    background: #1c2540;
    border-radius: 3px;
    color: #fff;
    font-weight: 700;
    text-align: center;
    pointer-events: none;
    z-index: 1;
    transition: all .2s ease
}


.chartist-tooltip:before {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -15px;
    border: 15px solid transparent;
    border-top-color: #1c2540
}

.chartist-tooltip.tooltip-show {
    opacity: 1
}

.ct-area,.ct-line {
    pointer-events: none
}
